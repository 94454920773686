// import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getToken } from "../../utils/CommonUtils";
import axios from "axios";

const config = {
  method: "POST", // *GET, POST, PUT, DELETE, etc.
  cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
  headers: {
    "Content-Type": "application/json",
    "Cross-Origin-Embedder-Policy": false,
    // "Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InRlc3QxQGdtYWlsLmNvbSIsInJvbGUiOiJyZWFsdG9yIiwiaWQiOiI2NTVjMmJiODMwNTU2OWRjYmVmOTM4MzAiLCJpYXQiOjE3MDczMDEzMDMsImV4cCI6MTczODgzNzMwM30.ki56OzG10bYOxk2VBPNgfakWBaTV7IfQItmisWY7wps"
    Authorization: "Bearer ",
  },
  redirect: "follow", // manual, *follow, error
  referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
};

const config2 = {
  method: "POST", // *GET, POST, PUT, DELETE, etc.
  cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
  headers: {
    "Content-Type": "multipart/form-data",
    "Cross-Origin-Embedder-Policy": false,
    Authorization: "Bearer ",
  },
  redirect: "follow", // manual, *follow, error
  referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
};

export const getUsersLocations = createAsyncThunk(
  "user/search",
  async (locationObject, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/user/search`,
        // `https://api.realtorganizer.com/user/search`,
        {
          ...config,
          headers: { ...config.headers, Authorization: "Bearer " + getToken() },
          body: JSON.stringify(locationObject),
        }
      );
      return response.json(); // parses JSON response into native JavaScript objects
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getAppContacts = createAsyncThunk(
  "constacts",
  async (object, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/contact/user`,
        // `https://api.realtorganizer.com/contact/user`,
        {
          ...config,
          method: "GET",
          headers: { ...config.headers, Authorization: "Bearer " + getToken() },
        }
      );
      return response.json(); // parses JSON response into native JavaScript objects
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const saveContact = createAsyncThunk(
  "saveContact",
  async (payload, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append("name", payload?.name);
      formData.append("contactNumber", payload?.contactNumber);
      formData.append("otherContact", payload?.otherContact);
      formData.append("email", payload?.email);
      formData.append("requirement", payload?.requirement);
      formData.append("reminder", payload?.reminder);
      formData.append("additionalNotes", payload?.additionalNotes);
      payload?.files?.forEach((file) => {
        formData.append("files", file);
      });

      // console.log("Testing creating contact: ", payload)

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/contact`,
        // `https://api.realtorganizer.com/contact`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + getToken(),
          },
        }
      );
      return response.data;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteContact = createAsyncThunk(
  "deleteContact",
  async (contactId, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/contact/${contactId}`,
        // `https://api.realtorganizer.com/contact/${contactId}`,
        {
          ...config,
          method: "DELETE",
          headers: { ...config.headers, Authorization: "Bearer " + getToken() },
        }
      );
      return response.json(); // parses JSON response into native JavaScript objects
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateContact = createAsyncThunk(
  "updateContact",
  async (payload, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append("name", payload?.name);
      formData.append("contactNumber", payload?.contactNumber);
      formData.append("otherContact", payload?.otherContact);
      formData.append("requirement", payload?.requirement);
      formData.append("reminder", payload?.reminder);
      formData.append("additionalNotes", payload?.reminderNotes);

      payload?.files?.forEach((file) => {
        formData.append("files", file);
      });

      console.log("Testing updating Contact: ", payload);

      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/contact/${payload?._id}`,
        // `https://api.realtorganizer.com/contact/${payload?._id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + getToken(),
          },
        }
      );

      return response.data;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const createIssue = createAsyncThunk(
  "createIssue",
  async (userIssueData, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/user-issue`,
        // "https://api.realtorganizer.com/user-issue",
        {
          ...config,
          headers: { ...config.headers, Authorization: "Bearer " + getToken() },
          body: JSON.stringify(userIssueData),
        }
      );
      return response.json(); // parses JSON response into native JavaScript objects
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

// export const createContactUs = createAsyncThunk(
//   "createContactUs",
//   async (contactUsData, { rejectWithValue }) => {
//     // console.log("data : ", contactUsData);

//     try {
//       const response = await axios.post(
//         //  `${process.env.REACT_APP_BASE_URL}/contact-us`,
//         "https://api.realtorganizer.com/contact-us",
//         contactUsData
//       );
//       // console.log("Response : ", response.json());
//       return response.json(); // parses JSON response into native JavaScript objects
//     } catch (error) {
//       // return custom error message from backend if present
//       if (error.response && error.response.data.message) {
//         return rejectWithValue(error.response.data.message);
//       } else {
//         return rejectWithValue(error.message);
//       }
//     }
//   }
// );

export const createContactUs = createAsyncThunk(
  "createContactUs",
  async (contactUsData, { rejectWithValue }) => {
    // Log the data being sent
    // console.log("data : ", contactUsData);

    try {
      // Make a POST request to the API endpoint
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/contact-us`,
        // "https://api.realtorganizer.com/contact-us",
        contactUsData
      );

      // Log the response (however, response.json() is not a valid method)
      // console.log("Response : ", response.data);

      // Return the response data (parsed from JSON)
      return response.data;
    } catch (error) {
      // If there's an error response from the server, return the custom error message
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        // Otherwise, return the default error message
        return rejectWithValue(error.message);
      }
    }
  }
);

const renderBodyFormat = (data, type) => {
  const formats = {
    "application/json": () => JSON.stringify(data),
    "multipart/form-data": () => {
      const form = new FormData();
      Object.keys(data).forEach((key) => form.append(key, data[key]));
      return form;
    },
  };
  return formats[type]();
};
