import React, { useState, useEffect } from "react";
import MoveBackIcon from "../../../assets/svgs/LeftArrowIcon";
import {
  addUser,
  removeUser,
} from "../../../api/actions/subscriptionsPlansActions";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "../../../utils/LoadingSpinner";
import { resetError } from "../../../api/reducerSlices/subscriptionsPlansSlice";
import {
  EventSuccessPopup,
  EventFailedPopup,
} from "../../popup-boxes/main-page-Popup's/mainPageEventPopup";
require("./HomeCSS/addUser.css");

const AddUserOverlay = ({
  subsDetailData,
  addUserOverlay,
  subsID,
  removeEmail,
}) => {
  // console.log(subsDetailData);
  const dispatch = useDispatch();
  const { status, error, userAdded, userRemoved } = useSelector(
    (state) => state.subscriptionsPlansSlice
  );
  const [indexForRemoveUser, setIndexForRemoveUser] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupMsg, setShowPopupMsg] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  const [emails, setEmails] = useState([
    subsDetailData.members?.length > 0
      ? subsDetailData?.members?.[0]?.email
      : "",
    subsDetailData.members?.length > 1
      ? subsDetailData?.members?.[1]?.email
      : "",
    subsDetailData.members?.length > 2
      ? subsDetailData?.members?.[2]?.email
      : "",
    subsDetailData.members?.length > 3
      ? subsDetailData?.members?.[3]?.email
      : "",
    subsDetailData.members?.length > 4
      ? subsDetailData?.members?.[4]?.email
      : "",
  ]);

  useEffect(() => {
    const initialEmails =
      subsDetailData.members?.map((member) => member.email) || [];
    // Pad with empty strings to ensure there are at least 5 elements in the array
    const paddedEmails = Array.from(
      { length: 5 },
      (_, index) => initialEmails[index] || ""
    );
    setEmails(paddedEmails);
  }, [subsDetailData !== null]);

  const handleChange = (index, email) => {
    const newEmails = [...emails];
    newEmails[index] = email;
    setEmails(newEmails);
  };

  const handleAddUser = () => {
    const filledEmails = emails.filter((email) => email.trim() !== "");

    if (filledEmails.length === 0) {
      alert("Please fill at least one email field.");
      return;
    }
    // if (!subsDetailData.members || subsDetailData.members.length === 0) {
    //   alert("There are no existing members.");
    //   return;
    // }

    const existingEmails = subsDetailData.members.map((member) => member.email);
    const newEmails = filledEmails.filter(
      (email) => !existingEmails.includes(email)
    );

    if (newEmails?.length === 0) {
      return;
    }

    const emailObject = { id: subsID && subsID, emails: newEmails };
    // console.log(JSON.stringify(emailObject));

    // Call the addUser API
    dispatch(addUser(emailObject));
  };

  useEffect(() => {
    if (userAdded === true) {
      setShowPopupMsg("Member Added!");
      setShowSuccessPopup(true);
      setTimeout(() => {
        setShowSuccessPopup(false);
        setShowPopupMsg("");
      }, 3000);
    }
    if (userRemoved === true) {
      setShowPopupMsg("Member Removed!");
      setShowSuccessPopup(true);
      setTimeout(() => {
        setShowSuccessPopup(false);
        setShowPopupMsg("");
      }, 3000);
    }
    if (error && error !== "") {
      setShowPopupMsg(error);
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
        setShowPopupMsg("");
        dispatch(resetError());
      }, 3000);
    }
  }, [error, userAdded, userRemoved]);

  const isValidEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleRemove = (index) => {
    const emailToRemove = emails[index];
    setIndexForRemoveUser(index);
    // Check if the email field is already empty
    if (!emailToRemove) {
      alert("Field is empty. nothing to remove!");
      return;
    }
    // Call API to remove the email
    if (subsID && subsDetailData.members[index]?._id) {
      let object = {
        subsId: subsID,
        userId: subsDetailData.members[index]?._id,
      };
      dispatch(removeUser(object));
    }
  };

  useEffect(() => {
    if (removeEmail) {
      const newEmails = [...emails];
      newEmails[indexForRemoveUser] = "";
      setEmails(newEmails);
    }
  }, [removeEmail]);

  const closePopup = () => {
    setShowPopup(false);
    setShowPopupMsg("");
  };

  const closeSuccessPopup = () => {
    setShowSuccessPopup(false);
    setShowPopupMsg("");
  };

  return (
    <>
      {status === "loading" && <LoadingSpinner />}
      {showSuccessPopup && (
        <EventSuccessPopup
          showPopupMsg={showPopupMsg}
          onClose={closeSuccessPopup}
        />
      )}
      {showPopup && (
        <EventFailedPopup showPopupMsg={showPopupMsg} onClose={closePopup} />
      )}
      <div className="addUserOverlay">
        <div className="headerLine">
          <div className="moveback" onClick={addUserOverlay}>
            <MoveBackIcon />
          </div>
          <span>Membership</span>
        </div>
        <div className="msgforUserAboutAddUser">
          Add 5 members to your subscription group. You can add members by their
          email address.
        </div>
        {emails.map((email, index) => (
          <div className="rowForadd" key={index}>
            <input
              type="email"
              value={email}
              onChange={(e) => handleChange(index, e.target.value)}
              placeholder="Enter email"
            />
            <button className="removeUser" onClick={() => handleRemove(index)}>
              Remove
            </button>
          </div>
        ))}
        <button className="addmember" onClick={handleAddUser}>
          Add Members
        </button>
      </div>
    </>
  );
};

export default AddUserOverlay;
