import React from "react";

const GoBackToCurrentLocationInMapIcon = () => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.49986 14.9287C12.5118 14.9287 14.9284 12.5356 14.9284 9.52453C14.9284 6.51258 12.5118 4.07153 9.49986 4.07153C6.48791 4.07153 4.07129 6.51258 4.07129 9.52453C4.07129 12.5356 6.48791 14.9287 9.49986 14.9287Z"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.49967 12.6668C11.2486 12.6668 12.6663 11.2491 12.6663 9.50016C12.6663 7.75126 11.2486 6.3335 9.49967 6.3335C7.75077 6.3335 6.33301 7.75126 6.33301 9.50016C6.33301 11.2491 7.75077 12.6668 9.49967 12.6668Z"
        fill="white"
      />
      <path
        d="M2.26172 9.50006H4.07124M14.9284 9.50006H16.7379M9.49981 2.26196V4.07149M9.49981 14.9286V16.7382"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default GoBackToCurrentLocationInMapIcon;
