import React, { useState, useEffect } from "react";
import SearchIcon from "../../../assets/svgs/SearchIcon";
import ContactAddForm from "./ContactAddForm";
import { getAppContacts } from "../../../api/actions/homeActions";
import { useDispatch, useSelector } from "react-redux";
import { resetState } from "../../../api/reducerSlices/homeSlice";
import LoadingSpinner from "../../../utils/LoadingSpinner";
import {
  EventSuccessPopup,
  EventFailedPopup,
} from "../../popup-boxes/main-page-Popup's/mainPageEventPopup";
require("./HomeCSS/Contact.css");
require("./HomeCSS/searchbar.css");

const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener when component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return windowWidth;
};

const truncateMessage = (message, maxLength, windowWidth) => {
  const messageLength = message?.length;

  // Check if the message length is greater than maxLength and the window width is within the specified range
  if (
    (messageLength > maxLength && windowWidth > 768) ||
    (messageLength > maxLength && windowWidth < 440)
  ) {
    // Adjust the substring length based on the maxLength
    const substringLength = maxLength === 15 ? maxLength - 5 : maxLength - 8;
    return `${message.substring(0, substringLength)}...`;
  }

  return message;
};

const scrollToTopOfDiv = (divId) => {
  const targetDiv = document.getElementById(divId);
  if (targetDiv) {
    targetDiv.scrollTop = 0;
  } else {
    console.error(`Div with id '${divId}' not found.`);
  }
};

const Contact = () => {
  const dispatch = useDispatch();
  const {
    status,
    error,
    appContacts,
    contactAdded,
    contactUpdated,
    contactDeleted,
  } = useSelector((state) => state.homeSlice);

  const [contactCount, setContactCount] = useState(
    localStorage.getItem("contactCount") || 0
  );
  const [isContactViewMode, setIsContactViewMode] = useState(
    localStorage.getItem("newcontact") === "true" ||
      localStorage.getItem("contactId")
      ? true
      : false
  );
  const [contacts, setContacts] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);
  const [filteredTiles, setFilteredTiles] = useState([]);
  const [searchQuerysfc, setSearchQuerysfc] = useState("");
  const [contactsCountOnsearch, setContactsCountOnsearch] = useState(0);
  const windowWidth = useWindowWidth();
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupMsg, setShowPopupMsg] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  useEffect(() => {
    dispatch(getAppContacts());
  }, []);

  useEffect(() => {
    setContacts(appContacts);
    setFilteredTiles(appContacts);
    if (
      localStorage.getItem("newcontact") === "true" &&
      localStorage.getItem("neContactSaved") === "true"
    ) {
      const storedNumber = localStorage.getItem("contactCount");
      if (storedNumber !== null && storedNumber < appContacts?.length) {
        const newData = appContacts[appContacts?.length - 1];
        setSelectedContact(newData);
        localStorage.setItem("contactId", newData?._id);
        // console.log("new data: " + newData);
      }
      setIsContactViewMode(true);
    }
    const contactId = localStorage.getItem("contactId");
    // Check if contactId exists in appContacts
    const foundContact = appContacts?.find(
      (contact) => contact._id === contactId
    );
    if (foundContact) {
      setSelectedContact(foundContact);
      setIsContactViewMode(true);
    }
  }, [appContacts, selectedContact]);

  useEffect(() => {
    // console.log(appContacts);
    if (contactDeleted) {
      setShowPopupMsg("Contact deleted!");
      setShowSuccessPopup(true);
      setTimeout(() => {
        setShowSuccessPopup(false);
        setShowPopupMsg("");
        dispatch(resetState());
      }, 3000);
    }
    if (contactAdded || contactUpdated || contactDeleted) {
      dispatch(getAppContacts());
      dispatch(resetState());
    }
  }, [contactAdded, contactUpdated, contactDeleted]);

  useEffect(() => {}, [selectedContact]);

  const handleSearchInputChange = (event) => {
    setSearchQuerysfc(event.target.value);
    if (window.innerWidth > 768) {
      scrollToTopOfDiv("cntList");
    }
  };

  useEffect(() => {
    const filtered = contacts?.filter(
      (contact) =>
        contact?.name
          .toLowerCase()
          .includes(searchQuerysfc.toLowerCase().trim()) ||
        // contact?.email.toLowerCase().includes(searchQuerysfc.toLowerCase()) ||
        contact?.contactNumber
          .toLowerCase()
          .includes(searchQuerysfc.toLowerCase().trim())
    );
    setFilteredTiles(filtered?.length > 0 ? filtered : contacts);
    setContactsCountOnsearch(filtered?.length > 0 ? filtered?.length : 0);
  }, [searchQuerysfc, contacts]);

  const openViewMode = () => {
    setIsContactViewMode(true);
    localStorage.setItem("newcontact", true);
    localStorage.setItem("contactCount", contactCount.toString());
  };

  const goBackToContacts = () => {
    setIsContactViewMode(false);
    if (selectedContact) {
      setSelectedContact(null);
      localStorage.removeItem("contactId");
    }
    if (localStorage.getItem("newcontact") === "true") {
      localStorage.setItem("newcontact", false);
    }
    if (localStorage.getItem("contactCount")) {
      localStorage.removeItem("contactCount");
    }
    if (localStorage.getItem("neContactSaved") === "true") {
      localStorage.setItem("neContactSaved", false);
    }
  };

  const openContactForm = (contact) => {
    setSelectedContact(contact);
    localStorage.setItem("contactId", contact._id);
    setIsContactViewMode(true);
  };

  // Function to truncate data based on length and width
  const truncateMessage = (message, maxLength) => {
    const messageLength = message?.length;

    // Check if the message length is greater than maxLength and the window width is within the specified range
    if (messageLength > maxLength && windowWidth > 768) {
      // Adjust the substring length based on the maxLength
      const substringLength = maxLength === 18 ? 14 : 20;
      return `${message.substring(0, substringLength)}...`;
    }
    if (messageLength > 40 && windowWidth < 440) {
      // Adjust the substring length based on the maxLength
      const substringLength = 35;
      return `${message.substring(0, substringLength)}...`;
    }

    return message;
  };

  const closePopup = () => {
    setShowPopup(false);
    setShowPopupMsg("");
  };

  const closeSuccessPopup = () => {
    setShowSuccessPopup(false);
    setShowPopupMsg("");
  };

  return (
    <>
      {showSuccessPopup && (
        <EventSuccessPopup
          showPopupMsg={showPopupMsg}
          onClose={closeSuccessPopup}
        />
      )}
      {showPopup && (
        <EventFailedPopup showPopupMsg={showPopupMsg} onClose={closePopup} />
      )}
      {status === "loading" && <LoadingSpinner />}
      <div className="contacts">
        {isContactViewMode ? (
          <ContactAddForm
            goBackToContacts={goBackToContacts}
            selectedContact={selectedContact}
          />
        ) : (
          <div className="contactsBox">
            <div className="cntsbc">
              <div className="header">
                {/* <span className="respspan">Contact</span> */}
                <button className="createContact" onClick={openViewMode}>
                  + Create Contact
                </button>
              </div>
              <form role="search" className="searchBarBox">
                <div className="searchIcon">
                  <SearchIcon />
                </div>
                <input
                  id="search"
                  type="search"
                  placeholder="Search..."
                  value={searchQuerysfc}
                  onChange={handleSearchInputChange}
                />
              </form>
              <div className="contactsCountLine">
                <span>Contacts (</span>
                <span className="contactCount">
                  {searchQuerysfc !== "" &&
                    contactsCountOnsearch + " found from "}
                  {filteredTiles && contacts?.length ? contacts?.length : "..."}
                </span>
                <span>)</span>
              </div>
            </div>
            <div className="contactList" id="cntList">
              <div className="contactListHeader">
                <span>Name</span>
                <span>Email</span>
                <span>Phone Number</span>
              </div>
              <div className="addedContact">
                {filteredTiles && filteredTiles.length > 0
                  ? filteredTiles?.map((contact) => (
                      <div
                        key={contact._id}
                        className="contact-box"
                        onClick={() => openContactForm(contact)}
                      >
                        <div className="profile-pic">
                          {typeof contact?.image === "string" &&
                          contact?.image !== "" ? (
                            <>
                              {/* {console.log("image" + contact.image)} */}
                              <img src={contact.image} alt="Profile" />
                            </>
                          ) : (
                            <div className="initials">
                              {contact?.name?.charAt(0).toUpperCase()}
                            </div>
                          )}
                        </div>
                        <div className="contact-details">
                          <span>
                            {/* {contact?.name} */}
                            {truncateMessage(contact?.name, 18)}
                          </span>
                          <span>
                            {/* {contact?.email} */}
                            {truncateMessage(contact?.email, 22)}
                          </span>
                          <span>
                            {contact?.contactNumber}
                            {/* {truncateMessage(
                          contact?.contactNumber,
                          30,
                          window.innerWidth
                        )} */}
                          </span>
                        </div>
                      </div>
                    ))
                  : // <div className="ldtxt">
                    //   {contacts !== null && contacts?.length === 0
                    //     ? " Nothing to caught"
                    //     : " Loading..."}
                    // </div>
                    ""}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Contact;
