import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Artboard_icon from "../../../assets/images/Artboard.png";
import ShowPasswordIcon from "../../../assets/svgs/ShowPasswordIcon";
import HidePasswordIcon from "../../../assets/svgs/HidePasswordIcon";
import LoadingSpinner from "../../../utils/LoadingSpinner";
import "../Css/defaultSignSignUpcss.css";
import "../Css/style2.css";
import { APP_ROUTES } from "../../../utils/AppConstants";
import { resetPassword } from "../../../api/actions/authActions";
import {
  PasswordResetSuccessPopup,
  PasswordResetFailedPopup,
} from "../../popup-boxes/auth-Popup's/authEventPopup";

const ResetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { status, error, resetPass } = useSelector((state) => state.auth);

  const [formData, setFormData] = useState({
    email: "", // Add the missing email field
    password: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupMsg, setShowPopupMsg] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  useEffect(() => {
    if (resetPass === true) {
      navigate(APP_ROUTES.SIGN_IN_ROUTE);
      setTimeout(() => {
        setShowSuccessPopup(false);
        navigate(APP_ROUTES.SIGN_IN_ROUTE);
      }, 3000);
    } else if (status === "failed" && error !== "") {
      setShowPopupMsg(error);
      setShowPopup(true);
    }
  }, [status, error, navigate, resetPass]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });

    // Clear the error when the user starts typing
    setErrors({
      ...errors,
      [e.target.id]: "",
    });
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newErrors = {};
    if (!state.userEmail.trim()) {
      newErrors.email = "Please enter your email address";
    }
    if (!formData.password.trim()) {
      newErrors.password = "Password is required";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const dataToSend = {
      email: state.userEmail, // Use the correct field name
      password: formData.password.trim(),
      otp: state.userOtp,
    };

    dispatch(resetPassword(dataToSend));
  };

  const closePopup = () => {
    setShowPopup(false);
    setShowPopupMsg("");
  };

  const closeSuccessPopup = () => {
    setShowSuccessPopup(false);
  };

  return (
    <>
      {status === "loading" && <LoadingSpinner />}
      {showSuccessPopup && (
        <PasswordResetSuccessPopup onClose={closeSuccessPopup} />
      )}
      {showPopup && (
        <PasswordResetFailedPopup
          showPopupMsg={showPopupMsg}
          onClose={closePopup}
        />
      )}
      <div className="container">
        <div className="artboard">
          <div className="artboardPic">
            <img src={Artboard_icon} alt="" />
          </div>
          <span>
            <strong>REALTOR</strong> ORGANIZER
          </span>
        </div>
        <div className="box bxcf">
          <form onSubmit={handleSubmit} className="fm">
            <span>Reset Password</span>
            <div className="input-group fc">
              <label htmlFor="email">Email Address:</label>
              <input
                disabled={true}
                type="email"
                id="email"
                placeholder={state?.userEmail}
                value={state?.userEmail}
                onChange={handleChange}
                autoFocus
              />
              {errors.email && <span className="error">{errors?.email}</span>}
            </div>
            <div className="input-group sc">
              <label htmlFor="password">Password:</label>
              <div className="password-input pos-rel">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  className="set"
                  placeholder="**************"
                  value={formData.password}
                  onChange={handleChange}
                  autoFocus
                />
                <button
                  type="button"
                  onClick={() => handleTogglePassword("password")}
                  className="password-toggle btn t-set"
                >
                  {showPassword ? <ShowPasswordIcon /> : <HidePasswordIcon />}
                </button>
              </div>
              {errors.password && (
                <span className="error">{errors.password}</span>
              )}
            </div>
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
    </>
  );
};
export default ResetPassword;
