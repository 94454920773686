import React from "react";
import ClockIcon from "../../../assets/svgs/ClockIcon";
require("./HomeCSS/notificationItems.css");

const NotificationItem = ({ notification, onViewFullContent }) => {
  const renderLastsendTime = (data) => {
    const lastSendAt = new Date(data);
    const timeOptions = { hour: "numeric", minute: "numeric" };
    return lastSendAt.toLocaleTimeString([], timeOptions);
  };

  return (
    <li
      className="notification-item"
      key={notification._id}
      onClick={() => {
        onViewFullContent(notification);
      }}
    >
      {/* {console.log(notification)} */}
      <div className="notification-content">
        {notification?.type === "userNotification" && (
          <>
            {notification?.data?.from?.image ? (
              <div className="profile-pic">
                <img src={notification?.data?.from?.image} alt="" />
              </div>
            ) : (
              <div className="initials">
                {notification?.data?.from?.name?.charAt(0)?.toUpperCase()}
              </div>
            )}
          </>
        )}
        {notification?.type === "contactAdded" && (
          <>
            {notification?.data?.form?.image ? (
              <div className="profile-pic">
                <img src={notification?.data?.form?.image} alt="" />
              </div>
            ) : (
              <>
                {notification?.data?.from?.image ? (
                  <div className="profile-pic">
                    <img src={notification?.data?.from?.image} alt="" />
                  </div>
                ) : (
                  <div className="initials">
                    {notification?.data?.form?.name?.charAt(0)?.toUpperCase()}
                  </div>
                )}
              </>
            )}
          </>
        )}
        {notification?.type === "propertyReminder" && (
          <>
            <div className="noti-image">
              <ClockIcon />
            </div>
          </>
        )}
        <div className="notification-details">
          {notification?.type === "userNotification" && (
            <>
              {notification?.data?.from?.name && (
                <div className="nameOnTile">
                  {notification?.data?.from?.name}
                </div>
              )}
            </>
          )}
          {notification?.type === "contactAdded" && (
            <>
              {notification?.data?.from?.name && (
                <div className="nameOnTile">
                  {notification?.data?.from?.name}
                </div>
              )}
              {notification?.data?.form?.name && (
                <div className="nameOnTile">
                  {notification?.data?.form?.name}
                </div>
              )}
            </>
          )}
          <div
            className={`notification-title ${
              notification?.type === "propertyReminder" ? "nameOnTile" : ""
            }`}
          >
            {notification?.message}
          </div>
        </div>
        <div className="noti-receivedTime">
          {renderLastsendTime(notification?.updatedAt)}
        </div>
      </div>
    </li>
  );
};

export default NotificationItem;
