import React, { useState, useRef, useEffect } from "react";
import MoveBackIcon from "../../../assets/svgs/LeftArrowIcon";
import PlusBoxIcon from "../../../assets/svgs/PlusBoxIcon";
import ReminderBox from "../../ShowBox/reminderBox";
import {
  deleteContact,
  saveContact,
  updateContact,
} from "../../../api/actions/homeActions";
import { resetState } from "../../../api/reducerSlices/homeSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  EventSuccessPopup,
  EventFailedPopup,
} from "../../popup-boxes/main-page-Popup's/mainPageEventPopup";
require("./HomeCSS/ContactAddForm.css");

const ContactAddForm = ({ goBackToContacts, selectedContact }) => {
  const dispatch = useDispatch();
  const { status, error, contactAdded, contactUpdated } = useSelector(
    (state) => state.homeSlice
  );
  const [isEditAbleContact, setIsEditAbleContact] = useState(true);
  const [isViewContact, setIsViewContact] = useState(
    localStorage.getItem("contactId") ? true : false
  );
  const [validationErrors, setValidationErrors] = useState({});
  const fileInputRef = useRef(null);
  const [reminderInfo, setReminderInfo] = useState({});
  const [isReminderBoxOpen, setIsReminderBoxOpen] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupMsg, setShowPopupMsg] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  useEffect(() => {
    if (
      (selectedContact !== null &&
        localStorage.getItem("newcontact") === "false") ||
      (localStorage.getItem("contactId") &&
        localStorage.getItem("newcontact") === "false")
    ) {
      setIsViewContact(true);
      setIsEditAbleContact(false);
      setFormData({
        name: selectedContact ? selectedContact.name : "",
        contactNumber: selectedContact ? selectedContact.contactNumber : "",
        otherContact: selectedContact ? selectedContact.otherContact : "",
        email: selectedContact ? selectedContact.email : "",
        requirement: selectedContact ? selectedContact.requirement : "",
        reminder: selectedContact ? selectedContact?.reminder : null,
        additionalNotes: selectedContact
          ? selectedContact.additionalNotes
          : null,
        files: selectedContact ? selectedContact.image : null,
        reminder: selectedContact ? selectedContact?.reminder : "",
        additionalNotes: selectedContact ? selectedContact.additionalNotes : "",
        files: selectedContact ? selectedContact.image : "",
      });
    }
    // else if (localStorage.getItem("newcontact") === "true") {
    //   setIsEditAbleContact(true);
    // }
    if (contactAdded === true) {
      setShowPopupMsg("Contact created!");
      setShowSuccessPopup(true);
      setTimeout(() => {
        setShowSuccessPopup(false);
        setShowPopupMsg("");
        dispatch(resetState());
      }, 3000);
      setIsViewContact(true);
      setIsEditAbleContact(false);
    }
    if (contactUpdated === true) {
      setShowPopupMsg("Contact updated!");
      setShowSuccessPopup(true);
      setTimeout(() => {
        setShowSuccessPopup(false);
        setShowPopupMsg("");
        dispatch(resetState());
      }, 3000);
    }
    if (error) {
      setShowPopupMsg(error);
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
        setShowPopupMsg("");
        dispatch(resetState());
      }, 3000);

      localStorage.setItem("neContactSaved", false);
    }
  }, [status, error, selectedContact]);

  const [formData, setFormData] = useState({
    name: selectedContact ? selectedContact.name : "",
    contactNumber: selectedContact ? selectedContact.contactNumber : "",
    otherContact: selectedContact ? selectedContact.otherContact : "",
    email: selectedContact ? selectedContact.email : "",
    requirement: selectedContact ? selectedContact.requirement : "",
    reminder: selectedContact ? selectedContact?.reminder : null,
    additionalNotes: selectedContact ? selectedContact.additionalNotes : null,
    files: selectedContact ? selectedContact.image : null,
    reminder: selectedContact ? selectedContact?.reminder : "",
    additionalNotes: selectedContact ? selectedContact.additionalNotes : "",
    files: selectedContact ? selectedContact.image : "",
  });

  const handleChange = (event) => {
    const fieldName = event.target.name;
    setFormData({ ...formData, [fieldName]: event.target.value });
    setValidationErrors((prevErrors) => {
      return { ...prevErrors, [fieldName]: undefined };
    });
  };

  const handleProfilePicChange = (event) => {
    const file = event.target.files[0];
    setFormData({ ...formData, files: file });
  };

  const handleSave = (event) => {
    event.preventDefault();

    // Validation logic (example)
    const errors = {};
    if (!formData.name) errors.name = "Name is required";
    if (!formData.contactNumber) errors.contactNumber = "Contact 1 is required";
    // if (!formData.email) errors.email = "Email is required";
    // if (!formData.requirement) errors.requirement = "Requirment is required";

    setValidationErrors(errors);

    if (Object.keys(errors).length === 0) {
      const dataToSend = {
        name: formData?.name?.trim(),
        contactNumber: formData?.contactNumber?.trim(),
        otherContact: formData?.otherContact?.trim(),
        email: formData?.email?.trim(),
        requirement: formData?.requirement?.trim(),
        reminder: formData?.reminder ? formData?.reminder : "",
        additionalNotes: formData?.additionalNotes
          ? formData?.additionalNotes
          : "",
      };

      if (selectedContact !== null) {
        if (
          formData?.files !== undefined &&
          typeof formData?.files !== "string"
        ) {
          dataToSend.files = [formData.files];
        }
        dataToSend._id = [selectedContact?._id];
        dispatch(updateContact(dataToSend));
        // updatedStatus();
      } else {
        dataToSend.files = [formData.files];
        dispatch(saveContact(dataToSend));
        localStorage.setItem("neContactSaved", true);
        // setIsViewContact(true);
        // setIsEditAbleContact(false);
      }
    }
  };

  const handleDeleteCatalog = () => {
    if (selectedContact?._id) {
      dispatch(deleteContact(selectedContact?._id));
      console.log("new success: ");
      goBackToContacts();
    }
  };

  const saveReminderInfoClicked = (reminderObj) => {
    formData.reminder = reminderObj.reminder;
    formData.additionalNotes = reminderObj.reminderNotes;
  };

  const handlePlusClick = () => {
    // Trigger a click event on the file input when plus is clicked
    fileInputRef.current.click();
  };

  const handleReminderBox = () => {
    setIsReminderBoxOpen(true);
  };

  const handleReminderBoxClose = () => {
    setIsReminderBoxOpen(false);
  };

  const goBackToContactPage = () => {
    goBackToContacts();
  };

  const closePopup = () => {
    setShowPopup(false);
    setShowPopupMsg("");
  };

  const closeSuccessPopup = () => {
    setShowSuccessPopup(false);
    setShowPopupMsg("");
  };

  return (
    <>
      {showSuccessPopup && (
        <EventSuccessPopup
          showPopupMsg={showPopupMsg}
          onClose={closeSuccessPopup}
        />
      )}
      {showPopup && (
        <EventFailedPopup showPopupMsg={showPopupMsg} onClose={closePopup} />
      )}
      <div className="contact-form">
        <div className="form">
          <div className="header">
            <div className="moveback" onClick={goBackToContactPage}>
              <MoveBackIcon />
            </div>
            <span className="respspan">Contact</span>
            <div className="contactFormButtons">
              {isEditAbleContact || !selectedContact ? (
                <button onClick={status === "loading" ? null : handleSave}>
                  {selectedContact ? "Update" : "Save"}
                </button>
              ) : (
                <button onClick={() => setIsEditAbleContact(true)}>Edit</button>
              )}
              {isViewContact && (
                <button
                  className="deleteButton btn"
                  onClick={handleDeleteCatalog}
                >
                  Delete
                </button>
              )}
            </div>
          </div>
          <div
            className="profile-pic-container"
            onClick={isEditAbleContact ? handlePlusClick : null}
            style={isEditAbleContact ? {} : { cursor: "auto" }}
          >
            {formData?.files !== null &&
            typeof formData?.files === "object" &&
            formData?.files instanceof File ? (
              <img
                src={URL.createObjectURL(formData?.files)}
                alt="Profile"
                // onError={() => handleImageError()}
              />
            ) : formData?.files !== null &&
              formData?.files !== undefined &&
              formData?.files !== "" ? (
              <img src={formData?.files} alt="Profile" />
            ) : (
              // <div className="image-not-loaded-msg">Image not loaded</div>
              ""
            )}
            {isEditAbleContact && <PlusBoxIcon />}
          </div>

          {isEditAbleContact && (
            <input
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              ref={fileInputRef}
              onChange={handleProfilePicChange}
            />
          )}
          <div className="inputGroupLine">
            <div className="inputGroup">
              <label>Name:</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className={validationErrors?.name ? "error" : ""}
                disabled={!isEditAbleContact}
              />
              {validationErrors.name && (
                <p className="error-message">{validationErrors.name}</p>
              )}
            </div>
            <div className="inputGroup">
              <label>Contact 1:</label>
              <input
                type="text"
                name="contactNumber"
                value={formData.contactNumber}
                onChange={handleChange}
                className={validationErrors.contactNumber ? "error" : ""}
                disabled={!isEditAbleContact}
              />
              {validationErrors.contactNumber && (
                <p className="error-message">
                  {validationErrors?.contactNumber}
                </p>
              )}
            </div>
          </div>
          <div className="inputGroupLine">
            <div className="inputGroup">
              <div className="labelline">
                <label>Contact 2:</label>
                <div className="optionaltag">(optional)</div>
              </div>
              <input
                type="text"
                name="otherContact"
                value={
                  formData?.otherContact !== "undefined"
                    ? formData?.otherContact
                    : ""
                }
                onChange={handleChange}
                disabled={!isEditAbleContact}
              />
            </div>
            <div className="inputGroup">
              <label>Email:</label>
              <input
                type="email"
                name="email"
                value={formData?.email}
                onChange={handleChange}
                className={validationErrors.email ? "error" : ""}
                disabled={!isEditAbleContact}
              />
              {validationErrors.email && (
                <p className="error-message">{validationErrors.email}</p>
              )}
            </div>
          </div>
          <div className="textArea">
            <label htmlFor="textarea">Requirement:</label>
            <textarea
              name="requirement"
              id="requirement"
              value={
                formData?.requirement !== "undefined"
                  ? formData?.requirement
                  : ""
              }
              onChange={handleChange}
              className={validationErrors.requirement ? "error" : ""}
              disabled={!isEditAbleContact}
            ></textarea>
            {validationErrors.requirement && (
              <p className="error-message">{validationErrors.requirement}</p>
            )}
          </div>
        </div>

        <div className="reminderBox">
          <>
            <span>Reminder</span>
            <label
              style={{
                fontSize: "clamp(12px, 2vw, 16px)",
                padding: "0 10px",
              }}
            >
              {formData?.reminder}
            </label>
          </>
          <button
            onClick={handleReminderBox}
            style={isEditAbleContact ? {} : { pointerEvents: "none" }}
          >
            <PlusBoxIcon />
          </button>
        </div>
      </div>
      {isReminderBoxOpen && (
        <ReminderBox
          closeReminderBox={handleReminderBoxClose}
          saveReminderInfo={(reminderObj) => {
            saveReminderInfoClicked(reminderObj);
          }}
          reminderInfo={{
            reminder: formData?.reminder,
            reminderNotes: formData?.additionalNotes,
          }}
        />
      )}
    </>
  );
};

export default ContactAddForm;
