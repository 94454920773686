// import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getToken } from "../../utils/CommonUtils";
import axios from "axios";

const config = {
  method: "POST", // *GET, POST, PUT, DELETE, etc.
  cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
  headers: {
    "Content-Type": "application/json",
    "Cross-Origin-Embedder-Policy": false,
    // "Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InRlc3QxQGdtYWlsLmNvbSIsInJvbGUiOiJyZWFsdG9yIiwiaWQiOiI2NTVjMmJiODMwNTU2OWRjYmVmOTM4MzAiLCJpYXQiOjE3MDczMDEzMDMsImV4cCI6MTczODgzNzMwM30.ki56OzG10bYOxk2VBPNgfakWBaTV7IfQItmisWY7wps"
    Authorization: "Bearer ",
  },
  redirect: "follow", // manual, *follow, error
  referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
};

const config2 = {
  method: "POST", // *GET, POST, PUT, DELETE, etc.
  cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
  headers: {
    // "Content-Type": "multipart/form-data",
    "Cross-Origin-Embedder-Policy": false,
    Authorization: "Bearer ",
  },
  redirect: "follow", // manual, *follow, error
  referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
};

export const getChats = createAsyncThunk(
  "getChats",
  async (object, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/groupchat`,
        // "https://api.realtorganizer.com/groupchat",
        {
          ...config,
          method: "GET",
          headers: { ...config.headers, Authorization: "Bearer " + getToken() },
        }
      );
      return response.json(); // parses JSON response into native JavaScript objects
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getChatDetails = createAsyncThunk(
  "getChatDetails",
  async (chatId, { rejectWithValue }) => {
    try {
      // console.log("chatId: ", chatId)
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/groupchat/${chatId}`,
        // `https://api.realtorganizer.com/groupchat/${chatId}`,
        {
          ...config,
          method: "GET",
          headers: { ...config.headers, Authorization: "Bearer " + getToken() },
        }
      );
      return response.json(); // parses JSON response into native JavaScript objects
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const sendMessage = createAsyncThunk(
  "sendMessage",
  async (formData, { rejectWithValue }) => {
    try {
      // console.log(JSON.stringify(formData));
      const { chatId, message, files } = formData;
      const formDataToSend = new FormData();
      formDataToSend.append("message", message);

      // Append each file to the formData
      if (files !== null) {
        formDataToSend.append(`files`, files);
      }

      // console.log(formDataToSend);

      // Log formDataToSend just before making the fetch request
      // console.log("final" + JSON.stringify(formDataToSend));

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/groupchat/message/${chatId}`,
        // `https://api.realtorganizer.com/groupchat/message/${chatId}`,
        {
          ...config2,
          headers: {
            ...config2.headers,
            Authorization: "Bearer " + getToken(),
          },
          body: formDataToSend,
        }
      );
      return response.json(); // parses JSON response into native JavaScript objects
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const createThreadId = createAsyncThunk(
  "createThreadId",
  async (data, { rejectWithValue }) => {
    // console.log(data);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/groupchat`,
        // `https://api.realtorganizer.com/groupchat`,
        {
          ...config,
          method: "POST",
          headers: { ...config.headers, Authorization: "Bearer " + getToken() },
          body: JSON.stringify(data),
        }
      );
      return response.json(); // parses JSON response into native JavaScript objects
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getCatalogDetail = createAsyncThunk(
  "getCatalogDetail",
  async (catalogId, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/catalog/${catalogId}`,
        // `https://api.realtorganizer.com/catalog/${catalogId}`,
        {
          ...config,
          method: "GET",
          headers: { ...config.headers, Authorization: "Bearer " + getToken() },
        }
      );
      return response.json(); // parses JSON response into native JavaScript objects
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getPropertyDetail = createAsyncThunk(
  "getPropertyDetail",
  async (data, { rejectWithValue }) => {
    const { perpertyId } = data;
    const { sections } = data;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/property/${perpertyId}?type=${sections}`,
        // `https://api.realtorganizer.com/property/${perpertyId}?type=${sections}`,
        {
          ...config,
          method: "GET",
          headers: { ...config.headers, Authorization: "Bearer " + getToken() },
        }
      );
      return response.json(); // parses JSON response into native JavaScript objects
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteChat = createAsyncThunk(
  "deleteChat",
  async (chatId, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/groupchat/${chatId}`,
        // `https://api.realtorganizer.com/groupchat/${chatId}`,
        {
          ...config,
          method: "DELETE",
          headers: { ...config.headers, Authorization: "Bearer " + getToken() },
        }
      );
      return response.json(); // parses JSON response into native JavaScript objects
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const renderBodyFormat = (data, type) => {
  const formats = {
    "application/json": () => JSON.stringify(data),
    "multipart/form-data": () => {
      const form = new FormData();
      Object.keys(data).forEach((key) => form.append(key, data[key]));
      return form;
    },
  };
  return formats[type]();
};
