import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../Css/defaultSignSignUpcss.css";
import "../Css/forgot.css";
import Artboard_icon from "../../../assets/images/Artboard.png";
import { APP_ROUTES } from "../../../utils/AppConstants";
import { useSelector } from "react-redux";
// import { forgotPassword, resendOTP } from "../../../api/actions/authActions";
import { resendOTP } from "../../../api/actions/authActions";
import { useDispatch } from "react-redux";
import LoadingSpinner from "../../../utils/LoadingSpinner";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    email: "",
  });

  const [errors, setErrors] = useState({});

  // const { status, error, authenticated, user } = useSelector(
  //   (state) => state.auth
  // );
  const { status, error } = useSelector((state) => state.auth);

  useEffect(() => {
    if (status === "succeeded") {
      navigate(APP_ROUTES.VERIFY_OTP_ROUTE, {
        state: {
          navigateToRoute: APP_ROUTES.RESET_PASSWORD_ROUTE,
          userEmail: formData.email.trim(),
        },
      });
    } else if (status === "failed" && error !== "") {
      alert(error);
    }
  }, [status, error, formData.email, navigate]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });

    // Clear the error when the user starts typing
    setErrors({
      ...errors,
      [e.target.id]: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newErrors = {};
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const dataToSend = {
      email: formData.email.trim(),
    };

    dispatch(resendOTP(dataToSend));
  };

  return (
    <>
      {status === "loading" && <LoadingSpinner />}
      <div className="container">
        <div className="artboard">
          <div className="artboardPic">
            <img src={Artboard_icon} alt="" />
          </div>
          <span>
            <strong>REALTOR</strong> ORGANIZER
          </span>
        </div>
        <div className="box bx1">
          <form onSubmit={handleSubmit}>
            <h2>FORGOT PASSWORD</h2>
            <div className="input-group fc">
              <label htmlFor="email">Email Address:</label>
              <input
                type="email"
                id="email"
                placeholder="example@gmail.com"
                value={formData.email}
                onChange={handleChange}
              />
              {errors.email && <span className="error">{errors.email}</span>}
            </div>
            <button type="submit" disabled={status === "loading"}>
              {status === "loading" ? "Loading..." : "CONTINUE"}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
