import React from "react";

const ChevronRightIcon = () => {
  return (
    <svg
      width="10"
      height="15"
      viewBox="0 0 10 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.83551 8.46426C6.52945 8.10391 6.64272 7.15793 6.05348 6.64392L0.870563 2.12285C0.40892 1.72016 0.406082 1.00353 0.86452 0.597198C1.24905 0.25637 1.82754 0.256371 2.21207 0.597198L8.83846 6.47047C9.45606 7.01789 9.45607 7.98211 8.83846 8.52953L2.21509 14.4001C1.82884 14.7425 1.24775 14.7425 0.861496 14.4001C0.403427 13.9941 0.403427 13.279 0.861496 12.873L5.83551 8.46426Z"
        fill="#373737"
      />
    </svg>
  );
};

export default ChevronRightIcon;
