import React from "react";

const ContactIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.00976562 3.07982C0.00976562 1.35982 1.39919 -0.000179581 3.14846 0.00982042C4.81776 0.00982042 6.20718 1.41982 6.20718 3.09982C6.20718 4.80982 4.80777 6.20982 3.10848 6.20982C1.38919 6.20982 0.00976562 4.81982 0.00976562 3.08982V3.07982ZM3.11847 4.95982C4.16803 4.93982 4.98769 4.09982 4.9577 3.05982C4.93771 2.00982 4.07807 1.18982 3.02851 1.21982C2.00893 1.24982 1.18927 2.11982 1.21926 3.15982C1.24925 4.15982 2.10889 4.97982 3.10848 4.95982H3.11847Z"
        fill="white"
      />
      <path
        d="M11.985 23.9998C10.2757 23.9998 8.89631 22.6098 8.9063 20.8898C8.9063 19.1798 10.3057 17.7998 12.015 17.7998C13.7143 17.7998 15.1037 19.2098 15.1037 20.9098C15.1037 22.6198 13.7143 23.9998 11.995 23.9898L11.985 23.9998ZM13.8742 20.9198C13.8742 19.8898 13.0346 19.0398 12.005 19.0398C10.9854 19.0398 10.1258 19.8898 10.1258 20.9098C10.1258 21.9198 10.9754 22.7698 11.985 22.7798C13.0046 22.7898 13.8742 21.9398 13.8742 20.9198Z"
        fill="white"
      />
      <path
        d="M15.1035 12.0104C15.1035 13.7204 13.7141 15.1004 11.9948 15.1004C10.3055 15.1004 8.90607 13.6904 8.91607 12.0004C8.91607 10.2804 10.3055 8.90039 12.0148 8.90039C13.7241 8.90039 15.1135 10.2904 15.1035 12.0104ZM13.884 12.0104C13.884 11.0004 13.0443 10.1504 12.0348 10.1304C11.0052 10.1204 10.1356 10.9804 10.1356 12.0204C10.1356 13.0304 10.9852 13.8804 12.0048 13.8804C13.0244 13.8804 13.884 13.0304 13.884 12.0104Z"
        fill="white"
      />
      <path
        d="M24.0002 3.09C24.0002 4.8 22.6308 6.19 20.9115 6.2C19.2322 6.2 17.8128 4.8 17.8028 3.13C17.7928 1.41 19.1722 0 20.8715 0C22.6108 0 23.9902 1.35 23.9902 3.09H24.0002ZM19.0423 3.09C19.0223 4.11 19.8619 4.97 20.9015 4.98C21.9011 4.99 22.7607 4.17 22.7807 3.17C22.8107 2.14 21.991 1.27 20.9615 1.24C19.9319 1.21 19.0723 2.04 19.0423 3.1V3.09Z"
        fill="white"
      />
      <path
        d="M23.9904 12.0004C23.9904 13.7204 22.601 15.1004 20.8917 15.1004C19.1824 15.1004 17.793 13.7004 17.793 12.0004C17.793 10.2804 19.1924 8.90039 20.9217 8.90039C22.611 8.90039 23.9904 10.3004 23.9904 12.0004ZM22.7709 11.9904C22.7709 10.9704 21.9013 10.1104 20.8817 10.1204C19.8621 10.1204 19.0325 10.9704 19.0225 11.9904C19.0225 13.0104 19.8721 13.8804 20.8917 13.8704C21.9213 13.8704 22.7609 13.0204 22.7609 11.9904H22.7709Z"
        fill="white"
      />
      <path
        d="M12.0152 0C13.7445 0 15.1039 1.4 15.094 3.14C15.084 4.82 13.6646 6.21 11.9653 6.2C10.276 6.2 8.88654 4.77 8.89654 3.06C8.91653 1.36 10.3059 0 12.0152 0ZM13.8745 3.1C13.8745 2.09 13.0448 1.23 12.0252 1.22C10.9957 1.21 10.126 2.06 10.126 3.1C10.126 4.11 10.9557 4.95 11.9853 4.97C13.0048 4.99 13.8645 4.14 13.8745 3.11V3.1Z"
        fill="white"
      />
      <path
        d="M0.00999606 12.0004C0.00999606 10.2904 1.38942 8.91039 3.10871 8.90039C4.798 8.90039 6.20741 10.3104 6.19742 11.9904C6.19742 13.7004 4.798 15.1004 3.09871 15.1004C1.37942 15.1004 0.00999583 13.7204 0 12.0004H0.00999606ZM4.96793 12.0204C4.98792 11.0104 4.16826 10.1604 3.13869 10.1304C2.13911 10.1004 1.25947 10.9404 1.22949 11.9504C1.2095 12.9904 2.03915 13.8604 3.07872 13.8804C4.09829 13.9004 4.94794 13.0704 4.96793 12.0204Z"
        fill="white"
      />
    </svg>
  );
};

export default ContactIcon;