import React, { useState, useEffect, useCallback } from "react";
// import "./HomeCSS/outgoingNotifications.css";
import MoveBackIcon from "../../../assets/svgs/LeftArrowIcon";
import CreateCustomNotification from "./CreateCustomNotification";
import SendNotification from "./SendNotification";
import { getUserNotifications } from "../../../api/actions/notificationActions";
import {
  resetState,
  resetStateAddNewNotificationData,
} from "../../../api/reducerSlices/notificationSlice";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "../../../utils/LoadingSpinner";
import {
  EventSuccessPopup,
  EventFailedPopup,
} from "../../popup-boxes/main-page-Popup's/mainPageEventPopup";
require("./HomeCSS/outgoingNotifications.css");

const OutgoingNotifications = ({
  searchQuerysfp,
  forSearchBarHide,
  forSearchBarShow,
}) => {
  const {
    status,
    error,
    userNotifications,
    childAdded,
    wholeNotificationDelete,
  } = useSelector((state) => state.notificationSlice);
  const dispatch = useDispatch();
  const [notifications, setNotifications] = useState([]);
  const [isCreateNewCustomNotification, setIsCreateNewCustomNotification] =
    useState(false);
  const [isSendNewCustomNotification, setIsSendNewCustomNotification] =
    useState(false);
  const [listData, setListData] = useState([]);
  const [
    selectedTileselectedNotificationId,
    setSelectedTileselectedNotificationId,
  ] = useState(null);
  const [isAddingNewToSend, setIsAddingNewToSend] = useState(false);
  const [tileUpdated, setTileUpdated] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const [isClearnoti, setIsClearnoti] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [classfr, setClassfr] = useState(false);
  const [filteredTiles, setFilteredTiles] = useState([]);
  const [isOnFullPage, setIsOnFullPage] = useState(false);
  const [headerTextShow, setHeaderTextShow] = useState(true);
  const [loaderset, setLoaderset] = useState(false);
  const [fetchNewIdForChild, setFetchNewIdForChild] = useState(null);
  const [fetchNewIdForChildPass, setFetchNewIdForChildPass] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupMsg, setShowPopupMsg] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  useEffect(() => {
    if (!isClearnoti && userNotifications !== null) {
      dispatch(resetState());
      setIsClearnoti(true);
    }
    const dataToSend = {
      userId: user?._id,
    };
    dispatch(getUserNotifications(dataToSend));
    setNotifications(userNotifications);
  }, [userNotifications]);

  useEffect(() => {
    if (fetchNewIdForChildPass === false) {
      localStorage.setItem(
        "userNotifications",
        JSON.stringify(userNotifications)
      );
    }
    setNotifications(userNotifications);
  }, [userNotifications !== null]);

  useEffect(() => {
    if (
      (listData && tileUpdated) ||
      (listData && isSendNewCustomNotification)
    ) {
      const foundNotification =
        Array.isArray(userNotifications) !== null &&
        userNotifications?.length > 0 &&
        userNotifications?.find(
          (notification) => notification?._id === listData?._id
        );
      if (foundNotification) {
        setListData(foundNotification);
      }
    }
    if (isClearnoti && userNotifications != null) {
      setNotifications(userNotifications);
      dispatch(resetState());
    }
    //  else if (error) {
    //   alert(error);
    //   dispatch(resetState());
    // }
  }, [
    status,
    error,
    userNotifications !== null,
    tileUpdated,
    listData,
    isSendNewCustomNotification,
  ]);

  useEffect(() => {
    if (childAdded === true) {
      setShowPopupMsg("Notification added!");
      setShowSuccessPopup(true);
      setTimeout(() => {
        setShowSuccessPopup(false);
        setShowPopupMsg("");
        dispatch(resetState());
      }, 3000);
    }
    if (wholeNotificationDelete === true) {
      setShowPopupMsg("Notification deleted!");
      setShowSuccessPopup(true);
      setTimeout(() => {
        setShowSuccessPopup(false);
        setShowPopupMsg("");
        dispatch(resetState());
      }, 3000);
    }
    if (error && error !== "") {
      setShowPopupMsg(error);
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
        setShowPopupMsg("");
        dispatch(resetState());
      }, 3000);
    }
  }, [error, childAdded, wholeNotificationDelete]);

  const CustomNotificationPage = () => {
    setIsSendNewCustomNotification(false);
    setIsCreateNewCustomNotification(true);
    handleForFullPage();
    forSearchBarHide();
  };

  const oneStepBack = () => {
    if (isAddingNewToSend) {
      if (isCreateNewCustomNotification) {
        setIsCreateNewCustomNotification(false);
        setTileUpdated(true);
        setIsSendNewCustomNotification(true);
        if (selectedTileselectedNotificationId !== null) {
          setSelectedTileselectedNotificationId(null);
        }
        return;
      }
      setIsSendNewCustomNotification(false);
      setIsAddingNewToSend(false);
      setIsOnFullPage(false);
      setListData(null);
      setTileUpdated(true);
      setLoaderset(false);
      forSearchBarShow();
    } else {
      setIsOnFullPage(false);
      if (isCreateNewCustomNotification) {
        setFetchNewIdForChild(null);
        setIsCreateNewCustomNotification(false);
        dispatch(resetStateAddNewNotificationData());
      } else if (isSendNewCustomNotification) {
        setIsSendNewCustomNotification(false);
        setListData(null);
      }
      setTileUpdated(true);
      setLoaderset(false);
      forSearchBarShow();
    }
  };

  const selectNotificationOpen = (selectedListData) => {
    const data = {
      ...selectedListData,
    };
    // console.log("noti " + JSON.stringify(data));
    forSearchBarHide();
    setListData(data);
    setIsSendNewCustomNotification(true);
    setIsOnFullPage(true);
    setIsAddingNewToSend(true);
    setHeaderTextShow(false);
    handleForFullPage();

    // Scroll to the top of the page if screen width is greater than 1000
    const screenWidth = window.innerWidth;
    if (screenWidth > 1000) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const setNewChild = (selectedListData) => {
    setListData(selectedListData);
  };

  const editTileNotification = (tileNotificationId) => {
    setTileUpdated(true);
    setSelectedTileselectedNotificationId(tileNotificationId);
    setIsSendNewCustomNotification(false);
    setIsCreateNewCustomNotification(true);
    handleForFullPage();
  };

  const pageSet = () => {
    const dataToSend = {
      userId: user?._id,
    };
    dispatch(getUserNotifications(dataToSend));
    setNotifications(userNotifications);
    setFilteredTiles(notifications);
    // console.log("new data: " + JSON.stringify(listData));
    if (listData !== null) {
      const foundNotification =
        Array.isArray(userNotifications) &&
        userNotifications?.length > 0 &&
        userNotifications.find(
          (notification) => notification?._id === listData?._id
        );
      if (foundNotification) {
        setListData(foundNotification);
      }
      // console.log("new data: " + JSON.stringify(listData));
    }
    oneStepBack();
  };

  useEffect(() => {
    // dispatch(resetState());
    // console.log("new data: " + JSON.stringify(listData));
  }, [listData, isSendNewCustomNotification]);

  useEffect(() => {
    if (listData) {
      const dataToSend = {
        userId: user?._id,
      };
      dispatch(getUserNotifications(dataToSend));
      setNotifications(userNotifications);
      setFilteredTiles(notifications);
      dispatch(resetState());
      setTileUpdated(false);
      // console.log("new data: " + JSON.stringify(listData));
      const foundNotification =
        Array.isArray(userNotifications) &&
        userNotifications?.length > 0 &&
        userNotifications?.find(
          (notification) => notification?._id === listData?._id
        );
      if (foundNotification) {
        setListData(foundNotification);
      }
      // console.log("new data: " + JSON.stringify(listData));
    }
  }, [dispatch, user?._id, tileUpdated, listData]);

  const pageSet2 = () => {
    setLoaderset(true);
    pageSet();
  };

  useEffect(() => {
    let userNotificationsCheck = localStorage.getItem("userNotifications");
    if (
      fetchNewIdForChildPass &&
      userNotificationsCheck &&
      userNotificationsCheck?.length < userNotifications?.length
    ) {
      setFetchNewIdForChild(
        userNotifications[userNotifications?.length - 1]?._id
      );
      setFetchNewIdForChildPass(false);
      console.log("yess");
      console.log(fetchNewIdForChild);
    }
    localStorage.removeItem("userNotifications");
    localStorage.setItem(
      "userNotifications",
      JSON.stringify(userNotifications)
    );

    setNotifications(userNotifications);
  }, [fetchNewIdForChildPass, userNotifications !== null]);

  const loaderOn = () => {
    setLoaderset(true);
  };

  useEffect(() => {}, [loaderset]);

  const handleForFullPage = useCallback(() => {
    const windowWidth = window.innerWidth;
    setWindowWidth(windowWidth);

    if (windowWidth > 768) {
      setIsOnFullPage(false);
      setHeaderTextShow(true);
      setClassfr(false);
    } else {
      if (isCreateNewCustomNotification || isSendNewCustomNotification) {
        setIsOnFullPage(true);
      }
      setHeaderTextShow(false);
      setClassfr(true);
    }
  }, [isCreateNewCustomNotification, isSendNewCustomNotification, windowWidth]);

  useEffect(() => {
    // Initial call to handleForFullPage
    handleForFullPage();

    const handleResize = () => {
      handleForFullPage();
    };

    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleForFullPage, setWindowWidth]);

  useEffect(() => {
    setFilteredTiles(notifications);
  }, [notifications]);

  useEffect(() => {
    const filtered =
      notifications?.length > 0 &&
      notifications?.filter(
        (tile) =>
          tile?.clientName
            .toLowerCase()
            .includes(searchQuerysfp.toLowerCase().trim()) ||
          tile?.propertyNumber
            .toLowerCase()
            .includes(searchQuerysfp.toLowerCase().trim())
      );
    setFilteredTiles(filtered?.length > 0 ? filtered : notifications);
  }, [searchQuerysfp, notifications]);

  useEffect(() => {
    if (windowWidth <= 768) {
      setClassfr(true);
    } else {
      setClassfr(false);
    }
  }, [windowWidth]);

  const closePopup = () => {
    setShowPopup(false);
    setShowPopupMsg("");
  };

  const closeSuccessPopup = () => {
    setShowSuccessPopup(false);
    setShowPopupMsg("");
  };

  return (
    <>
      {status === "loading" && <LoadingSpinner />}
      {showSuccessPopup && (
        <EventSuccessPopup
          showPopupMsg={showPopupMsg}
          onClose={closeSuccessPopup}
        />
      )}
      {showPopup && (
        <EventFailedPopup showPopupMsg={showPopupMsg} onClose={closePopup} />
      )}
      <div
        className={`outgoing-notification ${isOnFullPage ? "fullPage" : ""}`}
      >
        <div
          className={`customheader ${
            !isOnFullPage && classfr ? "customheader-small-screen " : ""
          } ${isOnFullPage ? "forFullPage" : ""}`}
        >
          {(isCreateNewCustomNotification || isSendNewCustomNotification) && (
            <div
              className={`moveback ${
                isSendNewCustomNotification ? "setPosition" : ""
              }`}
              onClick={oneStepBack}
            >
              <MoveBackIcon />
            </div>
          )}
          <span className="responsiveDisplayY">
            {headerTextShow &&
              !isCreateNewCustomNotification &&
              !isSendNewCustomNotification &&
              "Create Notification"}
            {isCreateNewCustomNotification && "Create Notification"}
            {isSendNewCustomNotification && "Send Notification"}
          </span>
          {!isCreateNewCustomNotification && !isSendNewCustomNotification && (
            <button
              className={`addCustomNotification ${
                isSendNewCustomNotification ? "btnStyle" : ""
              }`}
              onClick={CustomNotificationPage}
            >
              +ADD
            </button>
          )}
        </div>
        {!isCreateNewCustomNotification && !isSendNewCustomNotification && (
          <div className="customNotificationsList">
            <ul>
              {filteredTiles && filteredTiles?.length > 0 ? (
                filteredTiles?.map((userNotification) => (
                  <li
                    key={userNotification._id}
                    onClick={() => selectNotificationOpen(userNotification)}
                  >
                    <div className="fs">
                      <span>Client Name</span>
                      <p>{userNotification.clientName}</p>
                    </div>
                    <div className="ss">
                      <span>Property Number</span>
                      <p>{userNotification.propertyNumber}</p>
                    </div>
                  </li>
                ))
              ) : (
                <p
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    padding: "15px 0",
                    backgroundColor: "#ffffff",
                    wordBreak: "break-all",
                  }}
                >
                  {(filteredTiles &&
                    filteredTiles &&
                    searchQuerysfp.length === 0) ||
                  status === "loading"
                    ? "Loading..."
                    : "No notifications created yet."}
                </p>
              )}
            </ul>
          </div>
        )}
        {isCreateNewCustomNotification && (
          <CreateCustomNotification
            setAfterUpdate={pageSet2}
            listData={listData}
            selectedTileselectedNotificationId={
              selectedTileselectedNotificationId
            }
            setNewChildData={setNewChild}
          />
        )}
        {isSendNewCustomNotification && (
          <SendNotification
            loaderset={loaderset}
            setAfterUpdate={pageSet}
            listData={listData}
            addNewNotification={CustomNotificationPage}
            editSpecificNotification={editTileNotification}
            setloading={loaderOn}
          />
        )}
      </div>
    </>
  );
};

export default OutgoingNotifications;
