import { createSlice } from "@reduxjs/toolkit";
import {
  getUserProperties,
  getPropertyDetails,
  createProperty,
  addOrUpdateFileToProperty,
  updatePropertyDetails,
  deleteProperty,
  getPropertyImage,
  sharingProperty,
  imageOrderForPropertyPhotosSection,
} from "../actions/propertyActions";

const initialState = {
  status: "idle",
  error: "",
  data: [],
  properties: [],
  propertyDetails: {},
  propertyAdded: {},
  fileAdded: false,
  propertyDeleted: false,
  propertyImage: {},
  sharedProperty: null,
  imageOrderSet: false,
  propertyCreated: false,
  propertyUpdated: false,
  propertyCardDeleted: false,
};

const propertySlice = createSlice({
  name: "propertySlice",
  initialState,
  reducers: {
    resetState: (state) => {
      state.status = "";
      state.error = "";
      state.fileAdded = false;
      state.propertyAdded = null;
      state.propertyDetails = null;
      state.propertyDeleted = null;
      state.propertyImage = null;
      state.sharedProperty = null;
      state.imageOrderSet = false;
      state.propertyCreated = false;
      state.propertyUpdated = false;
      state.propertyCardDeleted = false;
    },
    resetError: (state) => {
      state.status = "idle";
      state.error = "";
      state.propertyCreated = false;
      state.propertyUpdated = false;
      state.propertyCardDeleted = false;
      state.fileAdded = false;
    },
    resetPropertyDetail: (state) => {
      state.status = "idle";
      state.error = "";
      state.propertyDetails = null;
    },
    resetErrorForImage: (state) => {
      state.imageOrderSet = false;
    },
    resetPropertyState: (state) => {
      state.data = [];
      state.properties = [];
      state.propertyDetails = {};
      state.propertyAdded = {};
      state.propertyImage = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(createProperty.pending, (state, action) => {
        state.status = "loading";
        state.propertyCreated = false;
      })
      .addCase(createProperty.fulfilled, (state, action) => {
        if (action.payload.status === true) {
          state.status = "succeeded";
          state.propertyAdded = action.payload.data;
          // console.log("createProperty: ", action.payload.data)
          state.propertyCreated = true;
        } else {
          state.status = "failed";
          state.error = action.payload.message;
          state.propertyAdded = null;
          state.propertyCreated = false;
        }
      })
      .addCase(createProperty.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.propertyAdded = null;
        state.propertyCreated = false;
      })
      .addCase(getUserProperties.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getUserProperties.fulfilled, (state, action) => {
        if (action.payload.status === true) {
          if (action.payload.data.length > 0) {
            state.status = "succeeded";
            state.properties = action.payload.data;
            // console.log("getUserProperties: ", action.payload.data)
          } else {
            state.status = "failed";
            state.error = "No records found";
            state.properties = null;
          }
        } else {
          state.status = "failed";
          state.error = action.payload.message;
          state.properties = null;
        }
      })
      .addCase(getUserProperties.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.properties = null;
      })
      .addCase(getPropertyDetails.pending, (state, action) => {
        if (state.imageOrderSet !== true) {
          state.status = "loading";
        }
      })
      .addCase(getPropertyDetails.fulfilled, (state, action) => {
        if (action.payload.status === true) {
          if (action.payload.data != null) {
            state.status = "succeeded";
            state.propertyDetails = action.payload.data;
            state.fileAdded = false;
            // console.log("getPropertyDetails: ", action.payload.data)
          } else {
            state.status = "failed";
            state.error = "No records found";
            state.propertyDetails = null;
          }
        } else {
          state.status = "failed";
          state.error = action.payload.message;
          state.propertyDetails = null;
        }
      })
      .addCase(getPropertyDetails.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.propertyDetails = null;
      })
      .addCase(getPropertyImage.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getPropertyImage.fulfilled, (state, action) => {
        if (action.payload.status === true) {
          if (action.payload.data != null) {
            state.status = "succeeded";
            state.propertyImage = action.payload.data;
            state.fileAdded = false;
            // console.log("getPropertyDetails: ", action.payload.data)
          } else {
            state.status = "failed";
            state.error = "No records found";
            state.propertyImage = null;
          }
        } else {
          state.status = "failed";
          state.error = action.payload.message;
          state.propertyImage = null;
        }
      })
      .addCase(getPropertyImage.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.propertyImage = null;
      })
      .addCase(updatePropertyDetails.pending, (state, action) => {
        state.status = "loading";
        state.propertyUpdated = false;
      })
      .addCase(updatePropertyDetails.fulfilled, (state, action) => {
        if (action.payload.status === true) {
          if (action.payload.data != null) {
            state.status = "succeeded";
            state.propertyDetails = action.payload.data;
            // console.log("updatePropertyDetails: ", action.payload.data)
            state.propertyUpdated = true;
          } else {
            state.status = "failed";
            state.error = "No records found";
            state.propertyDetails = null;
            state.propertyUpdated = false;
          }
        } else {
          state.status = "failed";
          state.error = action.payload.message;
          state.propertyUpdated = false;
        }
      })
      .addCase(updatePropertyDetails.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.propertyDetails = null;
        state.propertyUpdated = false;
      })
      .addCase(deleteProperty.pending, (state, action) => {
        state.status = "loading";
        state.propertyCardDeleted = false;
      })
      .addCase(deleteProperty.fulfilled, (state, action) => {
        if (action.payload.status === true) {
          state.status = "succeeded";
          state.propertyDeleted = true;
          // console.log("propertyDeleted: ", action.payload.data)
          state.propertyCardDeleted = true;
        } else {
          state.status = "failed";
          state.error = action.payload.message;
          state.propertyCardDeleted = false;
        }
      })
      .addCase(deleteProperty.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.propertyDeleted = null;
        state.propertyCardDeleted = false;
      })
      .addCase(addOrUpdateFileToProperty.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(addOrUpdateFileToProperty.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.fileAdded = true;
      })
      .addCase(addOrUpdateFileToProperty.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.fileAdded = false;
      })
      .addCase(sharingProperty.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(sharingProperty.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.catalogShared = true;
        console.log("succeeded");
      })
      .addCase(sharingProperty.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.catalogShared = false;
        console.log("failed");
      })
      .addCase(imageOrderForPropertyPhotosSection.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(
        imageOrderForPropertyPhotosSection.fulfilled,
        (state, action) => {
          if (action.payload.status === true) {
            if (action.payload.data != null) {
              state.status = "succeeded";
              state.propertyDetails = action.payload.data;
              state.imageOrderSet = true;
            } else {
              state.status = "failed";
              state.error = "No records found";
              // state.propertyDetails = null;
            }
          } else {
            state.status = "failed";
            state.error = action.payload.message;
            state.imageOrderSet = false;
          }
        }
      )
      .addCase(imageOrderForPropertyPhotosSection.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        // state.propertyDetails = null;
        state.imageOrderSet = false;
      });
  },
});

export const {
  resetState,
  resetError,
  resetErrorForImage,
  resetPropertyState,
  resetPropertyDetail,
} = propertySlice.actions;

export default propertySlice.reducer;
