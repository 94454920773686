import React from "react";

const LocationIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1303_4147)">
        <path
          d="M20.28 6.66C19.95 5.07 19.19 3.7 18.06 2.54C17.01 1.47 15.77 0.71 14.32 0.33C13.76 0.18 13.18 0.11 12.6 0H11.48C11.1 0.06 10.72 0.09 10.35 0.17C8.82004 0.49 7.47004 1.18 6.33004 2.24C4.80004 3.67 3.90004 5.42 3.66004 7.49C3.43004 9.44 3.86004 11.26 4.89004 12.93C7.09004 16.48 9.29004 20.02 11.49 23.57C11.86 24.16 12.46 24.15 12.83 23.55C14.97 19.98 17.11 16.41 19.25 12.83C20.39 10.91 20.74 8.85 20.29 6.66H20.28ZM19.06 8.71C19.02 9.9 18.68 11.01 18.07 12.03C16.12 15.3 14.16 18.56 12.21 21.82C12.2 21.84 12.18 21.86 12.14 21.9C12.08 21.81 12.04 21.74 11.99 21.67C10.04 18.53 8.09004 15.39 6.14004 12.25C5.26004 10.83 4.86004 9.3 5.05004 7.63C5.28004 5.72 6.14004 4.15 7.64004 2.94C9.15004 1.72 10.89 1.22 12.82 1.43C14.73 1.64 16.3 2.53 17.51 4.02C18.61 5.38 19.11 6.95 19.06 8.69V8.71Z"
          fill="white"
        />
        <path
          d="M12.0293 4.21996C9.70934 4.21996 7.81934 6.10996 7.81934 8.42996C7.81934 10.77 9.69934 12.65 12.0393 12.65C14.3693 12.65 16.2493 10.77 16.2493 8.43996C16.2493 6.09996 14.3593 4.20996 12.0293 4.20996V4.21996ZM12.0293 11.25C10.4793 11.25 9.22934 9.97996 9.22934 8.42996C9.22934 6.88996 10.4993 5.61996 12.0493 5.61996C13.5893 5.61996 14.8593 6.89996 14.8493 8.44996C14.8493 9.99996 13.5793 11.26 12.0293 11.25Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1303_4147">
          <rect
            width="16.88"
            height="24"
            fill="white"
            transform="translate(3.59961)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LocationIcon;