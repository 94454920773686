import React, { useState, useEffect } from "react";
import "./HomeCSS/SettingContent.css";
import MoveBackIcon from "../../../assets/svgs/LeftArrowIcon";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const SettingContent = ({ content, onMoveBack }) => {
  const [title, setTitle] = useState("");
  const [pdfLink, setPdfLink] = useState(null);
  const [numPages, setNumPages] = useState(null);

  useEffect(() => {
    if (content === "policy") {
      setPdfLink(
        "https://s3.amazonaws.com/realtorganizer.com23/Privacy+Policy.pdf"
      );
      setTitle("Privacy & Policy");
    } else if (content === "terms") {
      setPdfLink(
        "https://s3.amazonaws.com/realtorganizer.com23/Terms+and+Conditions.pdf"
      );
      setTitle("Terms and Conditions");
    }
  }, [content]);

  const [isMoveBack, setIsMoveBack] = useState(false);

  const moveBack = () => {
    setIsMoveBack(true);
    onMoveBack();
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <div className={`overlayStyle ${isMoveBack ? "moveBackAnimation" : ""}`}>
      <div className="overlayContentStyle">
        <div className="headerLine">
          <div className="moveback" onClick={moveBack}>
            <MoveBackIcon />
          </div>
          <span>{title}</span>
        </div>
        <div className="contentBox">
          {pdfLink && (
            <Document file={pdfLink} onLoadSuccess={onDocumentLoadSuccess}>
              {Array.from(new Array(numPages), (el, index) => (
                <Page
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  className="pdf-page"
                />
              ))}
            </Document>
          )}
        </div>
      </div>
    </div>
  );
};

export default SettingContent;
