import React, { useState, useEffect, useRef } from "react";
import "./HomeCSS/viewReviewIndividual.css";
import MoveBackIcon from "../../../assets/svgs/LeftArrowIcon";
import FillStar from "../../../assets/svgs/ReviewFillStarIcon";
import UnFillStar from "../../../assets/svgs/ReviewUnfillStarIcon";

const ViewReviewIndividual = ({ reviewData, goBackToProfilePage, onSave }) => {
  const initialReview = reviewData ? reviewData.text : "";
  const initialRating = reviewData ? reviewData.rating : 0;

  const [editableReview, setEditableReview] = useState(initialReview);
  const [rating, setRating] = useState(initialRating);
  const textareaRef = useRef(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = `${Math.min(scrollHeight, 260)}px`;
    }
  }, [editableReview]);

  const renderStarsforAdd = (selectedRating) => {
    return (
      <div className="startsBox">
        {[...Array(5)].map((_, index) => (
          <span
            key={index}
            className="filled-star star"
            onClick={() => setRating(index + 1)}
          >
            {index < selectedRating ? <FillStar /> : <UnFillStar />}
          </span>
        ))}
      </div>
    );
  };

  const renderStars = (rating) => {
    const filledStars = Math.floor(rating);
    const remainingStars = 5 - filledStars;

    return (
      <>
        <div className="startsBox">
          {[...Array(filledStars)].map((_, index) => (
            <span key={index} className="filled-star">
              <FillStar />
            </span>
          ))}
          {[...Array(remainingStars)].map((_, index) => (
            <span key={filledStars + index} className="unfilled-star">
              <UnFillStar />
            </span>
          ))}
        </div>
      </>
    );
  };

  const handleSave = () => {
    if (editableReview.trim() === "" || rating === 0) {
      alert("please provide rating and add review to save.");
      return;
    }
    const data = {
      text: editableReview,
      rating: rating,
    };
    onSave(data);
  };

  return (
    <div className="individualReview">
      <div className="individualReviewBox">
        <div className="headerLine">
          <div className="moveback" onClick={goBackToProfilePage}>
            <MoveBackIcon />
          </div>
          <span>Reviews</span>
        </div>
        {reviewData ? renderStars(rating) : renderStarsforAdd(rating)}
        <div className="reviewContentBox">
          {/* <span>Reviews</span> */}
          {reviewData ? (
            <div className="reviewContent">
              <pre ref={textareaRef}>{reviewData?.text}</pre>
            </div>
          ) : (
            <textarea
              ref={textareaRef}
              value={editableReview}
              onChange={(e) => setEditableReview(e.target.value)}
              className="editable-review"
            />
          )}
          {!reviewData && (
            <button onClick={handleSave} className="save-button">
              Save
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewReviewIndividual;
