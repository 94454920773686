import React from "react";

const HidePasswordIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9997 18.3507C12.9457 18.3507 13.8097 18.2499 14.5977 18.0757L12.8407 16.3562C12.5677 16.3767 12.2907 16.3934 11.9997 16.3934C6.64874 16.3934 4.57573 12.6294 4.07373 11.5C4.45068 10.6767 4.95978 9.91782 5.58173 9.25198L4.18373 7.88379C2.64573 9.51525 2.06273 11.1584 2.05173 11.1907C1.98276 11.3917 1.98276 11.6093 2.05173 11.8102C2.07273 11.8748 4.36673 18.3507 11.9997 18.3507ZM11.9997 4.64927C10.1627 4.64927 8.65373 5.03683 7.39573 5.60935L3.70673 2L2.29273 3.38385L20.2927 21L21.7067 19.6162L18.3877 16.3679C21.0017 14.4585 21.9347 11.8513 21.9487 11.8102C22.0177 11.6093 22.0177 11.3917 21.9487 11.1907C21.9267 11.1252 19.6327 4.64927 11.9997 4.64927ZM16.9717 14.9821L14.6917 12.7507C14.8817 12.3691 14.9997 11.9492 14.9997 11.5C14.9997 9.89399 13.6407 8.56397 11.9997 8.56397C11.5407 8.56397 11.1117 8.67946 10.7227 8.86638L8.91473 7.09694C9.90725 6.76362 10.9504 6.59782 11.9997 6.60662C17.3507 6.60662 19.4237 10.3706 19.9257 11.5C19.6237 12.1772 18.7597 13.7921 16.9717 14.9821Z"
        fill="#777777"
      />
    </svg>
  );
};

export default HidePasswordIcon;
