import React, { useState, useEffect, useRef } from "react";
import { getAppContacts } from "../../../api/actions/homeActions";
import { useDispatch, useSelector } from "react-redux";
import { resetState } from "../../../api/reducerSlices/homeSlice";
import XIcon from "../../../assets/svgs/XIcon";
import ShareIcon from "../../../assets/svgs/ShareIcon";
import SearchIcon from "../../../assets/svgs/SearchIcon";
import SendMessageButtonIcon from "../../../assets/svgs/SendMessageButtonIcon";
require("./HomeCSS/contactsSelect.css");
require("./HomeCSS/searchbar.css");

const ContactsToSelect = ({ closeSelectContactBox, sharingCatalog }) => {
  const dispatch = useDispatch();
  const { status, error, appContacts } = useSelector(
    (state) => state.homeSlice
  );
  const [contacts, setContacts] = useState();
  // const [selectedContact, setSelectedContact] = useState(null);
  const [filteredTiles, setFilteredTiles] = useState([]);
  const [searchQuerysfc, setSearchQuerysfc] = useState("");
  const [contactsCountOnsearch, setContactsCountOnsearch] = useState(0);
  const [buttonVisibility, setButtonVisibility] = useState([]);

  useEffect(() => {
    dispatch(getAppContacts());
  }, []);
  useEffect(() => {
    setContacts(appContacts);
    setButtonVisibility(new Array(appContacts.length).fill(true));
  }, [appContacts]);

  const handleSearchInputChange = (event) => {
    setSearchQuerysfc(event.target.value);
  };

  const shareCatalogs = (sharedPersonId, index) => {
    setButtonVisibility((prevVisibility) => {
      const newVisibility = [...prevVisibility];
      newVisibility[index] = !newVisibility[index];
      return newVisibility;
    });

    let makeSharemsg = {
      shareWith: sharedPersonId,
      // sections: ["CATALOGS"],
    };
    sharingCatalog(makeSharemsg);
  };

  useEffect(() => {
    const filtered = contacts?.filter(
      (contact) =>
        contact?.name
          .toLowerCase()
          .includes(searchQuerysfc.toLowerCase().trim()) ||
        // contact?.email.toLowerCase().includes(searchQuerysfc.toLowerCase()) ||
        contact?.contactNumber
          .toLowerCase()
          .includes(searchQuerysfc.toLowerCase().trim())
    );
    setFilteredTiles(filtered?.length > 0 ? filtered : contacts);
    setContactsCountOnsearch(filtered?.length > 0 ? filtered?.length : 0);
  }, [searchQuerysfc, contacts]);

  return (
    <div className="overlayBox">
      <div className="contactsTileToSelect">
        <div className="cntslHeader">
          <div className="text">
            Share with <ShareIcon />
          </div>
          <div className="closeIcon" onClick={closeSelectContactBox}>
            <XIcon />
          </div>
        </div>
        <div className="cntsbc">
          <form role="search" className="searchBarBox">
            <div className="searchIcon">
              <SearchIcon />
            </div>
            <input
              id="search"
              type="search"
              placeholder="Search..."
              value={searchQuerysfc}
              onChange={handleSearchInputChange}
            />
          </form>
        </div>
        <div className="contactsCountLine">
          <span>Contacts (</span>
          <span className="contactCount">
            {searchQuerysfc !== "" && contactsCountOnsearch + " found from "}
            {filteredTiles && contacts?.length ? contacts?.length : "..."}
          </span>
          <span>)</span>
        </div>
        <div className="addedContact fcstswo">
          {filteredTiles && filteredTiles.length > 0 ? (
            filteredTiles?.map((contact, index) => (
              <>
                {/* {console.log("contact data:" + JSON.stringify(contact))} */}
                <div
                  key={contact._id}
                  className="contact-box"
                  // onClick={() => markAsSelected(contact)}
                >
                  <div className="profile-pic">
                    {typeof contact.image === "string" &&
                    contact.image !== "" ? (
                      <img src={contact.image} alt="" />
                    ) : (
                      <div className="initials">
                        {contact?.name?.charAt(0).toUpperCase()}
                      </div>
                    )}
                  </div>
                  <div className="contact-details">
                    <span>{contact.name}</span>
                    {/* <span>{contact.email}</span> */}
                    <span>{contact.contactNumber}</span>
                  </div>
                  {buttonVisibility[index] && (
                    <div
                      className="sendForShare"
                      onClick={() => shareCatalogs(contact?.user, index)}
                    >
                      <SendMessageButtonIcon />
                    </div>
                  )}
                </div>
              </>
            ))
          ) : (
            // <>
            //   {contacts !== null && contacts?.length === 0 ? (
            //     <div className="msgforcontacts">
            //       <span>Nothing in contacts.</span>
            //     </div>
            //   ) : (
            //     <div className="ldtxt">Loading...</div>
            //   )}
              // </>
              ""
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactsToSelect;
