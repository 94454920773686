import React from "react";

const PlusBoxIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="5" fill="#4668A8" />
      <path
        d="M13.47 21.87V18.06H27.42V21.87H13.47ZM18.51 12.63H22.41V27.24H18.51V12.63Z"
        fill="white"
      />
    </svg>
  );
};

export default PlusBoxIcon;
