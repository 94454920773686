import React from "react";

const EditIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.1624 8.90858H18.1734C18.1734 9.00121 18.1818 9.08542 18.1818 9.16964C18.1818 11.5866 18.1818 13.9951 18.1818 16.412C18.1818 18.45 16.6231 20.008 14.5758 20.008C10.9192 20.008 7.26262 20.008 3.60603 20.008C1.55026 20.008 0 18.45 0 16.3868C0 12.7403 0 9.09385 0 5.44738C0 3.38413 1.55026 1.83459 3.60603 1.82617C6.0241 1.82617 8.43373 1.82617 10.8518 1.82617C10.9276 1.82617 11.0119 1.82617 11.1046 1.83459V2.84516C10.995 2.84516 10.8939 2.84516 10.8012 2.84516C8.40003 2.84516 6.00724 2.84516 3.60603 2.84516C2.27483 2.84516 1.27222 3.69572 1.04474 5.00104C1.01946 5.13578 1.02789 5.27895 1.02789 5.42211C1.02789 9.077 1.02789 12.7319 1.02789 16.3868C1.02789 17.8858 2.10633 18.9722 3.59761 18.9722C7.26262 18.9722 10.9276 18.9722 14.5926 18.9722C15.907 18.9722 16.8927 18.1469 17.1371 16.85C17.1708 16.6647 17.1708 16.471 17.1708 16.2857C17.1708 13.9277 17.1708 11.5697 17.1708 9.22017C17.1708 9.11911 17.1708 9.02647 17.1708 8.90015L17.1624 8.90858Z"
        fill="white"
      />
      <path
        d="M17.246 0.0158955C18.3538 0.0724764 19.247 0.565539 19.7279 1.59208C20.2174 2.6267 20.0456 3.61282 19.247 4.44537C18.5599 5.16476 17.8042 5.82756 17.0828 6.51462C14.4807 8.97993 11.8701 11.4372 9.26795 13.9025C9.07902 14.0803 8.8815 14.1935 8.5981 14.2177C7.44734 14.3147 6.30516 14.444 5.1544 14.5491C4.76795 14.5895 4.50172 14.3309 4.55325 13.9752C4.69066 12.8517 4.82806 11.7281 4.98264 10.6046C4.99982 10.4672 5.11146 10.3136 5.2231 10.2085C8.25459 7.33908 11.2947 4.46962 14.3347 1.60016C14.6697 1.28492 14.9874 0.969688 15.3395 0.670617C15.8548 0.226053 16.4817 0.0320615 17.246 0.0078125V0.0158955ZM5.66108 13.5226C6.50268 13.4337 7.29276 13.3609 8.07425 13.2801C8.28894 13.2558 8.48646 13.2558 8.66681 13.0861C11.2002 10.6774 13.7422 8.28479 16.2842 5.88414C16.3357 5.83565 16.3786 5.77907 16.413 5.74673C15.5714 4.9546 14.7384 4.17863 13.9054 3.40267C13.9054 3.40267 13.8968 3.40267 13.8796 3.41883C11.2775 5.86798 8.67539 8.32521 6.07329 10.7824C6.01318 10.839 5.97883 10.9279 5.96165 11.0088C5.89295 11.5018 5.83283 11.9949 5.76413 12.4879C5.72119 12.8193 5.68684 13.1588 5.6439 13.5306L5.66108 13.5226ZM17.1601 4.97077C17.6067 4.56662 18.0962 4.17863 18.5256 3.74215C19.1353 3.11168 19.0924 2.12556 18.4655 1.51125C17.8472 0.905024 16.7737 0.816111 16.0952 1.36575C15.5971 1.7699 15.1678 2.2468 14.704 2.68328C15.5285 3.45116 16.3615 4.22713 17.1601 4.97077Z"
        fill="white"
      />
    </svg>
  );
};

export default EditIcon;
