import React, { useState, useEffect } from "react";
import Artboard_icon from "../../../assets/images/Artboard.png";
import ShowPasswordIcon from "../../../assets/svgs/ShowPasswordIcon";
import HidePasswordIcon from "../../../assets/svgs/HidePasswordIcon";
import "./HomeCSS/changePassword.css";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "../../../utils/LoadingSpinner";
import MoveBackIcon from "../../../assets/svgs/LeftArrowIcon";
import { changePassword } from "../../../api/actions/authActions";
import { resetError } from "../../../api/reducerSlices/authSlice";
import {
  EventSuccessPopup,
  EventFailedPopup,
} from "../../popup-boxes/main-page-Popup's/mainPageEventPopup";

const ChangePassword = ({ overlayForChangePassword }) => {
  const dispatch = useDispatch();
  const [getError, setError] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);

  const [formData, setFormData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const { status, error, changePasswordPass } = useSelector(
    (state) => state.auth
  );
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupMsg, setShowPopupMsg] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  useEffect(() => {
    if (changePasswordPass === true) {
      setShowPopupMsg("Password changed!");
      setShowSuccessPopup(true);
      setTimeout(() => {
        setShowSuccessPopup(false);
        setShowPopupMsg("");
      }, 3000);
      setFormData({
        currentPassword: "",
        newPassword: "",
        confirmNewPassword: "",
      });
    }

    if (status === "failed") {
      setShowPopupMsg(error !== "" ? error : "Something went wrong...");
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
        setShowPopupMsg("");
        dispatch(resetError());
      }, 3000);
    }
  }, [status, error, changePasswordPass]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
    setError("");
  };

  const togglePasswordVisibility = (field) => {
    if (field === "currentPassword") {
      setShowCurrentPassword(!showCurrentPassword);
    } else if (field === "newPassword") {
      setShowNewPassword(!showNewPassword);
    } else if (field === "confirmNewPassword") {
      setShowConfirmNewPassword(!showConfirmNewPassword);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      !formData.currentPassword.trim() ||
      !formData.newPassword.trim() ||
      !formData.confirmNewPassword.trim()
    ) {
      setError("Please fill in all fields!");
      return;
    }

    if (formData.newPassword !== formData.confirmNewPassword) {
      setError("New Password and Confirm New Password must match!");
      return;
    }

    const dataToSendForChangePassword = {
      newPassword: formData.newPassword.trim(),
      currentPassword: formData.currentPassword.trim(),
    };
    // console.log(dataToSendForChangePassword);
    dispatch(changePassword(dataToSendForChangePassword));
  };

  useEffect(() => {
    // Reset form data when overlayForChangePassword changes
    // setFormData({
    //   currentPassword: "",
    //   newPassword: "",
    //   confirmNewPassword: "",
    // });
    setError("");
  }, [overlayForChangePassword]);

  const closePopup = () => {
    setShowPopup(false);
    setShowPopupMsg("");
  };

  const closeSuccessPopup = () => {
    setShowSuccessPopup(false);
    setShowPopupMsg("");
  };

  return (
    <>
      {status === "loading" && <LoadingSpinner />}
      {showSuccessPopup && (
        <EventSuccessPopup
          showPopupMsg={showPopupMsg}
          onClose={closeSuccessPopup}
        />
      )}
      {showPopup && (
        <EventFailedPopup showPopupMsg={showPopupMsg} onClose={closePopup} />
      )}
      <div className="changePasswordContainer">
        <div className="headerLine">
          <div className="moveback" onClick={overlayForChangePassword}>
            <MoveBackIcon />
          </div>
          <span>Change Password</span>
        </div>
        <div className="cpContainerBox">
          <div className="artboard">
            <div className="artboardPic">
              <img src={Artboard_icon} alt="" />
            </div>
            <span>
              <strong>REALTOR</strong> ORGANIZER
            </span>
          </div>
          <div className="box">
            <form onSubmit={handleSubmit} className="formSU">
              <div className="input-group">
                <label htmlFor="currentPassword">Current Password:</label>
                <div className="pos-rel">
                  <input
                    type={showCurrentPassword ? "text" : "password"}
                    id="currentPassword"
                    placeholder="**************"
                    value={formData.currentPassword}
                    onChange={handleChange}
                  />
                  <div
                    className="password-toggle btn"
                    onClick={() => togglePasswordVisibility("currentPassword")}
                  >
                    {showCurrentPassword ? (
                      <ShowPasswordIcon />
                    ) : (
                      <HidePasswordIcon />
                    )}
                  </div>
                </div>
              </div>
              <div className="input-group">
                <label htmlFor="newPassword">New Password:</label>
                <div className="pos-rel">
                  <input
                    type={showNewPassword ? "text" : "password"}
                    id="newPassword"
                    placeholder="**************"
                    value={formData.newPassword}
                    onChange={handleChange}
                  />
                  <div
                    className="password-toggle btn"
                    onClick={() => togglePasswordVisibility("newPassword")}
                  >
                    {showNewPassword ? (
                      <ShowPasswordIcon />
                    ) : (
                      <HidePasswordIcon />
                    )}
                  </div>
                </div>
              </div>
              <div className="input-group">
                <label htmlFor="confirmNewPassword">
                  Confirm New Password:
                </label>
                <div className="pos-rel">
                  <input
                    type={showConfirmNewPassword ? "text" : "password"}
                    id="confirmNewPassword"
                    placeholder="**************"
                    value={formData.confirmNewPassword}
                    onChange={handleChange}
                  />
                  <div
                    className="password-toggle btn"
                    onClick={() =>
                      togglePasswordVisibility("confirmNewPassword")
                    }
                  >
                    {showConfirmNewPassword ? (
                      <ShowPasswordIcon />
                    ) : (
                      <HidePasswordIcon />
                    )}
                  </div>
                </div>
              </div>
              {getError && <span className="error">{getError}</span>}
              <button type="submit" disabled={status === "loading" || getError}>
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
