import React from 'react'

const ArrowToMove = () => {
  return (
    <svg
      width="71"
      height="9"
      viewBox="0 0 71 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M70.3536 4.85355C70.5488 4.65829 70.5488 4.34171 70.3536 4.14645L67.1716 0.964466C66.9763 0.769204 66.6597 0.769204 66.4645 0.964466C66.2692 1.15973 66.2692 1.47631 66.4645 1.67157L69.2929 4.5L66.4645 7.32843C66.2692 7.52369 66.2692 7.84027 66.4645 8.03553C66.6597 8.2308 66.9763 8.2308 67.1716 8.03553L70.3536 4.85355ZM0 5H70V4H0V5Z"
        fill="#4668A8"
      />
    </svg>
  );
}

export default ArrowToMove
