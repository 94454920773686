import React, { useState, useEffect } from "react";
import MoveBackIcon from "../../../assets/svgs/LeftArrowIcon";
import "./HomeCSS/reportAnIssue.css";
import { createIssue } from "../../../api/actions/homeActions";
import { useDispatch, useSelector } from "react-redux";
import { resetState } from "../../../api/reducerSlices/homeSlice";
import {
  EventSuccessPopup,
  EventFailedPopup,
} from "../../popup-boxes/main-page-Popup's/mainPageEventPopup";
import LoadingSpinner from "../../../utils/LoadingSpinner";

const ReportAnIssue = ({ overlayReportAnIssue }) => {
  const dispatch = useDispatch();
  const { status, error, issueCreated } = useSelector(
    (state) => state.homeSlice
  );
  const [subject, setSubject] = useState("");
  const [issue, setIssue] = useState("");
  const [subjectError, setSubjectError] = useState("");
  const [issueError, setIssueError] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupMsg, setShowPopupMsg] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  useEffect(() => {
    if (issueCreated === true) {
      setSubject("");
      setIssue("");
      setShowPopupMsg("Issue Submited!");
      setShowSuccessPopup(true);
      setTimeout(() => {
        setShowSuccessPopup(false);
        setShowPopupMsg("");
        dispatch(resetState());
      }, 3000);
    }
    if (error && error !== "") {
      setShowPopupMsg(error);
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
        setShowPopupMsg("");
        dispatch(resetState());
      }, 3000);
    }
  }, [error, issueCreated]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!subject) {
      setSubjectError("Subject is required");
    } else {
      setSubjectError("");
    }
    if (!issue) {
      setIssueError("Issue description is required");
    } else {
      setIssueError("");
    }
    if (subject && issue) {
      // console.log("Subject:", subject);
      // console.log("Issue:", issue);
      let userIssueObject = {
        title: subject,
        comment: issue,
      };
      dispatch(createIssue(userIssueObject));
    }
  };

  const handleSubjectChange = (event) => {
    setSubject(event.target.value);
    setSubjectError(""); // Clear subject error when typing
  };

  const handleIssueChange = (event) => {
    setIssue(event.target.value);
    setIssueError(""); // Clear issue error when typing
  };

  const closePopup = () => {
    setShowPopup(false);
    setShowPopupMsg("");
  };

  const closeSuccessPopup = () => {
    setShowSuccessPopup(false);
    setShowPopupMsg("");
  };

  return (
    <>
      {status === "loading" && <LoadingSpinner />}
      {showSuccessPopup && (
        <EventSuccessPopup
          showPopupMsg={showPopupMsg}
          onClose={closeSuccessPopup}
        />
      )}
      {showPopup && (
        <EventFailedPopup showPopupMsg={showPopupMsg} onClose={closePopup} />
      )}
      <div className="containerfRAI">
        <div className="headerLine">
          <div className="moveback" onClick={overlayReportAnIssue}>
            <MoveBackIcon />
          </div>
          <span>Report An Issue</span>
        </div>
        <form className="cfsais" onSubmit={handleSubmit}>
          <label htmlFor="subject">
            Subject
            <input
              type="text"
              id="subject"
              name="subject"
              value={subject}
              onChange={handleSubjectChange}
            />
            {subjectError && <div className="error">{subjectError}</div>}
          </label>
          <label htmlFor="issue">
            Write the issue
            <textarea
              id="issue"
              name="issue"
              value={issue}
              onChange={handleIssueChange}
            />
            {issueError && <div className="error">{issueError}</div>}
          </label>
          <input type="submit" value="Submit" />
        </form>
      </div>
    </>
  );
};

export default ReportAnIssue;
