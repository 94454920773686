import React from "react";

const InstagramIcon = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.50892 9.99749H3.49596C3.43576 9.99248 3.37304 9.98246 3.31283 9.98246C2.91394 9.97244 2.51757 9.9549 2.12872 9.86221C1.29834 9.66178 0.683703 9.19329 0.317431 8.41665C0.0891389 7.93563 0.0214045 7.41954 0.0163871 6.89593C0.00635229 5.81865 -0.00368288 4.74137 0.00133453 3.66409C0.00133453 3.14799 0.00635206 2.62689 0.131787 2.12082C0.350045 1.23645 0.866839 0.605111 1.71729 0.25938C2.17388 0.0739872 2.65806 0.0213755 3.14475 0.0163649C4.16077 0.00634367 5.17931 -0.00367788 6.19784 0.00133272C6.7322 0.00133272 7.26906 0.00133291 7.79589 0.114072C8.76676 0.324517 9.44662 0.880695 9.79031 1.82519C9.95338 2.27865 9.99352 2.75216 9.99603 3.22816C9.99854 4.3806 10.0036 5.53304 9.99603 6.68548C9.99352 7.01368 9.96843 7.34438 9.91575 7.66756C9.81289 8.32896 9.51436 8.89516 8.99004 9.32607C8.47826 9.74696 7.87617 9.91482 7.22641 9.95992C6.9906 9.97495 6.75227 9.98747 6.51394 10L6.50892 9.99749ZM4.99868 9.11312C4.99868 9.11312 4.99868 9.1031 4.99868 9.09809C5.26209 9.09809 5.52802 9.10561 5.79143 9.09809C6.36091 9.07805 6.9354 9.08556 7.49986 9.01291C8.27004 8.9152 8.79185 8.47176 8.98 7.70765C9.05526 7.39699 9.08286 7.0713 9.08788 6.75062C9.10544 5.74599 9.10794 4.73886 9.10293 3.73173C9.10293 3.32838 9.08035 2.92252 9.03017 2.52417C8.92983 1.71747 8.42808 1.17131 7.62781 1.01348C7.28411 0.945832 6.93039 0.92579 6.57917 0.92078C5.64593 0.908253 4.71269 0.905748 3.78196 0.915769C3.33541 0.920779 2.88635 0.9308 2.44732 1.00345C1.69471 1.12371 1.19297 1.56965 1.02489 2.32124C0.949627 2.65696 0.927048 3.0102 0.919522 3.35844C0.901961 4.16765 0.896943 4.97937 0.904469 5.79109C0.909486 6.34977 0.924539 6.91096 0.979731 7.46714C1.03492 8.02833 1.30335 8.4868 1.80259 8.78242C2.12119 8.97032 2.47492 9.04798 2.83868 9.06301C3.55868 9.08807 4.27868 9.1006 4.99868 9.11813V9.11312Z"
        fill="white"
      />
      <path
        d="M4.9987 7.56484C3.57626 7.55983 2.42226 6.40489 2.42728 4.98939C2.43229 3.56888 3.59132 2.41644 5.00874 2.42647C6.43368 2.43398 7.57264 3.5764 7.57514 4.9944C7.57514 6.4124 6.41863 7.56985 5.00121 7.56484H4.9987ZM3.33292 4.9919C3.33292 5.91385 4.07299 6.65792 4.99619 6.66043C5.9194 6.66043 6.66448 5.92638 6.6695 5.00192C6.67452 4.07496 5.92943 3.33088 5.00121 3.32838C4.07801 3.32838 3.33543 4.06744 3.33292 4.9919Z"
        fill="white"
      />
      <path
        d="M8.27503 2.32624C8.27503 2.66195 8.00409 2.92501 7.67043 2.9225C7.34179 2.91999 7.07587 2.64942 7.07837 2.32123C7.07837 1.99053 7.3443 1.72747 7.67545 1.72497C8.01161 1.72497 8.27754 1.98802 8.27754 2.32373L8.27503 2.32624Z"
        fill="white"
      />
    </svg>
  );
};

export default InstagramIcon;
