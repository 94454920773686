import React, { useEffect, useState, useRef } from "react";
import "./ChatsCSS/chatList.css";
import { useDispatch, useSelector } from "react-redux";
import { convertISOToLocalFormatV2 } from "../../utils/CommonUtils";
import PersonIcon from "../../assets/svgs/PersonIcon";
import MoreOptionsIcon from "../../assets/svgs/moreOptionsIcon";
import { deleteChat } from "../../api/actions/chatActions";

const ChatListItem = ({ searchQueryForChats, userChats, onSelectContact }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [filteredTiles, setFilteredTiles] = useState([]);
  const [showOptions, setShowOptions] = useState(null);
  const moreOptionsRef = useRef(null);

  const chatTileOpen = (selectcontactForChat) => {
    onSelectContact(selectcontactForChat);
  };

  const ImageRendering = ({ children }) => {
    let image =
      children?.from?._id === user?._id
        ? children?.to
          ? children?.to?.image
          : children?.from?.image
        : children?.from?.image;
    let name =
      children?.from?._id === user?._id
        ? children?.to?.[0]?.name?.charAt(0).toUpperCase()
        : children?.from?.name?.charAt(0).toUpperCase();

    return (
      <>
        {image !== "" && typeof image === "string" ? (
          <img src={image} alt="Profile" />
        ) : (
          // <div className="initials">{name}</div>
          <div className="initials">
            <PersonIcon />
          </div>
        )}
      </>
    );
  };

  // useEffect(() => {
  //   setFilteredTiles(userChats);
  // }, [userChats]);

  useEffect(() => {
    // console.log(userChats);
    const filtered = userChats?.filter(
      (tile) =>
        (tile?.from?._id === user?._id
          ? tile?.to?.name
              .toLowerCase()
              .includes(searchQueryForChats.toLowerCase().trim())
          : tile?.from?.name
              .toLowerCase()
              .includes(searchQueryForChats.toLowerCase().trim())) ||
        (tile?.lastMessage &&
          tile?.lastMessage.length > 0 &&
          tile?.lastMessage
            .toLowerCase()
            .includes(searchQueryForChats.toLowerCase().trim()))
    );
    setFilteredTiles(filtered.length > 0 ? filtered : userChats);
  }, [searchQueryForChats, userChats]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        moreOptionsRef.current &&
        !moreOptionsRef.current.contains(event.target)
      ) {
        setShowOptions(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const deleteChatFunc = (chatId) => {
    dispatch(deleteChat(chatId));
    setShowOptions(null);
  };

  return (
    <div className="chatTileBox">
      {/* <div className="headerText">
        <span className="messageForRead">Messages</span>
      </div> */}
      <div className="contactList">
        {filteredTiles &&
          filteredTiles.length > 0 &&
          filteredTiles?.map((chatObj) => (
            <>
              {/* {console.log(chatObj)} */}
              <div className="chatTileUpperCover">
                <div
                  key={chatObj._id}
                  className="contactBox"
                  onClick={() => chatTileOpen(chatObj)}
                >
                  <div className="profile-pic">
                    {/* {console.log("hello  :  " + JSON.stringify(chatObj))} */}
                    <ImageRendering>{chatObj}</ImageRendering>
                  </div>
                  <div className="contact-details">
                    <span>
                      {chatObj?.from?._id === user?._id
                        ? chatObj?.to?.name
                        : chatObj?.from?.name}
                    </span>
                    <label>
                      {chatObj?.lastMessage?.length > 35
                        ? `${chatObj.lastMessage.substring(0, 25)}...`
                        : chatObj?.lastMessage}
                    </label>
                  </div>

                  <label className="msgTime">
                    {chatObj?.updatedAt
                      ? convertISOToLocalFormatV2(chatObj?.updatedAt)
                      : ""}
                  </label>
                </div>

                <div className="moreOptionBox">
                  <div
                    className="moreOptionInner"
                    onClick={() => setShowOptions(chatObj?._id)}
                  >
                    <MoreOptionsIcon />
                  </div>
                  {showOptions === chatObj?._id && (
                    <div className="moreOptionsBlock" ref={moreOptionsRef}>
                      <div
                        className="chatListmpItem"
                        onClick={() => deleteChatFunc(chatObj?._id)}
                      >
                        Delete
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          ))}
      </div>
    </div>
  );
};

export default ChatListItem;
