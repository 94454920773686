import React, { useState, useEffect } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Document, Page, pdfjs } from "react-pdf";

// icons
import MoveBackIcon from "../../../assets/svgs/LeftArrowIcon";
import FacebookIcon from "../../../assets/svgs/FacebookIcon";
import InstagramIcon from "../../../assets/svgs/InstagramIcon";
import LinkedIn from "../../../assets/svgs/LinkedIn";
import YoutubeIcon from "../../../assets/svgs/YoutubeIcon";
import InfoiIcon from "../../../assets/svgs/InfoiIcon";
import HomeIcon from "../../../assets/svgs/HomeIcon";
import CatalogIcon from "../../../assets/svgs/CatalogIcon";
import AchievementIcon from "../../../assets/svgs/AchievementIcon";
import ChevronRightIcon from "../../../assets/svgs/ChevronRightIcon";
import StarIcon from "../../../assets/svgs/StarIcon";
import FillStar from "../../../assets/svgs/ReviewFillStarIcon";
import UnFillStar from "../../../assets/svgs/ReviewUnfillStarIcon";
import FileIcon from "../../../assets/iconImages/DefaultForFile.png";
import ChatTextIcon from "../../../assets/svgs/ChatTextIcon";
import { Link } from "react-router-dom";

import {
  getUserProfile,
  getOtherUserProfile,
  createReview,
} from "../../../api/actions/profileActions";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "../../../utils/LoadingSpinner";
import {
  resetState,
  resetCreatedReviewStatus,
} from "../../../api/reducerSlices/profileSlice";
import {
  EventSuccessPopup,
  EventFailedPopup,
} from "../../popup-boxes/main-page-Popup's/mainPageEventPopup";
// components
import AddCatalog from "./AddCatalog";
import ReviewList from "./Reviews";
import ViewReviewIndividual from "./ViewReviewIndividual";
////////////////

// css files
require("./HomeCSS/profile.css");
require("./HomeCSS/viewOtherUserProfile.css");

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Profile = ({
  otherUserProfileId,
  goBackToMapPage,
  openChatsForOpenedProfile,
}) => {
  const dispatch = useDispatch();
  const { status, userData, getOtherUserProfileData, reviewCreated } =
    useSelector((state) => state.profileSlice);
  const [otherUserProfileIdSave, setOtherUserProfileIdSave] = useState(
    otherUserProfileId && otherUserProfileId
  );
  const [profileData, setProfileData] = useState({});
  const [selectedTile, setSelectedTile] = useState(null);
  const [isNotMoving, setIsNotMoving] = useState(true);
  const [isViewReviews, setIsViewReviews] = useState(false);
  const [isReviewAddedAlready, setIsReviewAddedAlready] = useState(true);
  const [isReviewOpenToView, setIsReviewOpenToView] = useState(false);
  const [reviewData, setReviewData] = useState(null);
  const [isViewCatalog, setIsViewCatalog] = useState(false);
  const [isCatalogOfOtherProfile, setIsCatalogOfOtherProfile] = useState(false);
  const [catalogSet, setCatalogSet] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupMsg, setShowPopupMsg] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [numPages, setNumPages] = useState(null);

  useEffect(() => {
    setOtherUserProfileIdSave(otherUserProfileId);
    dispatch(getUserProfile());
    dispatch(getOtherUserProfile(otherUserProfileIdSave));
  }, [otherUserProfileId, otherUserProfileIdSave]);

  useEffect(() => {
    if (reviewCreated) {
      dispatch(getOtherUserProfile(otherUserProfileIdSave));
      setShowPopupMsg("Review created!");
      setShowSuccessPopup(true);
      setTimeout(() => {
        setShowSuccessPopup(false);
        dispatch(resetCreatedReviewStatus());
        setShowPopupMsg("");
      }, 3000);
    }
  }, [reviewCreated]);

  useEffect(() => {
    setProfileData(getOtherUserProfileData);
    // console.log("other: " + JSON.stringify(getOtherUserProfileData));
    // console.log("other: " + JSON.stringify(profileData));
    setFormData({
      status: getOtherUserProfileData?.user?.status?.text
        ? getOtherUserProfileData?.user?.status?.text
        : null,
      name: getOtherUserProfileData?.user?.name
        ? getOtherUserProfileData?.user?.name
        : null,
      phoneNumber: getOtherUserProfileData?.user?.phoneNumber
        ? getOtherUserProfileData?.user?.phoneNumber
        : null,
      designation: getOtherUserProfileData?.user?.designation
        ? getOtherUserProfileData?.user?.designation
        : null,
      company: getOtherUserProfileData?.user?.company
        ? getOtherUserProfileData?.user?.company
        : null,
      location: getOtherUserProfileData?.user?.location
        ? getOtherUserProfileData?.user?.location
        : null,
      nationality: getOtherUserProfileData?.user?.nationality
        ? getOtherUserProfileData?.user?.nationality
        : null,
      brn: getOtherUserProfileData?.user?.BRN
        ? getOtherUserProfileData?.user?.BRN
        : null,
      languages: getOtherUserProfileData?.user?.languages
        ? getOtherUserProfileData?.user?.languages
        : null,
      socialLinks: {
        facebook: getOtherUserProfileData?.user?.socialLinks?.facebook
          ? getOtherUserProfileData?.user?.socialLinks?.facebook
          : "",
        instagram: getOtherUserProfileData?.user?.socialLinks?.instagram
          ? getOtherUserProfileData?.user?.socialLinks?.instagram
          : "",
        linkedIn: getOtherUserProfileData?.user?.socialLinks?.linkedIn
          ? getOtherUserProfileData?.user?.socialLinks?.linkedIn
          : "",
        youtube: getOtherUserProfileData?.user?.socialLinks?.youtube
          ? getOtherUserProfileData?.user?.socialLinks?.youtube
          : "",
      },
      areas: getOtherUserProfileData?.user?.areas
        ? getOtherUserProfileData?.user?.areas
        : null,
      about: getOtherUserProfileData?.user?.about
        ? getOtherUserProfileData?.user?.about
        : null,
      brokerServices: getOtherUserProfileData?.user?.brokerServices
        ? getOtherUserProfileData?.user?.brokerServices
        : null,
    });
  }, [getOtherUserProfileData]);

  const [formData, setFormData] = useState({
    status: profileData?.user?.status?.text
      ? profileData?.user?.status?.text
      : null,
    name: profileData?.user?.name ? profileData?.user?.name : null,
    designation: profileData?.user?.designation
      ? profileData?.user?.designation
      : null,
    company: profileData?.user?.company ? profileData?.user?.company : null,
    location: profileData?.user?.location ? profileData?.user?.location : null,
    phoneNumber: profileData?.user?.phoneNumber
      ? profileData?.user?.phoneNumber
      : null,
    nationality: profileData?.user?.nationality
      ? profileData?.user?.nationality
      : null,
    brn: profileData?.user?.BRN ? profileData?.user?.BRN : null,
    languages: profileData?.user?.languages
      ? profileData?.user?.languages
      : null,
    socialLinks: {
      facebook: profileData?.user?.socialLinks?.facebook
        ? profileData?.user?.socialLinks?.facebook
        : "",
      instagram: profileData?.user?.socialLinks?.instagram
        ? profileData?.user?.socialLinks?.instagram
        : "",
      linkedIn: profileData?.user?.socialLinks?.linkedIn
        ? profileData?.user?.socialLinks?.linkedIn
        : "",
      youtube: profileData?.user?.socialLinks?.youtube
        ? profileData?.user?.socialLinks?.youtube
        : "",
    },
    areas: profileData?.user?.areas ? profileData?.user?.areas : null,
    about: profileData?.user?.about ? profileData?.user?.about : null,
    brokerServices: profileData?.user?.brokerServices
      ? profileData?.user?.brokerServices
      : null,
  });

  useEffect(() => {
    const checkIfReviewAddedAlready = async () => {
      let isAlreadyAdded = false;
      if (otherUserProfileId && otherUserProfileId === userData?.user?._id) {
        isAlreadyAdded = true;
        // console.log("Own Profile: " + isAlreadyAdded);
      }
      profileData?.reviews?.forEach((review) => {
        if (review?.reviewer?._id === userData?.user?._id) {
          isAlreadyAdded = true;
          // console.log("already added: " + isAlreadyAdded);
        }
      });
      setIsReviewAddedAlready(isAlreadyAdded);
    };

    checkIfReviewAddedAlready();
  }, [profileData, getOtherUserProfileData, userData, isReviewAddedAlready]);

  // useEffect(() => {
  //   dispatch(resetState());
  // }, [dispatch]);

  const renderStars = (rating) => {
    const filledStars = Math.floor(rating);
    const remainingStars = 5 - filledStars;
    return (
      <>
        <div className="ratingStars">
          {[...Array(filledStars)].map((_, index) => (
            <span key={index} className="filled-star">
              <FillStar />
            </span>
          ))}
          {[...Array(remainingStars)].map((_, index) => (
            <span key={filledStars + index} className="unfilled-star">
              <UnFillStar />
            </span>
          ))}
        </div>
      </>
    );
  };

  const handleTileClick = (image) => {
    setSelectedTile(image);
  };

  const handleCloseModal = () => {
    setSelectedTile(null);
  };

  const openReviewPage = () => {
    setIsNotMoving(false);
    setIsViewReviews(true);
  };

  const setReviewPage = () => {
    setIsNotMoving(true);
    setIsViewReviews(false);
  };

  const addReview = () => {
    setIsReviewOpenToView(true);
  };

  const saveReview = (newReviewData) => {
    setIsReviewOpenToView(false);
    const dataTOSend = {
      text: newReviewData.text,
      isPublic: true,
      user: getOtherUserProfileData?.user?._id,
      rating: newReviewData.rating,
    };
    dispatch(createReview(dataTOSend));
  };

  const openReviewCard = (selectedRevieData) => {
    setIsReviewOpenToView(true);
    setReviewData(selectedRevieData);
  };
  const closeReviewCard = () => {
    setIsReviewOpenToView(false);
    setReviewData(null);
  };

  const handleEditCatalog = (catalogItem) => {
    const selected = catalogItem;
    setCatalogSet(selected);
    setIsViewCatalog(true);
    setIsNotMoving(false);
    setIsCatalogOfOtherProfile(true);
  };

  const moveBack = () => {
    setIsNotMoving(true);
    if (isViewCatalog) {
      setIsViewCatalog(false);
      setCatalogSet(null);
    }
  };

  const checkIfImage = (url) => {
    const imageExtensions = /\.(jpg|jpeg|png|gif|bmp|avif|webp)$/i;
    return imageExtensions.test(url);
  };

  const [isLoaderOpen, sesIsLoaderOpen] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      sesIsLoaderOpen(false);
    }, 3000);

    // Clean up the timer to avoid memory leaks
    return () => clearTimeout(timer);
  }, []);

  const closePopup = () => {
    setShowPopup(false);
    setShowPopupMsg("");
  };

  const closeSuccessPopup = () => {
    setShowSuccessPopup(false);
    setShowPopupMsg("");
  };

  const openChatRoom = () => {
    // let userId = otherUserProfileId;
    openChatsForOpenedProfile(profileData?.user?._id);
    localStorage.setItem("tupoa", true);
  };

  const getFileType = (url) => {
    const extension = url.toLowerCase().split(".").pop();
    if (extension === "pdf") {
      return "pdf";
    } else if (extension === "doc" || extension === "docx") {
      return "word";
    } else {
      return "unknown";
    }
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <>
      {showSuccessPopup && (
        <EventSuccessPopup
          showPopupMsg={showPopupMsg}
          onClose={closeSuccessPopup}
        />
      )}
      {showPopup && (
        <EventFailedPopup showPopupMsg={showPopupMsg} onClose={closePopup} />
      )}
      {isLoaderOpen ? (
        <LoadingSpinner />
      ) : (
        <>
          {getOtherUserProfile === null || status === "loading" ? (
            <LoadingSpinner />
          ) : (
            <>
              {isNotMoving && (
                <>
                  <div className="OtherUserProfileHeader">
                    <div className="moveback" onClick={goBackToMapPage}>
                      <MoveBackIcon />
                    </div>
                    {/* <span>Profile</span> */}
                    {profileData?.user?._id !== userData?.user?._id && (
                      <div
                        className="chatTextIcon"
                        onClick={
                          localStorage.getItem("tupoa") === "true"
                            ? null
                            : openChatRoom
                        }
                      >
                        <ChatTextIcon />
                      </div>
                    )}
                  </div>
                  <div className="profileMain topSet viewProfile">
                    {getOtherUserProfileData?.user?.role === "realtor" &&
                      formData?.status !== null && (
                        <div className="availableOfferTag">
                          <p>{formData?.status}</p>
                        </div>
                      )}
                    <div className="profilePersonalInfo">
                      <div className="profileBlock">
                        <div className="profilePic">
                          {profileData?.user?.image && (
                            <img src={profileData?.user?.image} alt="Profile" />
                          )}
                        </div>
                        {getOtherUserProfileData &&
                          getOtherUserProfileData?.user?.role === "realtor" && (
                            <div className="socialLinks">
                              {profileData?.user?.socialLinks?.facebook ? (
                                <div className="link Facebook">
                                  <Link
                                    target="_blank"
                                    to={
                                      profileData?.user?.socialLinks?.facebook
                                    }
                                  >
                                    <FacebookIcon />
                                  </Link>
                                </div>
                              ) : (
                                ""
                              )}
                              {profileData?.user?.socialLinks?.instagram ? (
                                <div className="link Instagram">
                                  <Link
                                    target="_blank"
                                    to={
                                      profileData?.user?.socialLinks?.instagram
                                    }
                                  >
                                    <InstagramIcon />
                                  </Link>
                                </div>
                              ) : (
                                ""
                              )}
                              {profileData?.user?.socialLinks?.linkedIn ? (
                                <div className="link LinkedIn">
                                  <Link
                                    target="_blank"
                                    to={
                                      profileData?.user?.socialLinks?.linkedIn
                                    }
                                  >
                                    <LinkedIn />
                                  </Link>
                                </div>
                              ) : (
                                ""
                              )}
                              {profileData?.user?.socialLinks?.youtube ? (
                                <div className="link Youtube">
                                  <Link
                                    target="_blank"
                                    to={profileData?.user?.socialLinks?.youtube}
                                  >
                                    <YoutubeIcon />
                                  </Link>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          )}
                      </div>
                      <div className="personalInfo">
                        <div className="headerLine">
                          <span>Personal Info</span>
                        </div>
                        <div className="firstLinePersonalInfo">
                          {formData?.name !== null && (
                            <div className="row">
                              <span>Name:</span>
                              <p>{formData?.name}</p>
                            </div>
                          )}
                          {getOtherUserProfileData?.user?.role ===
                            "realtor" && (
                            <>
                              {formData?.company !== null && (
                                <div className="row">
                                  <span>Company:</span>
                                  <p>{formData?.company}</p>
                                </div>
                              )}
                              {formData?.designation !== null && (
                                <div className="row">
                                  <span>Designation:</span>
                                  <p>{formData?.designation}</p>
                                </div>
                              )}
                              {formData?.phoneNumber !== null && (
                                <div className="row">
                                  <span>Number:</span>
                                  <p>{formData?.phoneNumber}</p>
                                </div>
                              )}
                            </>
                          )}
                          {formData?.user?.email !== null && (
                            <div className="row">
                              <span>Email:</span>
                              <p>{profileData?.user?.email}</p>
                            </div>
                          )}
                          {formData?.location?.address !== null && (
                            <div className="row">
                              <span>Address:</span>
                              <p>{formData?.location?.address}</p>
                            </div>
                          )}
                        </div>
                        {getOtherUserProfileData?.user?.role === "realtor" &&
                          formData?.brn !== null &&
                          formData?.nationality !== null &&
                          formData?.languages !== null &&
                          formData?.areas !== null && (
                            <div className="secondLinePersonalInfo">
                              {formData?.brn !== null && (
                                <div className="row">
                                  <span>BRN:</span>
                                  <p>{formData?.brn}</p>
                                </div>
                              )}
                              {formData?.nationality !== null && (
                                <div className="row">
                                  <span>Nationality:</span>
                                  <p>{formData?.nationality}</p>
                                </div>
                              )}
                              {formData?.languages !== null && (
                                <div className="row">
                                  <span>Languages:</span>
                                  <p>
                                    {Array.isArray(formData?.languages)
                                      ? formData?.languages.join(", ")
                                      : formData?.languages}
                                  </p>
                                </div>
                              )}
                              {formData?.areas !== null && (
                                <div className="row">
                                  <span>Areas:</span>
                                  <p>
                                    {Array.isArray(formData?.areas)
                                      ? formData?.areas.join(", ")
                                      : formData?.areas}
                                  </p>
                                </div>
                              )}
                            </div>
                          )}
                      </div>
                    </div>
                    {getOtherUserProfileData?.user?.role === "realtor" && (
                      <>
                        {formData?.about !== null && (
                          <div className="aboutmeInfo">
                            <div className="headerInfoLine">
                              <div className="heading">
                                <div className="infoIcon">
                                  <InfoiIcon />
                                </div>
                                <span>About Me</span>
                              </div>
                            </div>
                            <div className="aboutInfoPara">
                              <p>{formData?.about}</p>
                            </div>
                          </div>
                        )}
                        {profileData?.catalog &&
                          profileData?.catalog?.length > 0 && (
                            <div className="catalogInfo">
                              <div className="headerInfoLine">
                                <div className="heading">
                                  <div className="catalogIcon">
                                    <CatalogIcon />
                                  </div>
                                  <span>Catalog</span>
                                </div>
                              </div>
                              <div className="catalogs">
                                <div className="catalogsImages">
                                  {profileData?.catalog?.map((catalogItem) => (
                                    <div
                                      className="catalogsImage"
                                      key={catalogItem._id}
                                      onClick={() =>
                                        handleEditCatalog(catalogItem)
                                      }
                                    >
                                      {catalogItem.images &&
                                        catalogItem.images.length > 0 && (
                                          <img
                                            src={catalogItem.images[0].url}
                                            alt={`${catalogItem.images[0]._id}`}
                                          />
                                        )}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          )}
                        {formData?.brokerServices !== null && (
                          <div className="brokerServicesDetails">
                            <div className="headerInfoLine">
                              <div className="heading">
                                <div className="homeIcon">
                                  <HomeIcon />
                                </div>
                                <span>Broker Services Details</span>
                              </div>
                            </div>
                            <div className="brokerServicesPara">
                              <p>{formData?.brokerServices}</p>
                            </div>
                          </div>
                        )}
                        {profileData?.awards?.length > 0 && (
                          <div className="achievementTag">
                            <div className="headerInfoLine">
                              <div className="heading">
                                <div className="achievementIcon">
                                  <AchievementIcon />
                                </div>
                                <span>Achievements</span>
                              </div>
                            </div>
                            <div className="achievementTilesBox">
                              {profileData?.awards?.map((doc, index) => (
                                <div
                                  className="achievementTile"
                                  key={doc._id}
                                  onClick={() => handleTileClick(doc.image)}
                                >
                                  <div className="achievementTileImage">
                                    {doc?.image && checkIfImage(doc?.image) && (
                                      <img
                                        src={doc.image}
                                        alt={`award-${index + 1}`}
                                      />
                                    )}
                                    {!checkIfImage(doc?.image) && (
                                      <>
                                        {getFileType(doc?.image) === "word" ||
                                        getFileType(doc?.image) ===
                                          "unknown" ? (
                                          <img src={FileIcon} alt="File" />
                                        ) : (
                                          <>
                                            {getFileType(doc?.image) ===
                                            "pdf" ? (
                                              <Document file={doc?.image}>
                                                <Page pageNumber={1} />
                                              </Document>
                                            ) : (
                                              ""
                                            )}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </div>
                                  <div className="achievementTileTitile">
                                    <p>{doc.text}</p>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                    <div className="reviewsTag">
                      <div className="headerInfoLine">
                        <div className="heading">
                          <div className="starIcon">
                            <StarIcon />
                          </div>
                          <span>Reviews</span>
                        </div>
                        {!isReviewAddedAlready && (
                          <div
                            className="editButton savebtn"
                            onClick={() => addReview()}
                            style={{
                              pointerEvents: !isReviewAddedAlready
                                ? "visible"
                                : "none",
                            }}
                          >
                            Add
                          </div>
                        )}
                      </div>
                      {profileData?.reviews?.length > 0 && (
                        <div className="reviewList">
                          <div className="reviewcardLine">
                            {profileData?.reviews?.slice(0, 4).map((review) => (
                              <div
                                key={review?._id}
                                className="reviewCard"
                                onClick={() => openReviewCard(review)}
                              >
                                <div className="cardHeaderLine">
                                  <div className="cardFirstLine">
                                    <div className="userProfilePic">
                                      <img
                                        src={review?.reviewer?.image}
                                        alt={`User ${review?.reviewer?._id}`}
                                      />
                                    </div>
                                    <span className="userName">
                                      {review?.reviewer?.name}
                                    </span>
                                  </div>
                                  {renderStars(review?.rating)}
                                </div>
                                <div className="reviewContent">
                                  <p>
                                    {review?.text?.length > 150
                                      ? `${review.text.substring(0, 150)}...`
                                      : review?.text}
                                  </p>
                                </div>
                              </div>
                            ))}
                          </div>
                          {profileData?.reviews?.length > 0 && (
                            <div
                              className="seeMoreTag"
                              onClick={() => openReviewPage()}
                            >
                              <div className="chevronIcon">
                                <ChevronRightIcon />
                              </div>
                              {/* <div className="btntext">See More</div> */}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    {selectedTile && (
                      <div className="modal">
                        <span
                          className="closeButton"
                          onClick={handleCloseModal}
                        >
                          Close
                        </span>
                        {/* <img src={selectedTile} alt="Selected Award" /> */}
                        {selectedTile && selectedTile.endsWith(".pdf") ? (
                          <div
                            className="modalContent"
                            style={{
                              overflowY: "auto",
                            }}
                          >
                            <Document
                              file={selectedTile}
                              onLoadSuccess={onDocumentLoadSuccess}
                            >
                              {Array.from(new Array(numPages), (el, index) => (
                                <Page
                                  key={`page_${index + 1}`}
                                  pageNumber={index + 1}
                                  className="pdf-page"
                                />
                              ))}
                            </Document>
                          </div>
                        ) : (
                          <div className="modalContent">
                            <TransformWrapper
                              defaultScale={1}
                              defaultPositionX={100}
                              defaultPositionY={100}
                              wheel={{ step: 10 }}
                              options={{ limitToBounds: false }}
                              pan={{ disabled: true }}
                            >
                              <TransformComponent>
                                <img src={selectedTile} alt="Selected Award" />
                              </TransformComponent>
                            </TransformWrapper>
                          </div>
                        )}
                      </div>
                    )}
                    {isReviewOpenToView && (
                      <ViewReviewIndividual
                        reviewData={reviewData}
                        goBackToProfilePage={closeReviewCard}
                        onSave={saveReview}
                      />
                    )}
                  </div>
                </>
              )}
            </>
          )}
          {isViewReviews && (
            <ReviewList
              ReviewList={profileData?.reviews}
              goBackToProfilePage={setReviewPage}
            />
          )}
          {isViewCatalog && (
            <AddCatalog
              catalogSet={catalogSet}
              isViewCatalog={isViewCatalog}
              isCatalogOfOtherProfile={isCatalogOfOtherProfile}
              goBackToProfilePage={moveBack}
            />
          )}
        </>
      )}
    </>
  );
};

export default Profile;
