import React from "react";

const ProfileIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.4399 0.0195312C12.6699 0.0695313 12.9099 0.0995312 13.1399 0.159531C15.4899 0.779531 17.0099 2.27953 17.4999 4.65953C17.9299 6.72953 17.3399 8.56953 15.7599 10.0195C13.9799 11.6495 11.8899 12.0095 9.68991 11.1295C7.64991 10.3195 6.40992 8.73953 6.13992 6.51953C5.81992 3.88953 7.12991 1.58953 9.53991 0.499531C10.0099 0.289531 10.5499 0.209531 11.0599 0.0695313C11.1199 0.0495313 11.1699 0.0395312 11.2299 0.0195312C11.6399 0.0195312 12.0399 0.0195312 12.4499 0.0195312H12.4399ZM16.2099 5.78953C16.2099 3.35953 14.2599 1.41953 11.8499 1.42953C9.43991 1.42953 7.50991 3.36953 7.48991 5.77953C7.46991 8.21953 9.52991 10.1695 11.8499 10.1495C14.2799 10.1295 16.2099 8.21953 16.2099 5.78953Z"
        fill="white"
      />
      <path
        d="M12 23.97C9.99997 23.97 8.00997 23.98 6.00997 23.97C4.97997 23.97 4.04997 23.64 3.26997 22.94C2.56997 22.31 2.14997 21.53 2.06997 20.61C1.88997 18.62 2.11997 16.67 2.85997 14.81C3.29997 13.7 3.95997 12.74 5.05997 12.14C5.74997 11.77 6.47997 11.59 7.25997 11.6C7.49997 11.6 7.75997 11.72 7.97997 11.84C8.33997 12.03 8.66997 12.27 9.00997 12.49C10.05 13.14 11.17 13.5 12.4 13.4C13.3 13.33 14.13 13.03 14.9 12.57C15.17 12.4 15.46 12.25 15.7 12.05C16.33 11.55 17.02 11.53 17.76 11.7C19.17 12.01 20.16 12.86 20.83 14.11C21.4 15.17 21.71 16.32 21.85 17.51C21.95 18.37 21.99 19.25 21.99 20.12C21.99 22.01 20.69 23.54 18.83 23.88C18.46 23.95 18.09 23.98 17.72 23.98C15.82 23.98 13.92 23.98 12.01 23.98L12 23.97ZM12 22.57C12.72 22.57 13.43 22.57 14.15 22.57C15.4 22.57 16.64 22.59 17.89 22.57C19.64 22.53 20.61 21.51 20.58 19.77C20.57 19.08 20.52 18.38 20.44 17.7C20.32 16.69 20.08 15.7 19.58 14.79C19.12 13.96 18.5 13.33 17.54 13.11C17.12 13.01 16.73 12.99 16.38 13.3C16.19 13.47 15.95 13.59 15.74 13.72C13.58 15.05 11.33 15.24 9.02997 14.13C8.44997 13.85 7.91997 13.47 7.36997 13.14C7.28997 13.09 7.17997 13.04 7.08997 13.04C6.27997 13.07 5.56997 13.35 5.01997 13.97C4.49997 14.56 4.16997 15.25 3.93997 15.99C3.53997 17.27 3.43997 18.58 3.42997 19.91C3.42997 20.78 3.68997 21.55 4.43997 22.08C4.99997 22.48 5.64997 22.59 6.31997 22.59C8.20997 22.59 10.11 22.59 12 22.59V22.57Z"
        fill="white"
      />
    </svg>
  );
};

export default ProfileIcon;
