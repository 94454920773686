import React, { useState, useEffect } from "react";
import Artboard_icon from "../../../assets/images/Artboard.png";
import ProfilePic from "../../../assets/images/Ellipse.png";
import "../HomeComponent/HomeCSS/homeConstantsCSS.css";
// import SearchIcon from "../../../assets/svgs/SearchIcon";
import LocationIcon from "../../../assets/svgs/LocationIcon";
import PropertyIcon from "../../../assets/svgs/PropertyIcon";
import ContactIcon from "../../../assets/svgs/ContactIcon";
import NotificationIcon from "../../../assets/svgs/NotificationIcon";
import ProfileIcon from "../../../assets/svgs/ProfileIcon";
import SettingsIcon from "../../../assets/svgs/SettingsIcon";
import LogoutIcon from "../../../assets/svgs/LogoutIcon";
import sideNavItems from "../../../utils/json/TabsList.json";
import FacebookIcon from "../../../assets/svgs/FacebookIcon";
import InstagramIcon from "../../../assets/svgs/InstagramIcon";
import LinkedIn from "../../../assets/svgs/LinkedIn";
import YoutubeIcon from "../../../assets/svgs/YoutubeIcon";
import { Link, useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../../utils/AppConstants";
import { useDispatch, useSelector } from "react-redux";
import {
  resetNewImageStatus,
  resetUpdateProfile,
} from "../../../api/reducerSlices/profileSlice";
require("./nav.css");

const NavigationComponent = ({
  onNavigate,
  setSidebar,
  logoutLayout,
  activeTabForNav,
  setActiveTabForNav,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isActiveTab, setActiveTab] = useState(
    () => localStorage.getItem("isActiveTab") || sideNavItems[0].slug
  );
  const { user } = useSelector((state) => state.auth);
  const { userData, userImageUpdate, userUpdateProfile } = useSelector(
    (state) => state.profileSlice
  );
  const [userSideBarData, setUserSideBarData] = useState({
    name: "",
    image: "",
  });

  const [userSocialLinks, setUserSocialLinks] = useState({
    facebook: userData?.user?.socialLinks?.facebook
      ? userData?.user?.socialLinks?.facebook
      : "",
    instagram: userData?.user?.socialLinks?.instagram
      ? userData?.user?.socialLinks?.instagram
      : "",
    linkedIn: userData?.user?.socialLinks?.linkedIn
      ? userData?.user?.socialLinks?.linkedIn
      : "",
    youtube: userData?.user?.socialLinks?.youtube
      ? userData?.user?.socialLinks?.youtube
      : "",
  });

  // console.log(user)
  useEffect(() => {
    setUserSocialLinks({
      facebook: userData?.user?.socialLinks?.facebook
        ? userData?.user?.socialLinks?.facebook
        : "",
      instagram: userData?.user?.socialLinks?.instagram
        ? userData?.user?.socialLinks?.instagram
        : "",
      linkedIn: userData?.user?.socialLinks?.linkedIn
        ? userData?.user?.socialLinks?.linkedIn
        : "",
      youtube: userData?.user?.socialLinks?.youtube
        ? userData?.user?.socialLinks?.youtube
        : "",
    });
  }, [userData?.socialLinks]);

  useEffect(() => {
    setUserSideBarData((prevState) => ({
      ...prevState,
      name: userData?.user?.name || user?.name || "",
      image: userData?.user?.image || user?.image || "",
    }));
  }, []);

  useEffect(() => {
    setUserSideBarData((prevState) => ({
      ...prevState,
      image: userData?.user?.image || user?.image,
    }));
    dispatch(resetNewImageStatus());
  }, [userImageUpdate]);

  useEffect(() => {
    setUserSideBarData((prevState) => ({
      ...prevState,
      name: userData?.user?.name || user?.name,
    }));
    dispatch(resetUpdateProfile());
  }, [userUpdateProfile]);

  // console.log("user infoooo: " + JSON.stringify(user));
  useEffect(() => {
    if (isActiveTab === sideNavItems[6].slug) {
      localStorage.setItem("isActiveTab", sideNavItems[0].slug);
    } else {
      localStorage.setItem("isActiveTab", isActiveTab);
    }
  }, [isActiveTab]);

  const onTabSelected = (item) => {
    if (item === sideNavItems[6].slug) {
      logoutLayout();
    }
    if (
      localStorage.getItem("isActiveTab") &&
      localStorage.getItem("isActiveTab") === item
    ) {
      window.location.reload();
    } else {
      setSidebar();
      setActiveTab(item);
      switch (item) {
        case sideNavItems[0].slug:
          onNavigate(APP_ROUTES.Realtor_ROUTE);
          break;
        case sideNavItems[1].slug:
          onNavigate(APP_ROUTES.Property_ROUTE);
          break;
        case sideNavItems[2].slug:
          onNavigate(APP_ROUTES.Contact_ROUTE);
          break;
        case sideNavItems[3].slug:
          onNavigate(APP_ROUTES.Notifications_ROUTE);
          break;
        case sideNavItems[4].slug:
          onNavigate(APP_ROUTES.Profile_ROUTE);
          break;
        case sideNavItems[5].slug:
          onNavigate(APP_ROUTES.Settings_ROUTE);
          break;
        // case sideNavItems[6].slug:
        //   onNavigate("logout");
        //   break;
        default:
      }
    }
    // console.log(navigate);
  };

  useEffect(() => {
    if (activeTabForNav === true) {
      setActiveTab(sideNavItems[5].slug);
      setActiveTabForNav(false);
    }
  }, [activeTabForNav]);

  return (
    <>
      <div className="navigate">
        <div className="artboard">
          <div className="artboardPic">
            <img src={Artboard_icon} alt="" />
          </div>
          <span>
            <strong>REALTOR </strong>ORGANIZER
          </span>
        </div>
        <div className="navLinks">
          <div className="firstLine">
            <ul>
              <li
                className={`${
                  isActiveTab === sideNavItems[0].slug && "active"
                }`}
                onClick={() => {
                  onTabSelected(sideNavItems[0].slug);
                }}
              >
                <LocationIcon />
                {sideNavItems[0].text}
              </li>
              {user?.role === "realtor" && (
                <li
                  className={`${
                    isActiveTab === sideNavItems[1].slug && "active"
                  }`}
                  onClick={() => {
                    onTabSelected(sideNavItems[1].slug);
                  }}
                >
                  <PropertyIcon />
                  {sideNavItems[1].text}
                </li>
              )}
              {user?.role === "realtor" && (
                <li
                  className={`${
                    isActiveTab === sideNavItems[2].slug && "active"
                  }`}
                  onClick={() => {
                    onTabSelected(sideNavItems[2].slug);
                  }}
                >
                  <ContactIcon />
                  {sideNavItems[2].text}
                </li>
              )}
            </ul>
          </div>
          <div className="line"></div>
          <div className="secondLine">
            <ul>
              <li
                className={`${
                  isActiveTab === sideNavItems[3].slug && "active"
                }`}
                onClick={() => {
                  onTabSelected(sideNavItems[3].slug);
                }}
              >
                <NotificationIcon />
                {sideNavItems[3].text}
              </li>
              <li
                className={`${
                  isActiveTab === sideNavItems[4].slug && "active"
                }`}
                onClick={() => {
                  onTabSelected(sideNavItems[4].slug);
                }}
              >
                <ProfileIcon />
                {sideNavItems[4].text}
              </li>
              <li
                className={`${
                  isActiveTab === sideNavItems[5].slug && "active"
                }`}
                onClick={() => {
                  onTabSelected(sideNavItems[5].slug);
                }}
              >
                <SettingsIcon />
                {sideNavItems[5].text}
              </li>
              <li
                className={`${
                  isActiveTab === sideNavItems[6].slug && "active"
                }`}
                // onClick={() => {
                //   onTabSelected(sideNavItems[6].slug);
                // }}
                onClick={() => {
                  logoutLayout();
                }}
              >
                <LogoutIcon />
                {sideNavItems[6].text}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="profileBlock">
        <div className="profilePic">
          {userSideBarData?.image ? (
            <img src={userSideBarData?.image} alt={""} />
          ) : (
            // <img src={ProfilePic} alt={ProfilePic} />
            ""
          )}
        </div>
        <div className="userDetail">
          <strong className="userName">
            {userSideBarData?.name &&
              userSideBarData?.name?.slice(0, 25) +
                (user?.name?.length > 25 ? "..." : "")}
          </strong>
          {user?.role === "realtor" && (
            <div className="socialLinks">
              {user?.socialLinks?.facebook ? (
                <div className="link Facebook">
                  <Link
                    target="_blank"
                    to={
                      userSocialLinks?.facebook
                        ? userSocialLinks?.facebook
                        : user?.socialLinks?.facebook
                    }
                  >
                    <FacebookIcon />
                  </Link>
                </div>
              ) : (
                // <FacebookIcon />
                ""
              )}
              {user?.socialLinks?.instagram ? (
                <div className="link Instagram">
                  <Link
                    target="_blank"
                    to={
                      userSocialLinks?.instagram
                        ? userSocialLinks?.instagram
                        : user?.socialLinks?.instagram
                    }
                  >
                    <InstagramIcon />
                  </Link>
                </div>
              ) : (
                // <InstagramIcon />
                ""
              )}
              {user?.socialLinks?.linkedIn ? (
                <div className="link LinkedIn">
                  <Link
                    target="_blank"
                    to={
                      userSocialLinks?.linkedIn
                        ? userSocialLinks?.linkedIn
                        : user?.socialLinks?.linkedIn
                    }
                  >
                    <LinkedIn />
                  </Link>
                </div>
              ) : (
                // <LinkedIn />
                ""
              )}
              {user?.socialLinks?.youtube ? (
                <div className="link Youtube">
                  <Link
                    target="_blank"
                    to={
                      userSocialLinks?.youtube
                        ? userSocialLinks?.youtube
                        : user?.socialLinks?.youtube
                    }
                  >
                    <YoutubeIcon />
                  </Link>
                </div>
              ) : (
                // <YoutubeIcon />
                ""
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default NavigationComponent;
