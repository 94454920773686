import React from "react";

const LogoutIcon = () => {
  return (
    <svg width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1372_3520)">
        <path d="M14.5478 0C14.6174 0.026087 14.687 0.0608696 14.7565 0.0869565C15.2435 0.295652 15.513 0.791304 15.4261 1.32174C15.3478 1.81739 14.9043 2.21739 14.3913 2.24348C14.3043 2.24348 14.2174 2.24348 14.1304 2.24348C10.713 2.24348 7.30435 2.24348 3.88696 2.24348C2.85217 2.24348 2.23478 2.86087 2.23478 3.88696C2.23478 9.29565 2.23478 14.6957 2.23478 20.1043C2.23478 21.1304 2.85217 21.7478 3.88696 21.7478C7.34783 21.7478 10.8087 21.7478 14.2696 21.7478C14.8609 21.7478 15.3391 22.1304 15.4261 22.6696C15.5217 23.2522 15.2087 23.7565 14.6174 23.9565C14.5913 23.9652 14.5652 23.9826 14.5391 24H3.32174C3.32174 24 3.23478 23.9652 3.1913 23.9565C1.36522 23.687 0 22.1304 0 20.2696C0 14.7565 0 9.24348 0 3.73043C0 2.01739 1.17391 0.53913 2.82609 0.113043C2.9913 0.0695652 3.15652 0.0347826 3.32174 0C7.06087 0 10.8087 0 14.5478 0Z" fill="white" />
        <path d="M17.0176 10.8786C16.922 10.7742 16.8611 10.7133 16.8089 10.6525C15.7481 9.5916 14.6872 8.53073 13.635 7.46986C13.1046 6.93073 13.1742 6.10464 13.7828 5.70464C14.2002 5.42638 14.7481 5.46116 15.1307 5.7916C15.1742 5.82638 15.2089 5.86986 15.2524 5.90464C17.0002 7.65247 18.7481 9.40899 20.4959 11.1568C21.0089 11.6699 21.0089 12.3307 20.4959 12.8351C18.7481 14.5916 17.0002 16.3394 15.2524 18.0872C14.7655 18.5742 14.0785 18.5916 13.6263 18.1481C13.1655 17.6959 13.1828 16.9916 13.6698 16.4959C14.7133 15.4438 15.7655 14.4003 16.8089 13.3481C16.8698 13.2873 16.9481 13.2525 17.0176 13.2003C17.0002 13.1742 16.9915 13.1481 16.9742 13.122C16.8959 13.122 16.8176 13.122 16.7307 13.122C13.9046 13.122 11.0872 13.122 8.26111 13.122C7.69589 13.122 7.26111 12.8177 7.11328 12.3307C6.89589 11.6177 7.40894 10.8872 8.15676 10.8699C8.79155 10.8612 9.41763 10.8699 10.0524 10.8699C12.2698 10.8699 14.4785 10.8699 16.6959 10.8699C16.7828 10.8699 16.8698 10.8699 17.0089 10.8699L17.0176 10.8786Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_1372_3520">
          <rect width="20.8696" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LogoutIcon;