import React, { useState, useEffect } from "react";
import { getAppContacts } from "../../api/actions/homeActions";
import { useDispatch, useSelector } from "react-redux";
require("./ChatsCSS/contactForChats.css");

const ContactsForChats = ({
  searchQueryForContactsToChats,
  selectContactToChat,
}) => {
  const dispatch = useDispatch();
  const { appContacts } = useSelector((state) => state.homeSlice);
  const [contacts, setContacts] = useState();
  // const [selectedContact, setSelectedContact] = useState(null);
  const [filteredTiles, setFilteredTiles] = useState([]);
  const [searchQuerysfc, setSearchQuerysfc] = useState("");
  const [contactsCountOnsearch, setContactsCountOnsearch] = useState(0);
  const [buttonVisibility, setButtonVisibility] = useState([]);

  useEffect(() => {
    dispatch(getAppContacts());
  }, []);

  useEffect(() => {
    setContacts(appContacts);
  }, [appContacts]);

  useEffect(() => {
    const filtered = contacts?.filter(
      (contact) =>
        contact?.name
          .toLowerCase()
          .includes(searchQueryForContactsToChats.toLowerCase().trim()) ||
        // contact?.email.toLowerCase().includes(searchQuerysfc.toLowerCase()) ||
        contact?.contactNumber
          .toLowerCase()
          .includes(searchQueryForContactsToChats.toLowerCase().trim())
    );
    setFilteredTiles(filtered?.length > 0 ? filtered : contacts);
    setContactsCountOnsearch(filtered?.length > 0 ? filtered?.length : 0);
  }, [searchQueryForContactsToChats, contacts]);

  const markAsSelected = (contactdata) => {
    selectContactToChat(contactdata);
  };

  return (
    <div className="contactsTileToSelectForChats">
      <div className="contactsCountLine">
        <span>Contacts (</span>
        <span className="contactCount">
          {searchQueryForContactsToChats !== "" &&
            contactsCountOnsearch + " found from "}
          {filteredTiles && contacts?.length ? contacts?.length : "..."}
        </span>
        <span>)</span>
      </div>
      <div className="addedContact fcstswo">
        {filteredTiles && filteredTiles.length > 0 ? (
          filteredTiles?.map((contact, index) => (
            <>
              <div
                key={contact._id}
                className="contact-box"
                onClick={() => markAsSelected(contact)}
              >
                <div className="profile-pic">
                  {typeof contact.image === "string" && contact.image !== "" ? (
                    <img src={contact.image} alt="" />
                  ) : (
                    <div className="initials">
                      {contact?.name?.charAt(0).toUpperCase()}
                    </div>
                  )}
                </div>
                <div className="contact-details">
                  <span>{contact.name}</span>
                  {/* <span>{contact.email}</span> */}
                  <span>{contact.contactNumber}</span>
                </div>
              </div>
            </>
          ))
        ) : (
          <>
            {contacts !== null && contacts?.length === 0 ? (
              <div className="msgforcontacts">
                <span>Nothing in contacts.</span>
              </div>
            ) : (
              <div className="ldtxt">Loading...</div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ContactsForChats;
