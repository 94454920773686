import { createSlice } from "@reduxjs/toolkit";
import {
  createCatalog,
  deleteCatalog,
  updateCatalog,
  getUserProfile,
  getOtherUserProfile,
  updateUserProfile,
  createAward,
  updateAward,
  deleteAward,
  updateUserProfileImage,
  createReview,
  sharingCatalog,
  imageOrderForCatalog,
} from "../actions/profileActions";

const initialState = {
  status: "idle",
  error: "",
  userData: {},
  getOtherUserProfileData: {},
  catalogCreated: false,
  catalogUpdated: false,
  catalogDeleted: false,
  awardCreated: false,
  awardUpdated: false,
  awardDeleted: false,
  reviewCreated: false,
  catalogShared: null,
  imageOrderApply: false,
  userUpdateProfile: false,
  userImageUpdate: false,
  userImageUpdate: false,
};

const profileSlice = createSlice({
  name: "profileSlice",
  initialState,
  reducers: {
    resetState: (state) => {
      state.status = "";
      state.error = "";
      state.catalogCreated = false;
      state.catalogUpdated = false;
      state.catalogDeleted = false;
      state.awardCreated = false;
      state.awardUpdated = false;
      state.awardDeleted = false;
      state.userData = {};
      state.getOtherUserProfileData = {};
      state.reviewCreated = false;
      state.catalogShared = null;
      state.imageOrderApply = false;
      state.userUpdateProfile = false;
    },
    resetError: (state) => {
      state.status = "idle";
      state.error = "";
    },
    resetImageOrderApplyStatus: (state) => {
      state.imageOrderApply = false;
    },
    resetCreatedReviewStatus: (state) => {
      state.reviewCreated = false;
    },
    resetNewImageStatus: (state) => {
      state.userImageUpdate = false;
    },
    resetUpdateProfile: (state) => {
      state.userUpdateProfile = false;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getUserProfile.pending, (state, action) => {
        if (state.imageOrderApply !== true) {
          state.status = "loading";
        } else {
          state.status = "";
        }
      })
      .addCase(getUserProfile.fulfilled, (state, action) => {
        if (action.payload.status === true) {
          state.status = "succeeded";
          state.userData = action.payload.data;
          // console.log("getUserProfile: ", action.payload.data)
        } else {
          state.status = "failed";
          state.error = action.payload.message;
          state.userData = null;
        }
      })
      .addCase(getUserProfile.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.userData = null;
      })
      .addCase(getOtherUserProfile.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getOtherUserProfile.fulfilled, (state, action) => {
        if (action.payload.status === true) {
          state.status = "succeeded";
          state.getOtherUserProfileData = action.payload.data;
        } else {
          state.status = "failed";
          state.error = action.payload.message;
          state.getOtherUserProfileData = null;
        }
      })
      .addCase(getOtherUserProfile.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.getOtherUserProfileData = null;
      })
      .addCase(updateUserProfile.pending, (state, action) => {
        state.status = "loading";
        state.userUpdateProfile = false;
      })
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        if (action?.payload?.status === true) {
          state.status = "succeeded";
          state.userData = action?.payload?.data;
          state.userUpdateProfile = true;

          // console.log("getUserProfile: ", action.payload.data)
        } else {
          state.status = "failed";
          state.error = action?.payload?.message;
          state.userUpdateProfile = false;
        }
      })
      .addCase(updateUserProfile.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.userData = null;
        state.userUpdateProfile = false;
      })
      .addCase(updateUserProfileImage.pending, (state, action) => {
        state.status = "loading";
        state.userImageUpdate = false;
      })
      .addCase(updateUserProfileImage.fulfilled, (state, action) => {
        if (action.payload.status === true) {
          state.status = "succeeded";
          state.userData = action.payload.data;
          // console.log("getUserProfile: ", action.payload.data)
          state.userImageUpdate = true;
        } else {
          state.status = "failed";
          state.error = action.payload.message;
          state.userData = null;
          state.userImageUpdate = false;
        }
      })
      .addCase(updateUserProfileImage.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.userData = null;
        state.userImageUpdate = false;
      })
      .addCase(createCatalog.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(createCatalog.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.catalogCreated = true;
      })
      .addCase(createCatalog.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.catalogCreated = false;
      })
      .addCase(updateCatalog.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(updateCatalog.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.catalogUpdated = true;
      })
      .addCase(updateCatalog.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.catalogUpdated = false;
      })
      .addCase(deleteCatalog.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(deleteCatalog.fulfilled, (state, action) => {
        if (action.payload.status === true) {
          state.status = "succeeded";
          state.catalogDeleted = true;
          // console.log("getUserProfile: ", action.payload.data)
        } else {
          state.status = "failed";
          state.error = action.payload.message;
          state.catalogDeleted = false;
        }
      })
      .addCase(deleteCatalog.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.catalogDeleted = false;
      })
      .addCase(createAward.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(createAward.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.awardCreated = true;
      })
      .addCase(createAward.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.awardCreated = false;
      })
      .addCase(updateAward.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(updateAward.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.awardUpdated = true;
      })
      .addCase(updateAward.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.awardUpdated = false;
      })
      .addCase(deleteAward.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(deleteAward.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.awardDeleted = true;
      })
      .addCase(deleteAward.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.awardDeleted = false;
      })
      .addCase(createReview.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(createReview.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.reviewCreated = true;
        // console.log("succeeded");
      })
      .addCase(createReview.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.reviewCreated = false; // Set reviewCreated to false upon failed creation
        // console.log("failed");
      })
      .addCase(sharingCatalog.pending, (state, action) => {
        // state.status = "loading";
      })
      .addCase(sharingCatalog.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.catalogShared = true;
        // console.log("succeeded");
      })
      .addCase(sharingCatalog.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.catalogShared = false;
        // console.log("failed");
      })
      .addCase(imageOrderForCatalog.pending, (state, action) => {
        // state.status = "loading";
      })
      .addCase(imageOrderForCatalog.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.imageOrderApply = true;
      })
      .addCase(imageOrderForCatalog.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.imageOrderApply = false;
      });
  },
});

export const {
  resetState,
  resetError,
  resetImageOrderApplyStatus,
  resetCreatedReviewStatus,
  resetNewImageStatus,
  resetUpdateProfile,
} = profileSlice.actions;

export default profileSlice.reducer;
