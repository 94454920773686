import React from "react";

const LinkedIn = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.61172 10H3.54614V3.33575H5.52722V4.22202C5.67119 4.05604 5.79011 3.88693 5.94034 3.746C6.30651 3.40151 6.75092 3.22301 7.24541 3.17603C7.67417 3.13532 8.09981 3.16664 8.51292 3.29817C9.20145 3.51425 9.6083 3.99654 9.8086 4.67298C9.94318 5.12082 9.99012 5.58431 9.99325 6.0478C10.0026 7.33806 9.99951 8.62832 9.99951 9.91858C9.99951 9.93424 9.99951 9.9499 9.99325 9.97495H7.92454C7.92454 9.92798 7.92454 9.87787 7.92454 9.83089C7.92454 8.72854 7.92454 7.62618 7.92141 6.52069C7.92141 6.28894 7.89951 6.0572 7.85882 5.82858C7.73051 5.13648 7.28296 4.91099 6.65703 4.98302C6.07491 5.04879 5.75256 5.39327 5.6618 6.02588C5.6305 6.25449 5.61485 6.48624 5.61485 6.71798C5.60859 7.76084 5.61485 8.80056 5.61485 9.84342V9.99374L5.61172 10Z"
        fill="white"
      />
      <path d="M2.23164 10H0.172323V3.33265H2.22851V10H2.23164Z" fill="white" />
      <path
        d="M2.39754 1.21871C2.38502 1.89829 1.8342 2.43068 1.16445 2.41502C0.519741 2.39936 -0.0123022 1.83252 0.000216499 1.18113C0.0127352 0.51721 0.569816 -0.0183104 1.22392 0.000479812C1.88428 0.01927 2.41006 0.564186 2.39754 1.21871Z"
        fill="white"
      />
    </svg>
  );
};

export default LinkedIn;
