import React from "react";

const ShowPasswordIcon = () => {
  return (
    <svg
      width="24"
      height="17"
      viewBox="0 0 24 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.0045 8.52909C23.8845 8.72109 23.7885 8.92509 23.6565 9.10509C21.9645 11.2171 20.0085 13.0411 17.6445 14.3971C16.0965 15.2971 14.4405 15.8971 12.6285 16.0171C11.1525 16.1131 9.73652 15.8731 8.35652 15.3451C6.28052 14.5531 4.50452 13.3051 2.87252 11.8291C1.91252 10.9651 1.04852 10.0171 0.25652 8.99709C-0.0794803 8.56509 -0.0914803 8.22909 0.25652 7.79709C1.96052 5.63709 3.94052 3.78909 6.32852 2.40909C8.21252 1.32909 10.2165 0.66909 12.4125 0.76509C13.9245 0.83709 15.3525 1.24509 16.6965 1.90509C19.5405 3.29709 21.8085 5.37309 23.7525 7.83309C23.8485 7.95309 23.9085 8.12109 23.9925 8.26509V8.54109L24.0045 8.52909ZM1.79252 8.37309C1.87652 8.48109 1.93652 8.55309 2.00852 8.62509C2.74052 9.36909 3.43652 10.1611 4.20452 10.8571C5.69252 12.2131 7.34852 13.3171 9.26852 13.9771C10.4205 14.3731 11.6085 14.5411 12.8205 14.3971C14.3805 14.2291 15.7965 13.6531 17.1285 12.8491C18.8565 11.8171 20.3445 10.4731 21.6765 8.98509C21.8565 8.79309 22.0245 8.58909 22.2045 8.36109C21.7485 7.85709 21.3165 7.36509 20.8485 6.90909C19.3005 5.33709 17.5965 3.99309 15.5565 3.10509C14.5365 2.66109 13.4805 2.37309 12.3645 2.32509C10.6605 2.24109 9.10052 2.72109 7.60052 3.48909C5.93252 4.35309 4.49252 5.51709 3.17252 6.84909C2.69252 7.34109 2.23652 7.85709 1.76852 8.36109L1.79252 8.37309Z"
        fill="#757575"
      />
      <path
        d="M16.7444 8.40873C16.7204 11.0367 14.5964 13.1487 11.9924 13.1367C9.36436 13.1247 7.24036 10.9767 7.26436 8.36073C7.28836 5.73273 9.42437 3.62073 12.0284 3.64473C14.6564 3.66873 16.7684 5.79273 16.7444 8.40873ZM15.1604 8.40873C15.1604 6.66873 13.7684 5.25273 12.0164 5.24073C10.2764 5.21673 8.83636 6.66873 8.83636 8.40873C8.83636 10.1487 10.2524 11.5647 11.9924 11.5647C13.7324 11.5647 15.1484 10.1607 15.1604 8.40873Z"
        fill="#757575"
      />
    </svg>
  );
};

export default ShowPasswordIcon;
