// import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getToken } from "../../utils/CommonUtils";

const config = {
  method: "POST", // *GET, POST, PUT, DELETE, etc.
  cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
  headers: {
    "Content-Type": "application/json",
    "Cross-Origin-Embedder-Policy": false,
  },
  redirect: "follow", // manual, *follow, error
  referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
};

export const signinUser = createAsyncThunk(
  "auth/login",
  async (userObject, { rejectWithValue }) => {
    try {
      // const response = await fetch(`https://api.realtorganizer.com/login`, {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/login`, {
        ...config,
        body: JSON.stringify(userObject),
      });
      return response.json(); // parses JSON response into native JavaScript objects
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const signupUser = createAsyncThunk(
  "auth/user",
  async (userObject, { rejectWithValue }) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user`, {
        // const response = await fetch(`https://api.realtorganizer.com/user`, {
        ...config,
        body: JSON.stringify(userObject),
      });
      return response.json(); // parses JSON response into native JavaScript objects
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const verifyOtp = createAsyncThunk(
  "auth/confirm-account",
  async (userObject, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/confirm-account`,
        // `https://api.realtorganizer.com/confirm-account`,
        {
          ...config,
          body: JSON.stringify(userObject),
        }
      );
      return response.json(); // parses JSON response into native JavaScript objects
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const resendOTP = createAsyncThunk(
  "auth/resend-otp",
  async (userObject, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/resend-otp`,
        // `https://api.realtorganizer.com/resend-otp`,
        {
          ...config,
          body: JSON.stringify(userObject),
        }
      );
      return response.json(); // parses JSON response into native JavaScript objects
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const resetPassword = createAsyncThunk(
  "auth/reset-password",
  async (userObject, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/reset-password`,
        // `https://api.realtorganizer.com/reset-password`,
        {
          ...config,
          body: JSON.stringify(userObject),
        }
      );
      return response.json(); // parses JSON response into native JavaScript objects
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const changePassword = createAsyncThunk(
  "auth/changePassword",
  async (userObject, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/change-password`,
        // `https://api.realtorganizer.com/change-password`,
        {
          ...config,
          method: "put",
          headers: { ...config.headers, Authorization: "Bearer " + getToken() },
          body: JSON.stringify(userObject),
        }
      );
      return response.json(); // parses JSON response into native JavaScript objects
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
