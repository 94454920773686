import React, { useEffect, useState } from "react";
import ChatListItem from "./ChatListItem";
import ContactsForChats from "./ContactsForChats";
import CataLogSharedView from "./CataLogSharedView";
import PropertySharedView from "./PropertySharedView";
import ChatRoom from "./ChatRoom";
import SearchIcon from "../../assets/svgs/SearchIcon";
import PlusBoxIcon from "../../assets/svgs/PlusBoxIcon";
import MoveBackIcon from "../../assets/svgs/LeftArrowIcon";
import XIcon from "../../assets/svgs/XIcon";

import { useDispatch, useSelector } from "react-redux";
import {
  getChats,
  getChatDetails,
  createThreadId,
} from "../../api/actions/chatActions";
import LoadingSpinner from "../../utils/LoadingSpinner";
import {
  resetLoadedChatDetails,
  resetCatalogDetails,
  resetPropertyDetails,
  resetChatDeleteStatus,
  resetThreadCreatedStatus,
} from "../../api/reducerSlices/chatSlice";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import "./ChatsCSS/chats.css";
require("../home/HomeComponent/HomeCSS/searchbar.css");

const Chats = ({
  chkForChat,
  moveback,
  openRoom,
  userChatIdWithProfile,
  setUserChatIdWithProfile,
}) => {
  const dispatch = useDispatch();

  const { status, error, chatsList, threadCreated, chatDeleted } = useSelector(
    (state) => state.chatSlice
  );

  const [selectedContact, setSelectedContact] = useState(null);
  const [showChat, setShowChat] = useState(chkForChat);
  const [searchQueryForChats, setSearchQueryForChats] = useState("");
  const [searchQueryForContactsToChats, setSearchQueryForContactsToChats] =
    useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [getData, setGetData] = useState(3000);
  const [contactSearchForMsg, setContactSearchForMsg] = useState(false);
  const [plusIconShow, setPlusIconShow] = useState(true);
  const [catalogView, setCatalogView] = useState(false);
  const [propertyView, setPropertyView] = useState(false);
  const [getSharedViewId, setGetSharedViewId] = useState(null);
  const [proopertySections, setProopertySections] = useState(null);
  const [fromContact, setFromContact] = useState(false);
  const [threadId, setThreadId] = useState(null);
  const [threadIdForContact, setThreadIdForContact] = useState(null);
  const [loadingForChatDeleted, setLoadingForChatDeleted] = useState(false);
  const [selectedTile, setSelectedTile] = useState(null);

  useEffect(() => {
    dispatch(getChats());

    // const interval = setInterval(() => {
    // dispatch(getChats());
    // }, getData + 1000);
    // // Clean up interval on unmount
    // return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // Show loading spinner for 3 seconds
    const timer = setTimeout(() => {
      setShowLoading(false);
      setGetData(1000);
    }, getData);

    // Clean up timer
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(getChats());
    }, getData + 1000);
    // Clean up interval on unmount
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    dispatch(getChats());
    setLoadingForChatDeleted(true);
    const interval = setInterval(() => {
      setLoadingForChatDeleted(false);
    }, 2000);
    // Clean up interval on unmount
    return () => clearInterval(interval);
  }, [threadCreated === true || chatDeleted === true]);

  useEffect(() => {
    // setUserChats(chatsList);
    if (threadCreated === true) {
      dispatch(resetThreadCreatedStatus());
    }
    if (chatDeleted === true) {
      dispatch(resetChatDeleteStatus());
    }
  }, [
    (threadCreated === true && chatsList !== null) ||
      (chatDeleted === true && chatsList !== null),
  ]);

  const handleOpenChatOnCLickOnProfile = async () => {
    setShowChat(true);
    if (chkForChat === false) {
      openRoom();
    }
    // console.log("hello  :  " + JSON.stringify(userChatIdWithProfile));
    let object = {
      name: "chat",
      description: "chat",
      to: [userChatIdWithProfile],
      image: "",
    };
    let getChatId = await dispatch(createThreadId(object));
    // console.log("hello  :  " + JSON.stringify(getChatId.payload.data?._id));
    setSelectedContact(getChatId.payload.data);
    setPlusIconShow(false);
  };

  const [shouldOpenChat, setShouldOpenChat] = useState(false);

  useEffect(() => {
    if (
      userChatIdWithProfile !== null &&
      showChat === false &&
      !shouldOpenChat
    ) {
      setShouldOpenChat(true);
    }
  }, [userChatIdWithProfile, showChat, shouldOpenChat]);

  useEffect(() => {
    if (shouldOpenChat) {
      handleOpenChatOnCLickOnProfile();
      setThreadId(userChatIdWithProfile);
      setShouldOpenChat(false); // Reset the state variable
    }
  }, [shouldOpenChat, userChatIdWithProfile]);

  // Function to handle selecting a contact
  const handleSelectContact = async (selectcontactForChat) => {
    // console.log("hello  :  " + JSON.stringify(selectcontactForChat));
    // if (selectcontactForChat !== null) {
    //   setSelectedContact(null);
    // }
    // console.log("hello  :  " + JSON.stringify(selectcontactForChat?._id));
    // console.log("hello  :  " + JSON.stringify(selectcontactForChat));
    // dispatch(getChatDetails(selectcontactForChat?._id));
    setSelectedContact(selectcontactForChat);
    setShowChat(true);
    if (chkForChat === false) {
      openRoom();
    }
    if (contactSearchForMsg) {
      let object = {
        name: "chat",
        description: "chat",
        to: [selectcontactForChat?.user],
        image: "",
      };
      // console.log("hello  123 :  ", selectcontactForChat);

      let getChatId = await dispatch(createThreadId(object));
      setThreadIdForContact(getChatId.payload.data?._id);
      // console.log("hello  :  ", getChatId.payload.data);
      setContactSearchForMsg(false);
      setFromContact(true);
    }
    setPlusIconShow(false);
  };

  useEffect(() => {
    // console.log("hello  :  " + JSON.stringify(selectedContact));
  }, [selectedContact]);

  // Function to go back to the chat list
  const handleBack = () => {
    setSelectedContact(null);
    setShowChat(false);
    dispatch(resetLoadedChatDetails());
    dispatch(getChats());
    setPlusIconShow(true);
    setFromContact(false);
    if (threadIdForContact !== null) {
      setThreadIdForContact(null);
    }
    if (userChatIdWithProfile !== null) {
      setUserChatIdWithProfile(null);
    }
    // localStorage.setItem("tupoa", false);
  };

  const handleSearchInputChange = (event) => {
    if (contactSearchForMsg) {
      setSearchQueryForContactsToChats(event.target.value);
    }
    setSearchQueryForChats(event.target.value);
  };

  const clickPlusButtonForSearchContactForMsg = () => {
    setContactSearchForMsg(!contactSearchForMsg);
    setPlusIconShow(!plusIconShow);
  };

  const closeCatalogSharedView = () => {
    setCatalogView(false);
    dispatch(resetCatalogDetails());
    setGetSharedViewId(null);
  };

  const closePropertySharedView = () => {
    setPropertyView(false);
    dispatch(resetPropertyDetails());
    setGetSharedViewId(null);
    setProopertySections(null);
  };

  const handleTileClick = (image) => {
    setSelectedTile(image);
  };

  const handleCloseModal = () => {
    setSelectedTile(null);
  };

  return (
    <>
      <div className="chat-overlay">
        {showChat && chkForChat ? (
          <ChatRoom
            selectedContact={selectedContact}
            onBack={handleBack}
            catalogView={catalogView}
            propertyView={propertyView}
            setCatalogView={setCatalogView}
            setPropertyView={setPropertyView}
            setGetSharedViewId={setGetSharedViewId}
            setProopertySections={setProopertySections}
            fromContact={fromContact}
            threadIdForContact={threadIdForContact}
            handleTileClick={handleTileClick}
          />
        ) : (
          <>
            {loadingForChatDeleted === true && status === "loading" && (
              <LoadingSpinner />
            )}
            <div className="header">
              {contactSearchForMsg && (
                <div
                  className="contactshow"
                  onClick={clickPlusButtonForSearchContactForMsg}
                >
                  <MoveBackIcon />
                </div>
              )}
              <span>{contactSearchForMsg ? "Contact" : "Chat"}</span>
              <div className="moveback" onClick={moveback}>
                <XIcon />
              </div>
            </div>
            <form role="search" className="searchBarBox">
              <div className="searchIcon">
                <SearchIcon />
              </div>
              <input
                id="search"
                type="search"
                placeholder="Search..."
                value={searchQueryForChats}
                onChange={handleSearchInputChange}
              />
            </form>

            {status === "loading" &&
              localStorage.getItem("chatOpenFirstTime") === "true" && (
                <LoadingSpinner />
              )}

            {showLoading && status === "loading" ? (
              <LoadingSpinner />
            ) : chatsList === null || chatsList === 0 ? (
              <p className="mtsfngc">Nothing to caught!</p>
            ) : (
              <>
                <ChatListItem
                  searchQueryForChats={searchQueryForChats}
                  userChats={chatsList}
                  onSelectContact={handleSelectContact}
                />
                {plusIconShow && (
                  <div
                    className="plusSignBoxButton"
                    onClick={clickPlusButtonForSearchContactForMsg}
                  >
                    <PlusBoxIcon />
                  </div>
                )}
                {contactSearchForMsg && (
                  <ContactsForChats
                    searchQueryForContactsToChats={
                      searchQueryForContactsToChats
                    }
                    selectContactToChat={handleSelectContact}
                  />
                )}
              </>
            )}
          </>
        )}
      </div>
      {catalogView && (
        <CataLogSharedView
          getSharedViewId={getSharedViewId}
          goBackToProfilePage={closeCatalogSharedView}
        />
      )}
      {propertyView && (
        <PropertySharedView
          goBackToProfilePage={closePropertySharedView}
          getSharedViewId={getSharedViewId}
          proopertySections={proopertySections}
        />
      )}
      {selectedTile !== null && (
        <div className="chatPictureModal">
          <span className="closeButton" onClick={handleCloseModal}>
            Close
          </span>
          <div className="modalContent">
            {/* <img src={selectedTile} alt="Selected Award" /> */}
            <TransformWrapper
              defaultScale={1}
              defaultPositionX={100}
              defaultPositionY={100}
              wheel={{ step: 10 }}
              options={{ limitToBounds: false }}
              pan={{ disabled: true }}
            >
              <TransformComponent>
                <img src={selectedTile} alt="Selected Award" />
              </TransformComponent>
            </TransformWrapper>
          </div>
        </div>
      )}
    </>
  );
};

export default Chats;
