import React, { useState, useEffect } from "react";
import EditIcon from "../../../assets/svgs/EditIcon";
import {
  deleteNotification,
  sendChildNotification,
} from "../../../api/actions/notificationActions";
import { resetState } from "../../../api/reducerSlices/notificationSlice";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "../../../utils/LoadingSpinner";
import {
  EventSuccessPopup,
  EventFailedPopup,
} from "../../popup-boxes/main-page-Popup's/mainPageEventPopup";
require("./HomeCSS/sendNotification.css");

const SendNotification = ({
  loaderset,
  setAfterUpdate,
  listData,
  addNewNotification,
  editSpecificNotification,
  setloading,
}) => {
  const dispatch = useDispatch();
  const {
    status,
    error,
    childUpdated,
    childDeleted,
    notificationSend,
    notificationUpdated,
  } = useSelector((state) => state.notificationSlice);
  const [clientName, setClientName] = useState(
    listData ? listData.clientName : ""
  );
  const [propertyNumber, setPropertyNumber] = useState(
    listData ? listData.propertyNumber : ""
  );

  const [editableNotificationId, setEditableNotificationId] = useState(null);
  const [notificatonData, setNotificatonData] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupMsg, setShowPopupMsg] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  useEffect(() => {
    setClientName(listData?.clientName);
    setPropertyNumber(listData?.propertyNumber);
    setNotificatonData(listData);
    // console.log("selected notification data : " + JSON.stringify(listData));
  }, [listData, clientName, propertyNumber, notificatonData]);

  useEffect(() => {
    // console.log("new  notification data : " + JSON.stringify(notificatonData));
    // console.log("new  notification data 22: " + JSON.stringify(listData));
  }, [listData, notificatonData]);

  useEffect(() => {
    if (childUpdated === true || notificationUpdated === true) {
      setShowPopupMsg("Notification updated!");
      setShowSuccessPopup(true);
      setTimeout(() => {
        setShowSuccessPopup(false);
        setShowPopupMsg("");
        dispatch(resetState());
      }, 3000);
    }
    if (childDeleted === true) {
      setShowPopupMsg("Notification deleted!");
      setShowSuccessPopup(true);
      setTimeout(() => {
        setShowSuccessPopup(false);
        setShowPopupMsg("");
        dispatch(resetState());
      }, 3000);
    }
    if (notificationSend === true) {
      setShowPopupMsg("Notification send!");
      setShowSuccessPopup(true);
      setTimeout(() => {
        setShowSuccessPopup(false);
        setShowPopupMsg("");
        dispatch(resetState());
      }, 3000);
    }
    if (error && error !== "") {
      setShowPopupMsg(error);
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
        setShowPopupMsg("");
        dispatch(resetState());
      }, 3000);
    }
  }, [
    error,
    childUpdated,
    childDeleted,
    notificationSend,
    notificationUpdated,
  ]);

  const handleSendNotification = (childNotificationId) => {
    let object = {
      parent_id: listData?._id,
      child_id: childNotificationId,
    };
    dispatch(sendChildNotification(object));
    setloading();
  };

  const editSendNotification = (tileNotificationId) => {
    editSpecificNotification(tileNotificationId);
  };

  const deleteWholeNotification = () => {
    dispatch(deleteNotification(listData._id));
    setAfterUpdate();
  };

  const [isLoaderOpen, sesIsLoaderOpen] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      sesIsLoaderOpen(false);
    }, 1800);

    // Clean up the timer to avoid memory leaks
    return () => clearTimeout(timer);
  }, [loaderset]);

  const renderLastsendTime = (data) => {
    const lastSendAt = new Date(data);
    const timeOptions = { hour: "numeric", minute: "numeric" };
    return lastSendAt.toLocaleTimeString([], timeOptions);
  };

  const closePopup = () => {
    setShowPopup(false);
    setShowPopupMsg("");
  };

  const closeSuccessPopup = () => {
    setShowSuccessPopup(false);
    setShowPopupMsg("");
  };

  return (
    <>
      {/* {status === "loading" && <LoadingSpinner />} */}
      {isLoaderOpen && loaderset && <LoadingSpinner />}
      {showSuccessPopup && (
        <EventSuccessPopup
          showPopupMsg={showPopupMsg}
          onClose={closeSuccessPopup}
        />
      )}
      {showPopup && (
        <EventFailedPopup showPopupMsg={showPopupMsg} onClose={closePopup} />
      )}
      <div className="sendNotificationBox">
        <div className="posstk">
          <div className="listTile">
            <div className="fs">
              <span>Client Name</span>
              <p>{clientName}</p>
            </div>
            <div className="ss">
              <span>Property Number</span>
              <p>{propertyNumber}</p>
            </div>
          </div>
          <div className="notifyButtons">
            <button
              className="AddNotificationbtn"
              onClick={() => deleteWholeNotification()}
            >
              Delete all
            </button>
            <button
              className="AddNotificationbtn"
              onClick={() => addNewNotification()}
            >
              +Add
            </button>
          </div>
        </div>
        {notificatonData &&
          notificatonData?.childs?.map((notificatonDt) => (
            <div className="notificationActionBox" key={notificatonDt?._id}>
              <div className="firstline">
                <span>Notification</span>
                {notificatonDt?.lastSendAt && (
                  <div className="last-sent">
                    <span className="dot"></span> Last sent at:
                    <p>{renderLastsendTime(notificatonDt?.lastSendAt)}</p>
                  </div>
                )}
                <div className="flButtons">
                  <div
                    className="notificBtn"
                    onClick={() => editSendNotification(notificatonDt?._id)}
                  >
                    <EditIcon />
                  </div>
                  <button
                    className="sendNotificationbtn"
                    onClick={() => handleSendNotification(notificatonDt?._id)}
                  >
                    Send
                  </button>
                </div>
              </div>
              <div className="para">
                <div className="notificationTitle">{notificatonDt?.text}</div>
              </div>
              <div className="contactsTag">
                <span>Contact</span>
                <div className="contacts-container">
                  {notificatonDt.contacts &&
                    notificatonDt.contacts.map((contact) => (
                      <div key={contact?.id} className="contact-item">
                        {typeof contact?.image === "string" &&
                        contact?.image !== "" ? (
                          <img src={contact?.image} alt="Profile" />
                        ) : (
                          <div className="initials">
                            {contact?.name.charAt(0).toUpperCase()}
                          </div>
                        )}
                        {/* <p className="name">{contact?.name}</p> */}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default SendNotification;
