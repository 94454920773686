import React, { useState, useRef } from "react";
// import "./reminderBox.css";
import XIcon from "../../assets/svgs/XIcon";
require("./reminderBox.css");

const ReminderModal = ({
  closeReminderBox,
  saveReminderInfo,
  reminderInfo,
}) => {
  const [reminderDetails, setReminderDetails] = useState({
    reminder: reminderInfo ? reminderInfo.reminder : "",
    reminderNotes: reminderInfo ? reminderInfo.reminderNotes : "",
  });

  const handleDateChange = (event) => {
    setReminderDetails({ ...reminderDetails, reminder: event.target.value });
  };

  const handleNotesChange = (event) => {
    setReminderDetails({
      ...reminderDetails,
      reminderNotes: event.target.value,
    });
  };

  const handleSaveReminder = () => {
    // Implement your logic to save reminder details (e.g., send to server, update state, etc.)
    // console.log("Reminder Details:", reminderDetails);
    // if (!reminderDetails.reminder || !reminderDetails.reminderNotes) {
    //   // Display an alert if either field is empty
    //   alert("Please fill in both date and additional notes.");
    //   return; // Prevent further execution
    // }
    if (!reminderDetails.reminder) {
      alert("Please fill at least date field to save reminder");
      return; // Prevent further execution
    }

    // Close the modal after saving
    saveReminderInfo(reminderDetails);
    closeReminderBox();
  };
  const textareaRef = useRef();
  const handleFocusClick = () => {
    // Focus on the textarea when the container is clicked
    textareaRef.current.focus();
  };

  return (
    <>
      <div className="overlay">
        <div className="reminder-modal">
          <div className="closeReminderBox" onClick={closeReminderBox}>
            <XIcon />
          </div>
          <div className="reminderInputGroup">
            <label>Date:</label>
            <input
              type="date"
              value={reminderDetails.reminder}
              onChange={handleDateChange}
            />
          </div>
          <div
            className="reminderInputGroup txtarea"
            onClick={handleFocusClick}
          >
            <label>Reminder Notes:</label>
            <textarea
              ref={textareaRef}
              value={reminderDetails.reminderNotes}
              onChange={handleNotesChange}
            />
          </div>
          <button onClick={handleSaveReminder}>Save</button>
        </div>
      </div>
    </>
  );
};

export default ReminderModal;
