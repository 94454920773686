import React, { useEffect, useState, useRef } from "react";
import MoveBackIcon from "../../assets/svgs/LeftArrowIcon";
import Gallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { useDispatch, useSelector } from "react-redux";
import { getCatalogDetail } from "../../api/actions/chatActions";
import LoadingSpinner from "../../utils/LoadingSpinner";
require("./ChatsCSS/catalogSharedVirew.css");

const CataLogSharedView = ({ getSharedViewId, goBackToProfilePage }) => {
  const dispatch = useDispatch();
  const { status, catalogDetailForView, catalogDetaiGettingStatus } =
    useSelector((state) => state.chatSlice);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [images, setImages] = useState([]);

  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    dispatch(getCatalogDetail(getSharedViewId));
  }, []);

  useEffect(() => {
    if (status === "failed") {
      alert("Failed to load.");
      goBackToProfilePage();
    }
  }, [status]);

  useEffect(() => {
    setTitle(catalogDetailForView?.catalog?.title || "");
    setDescription(catalogDetailForView?.catalog?.description || "");
    if (catalogDetailForView && catalogDetailForView?.catalog?.images) {
      const sortedImages = [...catalogDetailForView.catalog?.images].sort(
        (a, b) => a?.orderNo - b?.orderNo
      );

      setImages(sortedImages);
    }
  }, [catalogDetailForView]);

  const renderText = (value, className) => (
    <pre className={className}>{value}</pre>
  );

  const divRef = useRef(null);

  useEffect(() => {
    // Calculate the height of the div based on its content
    if (divRef.current) {
      const height = divRef.current.scrollHeight;
      // Optionally, add some padding to the height if needed
      divRef.current.style.height = height + "px";
    }
  }, [description]);

  useEffect(() => {
    const resizeHandler = () => {
      if (window.innerWidth < 1000 && divRef.current) {
        const height = divRef.current.scrollHeight;
        // Optionally, add some padding to the height if needed
        divRef.current.style.height = height + "px";
      }
    };

    // Call the resize handler initially and on resize
    window.addEventListener("resize", resizeHandler);
    resizeHandler();

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, [description]);

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
  };

  const closeImageSlider = () => {
    setSelectedImageIndex(null);
  };

  const galleryImages = images.map((image) => ({
    original: image.url,
    thumbnail: image.url,
  }));

  return (
    <>
      {catalogDetaiGettingStatus && status === "loading" && <LoadingSpinner />}
      <div className="sharedViewOverlay">
        <div className="catalogSharedView">
          <div className="headerLine">
            <div className="movebackc" onClick={goBackToProfilePage}>
              <MoveBackIcon />
            </div>
            <span>Shared Catalog</span>
          </div>

          <div className="ctldCntBox">
            <div className="titleSection">
              <label htmlFor="title">Title</label>
              {renderText(title, "title")}
            </div>
            <div className="descriptionSection">
              <label htmlFor="description"> Description</label>
              <pre ref={divRef} e className="description">
                {description}
              </pre>
            </div>
            <div className="imagesSection">
              {images?.map((image, index) => (
                <>
                  <div
                    key={index}
                    className="catalogImage"
                    onClick={() => handleImageClick(index)}
                  >
                    {typeof image.image === "object" &&
                    image.image instanceof File ? (
                      // <img
                      //   src={URL.createObjectURL(image.image)}
                      //   alt={`${index + 1}`}
                      // />
                      ""
                    ) : (
                      <>
                        <img src={image.url} alt={`${index}`} />
                      </>
                    )}
                  </div>
                </>
              ))}
            </div>
            {selectedImageIndex !== null && (
              <div className="image-slider">
                <Gallery
                  items={galleryImages}
                  startIndex={selectedImageIndex}
                  onRequestClose={closeImageSlider}
                />
                <div className="close-slider" onClick={closeImageSlider}>
                  Close
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CataLogSharedView;
