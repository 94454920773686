import React from "react";

const ChatIcon = () => {
  return (
    <svg
      width="78"
      height="70"
      viewBox="0 0 78 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1697_7000)">
        <path
          d="M48.2638 60.8995H13.0842C5.85689 60.8995 0 55.0854 0 47.9109V12.9885C0 5.81407 5.85689 0 13.0842 0H48.2638C55.4912 0 61.3481 5.81407 61.3481 12.9885V47.9109C66.8987 55.2222 72.4494 62.5335 78 69.8448C68.0854 66.8656 58.1784 63.8787 48.2638 60.8995Z"
          fill="#4668A8"
        />
        <path
          d="M11.9209 25.4834C11.9209 25.3846 11.9209 25.2858 11.9209 25.187C11.9515 25.0198 11.9975 24.8526 12.0051 24.6778C12.0434 23.4998 12.3114 22.375 12.7478 21.2882C14.0569 18.005 16.4456 15.7097 19.5617 14.1441C22.8844 12.4797 26.4138 11.9857 30.0887 12.4949C33.0363 12.9053 35.7313 13.9465 38.0587 15.8085C41.3968 18.4686 43.2419 21.8582 42.9662 26.2131C42.8667 27.7635 42.4227 29.2303 41.6877 30.5907C39.9191 33.8511 37.1553 35.964 33.7177 37.2636C31.4975 38.1072 29.1853 38.3808 26.8273 38.3808C24.0098 38.3808 21.1847 38.3808 18.3673 38.3808C16.7059 38.3808 15.0446 38.3808 13.3909 38.3808C12.7248 38.3808 12.1506 38.1072 11.9975 37.446C11.9975 37.4156 11.9515 37.3928 11.9209 37.37C11.9209 37.294 11.9209 37.218 11.9209 37.1496C12.0664 36.6252 12.4492 36.2756 12.8243 35.9108C13.544 35.2116 14.256 34.4972 14.968 33.7827C15.3278 33.4255 15.3202 33.4255 14.9986 33.0531C13.4368 31.2519 12.4032 29.1999 12.0893 26.8287C12.0281 26.3803 11.9745 25.9243 11.9209 25.4759V25.4834ZM27.5086 24.1762C29.8897 24.1762 32.2707 24.1762 34.6518 24.1762C35.3944 24.1762 35.9074 23.667 35.9074 22.9526C35.9074 22.2458 35.3867 21.7366 34.6441 21.7366C29.882 21.7366 25.12 21.7366 20.3502 21.7366C20.2048 21.7366 20.0516 21.7442 19.9138 21.7974C19.309 21.995 18.9721 22.5802 19.087 23.2034C19.1865 23.7582 19.7071 24.1762 20.3273 24.1762C22.7236 24.1762 25.1123 24.1762 27.5086 24.1762ZM25.097 28.9339C26.6665 28.9339 28.236 28.9339 29.8131 28.9339C29.9739 28.9339 30.1423 28.9263 30.2878 28.8731C30.885 28.6755 31.2218 28.0979 31.107 27.4671C31.0075 26.9199 30.4945 26.5019 29.905 26.5019C26.7124 26.5019 23.5198 26.5019 20.3273 26.5019C19.5923 26.5019 19.0717 27.0187 19.0793 27.7331C19.0793 28.4323 19.6152 28.9415 20.3502 28.9415C21.935 28.9415 23.5198 28.9415 25.097 28.9415V28.9339Z"
          fill="white"
        />
        <path
          d="M45.6156 25.5293C46.2358 26.1829 46.8942 26.7985 47.476 27.4825C48.9843 29.2761 49.9107 31.3434 50.148 33.6614C50.4542 36.671 49.6351 39.3919 47.8053 41.8087C47.5832 42.1051 47.3459 42.3939 47.0932 42.6675C46.9018 42.8651 46.9171 42.9943 47.1085 43.1843C48.0273 44.0811 48.9383 44.9779 49.8341 45.8899C50.4083 46.4751 50.3088 47.3567 49.6197 47.7139C49.4054 47.8203 49.1451 47.8811 48.9001 47.8811C44.138 47.8887 39.3683 47.9191 34.6062 47.8811C31.1074 47.8507 27.8382 46.9387 24.9213 44.9551C23.459 43.9595 22.2034 42.7435 21.2081 41.2843C21.1239 41.1627 21.0473 41.0335 20.9248 40.8435C21.1086 40.8359 21.2387 40.8207 21.3612 40.8207C23.658 40.8207 25.9548 40.8967 28.244 40.8055C33.6951 40.5851 38.373 38.6166 42.0096 34.4822C43.87 32.3618 45.0031 29.8841 45.4012 27.0949C45.4702 26.6009 45.4855 26.0917 45.5314 25.5977L45.6156 25.5293Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1697_7000">
          <rect width="78" height="69.8448" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ChatIcon;
