import React, { useEffect, useState, useRef } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { Document, Page, pdfjs } from "react-pdf";
import Gallery from "react-image-gallery";
// import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
// import { Document, Page } from "react-pdf";
import PlusBoxIcon from "../../../assets/svgs/PlusBoxIcon";
import ShareIcon from "../../../assets/iconImages/shareIcon.png";
import FileIcon from "../../../assets/iconImages/DefaultForFile.png";
import XIcon from "../../../assets/svgs/XIcon";
import {
  addOrUpdateFileToProperty,
  createProperty,
  deleteProperty,
  getPropertyDetails,
  updatePropertyDetails,
  sharingProperty,
  imageOrderForPropertyPhotosSection,
} from "../../../api/actions/propertyActions";
import {
  resetError,
  resetState,
  resetErrorForImage,
} from "../../../api/reducerSlices/propertySlice";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "../../../utils/LoadingSpinner";
import { convertISOToLocalFormat2 } from "../../../utils/CommonUtils";
import ReminderBox from "../../ShowBox/reminderBox";
import PropertyInspectionReport from "./PropertyInspectionReport";
import ContactsToSelect from "./ContactsToSelect";
import ForwardIcon from "../../../assets/svgs/ForwardIcon";
import {
  EventSuccessPopup,
  EventFailedPopup,
} from "../../popup-boxes/main-page-Popup's/mainPageEventPopup";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
require("./HomeCSS/PropertyDetail.css");

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PROPERTY_ENUMS = Object.freeze({
  Description: "Description",
  OWNERSHIP_DOCS: "OWNERSHIP_DOCS",
  PHOTOS: "PHOTOS",
  FLOOR_PLANS: "FLOOR_PLANS",
  TENANCY_DETAILS: "TENANCY_DETAILS",
  SNAGGING: "SNAGGING",
});

const GetPropertyDetail = ({
  onPropertyDeleted,
  propertyBasicInfo,
  isPropertySnaggingInspectionVisible,
}) => {
  const dispatch = useDispatch();

  const {
    status,
    error,
    propertyDetails,
    fileAdded,
    propertyAdded,
    propertyDeleted,
    imageOrderSet,
    propertyCreated,
    propertyUpdated,
  } = useSelector((state) => state.propertySlice);

  const [propertyId, setPropertyId] = useState(0);
  const [propertyFormFill, setPropertyFormFill] = useState(false);
  // const [formData, setFormData] = useState({});
  const [formData, setFormData] = useState({
    communityArea: "",
    propertyNumber: "",
    contactNumber: "",
    email: "",
  });
  const [isAddingOnEdit, setIsAddingOnEdit] = useState(false);
  const [description, setDescription] = useState("");
  const [notes, setNotes] = useState("");
  const [viewstatus, setViewstatus] = useState(false);

  const [isChecked, setIsChecked] = useState(false);
  const [newDocument, setNewDocument] = useState({ title: "", files: [] });
  const [openNewDocumentAddBox, setOpenNewDocumentAddBox] = useState(false);

  // Separate state arrays for each section
  const [docsOwnershipDocs, setDocsOwnershipDocs] = useState([]);
  const [docsPhotos, setDocsPhotos] = useState([]);
  const [docsFloorPlans, setDocsfloorPlans] = useState([]);
  const [docsTenancyDetails, setDocsTenancyDetails] = useState([]);
  const [docsSnagging, setDocsSnagging] = useState([]);
  const [addBoxHeaderText, setAddBoxHeaderText] = useState("");
  // Separate state arrays for each section
  const [showAddBoxesOwnershipDocs, setShowAddBoxesOwnershipDocs] = useState(
    []
  );
  const [showAddBoxesPhotos, setShowAddBoxesPhotos] = useState([]);
  const [showAddBoxesFloorPlans, setShowAddBoxesFloorPlans] = useState([]);
  const [showAddBoxesTenancyDetails, setShowAddBoxesTenancyDetails] = useState(
    []
  );
  const [showAddBoxesSnagging, setShowAddBoxesSnagging] = useState([]);
  const [currentSection, setCurrentSection] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isReminderBoxOpen, setIsReminderBoxOpen] = useState(false);
  const [reminderInfo, setReminderInfo] = useState({});
  // handle cards
  const fileInputRef = useRef(null);
  const [isViewingExistingCard, setIsViewingExistingCard] = useState(false);
  const [isEditViewingExistingCard, setIsEditViewingExistingCard] =
    useState(false);
  const [isExistingCardData, setIsExistingCardData] = useState(null);
  const [fileDeleted, setFileDeleted] = useState(false);
  const [isPropertyReport, setIsPropertyReport] = useState(false);
  const [isContectBoxOpenToShare, setIsContectBoxOpenToShare] = useState(false);
  const [whatIsSharing, setWhatIsSharing] = useState("");
  const [imageToShare, setImageToShare] = useState("");
  const [selectedTile, setSelectedTile] = useState(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [photosimages, setPhotosImages] = useState([]);
  const [photosFiles, setPhotosFiles] = useState([]);

  const [showPopup, setShowPopup] = useState(false);
  const [showPopupMsg, setShowPopupMsg] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  // useEffect(() => {
  //   if (error) {
  //     alert(error);
  //     dispatch(resetError());
  //   }
  // }, [error]);

  // useEffect(() => {
  //   if (imageOrderSet) {
  //     dispatch(dispatch(resetErrorForImage()));
  //   }
  // }, [imageOrderSet]);

  useEffect(() => {
    if (propertyCreated === true) {
      setShowPopupMsg("Property created!");
      setShowSuccessPopup(true);
      setTimeout(() => {
        setShowSuccessPopup(false);
        setShowPopupMsg("");
        dispatch(resetError());
      }, 3000);
    }
    if (propertyUpdated === true || fileAdded === true) {
      setShowPopupMsg("Property updated!");
      setShowSuccessPopup(true);
      setTimeout(() => {
        setShowSuccessPopup(false);
        setShowPopupMsg("");
        dispatch(resetError());
      }, 3000);
    }
    if (error && error !== "") {
      setShowPopupMsg(error);
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
        setShowPopupMsg("");
        dispatch(resetError());
      }, 3000);
    }
  }, [error, propertyCreated, propertyUpdated, fileAdded]);

  useEffect(() => {
    // Populate form data if propertyData is provided
    if (propertyBasicInfo.hasOwnProperty("_id")) {
      if (propertyDetails !== null) {
        dispatch(resetState());
      }
      setPropertyId(propertyBasicInfo?._id);
      dispatch(getPropertyDetails(propertyBasicInfo?._id));
      setPropertyFormFill(true); // Set to true to display existing data
      setViewstatus(true);
      if (Array.isArray(propertyDetails?.PHOTOS)) {
        const sortedImages = [...propertyDetails.PHOTOS].sort(
          (a, b) => a?.orderNo - b?.orderNo
        );
        setDocsPhotos(sortedImages);
        setPhotosImages(sortedImages);
      }
    }
  }, [propertyBasicInfo !== undefined]);

  // Property Details API Response
  useEffect(() => {
    // console.log("propertyDetails ", propertyDetails);
    setFormData({
      communityArea: propertyDetails ? propertyDetails.communityArea : "",
      contactNumber: propertyDetails ? propertyDetails.contactNumber : "",
      propertyNumber: propertyDetails ? propertyDetails.propertyNumber : "",
      email: propertyDetails ? propertyDetails.email : "",
    });
    setDescription(propertyDetails?.description);
    setNotes(propertyDetails?.notes);

    setDocsOwnershipDocs(propertyDetails?.OWNERSHIP_DOCS);
    if (Array.isArray(propertyDetails?.PHOTOS)) {
      const sortedImages = [...propertyDetails?.PHOTOS].sort(
        (a, b) => a?.orderNo - b?.orderNo
      );
      setDocsPhotos(sortedImages);
      setPhotosImages(sortedImages);
      // console.log(sortedImages);
    }
    // if (Array.isArray(propertyDetails?.PHOTOS)) {
    //   const sortedImages = [...propertyDetails?.PHOTOS].sort(
    //     (a, b) => a?.orderNo - b?.orderNo
    //   );
    //   setPhotosImages(sortedImages);
    // }
    setDocsfloorPlans(propertyDetails?.FLOOR_PLANS);
    setDocsTenancyDetails(propertyDetails?.TENANCY_DETAILS);
    setDocsSnagging(propertyDetails?.SNAGGING);
    setReminderInfo({
      reminder: propertyDetails?.reminder
        ? convertISOToLocalFormat2(propertyDetails?.reminder)
        : "",
      reminderNotes: propertyDetails?.reminderNotes,
    });
    if (imageOrderSet) {
      dispatch(dispatch(resetErrorForImage()));
    }
    if (typeof window !== "undefined") {
      // Safety check for window object
      const textarea = document.querySelector('textarea[name="decriptiontxt"]'); // Adjust selector based on your component structure
      if (textarea) {
        textarea.dispatchEvent(new Event("input"));
      }
    }
  }, [propertyDetails]);

  // Create Property API Response
  useEffect(() => {
    if (propertyAdded !== null && propertyAdded.hasOwnProperty("_id")) {
      setPropertyId(propertyAdded?._id);
      setPropertyFormFill(true);
      // edit();
      dispatch(resetState());
    }
  }, [propertyAdded]);

  const divRef = useRef(null);
  useEffect(() => {
    // Calculate the height of the div based on its content
    if (divRef.current) {
      const height = divRef.current.scrollHeight;
      // Optionally, add some padding to the height if needed
      divRef.current.style.height = height + "px";
    }
  }, [description]);

  useEffect(() => {
    const resizeHandler = () => {
      if (divRef.current) {
        const height = divRef.current.scrollHeight;
        // Optionally, add some padding to the height if needed
        divRef.current.style.height = height + "px";
      }
    };

    // Call the resize handler initially and on resize
    window.addEventListener("resize", resizeHandler);
    resizeHandler();

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, [description]);

  // Create Property API Response
  useEffect(() => {
    if (propertyDeleted === true) {
      dispatch(resetState());
      onPropertyDeleted();
    }
  }, [propertyDeleted]);

  // Image / File Created API Response
  useEffect(() => {
    if (fileAdded === true || fileDeleted) {
      dispatch(getPropertyDetails(propertyId));
      if (currentSection !== "PHOTOS") {
        closeCardDetails(currentIndex, currentSection);
      }
      setFileDeleted(false);
    }
  }, [fileAdded, fileDeleted]);

  // Create Property API
  const createPropertyAPI = (event) => {
    event.preventDefault();
    setIsAddingOnEdit(false);
    setViewstatus(false);

    // Basic validation example
    if (
      !formData?.communityArea ||
      !formData?.propertyNumber ||
      !formData?.contactNumber ||
      !formData?.email
    ) {
      setShowPopupMsg("All fields are Required from form!");
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
        setShowPopupMsg("");
      }, 3000);
      return false;
    }

    const dataToSend = {
      communityArea: formData?.communityArea,
      propertyNumber: formData?.propertyNumber,
      contactNumber: formData?.contactNumber,
      email: formData?.email,
      description: description,
    };

    dispatch(createProperty(dataToSend));
  };

  // ADD Image API
  const validateAndUpoadImageFileAPI = (imageId, index, section) => {
    if (section === PROPERTY_ENUMS.PHOTOS) {
      // alert(imageId);
    } else {
      if (
        newDocument?.title?.trim() === "" ||
        newDocument?.files?.length === 0
      ) {
        setShowPopupMsg("Please provide a title and upload files.");
        setShowPopup(true);
        setTimeout(() => {
          setShowPopup(false);
          setShowPopupMsg("");
        }, 3000);
        return;
      }
    }

    setCurrentSection(section);
    setCurrentIndex(index);

    const dataToSend = {
      title: newDocument.title.trim(),
      files: newDocument.files,
      propertyId: propertyId,
      type: section,
      imageId: imageId,
    };

    dispatch(addOrUpdateFileToProperty(dataToSend));
  };

  // Update Property Details or delete image API
  const updatePropertyDetailsAPI = (deleteImage, imageId, section) => {
    if (section !== "PHOTOS") {
      setIsAddingOnEdit(false);
      setViewstatus(false);
    }
    // console.log("hello");
    if (
      formData?.communityArea === "" ||
      formData?.propertyNumber === "" ||
      formData?.contactNumber === "" ||
      formData?.email === ""
    ) {
      setShowPopupMsg("All fields are Required from form!");
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
        setShowPopupMsg("");
      }, 3000);
      return false;
    }

    const dataToSend = {
      id: propertyId,
      communityArea: formData.communityArea,
      contactNumber: formData.contactNumber,
      propertyNumber: formData.propertyNumber,
      email: formData.email,
      description: description,
      notes: notes,
      reminder: reminderInfo?.reminder,
      reminderNotes: reminderInfo?.reminderNotes,
    };

    if (deleteImage === true) {
      dataToSend["deleteImages"] = [imageId];
    }
    // console.log("testing: ", dataToSend)
    dispatch(updatePropertyDetails(dataToSend));
  };

  // delete Property Details API
  const deletePropertyAPI = () => {
    setIsAddingOnEdit(false);
    const dataToSend = {
      id: propertyId,
    };
    // console.log("testing: ", dataToSend)
    dispatch(deleteProperty(dataToSend));
  };

  // Handling form inputs selection
  // const handleChange = (event) => {
  //   const { name, value } = event.target;
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     [name]: value,
  //   }));
  // };

  const handleChange = (fieldName, value) => {
    setFormData({ ...formData, [fieldName]: value });
  };

  // Handling image / file selection
  const handleFileInputChange = (e) => {
    const files = e.target.files;
    if (!files || files.length === 0) {
      return;
    }

    let totalSize = 0;
    for (const file of files) {
      totalSize += file.size;
    }
    if (totalSize > 50 * 1024 * 1024) {
      // 50MB in bytes
      alert(
        "Total size of selected file exceeds the limit of 50MB.\nPlease keep it in limit."
      );
      e.target.value = "";
      return;
    }

    setNewDocument((prevDocument) => ({
      ...prevDocument,
      files: [...files],
    }));
    // console.log(newDocument?.files);
  };

  // const handleImageChange = (event) => {
  //   const selectedFiles = event.target.files;
  //   if (selectedFiles.length > 0) {
  //     if (selectedFiles.length > 10) {
  //       alert("You can only upload a maximum of 10 images at a time.");
  //       event.target.value = "";
  //       return;
  //     }
  //     const newImages = [...photosimages];
  //     const newFiles = [...photosFiles];
  //     for (const file of selectedFiles) {
  //       newFiles.push(file);
  //       newImages.push({
  //         image: URL.createObjectURL(file), // Assuming the URL is the file URL
  //         orderNo: newImages.length, // Assigning orderNo based on the current length of the array
  //         _id: `generated_id_${Date.now()}`, // You may generate a unique ID
  //         asp: true,
  //       });
  //     }
  //     setPhotosImages(newImages);
  //     setPhotosFiles(newFiles);
  //     setNewDocument((prevDocument) => ({
  //       ...prevDocument,
  //       // files: [...files],
  //       files: [...newFiles],
  //     }));

  //     const dataToSend = {
  //       title: newDocument.title.trim(),
  //       files: newFiles,
  //       propertyId: propertyId,
  //       type: "PHOTOS",
  //       imageId: "",
  //     };

  //     dispatch(addOrUpdateFileToProperty(dataToSend));
  //   }
  // };

  const handleImageChange = (event) => {
    const selectedFiles = event.target.files;
    let totalSize = 0;
    for (const file of selectedFiles) {
      totalSize += file.size;
    }
    if (totalSize > 50 * 1024 * 1024) {
      // 50MB in bytes
      alert(
        "Total size of selected images exceeds the limit of 50MB.\nPlease keep it in limit."
      );
      event.target.value = "";
      return;
    }

    if (selectedFiles.length > 0) {
      if (selectedFiles.length > 10) {
        alert("You can only upload a maximum of 10 images at a time.");
        event.target.value = "";
        return;
      }
      const newImages = [...photosimages];
      const newFiles = [...photosFiles];
      for (const file of selectedFiles) {
        newFiles.push(file);
        newImages.push({
          image: URL.createObjectURL(file), // Assuming the URL is the file URL
          orderNo: newImages.length, // Assigning orderNo based on the current length of the array
          _id: `generated_id_${Date.now()}`, // You may generate a unique ID
          asp: true,
        });
      }
      setPhotosImages(newImages);
      setPhotosFiles(newFiles);
      setNewDocument((prevDocument) => ({
        ...prevDocument,
        files: [...newFiles],
      }));

      const dataToSend = {
        title: newDocument.title.trim(),
        files: newFiles,
        propertyId: propertyId,
        type: "PHOTOS",
        imageId: "",
      };

      dispatch(addOrUpdateFileToProperty(dataToSend));
    }
  };

  useEffect(() => {
    // console.log("new added images", JSON.stringify(photosFiles));
    // console.log("prevDocument", JSON.stringify(newDocument));
  }, [photosFiles, newDocument]);

  // handle to close the adddocument box
  const closeCardDetails = (index, section) => {
    // setPhotosImages(propertyDetails?.PHOTOS);
    // setAddBoxHeaderText("");
    if (isViewingExistingCard) {
      setIsViewingExistingCard(false);
    }
    if (isEditViewingExistingCard) {
      setIsEditViewingExistingCard(false);
    }
    if (isExistingCardData !== null) {
      setIsExistingCardData(null);
    }
    setNewDocument({ title: "", files: [] }); // Clear the title and files

    // Use the corresponding setShowAddBoxes state based on the section
    if (section === PROPERTY_ENUMS.OWNERSHIP_DOCS) {
      setShowAddBoxesOwnershipDocs((prevState) => {
        const updatedState = [...prevState];
        updatedState[index] = false;
        return updatedState;
      });
    } else if (section === PROPERTY_ENUMS.PHOTOS) {
      setShowAddBoxesPhotos((prevState) => {
        const updatedState = [...prevState];
        updatedState[index] = false;
        return updatedState;
      });
    } else if (section === PROPERTY_ENUMS.FLOOR_PLANS) {
      setShowAddBoxesFloorPlans((prevState) => {
        const updatedState = [...prevState];
        updatedState[index] = false;
        return updatedState;
      });
    } else if (section === PROPERTY_ENUMS.TENANCY_DETAILS) {
      setShowAddBoxesTenancyDetails((prevState) => {
        const updatedState = [...prevState];
        updatedState[index] = false;
        return updatedState;
      });
    } else if (section === PROPERTY_ENUMS.SNAGGING) {
      setShowAddBoxesSnagging((prevState) => {
        const updatedState = [...prevState];
        updatedState[index] = false;
        return updatedState;
      });
    }
  };

  // Updating new doc or photo locally
  const addNewPhotoCardClicked = (index, section) => {
    // Use the corresponding setShowAddBoxes state based on the section
    if (section === PROPERTY_ENUMS.OWNERSHIP_DOCS) {
      setAddBoxHeaderText("Ownership Docs");
      setShowAddBoxesOwnershipDocs((prevState) => {
        const updatedState = [...prevState];
        updatedState[index] = !updatedState[index];
        return updatedState;
      });
    } else if (section === PROPERTY_ENUMS.PHOTOS) {
      setAddBoxHeaderText("Photos");
      setShowAddBoxesPhotos((prevState) => {
        const updatedState = [...prevState];
        updatedState[index] = !updatedState[index];
        return updatedState;
      });
    } else if (section === PROPERTY_ENUMS.FLOOR_PLANS) {
      setAddBoxHeaderText("FLoor Plan");
      setShowAddBoxesFloorPlans((prevState) => {
        const updatedState = [...prevState];
        updatedState[index] = !updatedState[index];
        return updatedState;
      });
    } else if (section === PROPERTY_ENUMS.TENANCY_DETAILS) {
      setShowAddBoxesTenancyDetails((prevState) => {
        setAddBoxHeaderText("Tenancy Details");
        const updatedState = [...prevState];
        updatedState[index] = !updatedState[index];
        return updatedState;
      });
    } else if (section === PROPERTY_ENUMS.SNAGGING) {
      setShowAddBoxesSnagging((prevState) => {
        setAddBoxHeaderText("Snagging");
        const updatedState = [...prevState];
        updatedState[index] = !updatedState[index];
        return updatedState;
      });
    }
  };

  // set view box for card indfo
  const openCardDetails = (cardData, cardIndex, section) => {
    setIsViewingExistingCard(true);
    setIsExistingCardData(cardData);
    addNewPhotoCardClicked(cardIndex, section);
    setCurrentIndex(cardIndex);
    setCurrentSection(section);
    setNewDocument({
      ...newDocument,
      title: cardData?.title,
      files: [cardData?.image],
    });
  };

  const openCardDetailsForSnagging = (cardData, cardIndex) => {
    openCardDetails(cardData, cardIndex, PROPERTY_ENUMS.SNAGGING);
  };

  const photoOrDocDeleteClicked = (removingCardDataExchange, section) => {
    setFileDeleted(true);
    setCurrentSection(section);
    updatePropertyDetailsAPI(true, removingCardDataExchange._id, "PHOTOS");
  };

  const textAreaRef = useRef(null);
  const edit = () => {
    setIsAddingOnEdit(true);
    setViewstatus(true);
    if (textAreaRef.current) {
      textAreaRef.current.style.height =
        textAreaRef.current.scrollHeight + "px";
    }
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto";
      textAreaRef.current.style.height =
        textAreaRef.current.scrollHeight + "px";
    }
  };

  const handleNotes = (e) => {
    setNotes(e.target.value);
  };

  // focus on box
  const textareaRef = useRef();
  const handleFocusClick = () => {
    textareaRef.current.focus();
  };

  const handlePropertyInspection = () => {
    setIsPropertyReport(!isPropertyReport);
    isPropertySnaggingInspectionVisible(!isPropertyReport);
    setOpenNewDocumentAddBox(false);
  };

  const openCardDetailsForSnaggingForEditShow = () => {
    // addNewPhotoCardClicked(0, PROPERTY_ENUMS.SNAGGING)
    //  openCardDetails(cardData, cardIndex, PROPERTY_ENUMS.SNAGGING);
    // console.log("helloo");
    handlePropertyInspection();
    setOpenNewDocumentAddBox(true);
  };

  const openCardDetailsForSnaggingForEditShowForNew = () => {
    addNewPhotoCardClicked(0, PROPERTY_ENUMS.SNAGGING);
  };

  // check if url is image
  const [isImage, setIsImage] = useState(null);
  const checkimage = (urlvalidate) => {
    const img = new Image();
    img.src = urlvalidate;

    img.onload = () => {
      setIsImage(true);
    };

    img.onerror = () => {
      setIsImage(false);
    };
  };

  const checkIfImage = (url) => {
    const imageExtensions = /\.(jpg|jpeg|png|gif|bmp|avif|webp)$/i;
    return imageExtensions.test(url);
  };

  const getFileType = (url) => {
    const extension = url.toLowerCase().split(".").pop();
    if (extension === "pdf") {
      return "pdf";
    } else if (extension === "doc" || extension === "docx") {
      return "word";
    } else {
      return "unknown";
    }
  };

  // check if data is url or in object
  const isValidUrlFormat = (input) => {
    // Regular expression to check if the input has a valid URL format
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    return urlRegex.test(input);
  };

  //for re-order the image
  const [draggedIndex, setDraggedIndex] = useState(null);
  const [imageDragged, setImageDragged] = useState(false);

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData("text/plain", index);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, targetIndex) => {
    e.preventDefault();
    const sourceIndex = e.dataTransfer.getData("text/plain");
    moveImage(sourceIndex, targetIndex);
  };

  const handleTouchStart = (e, index) => {
    setDraggedIndex(index);
  };

  const handleTouchMove = (e) => {
    if (draggedIndex === null) {
      return;
    }
    e.preventDefault(); // Prevent scrolling on touch devices
    const targetIndex = findTargetIndex(
      e.touches[0].clientX,
      e.touches[0].clientY
    );
    if (targetIndex !== null && targetIndex !== draggedIndex) {
      moveImage(draggedIndex, targetIndex);
      setDraggedIndex(targetIndex);
    }
  };

  const handleTouchEnd = () => {
    setDraggedIndex(null);
  };

  const moveImage = (sourceIndex, targetIndex) => {
    const updatedImages = [...photosimages];
    const imageToMove = updatedImages[sourceIndex];
    updatedImages.splice(sourceIndex, 1);
    updatedImages.splice(targetIndex, 0, imageToMove);
    // console.log("data" + JSON.stringify(images));
    setPhotosImages(updatedImages); // Update images state with new order
    setImageDragged(true);
  };

  useEffect(() => {
    if (imageDragged) {
      // console.log("data" + JSON.stringify(images));
      const orderList = photosimages.map((image, index) => {
        return {
          id: image._id, // Assuming each image has an ID property
          orderNo: index,
        };
      });
      let object = {
        propertyId: propertyId && propertyId,
        orderList: orderList,
      };
      // console.log("data" + JSON.stringify(object));
      dispatch(imageOrderForPropertyPhotosSection(object));
      setImageDragged(false);
    }
  }, [photosimages, imageDragged]);

  const findTargetIndex = (clientX, clientY) => {
    const draggedImageElement = document.getElementById(
      `catalogImage-${draggedIndex}`
    );
    if (!draggedImageElement) {
      return null;
    }
    const draggedImageRect = draggedImageElement.getBoundingClientRect();
    const targetIndex = Array.from(
      document.querySelectorAll(".catalogImage")
    ).findIndex((element, index) => {
      if (index === draggedIndex) {
        return false;
      }
      const rect = element.getBoundingClientRect();
      return (
        clientX > rect.left &&
        clientX < rect.right &&
        clientY > rect.top &&
        clientY < rect.bottom
      );
    });
    return targetIndex !== -1 ? targetIndex : null;
  };

  const photosInputRef = useRef(null);
  const addImageInPhotosSection = () => {
    photosInputRef.current.click();
  };

  const [numPages, setNumPages] = useState(null);
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  // handle adddocumentbox corresponding based on the section
  const renderAddDocumentBoxes = (section, showAddBoxes) => {
    return showAddBoxes.map(
      (showAddBox, index) =>
        showAddBox && (
          <div className="addDocumentBoxCover" key={index}>
            <div className="AddDocumentBox">
              <div className="hLine">
                <span>{addBoxHeaderText}</span>
                <div
                  className="closeAddDocumentBOx"
                  onClick={() => closeCardDetails(index, section)}
                >
                  <XIcon />
                </div>
              </div>
              {section !== PROPERTY_ENUMS.PHOTOS && (
                <>
                  {isViewingExistingCard && !isEditViewingExistingCard ? (
                    <p className="cardDetailTextArea">
                      {newDocument && newDocument?.title}
                    </p>
                  ) : (
                    <textarea
                      type="text"
                      placeholder="Document Title"
                      value={newDocument.title}
                      autoFocus
                      onChange={(e) =>
                        setNewDocument({
                          ...newDocument,
                          title: e.target.value,
                        })
                      }
                    />
                  )}
                </>
              )}
              {section === PROPERTY_ENUMS.PHOTOS &&
              section === PROPERTY_ENUMS.SNAGGING ? (
                <>
                  {isViewingExistingCard && !isEditViewingExistingCard ? (
                    <div
                      className="addDocumentPlusBox"
                      style={{ cursor: "auto" }}
                    >
                      {newDocument?.files.map((file, i) => (
                        <div className="addDocumentPlusBoxImage">
                          <img
                            key={i}
                            src={URL.createObjectURL(file)}
                            alt={file.name}
                          />
                        </div>
                      ))}
                      {/* <img
                          src={
                            newDocument?.files ||
                            URL.createObjectURL(newDocument?.files)
                          }
                          alt={newDocument?.files.name}
                        /> */}
                    </div>
                  ) : (
                    <>
                      {/* <input
                        type="file"
                        accept="image/*"
                        onChange={handleFileInputChange}
                        style={{ display: "none" }}
                        ref={fileInputRef}
                        multiple
                      /> */}
                    </>
                  )}
                </>
              ) : (
                <>
                  {isViewingExistingCard && !isEditViewingExistingCard && (
                    <div
                      className="addDocumentPlusBox"
                      style={{ cursor: "auto" }}
                    >
                      {checkimage(newDocument.files[0])}
                      {newDocument.files?.length > 0 &&
                        (newDocument.files?.type?.startsWith("image/") ||
                          isImage) && (
                          <div className="addDocumentPlusBoxImage">
                            <TransformWrapper
                              defaultScale={1}
                              defaultPositionX={100}
                              defaultPositionY={100}
                              wheel={{ step: 10 }}
                              options={{ limitToBounds: false }}
                              pan={{ disabled: true }}
                            >
                              <TransformComponent>
                                <img src={newDocument.files} alt="Image" />
                              </TransformComponent>
                            </TransformWrapper>
                          </div>
                        )}
                      {newDocument.files[0].endsWith(".pdf") && (
                        <>
                          <Document
                            file={newDocument?.files[0]}
                            onLoadSuccess={onDocumentLoadSuccess}
                          >
                            {Array.from(new Array(numPages), (el, index) => (
                              <Page
                                key={`page_${index + 1}`}
                                pageNumber={index + 1}
                                className="pdf-page"
                              />
                            ))}
                          </Document>
                        </>
                      )}
                    </div>
                  )}
                  {(!isViewingExistingCard || isEditViewingExistingCard) &&
                    section !== PROPERTY_ENUMS.PHOTOS &&
                    section !== PROPERTY_ENUMS.SNAGGING && (
                      <input
                        type="file"
                        accept="image/*, application/pdf"
                        onChange={handleFileInputChange}
                        style={{ display: "none" }}
                        ref={fileInputRef}
                      />
                    )}
                  {(!isViewingExistingCard || isEditViewingExistingCard) &&
                    section === PROPERTY_ENUMS.SNAGGING && (
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleFileInputChange}
                        style={{ display: "none" }}
                        ref={fileInputRef}
                      />
                    )}
                </>
              )}
              {(!isViewingExistingCard || isEditViewingExistingCard) && (
                <>
                  {section !== PROPERTY_ENUMS.PHOTOS ? (
                    <>
                      <div
                        onClick={() => fileInputRef.current.click()}
                        className="addDocumentPlusBox"
                      >
                        {newDocument?.files && (
                          <>{checkimage(newDocument?.files)}</>
                        )}
                        {newDocument?.files.length > 0 &&
                          (() => {
                            const isValidUrl = isValidUrlFormat(
                              newDocument?.files
                            );

                            const urlTestForImage = checkIfImage(
                              newDocument.files[0]
                            );

                            const ojectImageCheck =
                              urlTestForImage === false &&
                              typeof newDocument.files === "object" &&
                              newDocument.files[0]?.type &&
                              newDocument.files[0]?.type.startsWith("image/")
                                ? true
                                : false;

                            const imagetesting = ojectImageCheck
                              ? URL.createObjectURL(newDocument?.files[0])
                              : null;

                            return (
                              <>
                                {((isValidUrl && isImage) ||
                                  imagetesting ||
                                  urlTestForImage) && (
                                  // newDocument.files.type.startsWith("image/")
                                  //   ||
                                  // (typeof newDocument.files === "object" &&
                                  //     newDocument.files.type.startsWith("image/"))
                                  <div className="addDocumentPlusBoxImage">
                                    {/* <img
                                      src={
                                        imagetesting
                                          ? imagetesting
                                          : newDocument.files
                                      }
                                      alt={newDocument?.files?.name}
                                    /> */}
                                    {/* {console.log(typeof newDocument?.files[0])} */}
                                    {ojectImageCheck ? (
                                      <img
                                        src={URL.createObjectURL(
                                          newDocument?.files[0]
                                        )}
                                        alt={newDocument?.files?.name}
                                      />
                                    ) : (
                                      <TransformWrapper
                                        defaultScale={1}
                                        defaultPositionX={100}
                                        defaultPositionY={100}
                                        wheel={{ step: 10 }}
                                        options={{ limitToBounds: false }}
                                        pan={{ disabled: true }}
                                      >
                                        <TransformComponent>
                                          <img
                                            src={newDocument.files}
                                            alt="Image"
                                          />
                                        </TransformComponent>
                                      </TransformWrapper>
                                    )}
                                  </div>
                                )}
                                {(newDocument.files[0].type ===
                                  "application/pdf" ||
                                  (urlTestForImage === false &&
                                    ojectImageCheck === false &&
                                    newDocument.files[0].endsWith(".pdf"))) && (
                                  <>
                                    <Document
                                      file={newDocument?.files[0]}
                                      onLoadSuccess={onDocumentLoadSuccess}
                                    >
                                      {Array.from(
                                        new Array(numPages),
                                        (el, index) => (
                                          <Page
                                            key={`page_${index + 1}`}
                                            pageNumber={index + 1}
                                            className="pdf-page"
                                          />
                                        )
                                      )}
                                    </Document>
                                  </>
                                )}
                              </>
                            );
                          })()}
                      </div>
                      <div className="addDocumentPlusBoxHover">+</div>
                    </>
                  ) : (
                    <>
                      {/* <p>Limit 50 MB</p> */}
                      <div className="ppabl" onClick={addImageInPhotosSection}>
                        <label htmlFor="imageUpload">
                          <div className="plusBox">Add</div>
                          <input
                            ref={photosInputRef}
                            style={{ display: "none" }}
                            type="file"
                            id="image-upload"
                            accept="image/*"
                            onChange={handleImageChange}
                            multiple
                          />
                        </label>
                      </div>
                      <div className="imagesSection">
                        {photosimages?.map((doc, index) => (
                          <div
                            className="PhotosImage"
                            key={index}
                            draggable // Add draggable attribute
                            onDragStart={(e) => handleDragStart(e, index)}
                            onDragOver={(e) => handleDragOver(e)}
                            onDrop={(e) => handleDrop(e, index)}
                            onTouchStart={(e) => handleTouchStart(e, index)}
                            onTouchMove={(e) => handleTouchMove(e)}
                            onTouchEnd={handleTouchEnd}
                          >
                            {typeof doc.image === "object" &&
                            doc.image instanceof File ? (
                              <img
                                src={URL.createObjectURL(doc.image)}
                                alt={`${index + 1}`}
                              />
                            ) : (
                              <img src={doc.image} alt={`${index}`} />
                            )}
                            {/* {isEditingCatalogEnable && isViewCatalog && ( */}
                            <span
                              className="deleteSign"
                              onClick={() =>
                                photoOrDocDeleteClicked(doc, section)
                              }
                            >
                              <XIcon />
                            </span>
                            {/* )} */}
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </>
              )}
              {section !== "PHOTOS" && (
                <div className="addDocumentsButtons">
                  {(!isViewingExistingCard || isEditViewingExistingCard) && (
                    <button
                      className="addMoreFiles"
                      onClick={() =>
                        validateAndUpoadImageFileAPI(
                          isExistingCardData ? isExistingCardData._id : "",
                          isEditViewingExistingCard ? currentIndex : index,
                          section
                        )
                      }
                    >
                      Save
                    </button>
                  )}
                  {isViewingExistingCard && (
                    <>
                      {!isEditViewingExistingCard && (
                        <button
                          className="addMoreFiles"
                          onClick={() => {
                            setIsEditViewingExistingCard(true);
                          }}
                        >
                          Edit
                        </button>
                      )}
                      {section !== PROPERTY_ENUMS.PHOTOS && (
                        <button
                          className="addMoreFiles"
                          onClick={() =>
                            photoOrDocDeleteClicked(isExistingCardData, section)
                          }
                        >
                          Delete
                        </button>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        )
    );
  };

  const sharedSectionOnly = (propertySectionToShare, imageurl) => {
    setIsContectBoxOpenToShare(true);
    setWhatIsSharing(propertySectionToShare);
    setImageToShare(imageurl);
  };

  const makeItSharing = (data) => {
    let makeSharemsgready = {
      propertId: propertyId,
      shareWith: data.shareWith,
      sections: [whatIsSharing],
      image: imageToShare,
    };
    // console.log("data" + JSON.stringify(makeSharemsgready));
    dispatch(sharingProperty(makeSharemsgready));
  };

  const closeContactBox = () => {
    setIsContectBoxOpenToShare(false);
  };

  // const handleTileClick = (image) => {
  //   setSelectedTile(image);
  // };

  const handleCloseModal = () => {
    setSelectedTile(null);
  };

  // const calculateRows = () => {
  //   return description.split("\n").length;
  // };

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
  };

  const closeImageSlider = () => {
    setSelectedImageIndex(null);
  };

  const galleryImages = docsPhotos?.map((image) => ({
    original: image.image,
    thumbnail: image.image,
  }));

  const closePopup = () => {
    setShowPopup(false);
    setShowPopupMsg("");
  };

  const closeSuccessPopup = () => {
    setShowSuccessPopup(false);
    setShowPopupMsg("");
  };

  return (
    <>
      {showSuccessPopup && (
        <EventSuccessPopup
          showPopupMsg={showPopupMsg}
          onClose={closeSuccessPopup}
        />
      )}
      {showPopup && (
        <EventFailedPopup showPopupMsg={showPopupMsg} onClose={closePopup} />
      )}
      {status === "loading" && !imageOrderSet && <LoadingSpinner />}
      {isPropertyReport ? (
        <PropertyInspectionReport
          snaggingItems={docsSnagging}
          closeSnaggingInspenction={handlePropertyInspection}
          editSnaggingDetails={openCardDetailsForSnagging}
          openNewDocumentAddBox={openNewDocumentAddBox}
          setNew={openCardDetailsForSnaggingForEditShowForNew}
        />
      ) : (
        <div className="propertyDetails">
          {/* edit and update buttons */}
          {propertyFormFill && (
            <div className="eBLine">
              {/* <p>Please Click on Edit button to add info.</p> */}
              {isAddingOnEdit && viewstatus && (
                <button
                  className="PropertyDataHandleButton"
                  onClick={updatePropertyDetailsAPI}
                >
                  Update
                </button>
              )}
              {!isAddingOnEdit && (
                <button className="editPropertyData" onClick={edit}>
                  Edit
                </button>
              )}
              <button
                className="PropertyDataHandleButton"
                onClick={deletePropertyAPI}
              >
                Delete
              </button>
            </div>
          )}
          {isContectBoxOpenToShare && (
            <ContactsToSelect
              closeSelectContactBox={closeContactBox}
              sharingCatalog={makeItSharing}
            />
          )}
          {/* details submit form */}
          <form className="details" onSubmit={createPropertyAPI}>
            <div className="detailsTag">
              <span className="reqInfo">Community Area</span>
              {!isAddingOnEdit && propertyFormFill ? (
                <p id="communityArea" className="detailsTagStatus">
                  {formData.communityArea}
                </p>
              ) : (
                <input
                  type="text"
                  name="communityArea"
                  id="communityArea"
                  className="comArea"
                  value={formData.communityArea}
                  onChange={(e) =>
                    handleChange("communityArea", e.target.value)
                  }
                />
              )}
            </div>
            <div className="detailsTag">
              <span className="reqInfo">Property Number</span>
              {!isAddingOnEdit && propertyFormFill ? (
                <p id="propertyNumber" className="detailsTagStatus">
                  {formData.propertyNumber}
                </p>
              ) : (
                <input
                  type="text"
                  name="propertyNumber"
                  id="propertyNumber"
                  className="prpNum"
                  value={formData.propertyNumber}
                  onChange={(e) =>
                    handleChange("propertyNumber", e.target.value)
                  }
                />
              )}
            </div>
            <div className="detailsTag">
              <span className="reqInfo">Contact Number</span>
              {!isAddingOnEdit && propertyFormFill ? (
                <p id="contactNumber" className="detailsTagStatus">
                  {formData.contactNumber}
                </p>
              ) : (
                <input
                  type="text"
                  name="contactNumber"
                  id="contactNumber"
                  className="contNum"
                  value={formData.contactNumber}
                  onChange={(e) =>
                    handleChange("contactNumber", e.target.value)
                  }
                />
              )}
            </div>
            <div className="detailsTag">
              <span className="reqInfo">Email</span>
              {!isAddingOnEdit && propertyFormFill ? (
                <p id="email" className="detailsTagStatus">
                  {formData.email}
                </p>
              ) : (
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="email"
                  value={formData.email}
                  onChange={(e) => handleChange("email", e.target.value)}
                />
              )}
            </div>

            {!propertyFormFill && <button type="submit">Save</button>}
          </form>

          {/* description box */}
          <div className="detailsBox pbContainer">
            <input
              type="checkbox"
              id="checkboxForDesc"
              onChange={() => setIsChecked(!isChecked)}
            />
            <div className="descriptionBox infoBox">
              <div className="infoBoxHeader">
                <h2>Description:</h2>
                {propertyFormFill &&
                  !isAddingOnEdit &&
                  description !== "" &&
                  description !== undefined && (
                    <button
                      className="share-button"
                      onClick={() =>
                        sharedSectionOnly(
                          PROPERTY_ENUMS.Description,
                          description
                        )
                      }
                    >
                      <img src={ShareIcon} alt="" />
                    </button>
                  )}
              </div>

              {!isAddingOnEdit && propertyFormFill ? (
                <pre ref={divRef} className="notes-textarea">
                  {description}
                </pre>
              ) : (
                <TextareaAutosize
                  id="description"
                  className="description-text auto-resize-textarea"
                  value={description}
                  ref={textAreaRef}
                  onChange={handleDescriptionChange}
                />
              )}
            </div>
          </div>

          {propertyFormFill && (
            <div className="boxes pbContainer">
              {/* Ownership Documents */}
              <div className="detailsBox">
                <input
                  type="checkbox"
                  id="checkboxForDescOwnershipDocs"
                  onChange={() => setIsChecked(!isChecked)}
                />
                <div className="OwnershipDocs infoBox">
                  <div className="infoBoxHeader">
                    <h2>Ownership Documents:</h2>
                    {isAddingOnEdit ? (
                      <button
                        className="AddBox"
                        onClick={() =>
                          addNewPhotoCardClicked(
                            0,
                            PROPERTY_ENUMS.OWNERSHIP_DOCS
                          )
                        }
                      >
                        Add
                      </button>
                    ) : (
                      docsOwnershipDocs?.length > 0 && (
                        <button className="share-button">
                          <img
                            src={ShareIcon}
                            alt=""
                            onClick={() =>
                              sharedSectionOnly(
                                PROPERTY_ENUMS.OWNERSHIP_DOCS,
                                docsOwnershipDocs &&
                                  docsOwnershipDocs?.[0]?.image
                                  ? docsOwnershipDocs?.[0]?.image
                                  : ""
                              )
                            }
                          />
                        </button>
                      )
                    )}
                  </div>
                  <div className="OwnershipDocsCardsBox scrbrd">
                    {renderAddDocumentBoxes(
                      PROPERTY_ENUMS.OWNERSHIP_DOCS,
                      showAddBoxesOwnershipDocs
                    )}
                    {docsOwnershipDocs?.map((doc, index) => (
                      <div
                        className="OwnershipDocsCardAdd"
                        key={index}
                        onClick={() =>
                          openCardDetails(
                            doc,
                            index,
                            PROPERTY_ENUMS.OWNERSHIP_DOCS
                          )
                        }
                      >
                        <div className="docsPic">
                          {doc?.image && checkIfImage(doc?.image) && (
                            <img src={doc?.image} alt={`File ${index}`} />
                          )}
                          {/* {console.log("filesss", doc?.image)} */}
                          {!checkIfImage(doc?.image) && (
                            <>
                              {getFileType(doc?.image) === "word" ||
                              getFileType(doc?.image) === "unknown" ? (
                                <img src={FileIcon} alt="File" />
                              ) : (
                                <>
                                  {getFileType(doc?.image) === "pdf" ? (
                                    <Document file={doc?.image}>
                                      <Page pageNumber={1} />
                                    </Document>
                                  ) : (
                                    ""
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </div>
                        {doc?.title && (
                          <div className="Docstitle span">{doc.title}</div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {/* Photos */}
              <div className="detailsBox">
                <input
                  type="checkbox"
                  id="checkboxForDescPhotos"
                  onChange={() => setIsChecked(!isChecked)}
                />
                <div className="photos infoBox">
                  <div className="infoBoxHeader">
                    <h2>Photos:</h2>
                    {isAddingOnEdit ? (
                      <button
                        className="AddBox"
                        onClick={() =>
                          addNewPhotoCardClicked(0, PROPERTY_ENUMS.PHOTOS)
                        }
                      >
                        Add
                      </button>
                    ) : (
                      docsPhotos?.length > 0 && (
                        <button className="share-button">
                          <img
                            src={ShareIcon}
                            alt=""
                            onClick={() =>
                              sharedSectionOnly(
                                "PHOTOS",
                                docsPhotos && docsPhotos?.[0]?.image
                                  ? docsPhotos?.[0]?.image
                                  : ""
                              )
                            }
                          />
                        </button>
                      )
                    )}
                  </div>
                  <div className="photosTile scrbrd">
                    {renderAddDocumentBoxes(
                      PROPERTY_ENUMS.PHOTOS,
                      showAddBoxesPhotos
                    )}

                    {docsPhotos?.map((doc, index) => (
                      <div
                        className="photosCard"
                        key={index}
                        // onClick={() =>
                        //   openCardDetails(doc, index, PROPERTY_ENUMS.PHOTOS)
                        // }
                        // onClick={() => handleTileClick(doc.image)}
                        onClick={() => handleImageClick(index)}
                      >
                        {doc.image && (
                          <div className="photoTilePic" key={index}>
                            <img src={doc.image} alt={doc.title} />
                          </div>
                        )}
                        {/* <div className="phtotosTitle span">{doc.title}</div> */}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {/* FLoor Plans: */}
              <div className="detailsBox">
                <input
                  type="checkbox"
                  id="checkboxForDescPhotos"
                  onChange={() => setIsChecked(!isChecked)}
                />
                <div className="floorPlans infoBox">
                  <div className="infoBoxHeader">
                    <h2>Floor Plan:</h2>
                    {isAddingOnEdit ? (
                      <button
                        className="AddBox"
                        onClick={() =>
                          addNewPhotoCardClicked(0, PROPERTY_ENUMS.FLOOR_PLANS)
                        }
                      >
                        Add
                      </button>
                    ) : (
                      docsFloorPlans?.length > 0 && (
                        <button className="share-button">
                          <img
                            src={ShareIcon}
                            alt=""
                            onClick={() =>
                              sharedSectionOnly(
                                "FLOOR_PLANS",
                                docsFloorPlans && docsFloorPlans?.[0]?.image
                                  ? docsFloorPlans?.[0]?.image
                                  : ""
                              )
                            }
                          />
                        </button>
                      )
                    )}
                  </div>
                  <div className="floorPlansTile scrbrd">
                    {renderAddDocumentBoxes(
                      PROPERTY_ENUMS.FLOOR_PLANS,
                      showAddBoxesFloorPlans
                    )}
                    {docsFloorPlans?.map((doc, index) => (
                      <div
                        className="floorPlansTileCard"
                        key={index}
                        onClick={() =>
                          openCardDetails(
                            doc,
                            index,
                            PROPERTY_ENUMS.FLOOR_PLANS
                          )
                        }
                      >
                        {doc.image && checkIfImage(doc?.image) && (
                          <div className="floorPlansTileCardPic">
                            <img src={doc.image} alt={`File ${index}`} />
                          </div>
                        )}
                        {!checkIfImage(doc?.image) && (
                          <>
                            <div className="floorPlansTileCardPic">
                              {getFileType(doc?.image) === "word" ||
                              getFileType(doc?.image) === "unknown" ? (
                                <img src={FileIcon} alt="File" />
                              ) : (
                                <>
                                  {getFileType(doc?.image) === "pdf" ? (
                                    <Document file={doc?.image}>
                                      <Page pageNumber={1} />
                                    </Document>
                                  ) : (
                                    ""
                                  )}
                                </>
                              )}
                            </div>
                          </>
                        )}
                        {doc?.title && (
                          <div className="floorPlansTileCardTitle span">
                            {doc?.title}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {/* Tenancy Details */}
              <div className="detailsBox">
                <input
                  type="checkbox"
                  id="checkboxForDescPhotos"
                  onChange={() => setIsChecked(!isChecked)}
                />
                <div className="TenancyDetails infoBox">
                  <div className="infoBoxHeader">
                    <h2>Tenancy Details:</h2>
                    {isAddingOnEdit ? (
                      <buttton
                        className="AddBox"
                        onClick={() =>
                          addNewPhotoCardClicked(
                            0,
                            PROPERTY_ENUMS.TENANCY_DETAILS
                          )
                        }
                      >
                        Add
                      </buttton>
                    ) : (
                      docsTenancyDetails?.length > 0 && (
                        <button className="share-button">
                          <img
                            src={ShareIcon}
                            alt=""
                            onClick={() =>
                              sharedSectionOnly(
                                "TENANCY_DETAILS",
                                docsTenancyDetails &&
                                  docsTenancyDetails?.[0]?.image
                                  ? docsTenancyDetails?.[0]?.image
                                  : ""
                              )
                            }
                          />
                        </button>
                      )
                    )}
                  </div>
                  <div className="TenancyDetailsTile scrbrd">
                    {renderAddDocumentBoxes(
                      PROPERTY_ENUMS.TENANCY_DETAILS,
                      showAddBoxesTenancyDetails
                    )}
                    {docsTenancyDetails?.map((doc, index) => (
                      <div
                        className="TenancyDetailsTileCard"
                        key={index}
                        onClick={() =>
                          openCardDetails(
                            doc,
                            index,
                            PROPERTY_ENUMS.TENANCY_DETAILS
                          )
                        }
                      >
                        {doc.image && checkIfImage(doc?.image) && (
                          <div className="TenancyDetailsTileCardPic">
                            <img src={doc.image} alt={`File ${index}`} />
                          </div>
                        )}
                        {!checkIfImage(doc?.image) && (
                          <>
                            <div className="TenancyDetailsTileCardPic">
                              {getFileType(doc?.image) === "word" ||
                              getFileType(doc?.image) === "unknown" ? (
                                <img src={FileIcon} alt="File" />
                              ) : (
                                <>
                                  {getFileType(doc?.image) === "pdf" ? (
                                    <Document file={doc?.image}>
                                      <Page pageNumber={1} />
                                    </Document>
                                  ) : (
                                    ""
                                  )}
                                </>
                              )}
                            </div>
                          </>
                        )}
                        {doc?.title && (
                          <div className="TenancyDetailsTileCardTitle span">
                            {doc?.title}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {/* SNAGGING */}
              <div className="detailsBox">
                <input
                  type="checkbox"
                  id="checkboxForDescPhotos"
                  onChange={() => setIsChecked(!isChecked)}
                />
                <div className="snagging infoBox">
                  <div className="infoBoxHeader">
                    <h2>Snagging:</h2>
                    {isAddingOnEdit ? (
                      <button
                        className="AddBox"
                        onClick={() =>
                          // addNewPhotoCardClicked(0, PROPERTY_ENUMS.SNAGGING)
                          openCardDetailsForSnaggingForEditShow()
                        }
                      >
                        Add
                      </button>
                    ) : (
                      docsSnagging?.length > 0 && (
                        <button className="share-button">
                          <img
                            src={ShareIcon}
                            alt=""
                            onClick={() =>
                              sharedSectionOnly(
                                "SNAGGING",
                                docsSnagging && docsSnagging?.[0]?.image
                                  ? docsSnagging?.[0]?.image
                                  : ""
                              )
                            }
                          />
                        </button>
                      )
                    )}
                  </div>

                  <div className="snaggingBoxHeader">
                    <div className="snaggingTile scrbrd">
                      {docsSnagging?.map((doc, index) => (
                        <div
                          className="snaggingTileCard"
                          key={index}
                          // onClick={() => {
                          //   openCardDetails(
                          //     doc,
                          //     index,
                          //     PROPERTY_ENUMS.SNAGGING
                          //   );
                          //   // handlePropertyInspection()
                          // }}
                          onClick={() => {
                            handlePropertyInspection();
                          }}
                        >
                          <div className="snaggingTileCardPic">
                            {/* {doc?.files?.map((file, fileIndex) => (
                              <img
                                key={fileIndex}
                                src={URL.createObjectURL(file)}
                                alt={`File ${fileIndex}`}
                              />
                            ))} */}
                            {doc.image && checkIfImage(doc?.image) && (
                              <div className="snaggingTileCardPic" key={index}>
                                <img src={doc.image} alt={doc.title} />
                              </div>
                            )}
                          </div>
                          {/* <div className="snaggingCardTitle span">
                            {doc.title}
                          </div> */}
                        </div>
                      ))}
                    </div>

                    {docsSnagging?.length > 0 ? (
                      <div
                        className="snaggingButton"
                        onClick={() => {
                          handlePropertyInspection();
                        }}
                      >
                        <ForwardIcon />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="lastbox">
                <form
                  className="additionalNote"
                  onClick={isAddingOnEdit ? handleFocusClick : null}
                >
                  <div className="textArea">
                    <label htmlFor="textarea">Additional Notes:</label>
                    {isAddingOnEdit ? (
                      <textarea
                        ref={textareaRef}
                        className="notes-textarea"
                        name="message"
                        id="message"
                        value={notes}
                        onChange={handleNotes}
                      />
                    ) : (
                      <pre className="notes-textarea">{notes}</pre>
                    )}
                  </div>
                </form>
                <div
                  className={`reminderBox ${
                    isAddingOnEdit || isEditViewingExistingCard
                      ? ""
                      : "customSyle"
                  }`}
                >
                  <div>
                    <span>Reminder</span>
                    <label
                      style={{
                        fontSize: "clamp(12px, 2vw, 16px)",
                        padding: "0 10px",
                      }}
                    >
                      {reminderInfo?.reminder}
                    </label>
                  </div>
                  <button
                    onClick={() => {
                      {
                        isAddingOnEdit &&
                          setIsReminderBoxOpen(!isReminderBoxOpen);
                      }
                    }}
                  >
                    <PlusBoxIcon />
                  </button>
                </div>

                {isReminderBoxOpen && (
                  <ReminderBox
                    closeReminderBox={() => {
                      setIsReminderBoxOpen(!isReminderBoxOpen);
                    }}
                    saveReminderInfo={(reminderObj) => {
                      setReminderInfo(reminderObj);
                      // console.log("testing: ", reminderObj);
                    }}
                    reminderInfo={reminderInfo}
                  />
                )}
              </div>
            </div>
          )}
          {selectedTile && (
            <div className="modal">
              <span className="closeButton" onClick={handleCloseModal}>
                Close
              </span>
              <div className="modalContent">
                <img src={selectedTile} alt="Selected Award" />
              </div>
            </div>
          )}
          {selectedImageIndex !== null && (
            <div className="image-slider">
              <Gallery
                items={galleryImages}
                startIndex={selectedImageIndex}
                onRequestClose={closeImageSlider}
              />
              <div className="close-slider" onClick={closeImageSlider}>
                Close
              </div>
            </div>
          )}
        </div>
      )}
      {renderAddDocumentBoxes(PROPERTY_ENUMS.SNAGGING, showAddBoxesSnagging)}
    </>
  );
};

export default GetPropertyDetail;
