import React from "react";

const infoIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.9917 12.6749C21.95 12.9582 21.925 13.2415 21.8751 13.5249C21.3919 16.4499 19.9175 18.7332 17.4685 20.3832C16.2273 21.2165 14.8612 21.7499 13.3868 21.9165C9.27176 22.3665 6.00641 20.8915 3.64069 17.4915C2.71606 16.1665 2.21626 14.6665 2.05799 13.0665C1.59984 8.52487 4.1988 4.3332 8.38878 2.69987C9.28009 2.34987 10.2047 2.1332 11.171 2.06654C11.2293 2.06654 11.2876 2.04154 11.3376 2.0332C11.7791 2.0332 12.2206 2.0332 12.6621 2.0332C12.9036 2.06654 13.1535 2.09154 13.3951 2.12487C15.0528 2.36654 16.5688 2.96654 17.91 3.96654C20.1091 5.6082 21.4336 7.79154 21.8834 10.4999C21.9334 10.7832 21.9584 11.0749 22 11.3665V12.6915L21.9917 12.6749ZM21.0837 12.0082C21.0754 6.9832 17.002 2.91654 11.9957 2.92487C6.97269 2.9332 2.90765 7.0082 2.91598 12.0165C2.92431 17.0415 6.99768 21.1082 12.004 21.0999C17.027 21.0915 21.092 17.0165 21.0837 12.0082Z"
        fill="#1B1B1B"
      />
      <path
        d="M14.1537 15.9917C14.1204 16.1334 14.0787 16.2917 14.0371 16.4417C14.0204 16.4834 13.9788 16.5251 13.9371 16.5417C13.479 16.6917 13.0375 16.8917 12.571 16.9751C11.9463 17.0917 11.3215 17.0501 10.805 16.5917C10.4885 16.3084 10.3885 15.9501 10.4135 15.5334C10.4635 14.8417 10.7134 14.2084 10.88 13.5501C10.9966 13.1084 11.1299 12.6667 11.2215 12.2084C11.3965 11.3417 10.9467 11.2084 10.2469 11.3084C10.0803 11.3334 9.92206 11.4001 9.73047 11.4501C9.78045 11.2501 9.8221 11.0751 9.87208 10.9001C9.88041 10.8667 9.92206 10.8417 9.95538 10.8334C10.5468 10.6334 11.1216 10.3667 11.7713 10.3501C12.2795 10.3417 12.7376 10.4417 13.1041 10.8167C13.354 11.0751 13.429 11.3917 13.4207 11.7501C13.4123 12.3751 13.1874 12.9584 13.0291 13.5501C12.8792 14.1084 12.7459 14.6667 12.6043 15.2251C12.596 15.2667 12.596 15.3001 12.5877 15.3417C12.546 15.8584 12.7543 16.0917 13.2624 16.0834C13.5539 16.0751 13.8372 16.0167 14.1537 15.9834V15.9917Z"
        fill="#1B1B1B"
      />
      <path
        d="M14.2945 8.16636C14.2779 8.83303 13.6281 9.39969 12.9201 9.34136C12.5452 9.30803 12.2287 9.16636 11.9788 8.88303C11.5457 8.39136 11.6456 7.67469 12.1954 7.26636C12.8618 6.76636 13.8864 7.01636 14.1946 7.75803C14.2446 7.89136 14.2612 8.03303 14.2945 8.16636Z"
        fill="#1B1B1B"
      />
    </svg>
  );
};

export default infoIcon;
