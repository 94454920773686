import React, { useState, useEffect } from "react";
import NavigationComponent from "../NavigationComponent/NavigationTabs";
import Realtor from "./Realtor";
import Property from "./Property";
import Contact from "./Contacts";
import Notifications from "./Notifications";
import Profile from "./Profile";
import Settings from "./Settings";

// for chats
// import Draggable from "react-draggable";
import Chats from "../../Chats/Chats";
import ChatIcon from "../../../assets/svgs/ChatIcon";
////////////////////

import { APP_ROUTES } from "../../../utils/AppConstants";
import { logoutUser } from "../../../api/reducerSlices/authSlice";
import {
  resetAll,
  resetAllForNotInPageWhile,
} from "../../../utils/CommonUtils";
import { useDispatch, useSelector } from "react-redux";
import ListIcon from "../../../assets/svgs/ListIcon";
import XIcon from "../../../assets/svgs/XIcon";
import LoadingSpinner from "../../../utils/LoadingSpinner";
import LogoutConfirmatiom from "../../popup-boxes/LogoutConfirmatiom";
import { successSubscription } from "../../../api/actions/subscriptionsPlansActions";
import { getUserProfile } from "../../../api/actions/profileActions";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
require("./HomeCSS/home.css");
require("./HomeCSS/homeConstantsCSS.css");

const HomeScreen = () => {
  const { user } = useSelector((state) => state.auth);
  const { userData } = useSelector((state) => state.profileSlice);
  const [isNotchat, setIsNotchat] = useState(true);
  const [styleForProperty, setStyleForProperty] = useState(false);
  const [chkForChat, setChkForChat] = useState(false);
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(
    () => localStorage.getItem("activeTab") || "realtor"
  );
  const [activeTabForNav, setActiveTabForNav] = useState(false);
  // const { userData } = useSelector((state) => state.profileSlice);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isResponsive, setIsResponsive] = useState(window.innerWidth < 748);
  const [loading, setLoading] = useState(false);
  const [logoutConfirmStatus, setLogoutConfirmStatus] = useState(false);
  const [userChatIdWithProfile, setUserChatIdWithProfile] = useState(null);
  const [sed, setSed] = useState(null);

  useEffect(() => {
    if (activeTab === "logout") {
      localStorage.setItem("activeTab", APP_ROUTES.Realtor_ROUTE);
    } else {
      localStorage.setItem("activeTab", activeTab);
    }
  }, [activeTab]);

  useEffect(() => {
    localStorage.setItem("loginStatusfpr", true);
    dispatch(getUserProfile());
  }, []);

  useEffect(() => {
    if (userData?.user?.subscriptionEndDate) {
      setSed(userData?.user?.subscriptionEndDate);
    }
  }, [userData]);

  // useEffect(() => {
  //   if (loading) {
  //     // Set timeout to hide the loading spinner after 2 seconds
  //     const timeout = setTimeout(() => {
  //       setLoading(false);
  //     }, 2000);

  //     // Clear the timeout on component unmount or if loading state changes
  //     return () => clearTimeout(timeout);
  //   }
  // }, [loading]);

  const handleNavigation = (page) => {
    // setIsNotchat(true);
    setActiveTab(page);
  };

  const ifUserWantLogout = () => {
    setLogoutConfirmStatus(false);
    resetAll(dispatch);
    dispatch(logoutUser());
    localStorage.setItem("activeTab", APP_ROUTES.Realtor_ROUTE);
    localStorage.setItem("isActiveTab", "realtorTab");
  };

  const logoutConfirmationUiToggle = () => {
    setLogoutConfirmStatus(!logoutConfirmStatus);
  };

  const sfocfop = (userId) => {
    setUserChatIdWithProfile(userId);
    if (chkForChat) {
      setChkForChat(false);
    }
    if (isNotchat) {
      setIsNotchat(false);
    }
  };

  const renderMainComponent = () => {
    switch (activeTab) {
      // case "realtor":
      case APP_ROUTES.Realtor_ROUTE:
        return <Realtor statusForOpenChatsForOpenedProfile={sfocfop} />;
      // case "property":
      case APP_ROUTES.Property_ROUTE:
        return <Property />;
      // case "contact":
      case APP_ROUTES.Contact_ROUTE:
        return <Contact />;
      // case "notifications":
      case APP_ROUTES.Notifications_ROUTE:
        return <Notifications />;
      // case "profile":
      case APP_ROUTES.Profile_ROUTE:
        return <Profile />;
      // case "settings":
      case APP_ROUTES.Settings_ROUTE:
        return <Settings />;
      // case "logout":
      //   setLogoutConfirmStatus(true);
      //   break;
      // Add cases for other tabs
      default:
        return <Realtor />; // Default to Realtor component
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsResponsive(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call on initial render

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleOpenSidebar = () => {
    setIsSidebarOpen(true);
  };

  const handleCloseSidebar = () => {
    if (isResponsive) {
      setIsSidebarOpen(false);
    }
  };
  // const [isOpen, setIsOpen] = useState(false);
  // Function to handle button click
  // const sidebarOpen = () => {
  //   // Set the state to true when the button is clicked
  //   setIsOpen(true);
  // };
  // const sidebarClose = () => {
  //   // Set the state to true when the button is clicked
  //   setIsOpen(false);
  // };
  const moveback = () => {
    setIsNotchat(true);
    if (userChatIdWithProfile !== null) {
      setUserChatIdWithProfile(null);
    }
    renderMainComponent();
  };

  const openchats = () => {
    if (chkForChat) {
      setChkForChat(false);
    }
    if (isNotchat) {
      setIsNotchat(false);
    }
  };

  // Effect to set up session management
  useEffect(() => {
    let inactivityTimer;
    let isLeaving = false;

    const resetPage = () => {
      resetAllForNotInPageWhile();
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        isLeaving = true;
      } else {
        isLeaving = false;
      }
    };

    const startInactivityTimer = () => {
      inactivityTimer = setTimeout(() => {
        if (isLeaving) {
          resetPage();
        }
      }, 600000); // 5 minutes in milliseconds (adjust as needed)
    };

    window.addEventListener("beforeunload", () => {
      isLeaving = true;
    });

    startInactivityTimer();

    const resetTimer = () => {
      clearTimeout(inactivityTimer);
      if (!isLeaving) {
        startInactivityTimer();
      }
    };

    window.addEventListener("mousemove", resetTimer);
    window.addEventListener("keydown", resetTimer);
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      window.removeEventListener("mousemove", resetTimer);
      window.removeEventListener("keydown", resetTimer);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      clearTimeout(inactivityTimer);
    };
  }, []);

  const addAndGetSubscription = (success) => {
    // const urlParams = new URLSearchParams(window.location.search);
    // const success = urlParams.get("success");
    //  alert("success: " + success);
    //  if (planIdfsc) {
    //    alert("success: " + success);
    //  }
    // console.log("success: ", success);
    let planIdfsc = localStorage.getItem("planIdOnSuccess");
    if (success === "true" && planIdfsc) {
      // alert(`return: pass\n${planIdfsc}`);
      let object = {
        planId: planIdfsc,
      };
      dispatch(successSubscription(object));
      dispatch(getUserProfile());
    } else if (success === "false") {
      alert("return: failed");
    }
    window.history.replaceState({}, document.title, window.location.pathname);
    localStorage.removeItem("planIdOnSuccess");
  };

  const urlParams = new URLSearchParams(window.location.search);
  const success = urlParams.get("success");
  useEffect(() => {
    if (success === "true") {
      addAndGetSubscription(success);
    } else {
      localStorage.removeItem("planIdOnSuccess");
    }
  }, []);;

  //////////////////////////////////////////
  // uri scheme is = base-url?platform=ios&id=userid
  useEffect(() => {
    // Function to parse URI parameters
    const parseUriParameters = () => {
      // Get the current URL
      const currentUrl = window.location.href;

      // Check if the URL contains the expected parameters
      if (currentUrl.includes("platform=ios") && currentUrl.includes("id=")) {
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get("id");
        if (id === user?._id) {
          localStorage.setItem("isActiveTab", "settings");
          localStorage.setItem("Fromios", true);
          handleNavigation(APP_ROUTES.Settings_ROUTE);
          setActiveTabForNav(true);
          // renderMainComponent();
        } else {
          alert("Please login into your account in web");
        }
      }
    };

    // Call the function to parse URI parameters
    parseUriParameters();
  }, []);

  const openSubsComp = () => {
    localStorage.setItem("isActiveTab", "settings");
    localStorage.setItem("Fromios", true);
    handleNavigation(APP_ROUTES.Settings_ROUTE);
    setActiveTabForNav(true);
  };

  const SubscriptionAlertClose = () => setSed(null);

  const SubscriptionAlert = (subscriptionEndDate) => {
    // Convert subscription end date string to a Date object
    const endDate = new Date(subscriptionEndDate?.subscriptionDate);

    // Calculate the difference between the current date and subscription end date
    const currentDate = new Date();
    const differenceInMs = endDate - currentDate;
    const differenceInDays = Math.ceil(differenceInMs / (1000 * 60 * 60 * 24));
    // console.log(subscriptionEndDate?.subscriptionDate);
    // Render alert with subscribe button if subscription end date is near (7 days or fewer left)
    if (differenceInDays <= 7 && differenceInDays > 0) {
      return (
        <Alert
          severity="warning"
          onClose={() => {
            SubscriptionAlertClose();
          }}
        >
          Subscription renewal is imminent. Renew now!
        </Alert>
      );
    } else if (differenceInDays === 0) {
      return (
        <Alert
          severity="warning"
          onClose={() => {
            SubscriptionAlertClose();
          }}
        >
          Subscription renewal is imminent. Renew now!
          <button
            className="btnfsub"
            onClick={() => {
              openSubsComp();
            }}
          >
            Subscribe now
          </button>
        </Alert>
      );
    }
  };

  useEffect(() => {
    // Check subscription status every 24 hours
    const interval = setInterval(() => {
      setSed(userData?.user?.subscriptionEndDate);
    }, 4000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {logoutConfirmStatus && (
        <LogoutConfirmatiom
          onConfirm={ifUserWantLogout}
          onCancel={logoutConfirmationUiToggle}
        />
      )}
      {loading && <LoadingSpinner />}
      <div className={"mainContainer"}>
        {/* <div className={`sidebar ${isOpen ? "sidebarOpen" : ""}`}> */}
        <div className={`sidebar ${isSidebarOpen ? "sidebarOpen" : ""}`}>
          <div className="navCloseBox">
            {/* <div className="navBarClose" onClick={sidebarClose}> */}
            <div className="navBarClose" onClick={handleCloseSidebar}>
              <XIcon />
            </div>
          </div>
          <NavigationComponent
            onNavigate={handleNavigation}
            setSidebar={handleCloseSidebar}
            logoutLayout={logoutConfirmationUiToggle}
            activeTabForNav={activeTabForNav}
            setActiveTabForNav={setActiveTabForNav}
          />
        </div>
        <div className="componentContainer">
          <div className="navbarline">
            {/* <div className="navBarOpen" onClick={sidebarOpen}> */}
            <div className="navBarOpen" onClick={handleOpenSidebar}>
              <ListIcon />
            </div>
          </div>
          {sed !== null && (
            <>
              <SubscriptionAlert subscriptionDate={sed} />
            </>
          )}
          {renderMainComponent()}
        </div>
        {isNotchat && (
          // <div className="chatContainerhc">
          //   <Draggable bounds="parent">
          <div className="chat-container" onClick={openchats}>
            <ChatIcon />
          </div>
          //   </Draggable>
          // </div>
        )}
        {!isNotchat && (
          <Chats
            moveback={moveback}
            chkForChat={chkForChat}
            openRoom={() => setChkForChat(true)}
            userChatIdWithProfile={userChatIdWithProfile}
            setUserChatIdWithProfile={setUserChatIdWithProfile}
          />
        )}
      </div>
    </>
  );
};

export default HomeScreen;
