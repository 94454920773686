// import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getToken } from "../../utils/CommonUtils";
import axios from "axios";

const config = {
  method: "POST", // *GET, POST, PUT, DELETE, etc.
  cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
  headers: {
    "Content-Type": "application/json",
    "Cross-Origin-Embedder-Policy": false,
    // "Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InRlc3QxQGdtYWlsLmNvbSIsInJvbGUiOiJyZWFsdG9yIiwiaWQiOiI2NTVjMmJiODMwNTU2OWRjYmVmOTM4MzAiLCJpYXQiOjE3MDczMDEzMDMsImV4cCI6MTczODgzNzMwM30.ki56OzG10bYOxk2VBPNgfakWBaTV7IfQItmisWY7wps"
    Authorization: "Bearer ",
  },
  redirect: "follow", // manual, *follow, error
  referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
};

const config2 = {
  method: "POST", // *GET, POST, PUT, DELETE, etc.
  cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
  headers: {
    // "Content-Type": "multipart/form-data",
    "Cross-Origin-Embedder-Policy": false,
    Authorization: "Bearer ",
  },
  redirect: "follow", // manual, *follow, error
  referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
};

export const getUserProperties = createAsyncThunk(
  "properties",
  async (object, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/property`,
        // "https://api.realtorganizer.com/property",
        {
          ...config,
          method: "GET",
          headers: { ...config.headers, Authorization: "Bearer " + getToken() },
        }
      );
      return response.json(); // parses JSON response into native JavaScript objects
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getPropertyDetails = createAsyncThunk(
  "propertyDetails",
  async (propertyId, { rejectWithValue }) => {
    try {
      // console.log("PropertyId: ", propertyId)
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/property/${propertyId}`,
        // `https://api.realtorganizer.com/property/${propertyId}`,
        {
          ...config,
          method: "GET",
          headers: { ...config.headers, Authorization: "Bearer " + getToken() },
        }
      );
      return response.json(); // parses JSON response into native JavaScript objects
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getPropertyImage = createAsyncThunk(
  "getPropertyImages",
  async (propertyIdForImage, { rejectWithValue }) => {
    // const { _id } = property;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/property/${propertyIdForImage}`,
        // `https://api.realtorganizer.com/property/${propertyIdForImage}`,
        {
          ...config,
          method: "GET",
          headers: { ...config.headers, Authorization: "Bearer " + getToken() },
        }
      );
      return response.json(); // parses JSON response into native JavaScript objects
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const createProperty = createAsyncThunk(
  "createProperty",
  async (object, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/property`,
        // "https://api.realtorganizer.com/property",
        {
          ...config,
          headers: { ...config.headers, Authorization: "Bearer " + getToken() },
          body: JSON.stringify(object),
        }
      );
      return response.json(); // parses JSON response into native JavaScript objects
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const addOrUpdateFileToProperty = createAsyncThunk(
  "addOrUpdateFileToProperty",
  async (property, { rejectWithValue }) => {
    try {
      const { propertyId, type, title, files, imageId } = property;
      // console.log("property data: " + JSON.stringify(property));
      const formData = new FormData();
      // if (type === "PHOTOS") {
      //   formData.append("files", files);
      // } else {
      //   formData.append("files", files[0]);
      // }
      if (type === "PHOTOS") {
        files.forEach((file) => {
          formData.append("files", file);
        });
      } else {
        // Ensure files[0] is a File object before appending
        if (files[0] instanceof File) {
          formData.append("files", files[0]);
        } else {
          console.error("Invalid file object");
        }
      }

      formData.append("title", title);
      if (property.hasOwnProperty("imageId") && property.imageId !== "") {
        formData.append("id", imageId);
      }
      // console.log("property data: " + JSON.stringify(property));
      // console.log("formData data: " + JSON.stringify(formData));

      await axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/property/${propertyId}?type=${type}`,
          // `https://api.realtorganizer.com/property/${propertyId}?type=${type}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + getToken(),
            },
          }
        )
        .then((response) => {
          // console.log("Upload successful:", response.data);
          return response.data;
        })
        .catch((error) => {
          console.error("Upload failed:", error);
        });
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updatePropertyDetails = createAsyncThunk(
  "updatePropertyDetails",
  async (property, { rejectWithValue }) => {
    try {
      const { id } = property;
      delete property.id;

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/property/${id}`,
        // `https://api.realtorganizer.com/property/${id}`,
        {
          ...config,
          method: "PUT",
          headers: { ...config.headers, Authorization: "Bearer " + getToken() },
          body: JSON.stringify(property),
        }
      );
      return response.json(); // parses JSON response into native JavaScript objects
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteProperty = createAsyncThunk(
  "deleteProperty",
  async (property, { rejectWithValue }) => {
    try {
      const { id } = property;

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/property/${id}`,
        // `https://api.realtorganizer.com/property/${id}`,
        {
          ...config,
          method: "DELETE",
          headers: { ...config.headers, Authorization: "Bearer " + getToken() },
        }
      );
      return response.json(); // parses JSON response into native JavaScript objects
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const sharingProperty = createAsyncThunk(
  "sharingProperty",
  async (data, { rejectWithValue }) => {
    try {
      const { propertId } = data;
      delete data.propertId;
      // console.log(" property data sharing: " + JSON.stringify(data));
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/catalog/share/${propertId}`,
        // `https://api.realtorganizer.com/property/share/${propertId}`,
        {
          ...config,
          method: "POST",
          headers: { ...config.headers, Authorization: "Bearer " + getToken() },
          body: JSON.stringify(data),
        }
      );
      return response.json(); // parses JSON response into native JavaScript objects
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const imageOrderForPropertyPhotosSection = createAsyncThunk(
  "imageOrderForPropertyPhotosSection",
  async (orderList, { rejectWithValue }) => {
    try {
      const { propertyId } = orderList;
      delete orderList?.propertyId;

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/property/image-order/${propertyId}`,
        // `https://api.realtorganizer.com/property/image-order/${propertyId}`,
        {
          ...config,
          method: "PUT",
          headers: { ...config.headers, Authorization: "Bearer " + getToken() },
          body: JSON.stringify(orderList),
        }
      );
      return response.json(); // parses JSON response into native JavaScript objects
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const renderBodyFormat = (data, type) => {
  const formats = {
    "application/json": () => JSON.stringify(data),
    "multipart/form-data": () => {
      const form = new FormData();
      Object.keys(data).forEach((key) => form.append(key, data[key]));
      return form;
    },
  };
  return formats[type]();
};
