import React, { useEffect, useRef, useState } from "react";
import SearchIcon from "../../../assets/svgs/SearchIcon";
require("./HomeCSS/mapSearchBar.css");

let autoComplete;

const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};

const SearchPlacesOnmap = ({ onSelectPlace , searchText, setSearchText }) => {
  const [query, setQuery] = useState("");
  const autoCompleteRef = useRef(null);

  const handleScriptLoad = (updateQuery, autoCompleteRef) => {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      {
        // types: ["(cities)"],
      }
    );
    autoComplete.addListener("place_changed", () => {
      handlePlaceSelect(updateQuery);
    });
  };

  useEffect(() => {
    setQuery(searchText)
  }, [searchText]);

  const handlePlaceSelect = async (updateQuery) => {
    const addressObject = await autoComplete.getPlace();

    if (!addressObject.geometry || !addressObject.geometry.location) {
      // Address object or its location is invalid
      console.error("Invalid address object:", addressObject);
      return;
    }

    const query = addressObject.formatted_address;
    updateQuery(query);

    const latLng = {
      city: query,
      latitude: addressObject.geometry.location.lat(),
      longitude: addressObject.geometry.location.lng(),
    };

    onSelectPlace(latLng);
  };

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_LOCATION_API_KEY}&libraries=places`,
      () => handleScriptLoad(setQuery, autoCompleteRef)
    );
  }, []);

  return (
    <div className="search-autocomplete">
      <div className="inputBar">
        <div className="searchIcon">
          <SearchIcon />
        </div>
        <input
          ref={autoCompleteRef}
          className="form-control"
          onChange={(event) => setQuery(event.target.value)}
          placeholder="Search Places ..."
          value={query}
        />
      </div>
    </div>
  );
};

export default SearchPlacesOnmap;
