const APP_ROUTES = {
  SIGN_IN_ROUTE: "/signin",
  SIGN_UP_ROUTE: "/signup",
  FORGOT_PASSWORD_ROUTE: "/forgotPassword",
  RESET_PASSWORD_ROUTE: "/resetPassword",
  VERIFY_OTP_ROUTE: "/verifyOtp",
  HOME_ROUTE: "/home",
  Realtor_ROUTE: "/realtor",
  Property_ROUTE: "/property",
  Contact_ROUTE: "/contact",
  Create_Contact_ROUTE: "/contactaddform",
  Notifications_ROUTE: "/notification",
  Profile_ROUTE: "/profile",
  Settings_ROUTE: "/settings",
  ContactUs_Route:"/contactus",
  APP_ROUTE: "/",
};
export { APP_ROUTES };
