import React, { useEffect, useState, useRef } from "react";
import TextareaAutosize from "react-textarea-autosize";

import MoveBackIcon from "../../../assets/svgs/LeftArrowIcon";
import ShareIcon from "../../../assets/iconImages/shareIcon.png";
import XIcon from "../../../assets/svgs/XIcon";
import Gallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "../../../utils/LoadingSpinner";
import ContactsToSelect from "./ContactsToSelect";
import {
  createCatalog,
  deleteCatalog,
  updateCatalog,
  sharingCatalog,
  imageOrderForCatalog,
} from "../../../api/actions/profileActions";
// import { resetState } from "../../../api/reducerSlices/profileSlice";
import {
  EventSuccessPopup,
  EventFailedPopup,
} from "../../popup-boxes/main-page-Popup's/mainPageEventPopup";
require("./HomeCSS/addCatalog.css");

function AddCatalog({
  catalogSet,
  isViewCatalog,
  isCatalogOfOtherProfile,
  goBackToProfilePage,
  profileData,
  setCatalogSet,
}) {
  const dispatch = useDispatch();
  const { status, error, imageOrderApply, catalogUpdated } = useSelector(
    (state) => state.profileSlice
  );

  const [isPublic, setIsPublic] = useState(
    isViewCatalog ? catalogSet?.isPublic : true
  );
  const [isPublicCheck, setIsPublicCheck] = useState(
    isViewCatalog ? catalogSet?.isPublic : true
  );
  const [isView, setIsView] = useState(isViewCatalog);
  const [isCancelEdit, setIsCancelEdit] = useState(false);
  const [title, setTitle] = useState(isViewCatalog ? catalogSet?.title : "");
  const [titleCheck, setTitleCheck] = useState(
    isViewCatalog ? catalogSet?.title : ""
  );
  const [description, setDescription] = useState(
    isViewCatalog ? catalogSet?.description : ""
  );
  const [descriptionCheck, setDescriptionCheck] = useState(
    isViewCatalog ? catalogSet?.description : ""
  );

  const [images, setImages] = useState([]);
  const [files, setFiles] = useState([]);
  const [fileIds, setFileIds] = useState([]);
  const [isEditingCatalogEnable, setIsEditingCatalogEnable] = useState(
    !isViewCatalog
  );
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [isContectBoxOpenToShare, setIsContectBoxOpenToShare] = useState(false);
  const [sharingId, setSharingId] = useState(catalogSet?._id || "");
  const fileInputRef = useRef(null);
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupMsg, setShowPopupMsg] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("setIsViewCatalog") === "true" && !catalogSet) {
      setIsView(true);
      const storedCatalogData = localStorage.getItem("CatalogData");
      if (storedCatalogData) {
        // Parse the stored string back to an object
        const catalogData = JSON.parse(storedCatalogData);
        // console.log(JSON.stringify(storedCatalogData));
        setCatalogSet(catalogData);
        setSharingId(catalogData?._id);
        setIsPublic(catalogData?.isPublic);
        setIsPublicCheck(catalogData?.isPublic);
        setTitle(catalogData?.title);
        setTitleCheck(catalogData?.title);
        setDescription(catalogData?.description);
        setDescriptionCheck(catalogData?.description);
        setIsEditingCatalogEnable(false);
        const sortedImages = [...catalogData?.images].sort(
          (a, b) => a?.orderNo - b?.orderNo
        );
        setImages(sortedImages || []);
      }
    }
  }, []);

  useEffect(() => {
    if (catalogSet && catalogSet?.images) {
      const sortedImages = [...catalogSet.images].sort(
        (a, b) => a?.orderNo - b?.orderNo
      );

      setImages(sortedImages);
    }
  }, [catalogSet]);

  // useEffect(() => {
  //   if (error && error !== "") {
  //     alert(error);
  //   }
  // }, [error]);

  const handlePublicChange = () => {
    if (isEditingCatalogEnable === true) {
      setIsPublic(!isPublic);
    } else {
      setShowPopupMsg(
        "Please make Catalog editable then you\ncan change the public active status."
      );
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
        setShowPopupMsg("");
      }, 3000);
    }
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleImageChange = (event) => {
    const selectedFiles = event.target.files;
    const remainingSlots = 10 - images?.length; // Calculate remaining slots
    if (selectedFiles.length > remainingSlots) {
      setShowPopupMsg(`You can only upload ${remainingSlots} more images.`);
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
        setShowPopupMsg("");
      }, 3000);
      event.target.value = "";
      return;
    }

    const newImages = [...images];
    const newFiles = [...files];
    for (const file of selectedFiles) {
      newFiles.push(file);
      newImages.push({
        url: URL.createObjectURL(file),
        orderNo: newImages.length,
        _id: `generated_id_${Date.now()}`,
      });
    }
    setImages(newImages);
    setFiles(newFiles);
    handleSave();
  };

  const addimage = () => {
    if (images && images?.length >= 10) {
      setShowPopupMsg("Image Section Full!");
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
        setShowPopupMsg("");
      }, 3000);
    } else {
      fileInputRef.current.click();
    }
  };

  const renderText = (value, className) =>
    isView && <pre className={className}>{value}</pre>;

  const divRef = useRef(null);

  useEffect(() => {
    // Calculate the height of the div based on its content
    if (divRef.current) {
      const height = divRef.current.scrollHeight;
      // Optionally, add some padding to the height if needed
      divRef.current.style.height = height + "px";
    }
  }, [description]);

  useEffect(() => {
    const resizeHandler = () => {
      if (window.innerWidth < 1000 && divRef.current) {
        const height = divRef.current.scrollHeight;
        // Optionally, add some padding to the height if needed
        divRef.current.style.height = height + "px";
      }
    };

    // Call the resize handler initially and on resize
    window.addEventListener("resize", resizeHandler);
    resizeHandler();

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, [description]);

  const handleSavechk = () => {
    if (title === "" && description === "" && images?.length === 0) {
      setShowPopupMsg("Please fill at least one field");
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
        setShowPopupMsg("");
      }, 3000);
    } else {
      handleSave();
    }
    if (isViewCatalog) {
      setIsView(true);
      setIsEditingCatalogEnable(false);
    }
  };

  const handleSave = () => {
    const dataToSend = {
      title: title?.trim(),
      description: description.trim(),
      isPublic: isPublic,
      files: files,
    };

    if (isViewCatalog && fileIds.length > 0) {
      dataToSend.deleteImages = [fileIds];
    }

    if (isViewCatalog) {
      dataToSend._id = catalogSet?._id;
      dispatch(updateCatalog(dataToSend));
      setIsView(true);
      setIsEditingCatalogEnable(false);
      setIsCancelEdit(true);
    } else {
      dispatch(createCatalog(dataToSend));
    }
  };

  const handleEdit = () => {
    setIsView(false);
    setIsEditingCatalogEnable(true);
    setIsCancelEdit(true);
  };

  const cancelEdit = () => {
    let changes = false;
    if (isPublic !== isPublicCheck) {
      changes = true;
    }
    if (title !== "" && title !== titleCheck) {
      changes = true;
    }
    if (description !== "" && description !== descriptionCheck) {
      changes = true;
    }
    if (changes === true) {
      var userConfirmation = window.confirm(
        "Some changes you made may not be saved.\nDo you want to skip changes?"
      );
      if (userConfirmation) {
        setIsPublic(isPublicCheck);
        setTitle(titleCheck);
        setDescription(descriptionCheck);
        setIsView(true);
        setIsEditingCatalogEnable(false);
      }
    } else {
      setIsView(true);
      setIsEditingCatalogEnable(false);
    }
  };

  const deleteCatalogImage = (index) => {
    const updatedImages = [...images];
    setFileIds([...fileIds, updatedImages[index]?._id]);
    updatedImages.splice(index, 1);
    setSelectedImageIndex(null);
    setImages(updatedImages);
  };

  const handleDeleteCatalog = () => {
    dispatch(deleteCatalog(catalogSet?._id));
    setImages(null);
    goBackToProfilePage();
  };

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
  };

  const closeImageSlider = () => {
    setSelectedImageIndex(null);
  };

  const galleryImages = images.map((image) => ({
    original: image.url,
    thumbnail: image.url,
  }));

  const shareCatalogs = () => {
    setIsContectBoxOpenToShare(true);
  };

  const closeContactBox = () => {
    setIsContectBoxOpenToShare(false);
  };

  const makeItSharing = (data) => {
    let makeSharemsgready = {
      catalogId: catalogSet?._id || sharingId,
      shareWith: data?.shareWith,
      // sections: ["CATALOGS"],
      image: images?.[0]?.url,
    };
    dispatch(sharingCatalog(makeSharemsgready));
  };

  //for re-order the image
  const [draggedIndex, setDraggedIndex] = useState(null);
  const [imageDragged, setImageDragged] = useState(false);

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData("text/plain", index);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, targetIndex) => {
    e.preventDefault();
    const sourceIndex = e.dataTransfer.getData("text/plain");
    moveImage(sourceIndex, targetIndex);
  };

  const handleTouchStart = (e, index) => {
    setDraggedIndex(index);
  };

  const handleTouchMove = (e) => {
    if (draggedIndex === null) {
      return;
    }
    e.preventDefault(); // Prevent scrolling on touch devices
    const targetIndex = findTargetIndex(
      e.touches[0].clientX,
      e.touches[0].clientY
    );
    if (targetIndex !== null && targetIndex !== draggedIndex) {
      moveImage(draggedIndex, targetIndex);
      setDraggedIndex(targetIndex);
    }
  };

  const handleTouchEnd = () => {
    setDraggedIndex(null);
  };

  const moveImage = (sourceIndex, targetIndex) => {
    const updatedImages = [...images];
    const imageToMove = updatedImages[sourceIndex];
    updatedImages.splice(sourceIndex, 1);
    updatedImages.splice(targetIndex, 0, imageToMove);
    // console.log("data" + JSON.stringify(images));
    setImages(updatedImages); // Update images state with new order
    setImageDragged(true);
  };

  useEffect(() => {
    if (imageDragged) {
      // console.log("data" + JSON.stringify(images));
      const orderList = images.map((image, index) => {
        return {
          id: image._id, // Assuming each image has an ID property
          orderNo: index,
        };
      });
      let object = {
        catalogId: catalogSet?._id,
        orderList: orderList,
      };
      // console.log("data" + JSON.stringify(object));

      dispatch(imageOrderForCatalog(object));
      setImageDragged(false);
    }
  }, [images, imageDragged]);

  const findTargetIndex = (clientX, clientY) => {
    const draggedImageElement = document.getElementById(
      `catalogImage-${draggedIndex}`
    );
    if (!draggedImageElement) {
      return null;
    }
    const draggedImageRect = draggedImageElement.getBoundingClientRect();
    const targetIndex = Array.from(
      document.querySelectorAll(".catalogImage")
    ).findIndex((element, index) => {
      if (index === draggedIndex) {
        return false;
      }
      const rect = element.getBoundingClientRect();
      return (
        clientX > rect.left &&
        clientX < rect.right &&
        clientY > rect.top &&
        clientY < rect.bottom
      );
    });
    return targetIndex !== -1 ? targetIndex : null;
  };

  const closePopup = () => {
    setShowPopup(false);
    setShowPopupMsg("");
  };

  return (
    <>
      {!imageOrderApply && status === "loading" && <LoadingSpinner />}
      {showPopup && (
        <EventFailedPopup showPopupMsg={showPopupMsg} onClose={closePopup} />
      )}
      <div className="catalogpage">
        <div className="header">
          <div className="moveback" onClick={goBackToProfilePage}>
            <MoveBackIcon />
          </div>
          <span className="respspan">Catalog</span>
          {isEditingCatalogEnable && isCancelEdit && (
            <button className="deleteButton btn" onClick={handleDeleteCatalog}>
              Delete
            </button>
          )}
        </div>
        <div className="catalogStatusTag">
          {!isCatalogOfOtherProfile && (
            <div className="onactive">
              <div className={`selectMode ${isPublic ? "check" : ""}`}>
                <span className="on">Public</span>
                <div>
                  <input
                    type="checkbox"
                    id="isPublic"
                    checked={isPublic}
                    onChange={handlePublicChange}
                  />
                  <label htmlFor="isPublic"></label>
                </div>
                <span className="off">Private</span>
              </div>
            </div>
          )}
          {isView && (
            <button className="share-button" onClick={shareCatalogs}>
              <img src={ShareIcon} alt="" />
            </button>
          )}
        </div>
        <div className="buttons">
          {isEditingCatalogEnable && isCancelEdit && (
            <button className="saveButton btn" onClick={cancelEdit}>
              Cancel
            </button>
          )}
          {isEditingCatalogEnable ? (
            <button className="saveButton btn" onClick={handleSavechk}>
              {isEditingCatalogEnable && isCancelEdit ? "Update" : "Save"}
            </button>
          ) : (
            <>
              {!isCatalogOfOtherProfile && (
                <button className="saveButton btn" onClick={handleEdit}>
                  Edit
                </button>
              )}
            </>
          )}
        </div>
        <div className="titleSection">
          <label htmlFor="title">Title</label>
          {/* {isView && <div className="title">{title}</div>} */}
          {renderText(title, "title")}
          {isEditingCatalogEnable && (
            <input
              type="text"
              id="title"
              value={title}
              onChange={handleTitleChange}
            />
          )}
        </div>
        <div className="descriptionSection">
          <label htmlFor="description"> Description</label>
          {/* {renderText(description, "description")} */}
          {isView && (
            <pre ref={divRef} e className="description">
              {description}
            </pre>
          )}
          {isEditingCatalogEnable && (
            <TextareaAutosize
              // className="description"
              id="description"
              value={description}
              onChange={handleDescriptionChange}
            />
          )}
        </div>
        <div className="imagesSection">
          {isEditingCatalogEnable && !isView && (
            <div className="flfimgbt">
              <label htmlFor="imageUpload" onClick={addimage}>
                <div className="plusBox">Add picture</div>
                <input
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  type="file"
                  id="image-upload"
                  accept="image/*"
                  onChange={handleImageChange}
                  multiple
                />
              </label>
            </div>
          )}
          {images?.map((image, index) => (
            <>
              {/* {console.log(JSON.stringify(image))} */}
              <div
                key={index}
                className="catalogImage"
                draggable // Add draggable attribute
                onDragStart={(e) => handleDragStart(e, index)}
                onDragOver={(e) => handleDragOver(e)}
                onDrop={(e) => handleDrop(e, index)}
                onTouchStart={(e) => handleTouchStart(e, index)}
                onTouchMove={(e) => handleTouchMove(e)}
                onTouchEnd={handleTouchEnd}
                onClick={() => handleImageClick(index)}
              >
                {/* {console.log("data" + JSON.stringify(image?._id))} */}
                {typeof image.image === "object" &&
                image.image instanceof File ? (
                  // <img
                  //   src={URL.createObjectURL(image.image)}
                  //   alt={`${index + 1}`}
                  // />
                  ""
                ) : (
                  <>
                    <img src={image.url} alt={`${index}`} />
                  </>
                )}
                {isEditingCatalogEnable && isViewCatalog && (
                  <span
                    className="deleteSign"
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteCatalogImage(index);
                    }}
                  >
                    <XIcon />
                  </span>
                )}
              </div>
            </>
          ))}
        </div>
        {selectedImageIndex !== null && (
          <div className="image-slider">
            <Gallery
              items={galleryImages}
              startIndex={selectedImageIndex}
              onRequestClose={closeImageSlider}
            />
            <div className="close-slider" onClick={closeImageSlider}>
              Close
            </div>
          </div>
        )}
      </div>
      {isContectBoxOpenToShare && (
        <ContactsToSelect
          closeSelectContactBox={closeContactBox}
          sharingCatalog={makeItSharing}
        />
      )}
    </>
  );
}

export default AddCatalog;
