import React from "react";

const HomeIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1697_7513)">
        <path
          d="M11.9876 22.4878C10.4876 22.4878 8.97558 22.4878 7.47558 22.4878C5.44758 22.4878 3.76758 20.8198 3.76758 18.7798C3.76758 17.2798 3.76758 15.7678 3.76758 14.2678C3.76758 13.8838 4.03158 13.5598 4.39158 13.5118C4.76358 13.4638 5.11158 13.6678 5.23158 14.0398C5.25558 14.1358 5.26758 14.2438 5.26758 14.3398C5.26758 15.8038 5.26758 17.2678 5.26758 18.7438C5.26758 20.0038 6.26358 20.9998 7.52358 21.0118C10.5236 21.0118 13.5116 21.0118 16.5116 21.0118C17.7596 21.0118 18.7556 20.0038 18.7556 18.7678C18.7556 17.2918 18.7556 15.8158 18.7556 14.3398C18.7556 13.8478 19.0556 13.5118 19.5116 13.5238C19.9436 13.5238 20.2436 13.8598 20.2436 14.3398C20.2436 15.8518 20.2436 17.3638 20.2436 18.8758C20.2436 20.5918 18.8636 22.1998 17.1596 22.4518C16.9196 22.4878 16.6796 22.4878 16.4396 22.4998C16.4156 22.4998 16.3916 22.4998 16.3676 22.4998C14.9036 22.4998 13.4516 22.4998 11.9876 22.4998V22.4878Z"
          fill="#1B1B1B"
        />
        <path
          d="M12.0126 3.31186C11.1846 4.13986 10.3926 4.93186 9.60055 5.72386C7.36855 7.95586 5.14855 10.1879 2.91655 12.4079C2.49655 12.8279 2.04055 12.8759 1.71655 12.5279C1.39255 12.1919 1.44055 11.7479 1.84855 11.3399C5.01655 8.17186 8.17255 5.00386 11.3406 1.84786C11.7966 1.39186 12.1686 1.39186 12.6246 1.84786C15.8046 5.02786 18.9726 8.20786 22.1526 11.3759C22.3926 11.6159 22.5366 11.8799 22.4406 12.2159C22.2966 12.7559 21.6246 12.9479 21.2046 12.5639C20.9286 12.3119 20.6766 12.0359 20.4126 11.7719C17.6766 9.02386 14.9286 6.28786 12.1926 3.53986C12.1326 3.47986 12.0726 3.39586 12.0006 3.31186H12.0126Z"
          fill="#1B1B1B"
        />
      </g>
      <defs>
        <clipPath id="clip0_1697_7513">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HomeIcon;
