import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import { Document, Page, pdfjs } from "react-pdf";

// icons
import EditIcon from "../../../assets/svgs/EditIcon";
import PlusBoxIcon from "../../../assets/svgs/PlusBoxIcon";
import InfoiIcon from "../../../assets/svgs/InfoiIcon";
import CatalogIcon from "../../../assets/svgs/CatalogIcon";
import HomeIcon from "../../../assets/svgs/HomeIcon";
import AchievementIcon from "../../../assets/svgs/AchievementIcon";
import ChevronRightIcon from "../../../assets/svgs/ChevronRightIcon";
import StarIcon from "../../../assets/svgs/StarIcon";
import FillStar from "../../../assets/svgs/ReviewFillStarIcon";
import UnFillStar from "../../../assets/svgs/ReviewUnfillStarIcon";
import InvalidInputIcon from "../../../assets/svgs/InvalidInputIcon";
import FileIcon from "../../../assets/iconImages/DefaultForFile.png";

import {
  getUserProfile,
  updateUserProfile,
  updateUserProfileImage,
} from "../../../api/actions/profileActions";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "../../../utils/LoadingSpinner";

// components
import AddCatalog from "./AddCatalog";
import ReviewList from "./Reviews";
import {
  resetState,
  resetImageOrderApplyStatus,
} from "../../../api/reducerSlices/profileSlice";
import AddUpdateAchievementDetail from "./AddUpdateAchievementDetail";
import ViewReviewIndividual from "./ViewReviewIndividual";
import {
  EventSuccessPopup,
  EventFailedPopup,
} from "../../popup-boxes/main-page-Popup's/mainPageEventPopup";
////////////////
// css file
require("./HomeCSS/profile.css");

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
let dataToSend = {};
// set max chracters
const MAX_CHARACTERS_TAG = 155;
const MAX_CHARACTERS = 200;

const Profile = () => {
  const dispatch = useDispatch();
  const {
    status,
    error,
    userData,
    catalogCreated,
    catalogUpdated,
    catalogDeleted,
    awardCreated,
    awardUpdated,
    awardDeleted,
    imageOrderApply,
    userUpdateProfile,
    userImageUpdate,
  } = useSelector((state) => state.profileSlice);

  const [profileData, setProfileData] = useState({});
  const [catalogImages, setCatalogImages] = useState({});
  const [isEditingavailableOfferTag, setIsEditingavailableOfferTag] =
    useState(false);
  const [isEditingProfileInfo, setIsEditingProfileInfor] = useState(false);
  const [isEditingSocialLinks, setIsEditingSocialLinks] = useState(false);
  const [isEditingAbout, setIsEditingAbout] = useState(false);
  const [isEditingCatalog, setIsEditingCatalog] = useState(
    localStorage.getItem("setIsEditingCatalog") === "true" || false
  );
  const [isViewCatalog, setIsViewCatalog] = useState(false);
  const [catalogSet, setCatalogSet] = useState(null);
  const [isEditingBrokerServicesDetails, setIsEditingBrokerServicesDetails] =
    useState(false);
  const [isEditingAchievementDetail, setIsEditingAchievementDetail] =
    useState(false);

  // handle to move next component
  const [isNotMoving, setIsNotMoving] = useState(true);
  const fileInputRef = useRef(null);
  const [isAwardsEditable, setIsAwardsEditable] = useState(false);
  const [selectedAwards, setSelectedAwards] = useState(null);
  const [isViewReviews, setIsViewReviews] = useState(false);
  const [isClear, setIsClear] = useState(false);

  const [isReviewOpenToView, setIsReviewOpenToView] = useState(false);
  const [reviewData, setReviewData] = useState(null);

  const [validationErrorsForURL, setValidationErrorsForURL] = useState({
    facebook: false,
    instagram: false,
    linkedIn: false,
    youtube: false,
  });

  const [showPopup, setShowPopup] = useState(false);
  const [showPopupMsg, setShowPopupMsg] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("setIsViewCatalog") === "true") {
      setIsNotMoving(false);
      setIsEditingCatalog(true);
    }
    // if (localStorage.getItem("setIsViewCatalog") === "true") {
    //   localStorage.removeItem("CatalogData");
    //   localStorage.setItem("CatalogData", JSON.stringify(catalogItem));
    // }
  }, []);

  useEffect(() => {
    if (!isClear && userData !== null) {
      dispatch(resetState());
      setIsClear(true);
    }
    dispatch(getUserProfile());
  }, [isClear]);

  useEffect(() => {
    if (userUpdateProfile === true) {
      setShowPopupMsg("Profile updated!");
      setShowSuccessPopup(true);
      setTimeout(() => {
        setShowSuccessPopup(false);
        setShowPopupMsg("");
      }, 3000);
    }
    if (userImageUpdate === true) {
      setShowPopupMsg("Profile image updated!");
      setShowSuccessPopup(true);
      setTimeout(() => {
        setShowSuccessPopup(false);
        setShowPopupMsg("");
      }, 3000);
    }
    if (error && error !== "") {
      setShowPopupMsg(error);
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
        setShowPopupMsg("");
        dispatch(resetState());
      }, 3000);
    }
  }, [error, userUpdateProfile, userImageUpdate]);

  // Function to check if the provided URL is a valid Facebook link
  function isValidFacebookLink(url) {
    const facebookUrlRegex = /^(https?:\/\/)?(www\.)?(web\.)?facebook.com\/.*/;
    return facebookUrlRegex.test(url);
  }

  // Function to check if the provided URL is a valid Instagram link
  function isValidInstagramLink(url) {
    const instagramUrlRegex = /^(https?:\/\/)?(www\.)?instagram.com\/.*/;
    return instagramUrlRegex.test(url);
  }

  // Function to check if the provided URL is a valid LinkedIn link
  function isValidLinkedInLink(url) {
    const linkedInUrlRegex = /^(https?:\/\/)?(www\.)?linkedin.com\/.*/;
    return linkedInUrlRegex.test(url);
  }

  // Function to check if the provided URL is a valid YouTube link
  function isValidYouTubeLink(url) {
    const youtubeUrlRegex = /^(https?:\/\/)?(www\.)?youtube.com\/.*/;
    return youtubeUrlRegex.test(url);
  }

  const heckurlvalidity = (fieldName, value) => {
    switch (fieldName) {
      case "facebook":
        setValidationErrorsForURL({
          ...validationErrorsForURL,
          facebook: isValidFacebookLink(value) ? false : true,
        });
        break;
      case "instagram":
        setValidationErrorsForURL({
          ...validationErrorsForURL,
          instagram: isValidInstagramLink(value) ? false : true,
        });
        break;
      case "linkedIn":
        setValidationErrorsForURL({
          ...validationErrorsForURL,
          linkedIn: isValidLinkedInLink(value) ? false : true,
        });
        break;
      case "youtube":
        setValidationErrorsForURL({
          ...validationErrorsForURL,
          youtube: isValidYouTubeLink(value) ? false : true,
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setProfileData(userData);
    setCatalogImages(userData?.catalog);
    // console.log("profile data: " + JSON.stringify(profileData));
    setFormData({
      status: userData?.user?.status?.text ? userData?.user?.status?.text : "",
      isOnline: userData?.user?.isOnline ? userData?.user?.isOnline : false,
      isOpenHouse: userData?.user?.isOpenHouse
        ? userData?.user?.isOpenHouse
        : false,
      name: userData?.user?.name ? userData?.user?.name : "",
      designation: userData?.user?.designation
        ? userData?.user?.designation
        : "",
      company: userData?.user?.company ? userData?.user?.company : "",
      location: userData?.user?.location ? userData?.user?.location : "",
      phoneNumber: userData?.user?.phoneNumber
        ? userData?.user?.phoneNumber
        : "",
      nationality: userData?.user?.nationality
        ? userData?.user?.nationality
        : null,
      brn: userData?.user?.BRN ? userData?.user?.BRN : null,
      languages: userData?.user?.languages ? userData?.user?.languages : null,
      areas: userData?.user?.areas ? userData?.user?.areas : null,
      socialLinks: {
        facebook: userData?.user?.socialLinks?.facebook
          ? userData?.user?.socialLinks?.facebook
          : "",
        instagram: userData?.user?.socialLinks?.instagram
          ? userData?.user?.socialLinks?.instagram
          : "",
        linkedIn: userData?.user?.socialLinks?.linkedIn
          ? userData?.user?.socialLinks?.linkedIn
          : "",
        youtube: userData?.user?.socialLinks?.youtube
          ? userData?.user?.socialLinks?.youtube
          : "",
      },
      about: userData?.user?.about ? userData?.user?.about : null,
      brokerServices: userData?.user?.brokerServices
        ? userData?.user?.brokerServices
        : null,
    });
    heckurlvalidity("facebook", userData?.user?.socialLinks?.facebook);
    heckurlvalidity("instagram", userData?.user?.socialLinks?.instagram);
    heckurlvalidity("linkedIn", userData?.user?.socialLinks?.linkedIn);
    heckurlvalidity("youtube", userData?.user?.socialLinks?.youtube);
  }, [userData]);

  useEffect(() => {
    if (
      catalogCreated ||
      catalogUpdated ||
      catalogDeleted ||
      awardCreated ||
      awardUpdated ||
      awardDeleted ||
      imageOrderApply
    ) {
      if (catalogCreated === true) {
        setShowPopupMsg("Catalog created!");
      }
      if (catalogUpdated === true) {
        setShowPopupMsg("Catalog updated!");
      }
      if (catalogDeleted === true) {
        setShowPopupMsg("Catalog deleted!");
      }
      if (awardCreated === true) {
        setShowPopupMsg("Award created!");
      }
      if (awardUpdated === true) {
        setShowPopupMsg("Award updated!");
      }
      if (awardDeleted === true) {
        setShowPopupMsg("Award deleted!");
      }
      setShowSuccessPopup(true);
      setTimeout(() => {
        setShowSuccessPopup(false);
        setShowPopupMsg("");
      }, 3000);
      // setIsEditingAchievementDetail(false);
      dispatch(getUserProfile());
      // dispatch(resetState());
      if (imageOrderApply) {
        setShowSuccessPopup(false);
        dispatch(resetImageOrderApplyStatus());
      }
    }
  }, [
    catalogCreated,
    catalogUpdated,
    catalogDeleted,
    awardCreated,
    awardUpdated,
    awardDeleted,
    imageOrderApply,
  ]);

  const [formData, setFormData] = useState({
    status: profileData?.user?.status?.text
      ? profileData?.user?.status?.text
      : "",
    isOnline: profileData?.user?.isOnline ? profileData?.user?.isOnline : false,
    isOpenHouse: profileData?.user?.isOpenHouse
      ? profileData?.user?.isOpenHouse
      : false,
    name: profileData?.user?.name ? profileData?.user?.name : "",
    designation: profileData?.user?.designation
      ? profileData?.user?.designation
      : "",
    company: profileData?.user?.company ? profileData?.user?.company : "",
    location: profileData?.user?.location ? profileData?.user?.location : "",
    phoneNumber: profileData?.user?.phoneNumber
      ? profileData?.user?.phoneNumber
      : "",
    nationality: profileData?.user?.nationality
      ? profileData?.user?.nationality
      : null,
    brn: profileData?.user?.BRN ? profileData?.user?.BRN : null,
    languages: profileData?.user?.languages
      ? profileData?.user?.languages
      : null,
    areas: profileData?.user?.areas ? profileData?.user?.areas : null,
    socialLinks: {
      facebook: profileData?.user?.socialLinks?.facebook
        ? profileData?.user?.socialLinks?.facebook
        : "",
      instagram: profileData?.user?.socialLinks?.instagram
        ? profileData?.user?.socialLinks?.instagram
        : "",
      linkedIn: profileData?.user?.socialLinks?.linkedIn
        ? profileData?.user?.socialLinks?.linkedIn
        : "",
      youtube: profileData?.user?.socialLinks?.youtube
        ? profileData?.user?.socialLinks?.youtube
        : "",
    },
    about: profileData?.user?.about ? profileData?.user?.about : null,
    brokerServices: profileData?.user?.brokerServices
      ? profileData?.user?.brokerServices
      : null,
  });

  // const updatePersonalInfo1S = () => { };

  const updatePersonalInfo = () => {
    const errors = {};
    if (!formData.name) errors.name = "Name is required";
    if (!formData.phoneNumber) errors.phoneNumber = "Phone number is required";
    // if (!formData.location) errors.location = "Location is required";
    if (profileData.user.role === "realtor") {
      if (!formData.company) errors.company = "Company is required";
      if (!formData.designation) errors.designation = "Designation is required";
      if (!formData.nationality) errors.nationality = "Nationality is required";
      if (!formData.brn) errors.brn = "BRN is required";
      if (!formData.languages) errors.languages = "Location is required";
      if (!formData.areas) errors.areas = "Areas is required";
    }

    // setValidationErrors(errors);
    if (Object.keys(errors).length === 0) {
      setIsEditingProfileInfor(false);

      // let location = {
      //   address: "Lahore, Palkistan",
      //   latitude: 31.5497,
      //   longitude: 74.3436,
      // };
      dataToSend = {
        name: formData.name?.trim(),
        designation: formData.designation?.trim(),
        company: formData.company?.trim(),
        // location: {
        //   address: formData?.location,
        //   latitude: 31.5497,
        //   longitude: 74.3436,
        // },
        // location: location,
        phoneNumber: formData.phoneNumber?.trim(),
        nationality: formData.nationality?.trim(),
        BRN: formData.brn,
        languages: Array.isArray(formData?.languages)
          ? formData?.languages.join(", ")
          : formData?.languages,
        areas: Array.isArray(formData?.areas)
          ? formData?.areas.join(", ")
          : formData?.areas,
      };

      // console.log("Testing: ", dataToSend);
      updateProfileAPI(dataToSend);
    } else {
      alert(errors[0]);
    }
  };

  const updateSocialLinksInfo = () => {
    dataToSend = {
      socialLinks: {
        facebook: formData?.socialLinks?.facebook?.trim(),
        instagram: formData?.socialLinks?.instagram?.trim(),
        linkedIn: formData?.socialLinks?.linkedIn?.trim(),
        youtube: formData?.socialLinks?.youtube?.trim(),
      },
    };

    console.log("Testing: ", dataToSend);
    updateProfileAPI(dataToSend);
    setIsEditingSocialLinks(false);
  };

  const updateAboutInfo = () => {
    const errors = {};
    if (!formData.about) errors.about = "About me is required";

    // setValidationErrors(errors);
    if (Object.keys(errors).length === 0) {
      setIsEditingAbout(false);

      dataToSend = {
        about: formData?.about?.trim(),
      };
      updateProfileAPI(dataToSend);
    } else {
      alert(errors[0]);
    }
  };

  const updateBrokerServicesInfo = () => {
    const errors = {};
    if (!formData.brokerServices)
      errors.brokerServices = "Broker Sevices is required";

    // setValidationErrors(errors);
    if (Object.keys(errors).length === 0) {
      setIsEditingBrokerServicesDetails(false);

      dataToSend = {
        brokerServices: formData?.brokerServices?.trim(),
      };
      updateProfileAPI(dataToSend);
    } else {
      alert(errors[0]);
    }
  };

  const updateProfileAPI = (dataToSend) => {
    dispatch(updateUserProfile(dataToSend));
  };

  const handleSatausChange = () => {
    dataToSend = {
      status: {
        text: formData.status?.trim(),
        updatedAt: new Date().toISOString(),
      },
    };
    updateProfileAPI(dataToSend);
    setIsEditingavailableOfferTag(false);
  };

  const handleCheckboxChange = (field) => {
    if (field === "isOnline") {
      handleProfileInfoChange(field, !formData?.isOnline);
      dataToSend = { isOnline: !formData?.isOnline };
    } else if (field === "isOpenHouse") {
      handleProfileInfoChange(field, !formData?.isOpenHouse);
      dataToSend = { isOpenHouse: !formData?.isOpenHouse };
    }
    updateProfileAPI(dataToSend);
  };

  const handleProfileInfoChange = (fieldName, value) => {
    setFormData({ ...formData, [fieldName]: value });
  };

  const checkIfImage = (url) => {
    const imageExtensions = /\.(jpg|jpeg|png|gif|bmp|avif|webp)$/i;
    return imageExtensions.test(url);
  };

  const getFileType = (url) => {
    const extension = url.toLowerCase().split(".").pop();
    if (extension === "pdf") {
      return "pdf";
    } else if (extension === "doc" || extension === "docx") {
      return "word";
    } else {
      return "unknown";
    }
  };

  const handleSocialLinksInfoChange = (fieldName, value) => {
    setFormData({
      ...formData,
      socialLinks: {
        ...formData.socialLinks,
        [fieldName]: value,
      },
    });
    switch (fieldName) {
      case "facebook":
      case "instagram":
      case "linkedIn":
      case "youtube":
        heckurlvalidity(fieldName, value);
        break;
      default:
        break;
    }
  };

  const handleEditClick = (field) => {
    switch (field) {
      case "status":
        setIsEditingavailableOfferTag(true);
        break;
      case "profileInfo":
        setIsEditingProfileInfor(true);
        break;
      case "socialLinks":
        setIsEditingSocialLinks(true);
        break;
      case "about":
        setIsEditingAbout(true);
        break;
      case "catalog":
        setIsNotMoving(false);
        // localStorage.setItem("setIsEditingCatalog", true);
        setIsEditingCatalog(true);
        break;
      case "brokerServiceDetail":
        setIsEditingBrokerServicesDetails(true);
        break;
      case "achievementDetail":
        setIsNotMoving(false);
        setIsEditingAchievementDetail(true);
        break;
      case "reviews":
        setIsNotMoving(false);
        setIsViewReviews(true);
        break;
      default:
        break;
    }
  };

  const handleAddAwards = () => {
    setIsNotMoving(false);
    setIsEditingAchievementDetail(true);
  };

  const handleEditAchievement = (awardId) => {
    const selected = profileData?.awards.find((data) => data._id === awardId);
    setSelectedAwards(selected);
    setIsAwardsEditable(true);
    handleEditClick("achievementDetail");
  };

  const handleSaveClick = (field) => {
    switch (field) {
      case "status":
        handleSatausChange();
        break;
      case "profileInfo":
        updatePersonalInfo();
        break;
      case "socialLinks":
        updateSocialLinksInfo();
        break;
      case "about":
        updateAboutInfo();
        break;
      case "catalog":
        setIsEditingCatalog(false);
        break;
      case "brokerServiceDetail":
        updateBrokerServicesInfo();
        break;
      case "achievementDetail":
        setIsNotMoving(true);
        setIsEditingAchievementDetail(false);
        setSelectedAwards(null);
        break;
      default:
        break;
    }
  };

  const handlePlusClick = () => {
    if (isEditingProfileInfo) {
      fileInputRef.current.click();
    }
  };

  const handleProfilePicChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileData({
          ...setProfileData,
          user: { ...profileData?.user, image: reader.result },
        });
      };
      reader.readAsDataURL(file);

      dataToSend = {
        files: file,
      };
      dispatch(updateUserProfileImage(dataToSend));
      // setIsEditingProfileInfor(false);
    }
  };

  // const handleProfilePicDelete = () => {
  //   setShowDropdown(false);
  //   setProfileData({
  //     ...setProfileData,
  //     user: { ...profileData?.user, image: "" },
  //   });
  //   const file = "";
  //   const dataToSend = {
  //     files: file,
  //   };
  //   dispatch(updateUserProfileImage(dataToSend));
  // };

  const handleTextareaChange = (field, e) => {
    const inputValue = e.target.value;
    let count = 0;
    if (field === "status") {
      count = MAX_CHARACTERS_TAG;
    } else {
      count = MAX_CHARACTERS;
    }
    if (inputValue.length < count) {
      handleProfileInfoChange(field, inputValue);
    } else {
      setShowPopupMsg(
        `Limit is ${count} characters and You've reached ${inputValue.length} characters.\nMaximum character limit reached!`
      );
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
        setShowPopupMsg("");
      }, 3000);
      // alert(
      //   `Limit is ${count} characters and You've reached ${inputValue.length} characters.\nMaximum character limit reached!`
      // );
    }
  };

  const handleEditCatalog = (catalogItem) => {
    const selected = catalogItem;
    setCatalogSet(selected);
    setIsViewCatalog(true);
    localStorage.setItem("setIsViewCatalog", true);
    localStorage.setItem("CatalogData", JSON.stringify(catalogItem));
    handleEditClick("catalog");
  };

  useEffect(() => {
    const storedCatalogData = localStorage.getItem("CatalogData");
    if (storedCatalogData) {
      if (storedCatalogData?._id) {
        profileData?.catalog.forEach((catalogItem) => {
          if (catalogItem?._id === storedCatalogData?._id) {
            localStorage.removeItem("CatalogData");
            localStorage.setItem("CatalogData", JSON.stringify(catalogItem));
            setCatalogSet(catalogItem);
          }
        });
      }
    }
  }, [catalogUpdated]);

  const moveBack = () => {
    setIsNotMoving(true);
    if (isEditingAchievementDetail) {
      setIsEditingAchievementDetail(false);
    }
    if (isAwardsEditable) {
      setIsAwardsEditable(false);
    }
    if (isEditingCatalog) {
      // dispatch(getUserProfile());
      setIsEditingCatalog(false);
      localStorage.setItem("setIsEditingCatalog", false);
      localStorage.setItem("setIsViewCatalog", false);
      localStorage.removeItem("CatalogData");
      setCatalogSet(null);
    }
    if (isViewCatalog) {
      // dispatch(getUserProfile());
      setIsViewCatalog(false);
      localStorage.setItem("setIsViewCatalog", false);
      setCatalogSet(null);
    }
    setSelectedAwards(null);
  };

  const setReviewPage = () => {
    setIsNotMoving(true);
    setIsViewReviews(false);
  };

  const renderStars = (rating) => {
    const filledStars = Math.floor(rating);
    const remainingStars = 5 - filledStars;

    return (
      <>
        {userData === null && <LoadingSpinner />}
        <div className="ratingStars">
          {[...Array(filledStars)].map((_, index) => (
            <span key={index} className="filled-star">
              <FillStar />
            </span>
          ))}
          {[...Array(remainingStars)].map((_, index) => (
            <span key={filledStars + index} className="unfilled-star">
              <UnFillStar />
            </span>
          ))}
        </div>
      </>
    );
  };

  const openReviewCard = (selectedRevieData) => {
    setIsReviewOpenToView(true);
    setReviewData(selectedRevieData);
  };
  const closeReviewCard = () => {
    setIsReviewOpenToView(false);
  };

  const closePopup = () => {
    setShowPopup(false);
    setShowPopupMsg("");
  };

  const closeSuccessPopup = () => {
    setShowSuccessPopup(false);
    setShowPopupMsg("");
  };

  return (
    <>
      {showSuccessPopup && (
        <EventSuccessPopup
          showPopupMsg={showPopupMsg}
          onClose={closeSuccessPopup}
        />
      )}
      {showPopup && (
        <EventFailedPopup showPopupMsg={showPopupMsg} onClose={closePopup} />
      )}
      {userData === null || status === "loading" ? (
        <LoadingSpinner />
      ) : (
        isNotMoving && (
          <div className="profileMain">
            {/* <div className="header">
              <span className="respspan">Profile</span>
            </div> */}
            {profileData?.user?.role === "realtor" && (
              <>
                <div className="availableOfferTag">
                  {isEditingavailableOfferTag ? (
                    <button
                      className="tagbtn saveButton"
                      onClick={() => handleSaveClick("status")}
                    >
                      save
                    </button>
                  ) : (
                    <div
                      className="tagbtn editButton"
                      onClick={() => handleEditClick("status")}
                    >
                      <EditIcon />
                    </div>
                  )}
                  {isEditingavailableOfferTag ? (
                    <textarea
                      value={formData?.status !== null && formData?.status}
                      onChange={(e) => handleTextareaChange("status", e)}
                    />
                  ) : (
                    <>
                      <p>{formData?.status}</p>
                    </>
                  )}
                </div>
                <div className="activeStatusTag">
                  <div className="onactive">
                    <span>Online/Offline:</span>
                    <div
                      className={`selectMode ${
                        formData?.isOnline ? "check" : ""
                      }`}
                    >
                      <span className="on">ON</span>
                      <div>
                        <input
                          type="checkbox"
                          id="onlineSwitch"
                          checked={formData?.isOnline}
                          onChange={() => handleCheckboxChange("isOnline")}
                        />
                        <label htmlFor="onlineSwitch"></label>
                      </div>
                      <span className="off">OFF</span>
                    </div>
                  </div>
                  <div className="line"></div>
                  <div className="houseActive">
                    <span>Open House:</span>
                    <div
                      className={`selectMode ${
                        formData?.isOpenHouse ? "check" : ""
                      }`}
                    >
                      <span className="on">ON</span>
                      <div>
                        <input
                          type="checkbox"
                          id="openHouseSwitch"
                          checked={formData?.isOpenHouse}
                          onChange={() => handleCheckboxChange("isOpenHouse")}
                        />
                        <label htmlFor="openHouseSwitch"></label>
                      </div>
                      <span className="off">OFF</span>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="profilePersonalInfo">
              <div className="profileBlock">
                <div
                  className="profilePic"
                  onClick={handlePlusClick}
                  style={
                    isEditingProfileInfo
                      ? { cursor: "pointer" }
                      : { cursor: "auto" }
                  }
                >
                  {isEditingProfileInfo ? (
                    <>
                      {profileData?.user?.image ? (
                        <>
                          <img src={profileData?.user?.image} alt="Profile" />
                          <input
                            type="file"
                            accept="image/*"
                            style={{ display: "none" }}
                            ref={fileInputRef}
                            onChange={handleProfilePicChange}
                          />
                          <PlusBoxIcon />
                        </>
                      ) : (
                        <>
                          <input
                            type="file"
                            accept="image/*"
                            style={{ display: "none" }}
                            ref={fileInputRef}
                            onChange={handleProfilePicChange}
                          />
                          <PlusBoxIcon />
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {profileData?.user?.image && (
                        <img src={profileData?.user?.image} alt="Profile" />
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="personalInfo">
                <div className="headerLine">
                  <span>Personal Info</span>
                  {isEditingProfileInfo ? (
                    <button
                      className="saveButton"
                      onClick={() => handleSaveClick("profileInfo")}
                    >
                      save
                    </button>
                  ) : (
                    <div
                      className="editButton"
                      onClick={() => handleEditClick("profileInfo")}
                    >
                      <EditIcon />
                    </div>
                  )}
                </div>
                <div className="firstLinePersonalInfo">
                  <div className="row">
                    <span>Name:</span>
                    {isEditingProfileInfo ? (
                      <input
                        type="text"
                        value={formData?.name}
                        onChange={(e) =>
                          handleProfileInfoChange("name", e.target.value)
                        }
                        autoFocus
                      />
                    ) : (
                      <p>{formData?.name}</p>
                    )}
                  </div>
                  {profileData?.user?.role === "realtor" && (
                    <>
                      <div className="row">
                        <span>Company:</span>
                        {isEditingProfileInfo ? (
                          <input
                            type="text"
                            value={formData?.company}
                            onChange={(e) =>
                              handleProfileInfoChange("company", e.target.value)
                            }
                          />
                        ) : (
                          <p>{formData?.company}</p>
                        )}
                      </div>
                      <div className="row">
                        <span>Designation:</span>
                        {isEditingProfileInfo ? (
                          <input
                            type="text"
                            value={formData?.designation}
                            onChange={(e) =>
                              handleProfileInfoChange(
                                "designation",
                                e.target.value
                              )
                            }
                          />
                        ) : (
                          <p>{formData?.designation}</p>
                        )}
                      </div>
                    </>
                  )}
                  <div className="row">
                    <span>Number:</span>
                    {isEditingProfileInfo ? (
                      <input
                        type="text"
                        value={formData?.phoneNumber}
                        onChange={(e) =>
                          handleProfileInfoChange("phoneNumber", e.target.value)
                        }
                      />
                    ) : (
                      <p>{formData?.phoneNumber}</p>
                    )}
                  </div>
                  <div className="row">
                    <span>Email:</span>
                    {/* {isEditingProfileInfo ? (
                      <input
                        type="email"
                        disabled={true}
                        value={profileData?.user?.email}
                        onChange={(e) =>
                          handleProfileInfoChange("email", e.target.value)
                        }
                        style={{ cursor: "not-allowed", opacity: 0.5 }}
                      />
                    ) : ( */}
                    <p>{profileData?.user?.email}</p>
                    {/* )} */}
                  </div>
                  {profileData?.user?.role === "realtor" && (
                    <div className="row">
                      <span>Address:</span>
                      {/* {isEditingProfileInfo ? (
                        <input
                          type="text"
                          disabled={true}
                          value={formData?.location?.address}
                          onChange={(e) =>
                            handleProfileInfoChange("location", e.target.value)
                          }
                          style={{ cursor: "not-allowed", opacity: 0.5 }}
                        />
                      ) : ( */}
                      <p>{formData?.location?.address}</p>
                      {/* )} */}
                    </div>
                  )}
                </div>
                {profileData?.user?.role === "realtor" && (
                  <div className="secondLinePersonalInfo">
                    <div className="row">
                      <span>BRN:</span>
                      {isEditingProfileInfo ? (
                        <input
                          type="text"
                          value={formData?.brn}
                          onChange={(e) =>
                            handleProfileInfoChange("brn", e.target.value)
                          }
                        />
                      ) : (
                        <p>{formData?.brn}</p>
                      )}
                    </div>
                    <div className="row">
                      <span>Nationality:</span>
                      {isEditingProfileInfo ? (
                        <input
                          type="text"
                          value={formData?.nationality}
                          onChange={(e) =>
                            handleProfileInfoChange(
                              "nationality",
                              e.target.value
                            )
                          }
                        />
                      ) : (
                        <p>{formData?.nationality}</p>
                      )}
                    </div>
                    <div className="row">
                      <span>Languages:</span>
                      {isEditingProfileInfo ? (
                        <input
                          type="text"
                          value={
                            Array.isArray(formData?.languages)
                              ? formData?.languages.join(", ")
                              : formData?.languages
                          }
                          onChange={(e) => {
                            handleProfileInfoChange(
                              "languages",
                              e.target.value
                                .split(",")
                                .map((lang) => lang.trim())
                            );
                          }}
                        />
                      ) : (
                        <p>
                          {Array.isArray(formData?.languages)
                            ? formData?.languages.join(", ")
                            : formData?.languages}
                        </p>
                      )}
                    </div>
                    <div className="row">
                      <span>Areas:</span>
                      {isEditingProfileInfo ? (
                        <input
                          type="text"
                          value={
                            Array.isArray(formData?.areas)
                              ? formData?.areas.join(", ")
                              : formData?.areas
                          }
                          onChange={(e) =>
                            handleProfileInfoChange(
                              "areas",
                              e.target.value
                                .split(",")
                                .map((area) => area.trim())
                            )
                          }
                        />
                      ) : (
                        <p>
                          {Array.isArray(formData?.areas)
                            ? formData?.areas.join(", ")
                            : formData?.areas}
                        </p>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            {profileData?.user?.role === "realtor" && (
              <>
                <div className="socialLinksTag">
                  <div className="headerInfoLine">
                    <div className="heading">
                      <span>Social Links</span>
                    </div>
                    {isEditingSocialLinks ? (
                      <button
                        className="saveButton"
                        onClick={() => handleSaveClick("socialLinks")}
                      >
                        save
                      </button>
                    ) : (
                      <div
                        className="editButton"
                        onClick={() => handleEditClick("socialLinks")}
                      >
                        <EditIcon />
                      </div>
                    )}
                  </div>
                  <div className="socialLinks">
                    <div className="row">
                      <span>Facebook:</span>
                      {isEditingSocialLinks ? (
                        <input
                          type="text"
                          value={formData?.socialLinks?.facebook}
                          onChange={(e) =>
                            handleSocialLinksInfoChange(
                              "facebook",
                              e.target.value
                            )
                          }
                        />
                      ) : (
                        <p>{formData?.socialLinks?.facebook}</p>
                      )}
                      {validationErrorsForURL.facebook && (
                        <div className="invalidInputIcon">
                          <InvalidInputIcon />
                        </div>
                      )}
                    </div>
                    <div className="row">
                      <span>Instagram:</span>
                      {isEditingSocialLinks ? (
                        <input
                          type="text"
                          value={formData?.socialLinks?.instagram}
                          onChange={(e) =>
                            handleSocialLinksInfoChange(
                              "instagram",
                              e.target.value
                            )
                          }
                        />
                      ) : (
                        <p>{formData?.socialLinks?.instagram}</p>
                      )}
                      {validationErrorsForURL.instagram && (
                        <div className="invalidInputIcon">
                          <InvalidInputIcon />
                        </div>
                      )}
                    </div>
                    <div className="row ">
                      <span>LinkedIn:</span>
                      {isEditingSocialLinks ? (
                        <input
                          type="text"
                          value={formData?.socialLinks?.linkedIn}
                          onChange={(e) =>
                            handleSocialLinksInfoChange(
                              "linkedIn",
                              e.target.value
                            )
                          }
                        />
                      ) : (
                        <p>{formData?.socialLinks?.linkedIn}</p>
                      )}
                      {validationErrorsForURL.linkedIn && (
                        <div className="invalidInputIcon">
                          <InvalidInputIcon />
                        </div>
                      )}
                    </div>
                    <div className="row">
                      <span>Youtube:</span>
                      {isEditingSocialLinks ? (
                        <input
                          type="text"
                          value={formData?.socialLinks?.youtube}
                          onChange={(e) =>
                            handleSocialLinksInfoChange(
                              "youtube",
                              e.target.value
                            )
                          }
                        />
                      ) : (
                        <p>{formData?.socialLinks?.youtube}</p>
                      )}
                      {validationErrorsForURL.youtube &&
                        formData?.socialLinks?.youtube !== "" && (
                          <div className="invalidInputIcon">
                            <InvalidInputIcon />
                          </div>
                        )}
                    </div>
                  </div>
                </div>
                <div className="aboutmeInfo">
                  <div className="headerInfoLine">
                    <div className="heading">
                      <div className="infoIcon">
                        <InfoiIcon />
                      </div>
                      <span>About Me</span>
                    </div>
                    {isEditingAbout ? (
                      <button
                        className="saveButton"
                        onClick={() => handleSaveClick("about")}
                      >
                        save
                      </button>
                    ) : (
                      <div
                        className="editButton"
                        onClick={() => handleEditClick("about")}
                      >
                        <EditIcon />
                      </div>
                    )}
                  </div>
                  <div className="aboutInfoPara">
                    {isEditingAbout ? (
                      <textarea
                        value={formData?.about}
                        onChange={(e) => handleTextareaChange("about", e)}
                        autoFocus
                      />
                    ) : (
                      <p>{formData?.about}</p>
                    )}
                  </div>
                </div>
                <div className="catalogInfo">
                  <div className="headerInfoLine">
                    <div className="heading">
                      <div className="catalogIcon">
                        <CatalogIcon />
                      </div>
                      <span>Catalog</span>
                    </div>
                    <div
                      className="editButton savebtn"
                      onClick={() => handleEditClick("catalog")}
                    >
                      Add
                    </div>
                  </div>
                  <div className="catalogs">
                    <div className="catalogsImages">
                      {catalogImages?.map((catalogItem) => (
                        <div
                          className="catalogsImage"
                          key={catalogItem?._id}
                          onClick={() => handleEditCatalog(catalogItem)}
                        >
                          {catalogItem?.images &&
                            catalogItem?.images?.length > 0 && (
                              <img
                                src={
                                  catalogItem.images.find(
                                    (image) => image?.orderNo === 0
                                  )?.url || ""
                                }
                                alt={
                                  catalogItem.images.find(
                                    (image) => image?.orderNo === 0
                                  )?._id || ""
                                }
                              />
                            )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="brokerServicesDetails">
                  <div className="headerInfoLine">
                    <div className="heading">
                      <div className="homeIcon">
                        <HomeIcon />
                      </div>
                      <span>Broker Services Details</span>
                    </div>
                    {isEditingBrokerServicesDetails ? (
                      <button
                        className="saveButton"
                        onClick={() => handleSaveClick("brokerServiceDetail")}
                      >
                        save
                      </button>
                    ) : (
                      <div
                        className="editButton"
                        onClick={() => handleEditClick("brokerServiceDetail")}
                      >
                        <EditIcon />
                      </div>
                    )}
                  </div>
                  <div className="brokerServicesPara">
                    {isEditingBrokerServicesDetails ? (
                      <textarea
                        value={formData?.brokerServices}
                        onChange={(e) =>
                          handleTextareaChange("brokerServices", e)
                        }
                        autoFocus
                      />
                    ) : (
                      <p>{formData?.brokerServices}</p>
                    )}
                  </div>
                </div>
                <div className="achievementTag">
                  <div className="headerInfoLine">
                    <div className="heading">
                      <div className="achievementIcon">
                        <AchievementIcon />
                      </div>
                      <span>Achievements</span>
                    </div>
                    <div
                      className="editButton savebtn"
                      onClick={() => handleAddAwards()}
                    >
                      Add
                    </div>
                  </div>
                  <div className="achievementTilesBox">
                    {profileData?.awards?.map((doc, index) => (
                      <div
                        className="achievementTile"
                        key={doc._id}
                        onClick={() => handleEditAchievement(doc._id)}
                      >
                        {doc?.image && (
                          <div className="achievementTileImage">
                            {doc?.image && checkIfImage(doc?.image) && (
                              <img src={doc.image} alt={`award-${index + 1}`} />
                            )}
                            {!checkIfImage(doc?.image) && (
                              <>
                                {getFileType(doc?.image) === "word" ||
                                getFileType(doc?.image) === "unknown" ? (
                                  <img src={FileIcon} alt="File" />
                                ) : (
                                  <>
                                    {getFileType(doc?.image) === "pdf" ? (
                                      <Document file={doc?.image}>
                                        <Page pageNumber={1} />
                                      </Document>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </div>
                        )}
                        <div className="achievementTileTitile">{doc.text}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}
            <div className="reviewsTag">
              <div className="headerInfoLine">
                <div className="heading">
                  <div className="starIcon">
                    <StarIcon />
                  </div>
                  <span>Reviews</span>
                </div>
              </div>
              <div className="reviewList">
                <div className="reviewcardLine">
                  {profileData?.reviews?.slice(0, 4).map((review) => (
                    <div
                      key={review?._id}
                      className="reviewCard"
                      onClick={() => openReviewCard(review)}
                    >
                      <div className="cardHeaderLine">
                        <div className="cardFirstLine">
                          <div className="userProfilePic">
                            <img
                              src={review?.reviewer?.image}
                              alt={`User ${review?.reviewer?._id}`}
                            />
                          </div>
                          <span className="userName">
                            {review?.reviewer?.name}
                          </span>
                        </div>
                        {renderStars(review?.rating)}
                      </div>
                      <div className="reviewContent">
                        <p>
                          {review?.text?.length > 150
                            ? `${review.text.substring(0, 150)}...`
                            : review?.text}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
                {profileData?.reviews?.length > 0 && (
                  <div
                    className="seeMoreTag"
                    onClick={() => handleEditClick("reviews")}
                  >
                    <div className="chevronIcon">
                      <ChevronRightIcon />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )
      )}
      {isEditingAchievementDetail && (
        <AddUpdateAchievementDetail
          onSave={() => handleSaveClick("achievementDetail")}
          award={selectedAwards}
          goBackToProfilePage={moveBack}
          isAwardsEditable={isAwardsEditable}
        />
      )}
      {isEditingCatalog && (
        <AddCatalog
          catalogSet={catalogSet}
          isViewCatalog={isViewCatalog}
          goBackToProfilePage={moveBack}
          profileData={profileData}
          setCatalogSet={setCatalogSet}
        />
      )}
      {isViewReviews && (
        <ReviewList
          ReviewList={profileData?.reviews}
          goBackToProfilePage={setReviewPage}
        />
      )}
      {isReviewOpenToView && (
        <ViewReviewIndividual
          reviewData={reviewData}
          goBackToProfilePage={closeReviewCard}
        />
      )}
    </>
  );
};

export default Profile;
