import React, { useState, useEffect } from "react";
import MoveBackIcon from "../../../assets/svgs/LeftArrowIcon";
import "./HomeCSS/reviewList.css";
import FillStar from "../../../assets/svgs/ReviewFillStarIcon";
import UnFillStar from "../../../assets/svgs/ReviewUnfillStarIcon";
// import ReviewsList from "../../../utils/json/reviews.json";

const Reviews = ({ ReviewList, goBackToProfilePage }) => {
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    setReviews(ReviewList);
  }, []);

  const renderStars = (rating) => {
    const filledStars = Math.floor(rating);
    const remainingStars = 5 - filledStars;

    return (
      <div className="ratingStars">
        {[...Array(filledStars)].map((_, index) => (
          <span key={index} className="filled-star">
            <FillStar />
          </span>
        ))}
        {[...Array(remainingStars)].map((_, index) => (
          <span key={filledStars + index} className="unfilled-star">
            <UnFillStar />
          </span>
        ))}
      </div>
    );
  };
  return (
    <div className="reviewsBox">
      <div className="header">
        <div className="moveback" onClick={goBackToProfilePage}>
          <MoveBackIcon />
        </div>
        <span>Reviews</span>
      </div>
      <div className="reviewList">
        {reviews.map((review) => (
          <div key={review.id} className="reviewCard">
            <div className="cardHeaderLine">
              <div className="cardFirstLine">
                <div className="userProfilePic">
                  <img
                    src={review.reviewer.image}
                    alt={`User ${review.reviewer._id}`}
                  />
                </div>
                <span className="userName">{review.reviewer.name}</span>
              </div>
              {renderStars(review.rating)}
            </div>
            <div className="reviewContent">
              <p>{review.text}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Reviews;
