import { createSlice } from "@reduxjs/toolkit";
import {
  signinUser,
  signupUser,
  verifyOtp,
  resetPassword,
  resendOTP,
  changePassword,
} from "../actions/authActions";
import { deleteToken, setToken } from "../../utils/CommonUtils";

const initialState = {
  status: "idle",
  error: "",
  authenticated: false,
  user: {},
  data: {},
  otpVerified: false,
  resetPass: false,
  changePasswordPass: false,
};

const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logoutUser(state) {
      deleteToken();
      Object.assign(state, initialState);
    },
    resetError: (state) => {
      state.status = "idle";
      state.error = "";
    },
    resetAfterSignUp: (state) => {
      state.status = "idle";
      state.error = "";
      state.authenticated = false;
    },
    resetAfterOTP: (state) => {
      state.status = "idle";
      state.error = "";
      state.otpVerified = false;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(signinUser.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(signinUser.fulfilled, (state, action) => {
        if (action.payload.status === true) {
          if (action.payload.data.isEmailVerified === true) {
            state.authenticated = true;
            state.status = "succeeded";
            setToken(action.payload.data.accessToken);
            state.user = action.payload.data;
            // console.log("Signin: ", action.payload.data)
          } else {
            state.authenticated = false;
            state.status = "failed";
            state.error = "Email is not verified";
          }
        } else {
          state.authenticated = false;
          state.status = "failed";
          state.error = action.payload.message;
        }
      })
      .addCase(signinUser.rejected, (state, action) => {
        state.authenticated = false;
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(signupUser.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(signupUser.fulfilled, (state, action) => {
        if (action.payload.status === true) {
          state.authenticated = true;
          state.status = "succeeded";
          state.user = action.payload.data;
          console.log("Signup: ", action.payload.data);
        } else {
          state.authenticated = false;
          state.status = "failed";
          state.error = action.payload.message;
        }
      })
      .addCase(signupUser.rejected, (state, action) => {
        state.authenticated = false;
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(verifyOtp.pending, (state, action) => {
        state.status = "loading";
        state.otpVerified = false;
      })
      .addCase(verifyOtp.fulfilled, (state, action) => {
        if (action.payload.status === true) {
          state.otpVerified = true;
          state.status = "succeeded";
          state.data = action.payload.data;
          // console.log("verifyOtp: ", action.payload.data)
        } else {
          state.otpVerified = false;
          state.status = "failed";
          state.error = action.payload.message;
        }
      })
      .addCase(verifyOtp.rejected, (state, action) => {
        state.otpVerified = false;
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(resendOTP.pending, (state, action) => {
        state.status = "loading";
        state.otpVerified = false;
      })
      .addCase(resendOTP.fulfilled, (state, action) => {
        if (action.payload.status === true) {
          state.status = "succeeded";
          state.data = action.payload.data;
          // console.log("verifyOtp: ", action.payload.data)
        } else {
          state.status = "failed";
          state.error = action.payload.message;
        }
      })
      .addCase(resendOTP.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(resetPassword.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        if (action.payload.status === true) {
          state.status = "succeeded";
          state.resetPass = true;
          state.data = action.payload.data;
          // console.log("forgotPAss: ", action.payload.data)
        } else {
          state.status = "failed";
          state.resetPass = false;
          state.error = action.payload.message;
        }
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.resetPass = false;
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(changePassword.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        if (action.payload.status === true) {
          state.status = "succeeded";
          state.changePasswordPass = true;
          state.data = action.payload.data;
          console.log("changePassword: ", action.payload.data);
        } else {
          state.status = "failed";
          state.changePasswordPass = false;
          state.error = action.payload.message;
        }
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.changePasswordPass = false;
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { logoutUser, resetError, resetAfterSignUp, resetAfterOTP } =
  AuthSlice.actions;
export default AuthSlice.reducer;
