import React, { useState } from "react";
import MoveBackIcon from "../../../assets/svgs/LeftArrowIcon";
import EditIcon from "../../../assets/svgs/EditIcon";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
require("./HomeCSS/propertySnaggingReport.css");

const PropertyInspectionReport = ({
  snaggingItems,
  closeSnaggingInspenction,
  editSnaggingDetails,
  openNewDocumentAddBox,
  setNew,
}) => {
  const [openForEdit, setOpenForEdit] = useState(openNewDocumentAddBox);
  const [selectedTile, setSelectedTile] = useState(null);

  const editSnaggingInfo = (item, index) => {
    editSnaggingDetails(item, index);
  };

  const handleTileClick = (image) => {
    setSelectedTile(image);
  };

  const handleCloseModal = () => {
    setSelectedTile(null);
  };

  return (
    <>
      <div
        className="headersp"
        style={{ justifyContent: openForEdit ? "space-between" : "unset" }}
      >
        <div
          className="moveback"
          onClick={() => {
            closeSnaggingInspenction();
          }}
        >
          <MoveBackIcon />
        </div>
        {openForEdit && (
          <div
            className="addNew"
            onClick={() => {
              setNew();
            }}
          >
            Add
          </div>
        )}
        {/* <div className="span">Snagging / Inspection Report:</div> */}
      </div>
      <div className="propertReport">
        <div className="content-container">
          {snaggingItems?.map((item, index) => (
            <>
              {openForEdit && (
                <div
                  className="editButtonForSnagging"
                  onClick={() => {
                    editSnaggingInfo(item, index);
                  }}
                >
                  <EditIcon />
                </div>
              )}
              <div
                key={index}
                className={`content-block ${index % 2 === 0 ? "even" : "odd"}`}
              >
                <div
                  className="content-image"
                  onClick={() => handleTileClick(item?.image)}
                >
                  <img src={item?.image} alt={`${index + 1}`} />
                </div>
                <div className="content-text">
                  <pre>{item?.title}</pre>
                  {/* <pre>{item.content}</pre> */}
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
      {selectedTile && (
        <div className="pictureModal">
          <span className="closeButton" onClick={handleCloseModal}>
            Close
          </span>
          <div className="modalContent">
            {/* <img src={selectedTile} alt="Selected Award" /> */}
            <TransformWrapper
              defaultScale={1}
              defaultPositionX={100}
              defaultPositionY={100}
              wheel={{ step: 10 }}
              options={{ limitToBounds: false }}
              pan={{ disabled: true }}
            >
              <TransformComponent>
                <img src={selectedTile} alt="Selected Award" />
              </TransformComponent>
            </TransformWrapper>
          </div>
        </div>
      )}
    </>
  );
};

export default PropertyInspectionReport;
