import React, { useState, useEffect, useRef } from "react";
import {
  addNotification,
  updateNotification,
  createChildNotification,
  updateChildNotification,
  deleteChildNotification,
} from "../../../api/actions/notificationActions";
import { useDispatch, useSelector } from "react-redux";
import { getAppContacts } from "../../../api/actions/homeActions";
import { resetState } from "../../../api/reducerSlices/homeSlice";
import { resetState as resetNotificationState } from "../../../api/reducerSlices/notificationSlice";
import LoadingSpinner from "../../../utils/LoadingSpinner";
import {
  EventSuccessPopup,
  EventFailedPopup,
} from "../../popup-boxes/main-page-Popup's/mainPageEventPopup";
require("./HomeCSS/CreateCustomNotification.css");

const CreateCustomNotification = ({
  setAfterUpdate,
  listData,
  selectedTileselectedNotificationId,
  setNewChildData,
}) => {
  const dispatch = useDispatch();
  const { appContacts } = useSelector((state) => state.homeSlice);
  const {
    status,
    error,
    notificationCreated,
    newNotificationData,
    newNotificationDataStatus,
  } = useSelector((state) => state.notificationSlice);
  const [title, setTitle] = useState(listData ? listData?.title : "");
  // const [title, setTitle] = useState(listData ? listData?.title : "");
  const [clientName, setClientName] = useState(
    listData ? listData?.clientName : ""
  );
  const [propertyNumber, setPropertyNumber] = useState(
    listData ? listData?.propertyNumber : ""
  );
  const [message, setMessage] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [buttonStatus, setButtonStatus] = useState(false);
  const [contacts, setContacts] = useState([]);
  // const [tileData, seTileData] = useState([]);
  // const [contactsText, setContactsText] = useState("");
  const [contactHandleBox, setContactHandleBox] = useState("");
  const [searchQuerysfc, setSearchQuerysfc] = useState("");
  const [filteredContacts, setFilteredContacts] = useState([]);
  const textareaRef = React.createRef();
  const [emails, setEmails] = useState("");
  const inputRef = useRef(null);
  const [timeoutId, setTimeoutId] = useState(null);
  const [showContactList, setShowContactList] = useState(false);
  const [selectedContacts, setSelectedContacts] = useState([]);

  const [showPopup, setShowPopup] = useState(false);
  const [showPopupMsg, setShowPopupMsg] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  useEffect(() => {
    if (listData && listData?.length !== 0) {
      setClientName(listData?.clientName);
      setPropertyNumber(listData?.propertyNumber);
      validateForm(listData?.clientName, listData?.propertyNumber);
      if (selectedTileselectedNotificationId !== null) {
        const selectedNotification = listData?.childs?.find(
          (notification) =>
            notification?._id === selectedTileselectedNotificationId
        );
        setMessage(selectedNotification ? selectedNotification?.text : "");
        if (selectedNotification) {
          const emails = selectedNotification?.emails || [];
          const filteredEmails = emails
            ?.filter((email) => email.trim() !== "")
            .join(", ");
          setEmails(filteredEmails);
          const contactsFound = selectedNotification?.contacts?.map(
            (contact) => ({
              name: contact?.name,
              // image: contact?.image,
              _id: contact?._id,
            })
          );
          if (contactsFound) {
            setSelectedContacts(contactsFound);
          }
          setContactHandleBox(
            `@${contactsFound.map((contact) => contact.name).join(" @")}`
          );
        }
      }
      setButtonStatus(true);
    }
    if (appContacts === null) {
      dispatch(getAppContacts());
    }
    setContacts(appContacts);
    setFilteredContacts(appContacts);
  }, [selectedTileselectedNotificationId, appContacts, listData]);

  // useEffect(() => {
  //   setContacts(appContacts);
  //   setFilteredContacts(appContacts);
  // }, [appContacts, filteredContacts]);

  // useEffect(() => {
  //   if (status === "loading") {
  //     console.log("loading ");
  //   }
  // }, [status]);

  useEffect(() => {
    if (notificationCreated === true) {
      setShowPopupMsg("Notification created!");
      setShowSuccessPopup(true);
      setTimeout(() => {
        setShowSuccessPopup(false);
        setShowPopupMsg("");
        dispatch(resetNotificationState());
      }, 3000);
    }
    if (error && error !== "") {
      setShowPopupMsg(error);
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
        setShowPopupMsg("");
        dispatch(resetNotificationState());
      }, 3000);
    }
  }, [error, notificationCreated]);

  const validateForm = (newClientName, newPropertyNumber) => {
    const isValid = newClientName !== "" && newPropertyNumber !== "";
    setIsFormValid(isValid);
    return isValid;
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
    // if (buttonStatus) {
    //   validateForm(clientName, propertyNumber);
    // }
  };

  const handleClientNameChange = (e) => {
    setClientName(e.target.value);
    if (buttonStatus) {
      validateForm(e.target.value, propertyNumber);
    }
  };

  const handlePropertyNumberChange = (e) => {
    setPropertyNumber(e.target.value);
    if (buttonStatus) {
      validateForm(clientName, e.target.value);
    }
  };

  const handleCreateNotificationSave = () => {
    if (validateForm(clientName, propertyNumber)) {
      setButtonStatus(true);
    } else {
      alert("Please fill all fields!");
    }
    const newTileData = {
      title: title,
      clientName: clientName,
      propertyNumber: propertyNumber,
    };
    dispatch(addNotification(newTileData));
  };

  useEffect(() => {
    // console.log("new data: " + JSON.stringify(newNotificationData));
    if (newNotificationDataStatus && newNotificationData !== null) {
      setNewChildData(newNotificationData);
    }
  }, [newNotificationData, newNotificationDataStatus]);

  const handleTextareaChange = (e) => {
    setMessage(e.target.value);
  };

  const handleCreateNotificationUpdate = () => {
    if (!validateForm(clientName, propertyNumber)) {
      alert("Please provide client name and property number all fields!");
      return;
    }
    if (selectedContacts?.length === 0) {
      alert("Please add atleast one contact");
      return;
    } else {
      if (selectedTileselectedNotificationId) {
        const updatedTileChildData = {
          parent_id: listData?._id,
          child_id: selectedTileselectedNotificationId,
          text: message.trim(),
          contacts: selectedContacts.map((contact) => contact?._id),
          emails: emails,
        };
        dispatch(updateChildNotification(updatedTileChildData));
      } else {
        // console.log("id ... " + newNotificationData?._id);
        const createTileChildData = {
          _id: listData?._id || newNotificationData?._id,
          text: message?.trim(),
          contacts: selectedContacts.map((contact) => contact._id),
          emails: emails,
        };
        if (createTileChildData?._id === null) {
          alert("Failed To create child notification.");
          return;
        }
        dispatch(createChildNotification(createTileChildData));
      }
      // dispatch(resetState());
      if (listData !== null || newNotificationData !== null) {
        const updatedTileData = {
          _id: listData?._id || newNotificationData?._id,
          title: title,
          clientName: clientName,
          propertyNumber: propertyNumber,
        };
        if (updatedTileData?._id === null) {
          alert("Failed To update notification.");
          return;
        }
        dispatch(resetState());
        dispatch(updateNotification(updatedTileData));
      }
    }
    setAfterUpdate();
    setContacts([]);
    setIsFormValid(false);
  };

  const handleDeleteNotificationChild = () => {
    const deleteTileChildData = {
      parent_id: listData?._id,
      child_id: selectedTileselectedNotificationId,
    };
    dispatch(deleteChildNotification(deleteTileChildData));
    setAfterUpdate();
    setContacts([]);
    setIsFormValid(false);
  };

  const validateEmail = (email) => {
    // Regular expression for email validation, enhanced with stricter checks
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
  };

  const handleValidation = () => {
    const inputEmails = emails.trim().split(/\s*,\s*|\n+/);
    const invalidEmails = [];

    for (const email of inputEmails) {
      if (!validateEmail(email)) {
        invalidEmails.push(email);
      }
    }

    // if (invalidEmails.length > 0) {
    //   alert(`Invalid emails found: ${invalidEmails.join(", ")}`);
    // }
  };

  const handleEmails = (event) => {
    setEmails(event.target.value);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    setTimeoutId(setTimeout(() => handleValidation(), 1000)); // Delay validation for 500ms after last keystroke
  };

  const handleKeyDownForEmails = (event) => {
    if (event.key === " " && !event.shiftKey) {
      event.preventDefault(); // Prevents adding a space
      setEmails((prevValue) => prevValue + ", "); // Append comma
    }
  };

  useEffect(() => {
    // Cleanup cleanup function on unmount
    return () => clearTimeout(timeoutId);
  }, [timeoutId]);

  const handleTextChangeForContactsKeyDown = (e) => {
    if (e.key === " ") {
      handleTextChangeForContacts(e);
    } else if (e.key === "Backspace") {
      const inputText = e.target.value;
      const cursorPosition = e.target.selectionStart;
      const textBeforeCursor = inputText.slice(0, cursorPosition);

      if (!textBeforeCursor.includes("@")) {
        // If "@" is not present, check the previous word
        const words = textBeforeCursor.split(/\s+/);
        const lastWord = words[words.length - 1];
        if (lastWord.startsWith("@")) {
          // If the previous word starts with "@", remove it
          const contactName = lastWord.slice(1); // Remove "@" from the word
          handleRemoveMention(contactName);
        }
      }
    }
  };

  const handleTextChangeForContacts = (event) => {
    const inputText = event.target.value;
    const cursorPosition = event.target.selectionStart;
    const textBeforeCursor = inputText.slice(0, cursorPosition);
    const lastAtIndex = textBeforeCursor.lastIndexOf("@");
    const lastMention = textBeforeCursor.match(/@\w+$/);
    const username = lastMention !== null ? lastMention[0].slice(1) : "";
    const isAtTyped = lastMention !== null || textBeforeCursor.endsWith("@");

    setShowContactList(isAtTyped || selectedContacts.length > 0);
    setSearchQuerysfc(username);

    const filterContacts = appContacts.filter((contact) =>
      contact?.name.toLowerCase().includes(username.toLowerCase())
    );
    setFilteredContacts(
      filterContacts.length > 0 ? filterContacts : appContacts
    );

    if (event.key === "Backspace") {
      if (lastMention) {
        handleRemoveMention(lastMention[0].slice(1)); // Remove mention
      } else if (isAtTyped) {
        setShowContactList(true);
      }
    }

    // Check if text length decreased
    if (inputText.length < contactHandleBox.length) {
      // Determine which mentions were deleted
      const deletedMentions = [];
      const inputTextArray = inputText.split("@");
      const contactHandleBoxArray = contactHandleBox.split("@");
      for (let i = 1; i < contactHandleBoxArray.length; i++) {
        if (!inputTextArray.includes(contactHandleBoxArray[i])) {
          deletedMentions.push(contactHandleBoxArray[i]);
        }
      }

      // Remove deleted mentions from selectedContacts
      deletedMentions.forEach((mention) => {
        handleRemoveMention(mention);
      });
    }

    setContactHandleBox(inputText);
  };

  const handleRemoveMention = (contactName) => {
    const mentionIndex = contactHandleBox.lastIndexOf(`@${contactName}`);
    const newText =
      mentionIndex !== -1
        ? contactHandleBox.slice(0, mentionIndex) +
          contactHandleBox.slice(mentionIndex + contactName.length + 1)
        : contactHandleBox;
    setContactHandleBox(newText);

    // Update selected contacts based on removed mention
    const newSelectedContacts = selectedContacts.filter(
      (contact) => contact.name !== contactName
    );
    setSelectedContacts(newSelectedContacts);

    console.log(
      "textarea updated selected: " + JSON.stringify(selectedContacts)
    );
  };

  const handleContactClick = (contact) => {
    const isAlreadySelected = selectedContacts.some(
      (c) => c.name === contact?.name
    );

    if (!isAlreadySelected) {
      const mentionIndex = contactHandleBox.lastIndexOf("@");
      const existingText =
        mentionIndex !== -1 ? contactHandleBox.substring(0, mentionIndex) : "";
      const newText = `${existingText}@${contact?.name} `;
      setContactHandleBox(newText);

      // setSelectedContacts([
      //   ...selectedContacts,
      //   { id: contact?._id, name: contact?.name, image: contact?.image },
      // ]);
      setSelectedContacts([
        ...selectedContacts,
        { _id: contact?._id, name: contact?.name },
      ]);
    } else {
      // Complete the mention if the full name is not mentioned
      const mentionIndex = contactHandleBox.lastIndexOf("@");
      if (
        mentionIndex !== -1 &&
        !contactHandleBox.slice(mentionIndex).includes(contact.name)
      ) {
        const newText =
          contactHandleBox.slice(0, mentionIndex) + "@" + contact.name + " ";
        setContactHandleBox(newText);
      }
    }

    textareaRef.current.focus();
    setShowContactList(false);
  };

  useEffect(() => {
    // console.log(
    //   "textarea updated selected: " + JSON.stringify(selectedContacts)
    // );
  }, [selectedContacts]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showContactList && !textareaRef?.current?.contains(event.target)) {
        setShowContactList(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showContactList]);

  const closePopup = () => {
    setShowPopup(false);
    setShowPopupMsg("");
  };

  const closeSuccessPopup = () => {
    setShowSuccessPopup(false);
    setShowPopupMsg("");
  };

  return (
    <>
      {status === "loading" && <LoadingSpinner />}
      {showSuccessPopup && (
        <EventSuccessPopup
          showPopupMsg={showPopupMsg}
          onClose={closeSuccessPopup}
        />
      )}
      {showPopup && (
        <EventFailedPopup showPopupMsg={showPopupMsg} onClose={closePopup} />
      )}
      <div className="createNotification">
        {buttonStatus ? (
          <button
            className="createNotificationbtn"
            onClick={handleCreateNotificationUpdate}
          >
            Update
          </button>
        ) : (
          <button
            className="createNotificationbtn"
            onClick={handleCreateNotificationSave}
          >
            Save
          </button>
        )}
        <div className="titleSection">
          <span>Title</span>
          <input type="text" value={title} onChange={handleTitleChange} />
        </div>

        <div className="secondLine">
          <div>
            <span>Client Name</span>
            <input
              type="text"
              value={clientName}
              onChange={handleClientNameChange}
            />
          </div>
          <div>
            <span>Property Number</span>
            <input
              type="text"
              value={propertyNumber}
              onChange={handlePropertyNumberChange}
            />
          </div>
        </div>

        {isFormValid && (
          <>
            <div className="para">
              <span>Additional notes</span>
              <textarea value={message} onChange={handleTextareaChange} />
            </div>
            <div className="emailAddresses para">
              <span>Email</span>
              <textarea
                ref={inputRef}
                value={emails}
                onKeyDown={handleKeyDownForEmails}
                onChange={handleEmails}
                placeholder="example@mail.com, example@mail.com"
              />
            </div>
            <div className="contactsTag para">
              <span>Contacts</span>
              {showContactList && (
                <div className="contacts-container">
                  {filteredContacts && filteredContacts.length > 0
                    ? filteredContacts?.map((contact) => (
                        <div
                          key={contact?._id}
                          className="contact-item"
                          onClick={() => handleContactClick(contact)}
                        >
                          <div className="contactrofilePic">
                            {typeof contact?.image === "string" &&
                            contact?.image !== "" ? (
                              <>
                                {contact?.image ? (
                                  <img src={contact?.image} alt="Profile" />
                                ) : (
                                  ""
                                )}
                              </>
                            ) : (
                              <div className="initials">
                                {contact?.name?.charAt(0)?.toUpperCase()}
                              </div>
                            )}
                          </div>
                          <div className="contactDetail">
                            <span>{contact?.name}</span>
                            <p>{contact?.contactNumber}</p>
                          </div>
                        </div>
                      ))
                    : "No Contact added"}
                </div>
              )}
              <textarea
                ref={textareaRef}
                value={contactHandleBox}
                onChange={handleTextChangeForContacts}
                onClick={handleTextChangeForContacts} // Add onClick for '@' triggering
                onKeyDown={handleTextChangeForContactsKeyDown} // Handle Backspace for removal
              />
            </div>
          </>
        )}

        {buttonStatus && selectedTileselectedNotificationId !== null && (
          <button
            className="createNotificationbtn delChildNoti"
            onClick={handleDeleteNotificationChild}
          >
            Delete
          </button>
        )}
      </div>
    </>
  );
};

export default CreateCustomNotification;
