import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./Componenets/auth/LoginComponent/Login";
import ContactUs from "./Componenets/home/HomeComponent/ContactUs";
import Signup from "./Componenets/auth/SignupComponent/Signup";
import ForgotPassword from "./Componenets/auth/LoginComponent/ForgotPassword";
import ResetPassword from "./Componenets/auth/SignupComponent/ResetPassword";
import OTPScreen from "./Componenets/auth/OTPScreen";
import HomeScreen from "./Componenets/home/HomeComponent/HomeScreen";
import { APP_ROUTES } from "./utils/AppConstants";
import ProtectedRoute from "./Componenets/ProtectedRoute";

function App() {
  return (
    <Router>
      <Routes>
        <Route path={APP_ROUTES.SIGN_IN_ROUTE} element={<Login />} />
        <Route path={APP_ROUTES.ContactUs_Route} element={<ContactUs />} />
        <Route path={APP_ROUTES.SIGN_UP_ROUTE} element={<Signup />} />
        <Route
          path={APP_ROUTES.FORGOT_PASSWORD_ROUTE}
          element={<ForgotPassword />}
        />
        <Route
          path={APP_ROUTES.RESET_PASSWORD_ROUTE}
          element={<ResetPassword />}
        />
        <Route path={APP_ROUTES.VERIFY_OTP_ROUTE} element={<OTPScreen />} />

        <Route element={<ProtectedRoute />}>
          <Route path={APP_ROUTES.APP_ROUTE} element={<HomeScreen />} />
          <Route path={APP_ROUTES.HOME_ROUTE} exact element={<HomeScreen />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
