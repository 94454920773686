// import React, { useState, useEffect } from "react";
// import ReactDOMServer from "react-dom/server";
// import L from "leaflet";
// import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
// import "leaflet/dist/leaflet.css";
// import { useSelector, useDispatch } from "react-redux";
// import Ellipse from "../../../assets/images/user_placeholder.png";
// import GoBackToCurrentLocationInMapIcon from "../../../assets/svgs/GoBackToCurrentLocationInMapIcon";
// import {
//   MAP_TILE_LAYER_URL,
//   MAP_ATTRIBUTION,
// } from "../../../utils/linksAndUrlConstants";
// import { getUsersLocations } from "../../../api/actions/homeActions";
// import {
//   getCityNameFromCurrentLocation,
//   getMapIcon,
// } from "../../../utils/CommonUtils";
// import MapWithUserLocation from "../HomeComponent/realtor/MapWithUserLocation";
// import ViewOtherUserProfile from "../HomeComponent/ViewOtherUserProfile";
// import SearchPlacesOnmap from "./SearchPlacesOnmap";
// import FireIconImage from "../../../assets/iconImages/FireIconImage.png";
// import OpenHouseFlag from "../../../assets/svgs/OpenHouseFlag";
// import OnlineDotIcon from "../../../assets/svgs/onlineDotIcon";
// // import OfflineDotIcon from "../../../assets/svgs/offlineDotIcon";
// require("./HomeCSS/realtor.css");
// require("./HomeCSS/searchbar.css");

// const Realtor = ({ statusForOpenChatsForOpenedProfile }) => {
//   const dispatch = useDispatch();
//   const { user } = useSelector((state) => state.auth);
//   const { status, error, data } = useSelector((state) => state.homeSlice);
//   const [selectedPlace, setSelectedPlace] = useState(null); // State to store the selected place
//   const [searchText, setSearchText] = useState("");
//   const [userCurrentLocation, setUserCurrentLocation] = useState([
//     user?.location?.latitude,
//     user?.location?.latitude,
//   ]);
//   const [otherUserProfile, setOtherUserProfile] = useState(false);
//   const [otherUserProfileId, setOtherUserProfileId] = useState("");

//   const mapOptions = {
//     center: userCurrentLocation || [(31.5497, 74.3436)],
//     zoom: 13,
//     maxZoom: 20,
//     minZoom: 5,
//   };

//   const updateSearchText = (newText) => {
//     setSearchText(newText);
//   };

//   const handleLocationSelect = async (place) => {
//     // const latitude = place.latitude;
//     // const longitude = place.longitude;
//     // console.log("search location new: " + JSON.stringify(place));
//     setSelectedPlace(place); // Update the selected place
//     // console.log("search location new 1123456" + JSON.stringify(selectedPlace));

//     const locationObj = {
//       search: place?.city,
//       coords: { latitude: place?.latitude, longitude: place?.longitude },
//     };

//     // console.log("search location" + JSON.stringify(place));
//     // console.log("search location locationObj" + JSON.stringify(locationObj));

//     if (place !== null && place !== undefined) {
//       const cityName = await getCityNameFromCurrentLocation(locationObj);
//       locationObj.search = cityName;
//       // console.log("search location 12345678" + JSON.stringify(locationObj));
//     }
//     dispatch(getUsersLocations(locationObj));
//   };

//   useEffect(() => {}, [selectedPlace]);

//   const getMapUsersFromApi = async (position) => {
//     // console.log("positioncheck" + JSON.stringify(position));
//     // console.log("user data location new: " + JSON.stringify(user));

//     const locationObj = {
//       search: user?.location?.city || "Lahore",
//     };
//     if (position !== null && position !== undefined) {
//       const cityName = await getCityNameFromCurrentLocation(position);
//       locationObj.search = cityName;
//     }
//     dispatch(getUsersLocations(locationObj));
//   };

//   const openOtherUserProfile = (userId) => {
//     setOtherUserProfile(true);
//     setOtherUserProfileId(userId);
//     localStorage.setItem("tupoa", false);
//   };

//   const openMapPage = () => {
//     setOtherUserProfile(false);
//     setOtherUserProfileId("");
//     if (selectedPlace === null) {
//       const gtcl = {
//         city: user?.location?.city,
//         latitude: user?.location?.latitude,
//         longitude: user?.location?.longitude,
//       };
//       setSelectedPlace(gtcl);
//     } else {
//       updateSearchText(selectedPlace.city);
//     }
//     handleLocationSelect(selectedPlace);
//   };
//   useEffect(() => {}, [selectedPlace]);

//   const handleReturnToOriginalPosition = () => {
//     let locset = {
//       search: user?.location.city,
//       coords: {
//         latitude: user?.location?.latitude,
//         longitude: user?.location?.longitude,
//       },
//     };
//     getMapUsersFromApi(locset);
//     const gtcl = {
//       city: user?.location?.city,
//       latitude: user?.location?.latitude,
//       longitude: user?.location?.longitude,
//     };
//     setSelectedPlace(gtcl);
//   };

//   const pidfopfc = (userId) => {
//     statusForOpenChatsForOpenedProfile(userId);
//   };

//   // Create custom icons
//   const onlineIcon = new L.Icon({
//     iconUrl: `data:image/svg+xml;base64,${btoa(
//       ReactDOMServer.renderToString(<OnlineDotIcon />)
//     )}`,
//     iconSize: [16, 16], // Adjust the size as needed
//     iconAnchor: [-17, -11], // Center of the icon
//     popupAnchor: [15, 0],
//     className: "online-offline-icon",
//   });

//   // const offlineIcon = new L.Icon({
//   //   iconUrl: `data:image/svg+xml;base64,${btoa(
//   //     ReactDOMServer.renderToString(<OfflineDotIcon />)
//   //   )}`,
//   //   iconSize: [16, 16],
//   //   iconAnchor: [-17, -11],
//   //   popupAnchor: [15, 0],
//   //   className: "online-offline-icon",
//   // });

//   const openHouseFlagIcon = new L.Icon({
//     iconUrl: `data:image/svg+xml;base64,${btoa(
//       ReactDOMServer.renderToString(<OpenHouseFlag />)
//     )}`,
//     iconSize: ["auto", 19],
//     iconAnchor: [20, -26],
//     popupAnchor: [15, 0],
//     className: "open-house-flag-icon",
//   });

//   const setFireIconFunc = (status) => {
//     if (status === null) return false; // If updatedAt is not provided or invalid, return false
//     const updatedAt = status?.updatedAt;
//     const updateTimeObject = new Date(updatedAt); // Convert updatedAt to a Date object
//     const currentTime = new Date(); // Get the current time
//     const timeDifference = currentTime - updateTimeObject; // Calculate the time difference in milliseconds
//     const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60)); // Convert milliseconds to hours

//     return hoursDifference < 24; // Return true if the difference is less than 24 hours, otherwise false
//   };

//   const fireIcon = new L.Icon({
//     iconUrl: FireIconImage,
//     iconSize: [16, 16],
//     iconAnchor: [-17, 16],
//     popupAnchor: [15, 0],
//     className: "fire-icon",
//   });

//   return (
//     <>
//       {otherUserProfile ? (
//         <ViewOtherUserProfile
//           otherUserProfileId={otherUserProfileId}
//           goBackToMapPage={openMapPage}
//           openChatsForOpenedProfile={pidfopfc}
//         />
//       ) : (
//         <MapContainer
//           {...mapOptions}
//           style={{ height: "100vh", width: "100%" }}
//           center={[
//             selectedPlace?.latitude || user?.location?.latitude,
//             selectedPlace?.longitude || user?.location?.longitude,
//           ]}
//         >
//           <SearchPlacesOnmap
//             onSelectPlace={handleLocationSelect}
//             searchText={searchText}
//             setSearchText={updateSearchText}
//           />
//           <TileLayer
//             url={MAP_TILE_LAYER_URL}
//             attribution={MAP_ATTRIBUTION}
//             maxZoom={20}
//           />
//           {data &&
//             data.map((data) => (
//               <>
//                 {/* {console.log(data)} */}
//                 <Marker
//                   key={data._id}
//                   position={[data.location.latitude, data.location.longitude]}
//                   icon={getMapIcon(data?.image != null ? data?.image : Ellipse)}
//                   eventHandlers={{
//                     click: () => openOtherUserProfile(data._id),
//                   }}
//                   style={{ cursor: "pointer" }}
//                 >
//                   <Popup>{data.location.address}</Popup>
//                 </Marker>
//                 {/* for onlinedot show */}
//                 {data?.isOnline === true && (
//                   <Marker
//                     key={data._id}
//                     position={[data.location.latitude, data.location.longitude]}
//                     icon={onlineIcon}
//                   ></Marker>
//                 )}
//                 {data?.isOpenHouse === true && (
//                   <Marker
//                     key={data._id}
//                     position={[data.location.latitude, data.location.longitude]}
//                     icon={openHouseFlagIcon}
//                   ></Marker>
//                 )}
//                 {setFireIconFunc(data?.status) && (
//                   <Marker
//                     key={data._id}
//                     position={[data.location.latitude, data.location.longitude]}
//                     icon={fireIcon}
//                   ></Marker>
//                 )}
//               </>
//             ))}
//           <MapWithUserLocation
//             user={user}
//             onLocationFound={getMapUsersFromApi}
//             searchedLocation={selectedPlace}
//             setOriginalPosition={setUserCurrentLocation}
//           />
//         </MapContainer>
//       )}
//       {!otherUserProfile && (
//         <div className="mapActionsRU">
//           <div
//             className="moveToUserCurrentPosition"
//             onClick={handleReturnToOriginalPosition}
//           >
//             <GoBackToCurrentLocationInMapIcon />
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

// export default Realtor;

// ////////////////////////////////////////////////////

import React, { useState, useEffect } from "react";
import ReactDOMServer from "react-dom/server";
import L from "leaflet";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { useSelector, useDispatch } from "react-redux";
import Ellipse from "../../../assets/images/user_placeholder.png";
import GoBackToCurrentLocationInMapIcon from "../../../assets/svgs/GoBackToCurrentLocationInMapIcon";
import {
  MAP_TILE_LAYER_URL,
  MAP_ATTRIBUTION,
} from "../../../utils/linksAndUrlConstants";
import { getUsersLocations } from "../../../api/actions/homeActions";
import {
  getCityNameFromCurrentLocation,
  getMapIcon,
} from "../../../utils/CommonUtils";
import MapWithUserLocation from "../HomeComponent/realtor/MapWithUserLocation";
import ViewOtherUserProfile from "../HomeComponent/ViewOtherUserProfile";
import SearchPlacesOnmap from "./SearchPlacesOnmap";
import FireIconImage from "../../../assets/iconImages/FireIconImage.png";
import OpenHouseFlag from "../../../assets/svgs/OpenHouseFlag";
import OnlineDotIcon from "../../../assets/svgs/onlineDotIcon";
// import OfflineDotIcon from "../../../assets/svgs/offlineDotIcon";
require("./HomeCSS/realtor.css");
require("./HomeCSS/searchbar.css");

const defaultLocation = {
  city: "Dubai",
  latitude: 25.276987,
  longitude: 55.296249,
};

const Realtor = ({ statusForOpenChatsForOpenedProfile }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { status, error, data } = useSelector((state) => state.homeSlice);
  const [selectedPlace, setSelectedPlace] = useState(null); // State to store the selected place
  const [searchText, setSearchText] = useState("");
  const [userCurrentLocation, setUserCurrentLocation] = useState([
    user?.location?.latitude || defaultLocation.latitude,
    user?.location?.longitude || defaultLocation.longitude,
  ]);
  const [otherUserProfile, setOtherUserProfile] = useState(false);
  const [otherUserProfileId, setOtherUserProfileId] = useState("");

  const mapOptions = {
    center: userCurrentLocation || [
      defaultLocation.latitude,
      defaultLocation.longitude,
    ],
    zoom: 13,
    maxZoom: 20,
    minZoom: 5,
  };

  const updateSearchText = (newText) => {
    setSearchText(newText);
  };

  const handleLocationSelect = async (place) => {
    setSelectedPlace(place); // Update the selected place

    const locationObj = {
      search: place?.city,
      coords: { latitude: place?.latitude, longitude: place?.longitude },
    };

    if (place !== null && place !== undefined) {
      const cityName = await getCityNameFromCurrentLocation(locationObj);
      locationObj.search = cityName;
    }
    dispatch(getUsersLocations(locationObj));
  };

  const getMapUsersFromApi = async (position) => {
    const locationObj = {
      search: user?.location?.city || defaultLocation.city,
    };
    console.log(position);
    if (position !== null && position !== undefined) {
      const cityName = await getCityNameFromCurrentLocation(position);
      locationObj.search = cityName;
    }
    console.log(locationObj);
    dispatch(getUsersLocations(locationObj));
  };

  const openOtherUserProfile = (userId) => {
    setOtherUserProfile(true);
    setOtherUserProfileId(userId);
    localStorage.setItem("tupoa", false);
  };

  const openMapPage = () => {
    setOtherUserProfile(false);
    setOtherUserProfileId("");
    if (selectedPlace === null) {
      const gtcl = {
        city: user?.location?.city,
        latitude: user?.location?.latitude,
        longitude: user?.location?.longitude,
      };
      setSelectedPlace(gtcl);
    } else {
      updateSearchText(selectedPlace.city);
    }
    handleLocationSelect(selectedPlace);
  };

  const handleReturnToOriginalPosition = () => {
    let locset = {
      search: user?.location.city,
      coords: {
        latitude: user?.location?.latitude,
        longitude: user?.location?.longitude,
      },
    };
    getMapUsersFromApi(locset);
    const gtcl = {
      city: user?.location?.city,
      latitude: user?.location?.latitude,
      longitude: user?.location?.longitude,
    };
    setSelectedPlace(gtcl);
  };

  const pidfopfc = (userId) => {
    statusForOpenChatsForOpenedProfile(userId);
  };

  // Create custom icons
  const onlineIcon = new L.Icon({
    iconUrl: `data:image/svg+xml;base64,${btoa(
      ReactDOMServer.renderToString(<OnlineDotIcon />)
    )}`,
    iconSize: [16, 16], // Adjust the size as needed
    iconAnchor: [-17, -11], // Center of the icon
    popupAnchor: [15, 0],
    className: "online-offline-icon",
  });

  const openHouseFlagIcon = new L.Icon({
    iconUrl: `data:image/svg+xml;base64,${btoa(
      ReactDOMServer.renderToString(<OpenHouseFlag />)
    )}`,
    iconSize: ["auto", 19],
    iconAnchor: [20, -26],
    popupAnchor: [15, 0],
    className: "open-house-flag-icon",
  });

  const setFireIconFunc = (status) => {
    if (status === null) return false; // If updatedAt is not provided or invalid, return false
    const updatedAt = status?.updatedAt;
    const updateTimeObject = new Date(updatedAt); // Convert updatedAt to a Date object
    const currentTime = new Date(); // Get the current time
    const timeDifference = currentTime - updateTimeObject; // Calculate the time difference in milliseconds
    const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60)); // Convert milliseconds to hours

    return hoursDifference < 24; // Return true if the difference is less than 24 hours, otherwise false
  };

  const fireIcon = new L.Icon({
    iconUrl: FireIconImage,
    iconSize: [16, 16],
    iconAnchor: [-17, 16],
    popupAnchor: [15, 0],
    className: "fire-icon",
  });

  return (
    <>
      {otherUserProfile ? (
        <ViewOtherUserProfile
          otherUserProfileId={otherUserProfileId}
          goBackToMapPage={openMapPage}
          openChatsForOpenedProfile={pidfopfc}
        />
      ) : (
        <MapContainer
          {...mapOptions}
          style={{ height: "100vh", width: "100%" }}
          center={[
            selectedPlace?.latitude || userCurrentLocation[0],
            selectedPlace?.longitude || userCurrentLocation[1],
          ]}
        >
          <SearchPlacesOnmap
            onSelectPlace={handleLocationSelect}
            searchText={searchText}
            setSearchText={updateSearchText}
          />
          <TileLayer
            url={MAP_TILE_LAYER_URL}
            attribution={MAP_ATTRIBUTION}
            maxZoom={20}
          />
          {data &&
            data.map((data) => (
              <>
                <Marker
                  key={data?._id}
                  position={[data.location?.latitude, data.location?.longitude]}
                  icon={getMapIcon(data?.image != null ? data?.image : Ellipse)}
                  eventHandlers={{
                    click: () => openOtherUserProfile(data?._id),
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <Popup>{data.location?.address}</Popup>
                </Marker>
                {data?.isOnline === true && (
                  <Marker
                    key={data._id}
                    position={[
                      data.location?.latitude,
                      data.location?.longitude,
                    ]}
                    icon={onlineIcon}
                  ></Marker>
                )}
                {data?.isOpenHouse === true && (
                  <Marker
                    key={data._id}
                    position={[
                      data.location?.latitude,
                      data.location?.longitude,
                    ]}
                    icon={openHouseFlagIcon}
                  ></Marker>
                )}
                {setFireIconFunc(data?.status) && (
                  <Marker
                    key={data._id}
                    position={[
                      data.location?.latitude,
                      data.location?.longitude,
                    ]}
                    icon={fireIcon}
                  ></Marker>
                )}
              </>
            ))}
          <MapWithUserLocation
            user={user}
            onLocationFound={getMapUsersFromApi}
            searchedLocation={selectedPlace}
            setOriginalPosition={setUserCurrentLocation}
            defaultLocation={defaultLocation}
          />
        </MapContainer>
      )}
      {!otherUserProfile && (
        <div className="mapActionsRU">
          <div
            className="moveToUserCurrentPosition"
            onClick={handleReturnToOriginalPosition}
          >
            <GoBackToCurrentLocationInMapIcon />
          </div>
        </div>
      )}
    </>
  );
};

export default Realtor;
