import React, { useState, useEffect } from "react";
import SettingContent from "./SettingContent";
import AddUserOverlay from "./AddUserOverlay";
import ChangePassword from "./ChangePassword";
import ReportAnIssue from "./ReportAnIssue";
import MoveBackIcon from "../../../assets/svgs/LeftArrowIcon";
import ActiveOfferIcon from "../../../assets/svgs/ActiveOfferIcon";
import PlusSignIcon from "../../../assets/svgs/PlusSignIcon";
import {
  getSubscriptionPlans,
  startSubscription,
  getSubscriptionDetail,
} from "../../../api/actions/subscriptionsPlansActions";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "../../../utils/LoadingSpinner";
import {
  resetSubscriptionSuccessStatus,
  resetUserAddedStatus,
  resetUserRemovedStatus,
  resetError,
} from "../../../api/reducerSlices/subscriptionsPlansSlice";
import { getUserProfile } from "../../../api/actions/profileActions";
import { EventSuccessPopup } from "../../popup-boxes/main-page-Popup's/mainPageEventPopup";
require("./HomeCSS/setting.css");

const Settings = () => {
  const dispatch = useDispatch();
  const {
    status,
    subscriptionPlans,
    subscriptionDetail,
    subscriptionSuccessStatus,
    userAdded,
    userRemoved,
  } = useSelector((state) => state.subscriptionsPlansSlice);
  const { status: profileStatus, userData } = useSelector(
    (state) => state.profileSlice
  );
  const { user } = useSelector((state) => state.auth);

  // for subscriptionPlans ui handling
  const [singlePlans, setSinglePlans] = useState([]);
  const [groupPlans, setGroupPlans] = useState([]);

  const [showOverlayForSubscriptionPlan, setShowOverlayForSubscriptionPlan] =
    useState(localStorage.getItem("subsTileOpen") === "true" || false);
  const [currentPage, setCurrentPage] = useState("");
  const [planIdfsc, setPlanIdfsc] = useState("");
  const [subsDetailData, setSubsDetailData] = useState(null);
  const [addUserOverlayOpen, setaddUserOverlayOpen] = useState(false);
  const [removeEmail, setRemoveEmail] = useState(false);
  const [addUserUIStatus, setAddUserUIStatus] = useState(
    (userData?.user?.subscriptionType &&
      userData?.user?.subscriptionType === "GroupMonthly") ||
      (userData?.user?.subscriptionType &&
        userData?.user?.subscriptionType === "GroupQuarterly") ||
      (userData?.user?.subscriptionType &&
        userData?.user?.subscriptionType === "GroupYearly")
      ? true
      : false
  );
  /////////////////////////////////////////
  const [showOverlay, setShowOverlay] = useState(false);
  const [showOverlayForChangePassword, setShowOverlayForChangePassword] =
    useState(false);
  const [showOverlayForReportAnIssue, setShowOverlayForReportAnIssue] =
    useState(false);

  const [showPopupMsg, setShowPopupMsg] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  /// onload get subscriptionPlans
  useEffect(() => {
    dispatch(getUserProfile());
  }, []);

  useEffect(() => {
    if (userData?.user?.subscriptionId) {
      dispatch(getSubscriptionDetail(userData?.user?.subscriptionId));
    }
  }, [userData]);

  useEffect(() => {
    dispatch(getSubscriptionPlans());
  }, [dispatch]);

  // check if user has subscription so get subscription details
  useEffect(() => {
    // console.log("user", userData?.user);
    dispatch(getSubscriptionDetail(userData?.user?.subscriptionId));
    if (userData?.user?.subscriptionId) {
      dispatch(getSubscriptionDetail(userData?.user?.subscriptionId));
    }
  }, [userData]);

  ////// update ui after remove user in from group subscription
  useEffect(() => {
    if (userData?.user?.subscriptionId && userRemoved) {
      dispatch(getSubscriptionDetail(userData?.user?.subscriptionId));
      setRemoveEmail(true);
      dispatch(resetUserRemovedStatus());
    }
  }, [userRemoved]);

  //// update subscription after add or remove user
  useEffect(() => {
    if (subscriptionDetail !== null) {
      // console.log(subscriptionDetail);
      setSubsDetailData(subscriptionDetail);
    }
  }, [subscriptionDetail]);

  useEffect(() => {
    if (userData?.user?.subscriptionId && userAdded) {
      dispatch(getSubscriptionDetail(userData?.user?.subscriptionId));
      dispatch(resetUserAddedStatus());
    }
  }, [userAdded]);

  useEffect(() => {
    if (subscriptionDetail !== null) {
      setSubsDetailData(subscriptionDetail);
      // console.log(subsDetailData);
    }
  }, [subscriptionDetail]);

  // onload page set subscription plans seprate into single and group
  useEffect(() => {
    if (subscriptionPlans) {
      const single = subscriptionPlans.filter((plan) =>
        plan.name.toLowerCase().includes("single")
      );
      const group = subscriptionPlans.filter((plan) =>
        plan.name.toLowerCase().includes("group")
      );
      setSinglePlans(single);
      setGroupPlans(group);
    }
  }, [dispatch, subscriptionPlans]);

  useEffect(() => {
    if (
      (userData?.user?.subscriptionType &&
        userData?.user?.subscriptionType === "GroupMonthly") ||
      (userData?.user?.subscriptionType &&
        userData?.user?.subscriptionType === "GroupQuarterly") ||
      (userData?.user?.subscriptionType &&
        userData?.user?.subscriptionType === "GroupYearly")
    ) {
      setAddUserUIStatus(true);
    } else {
      setAddUserUIStatus(false);
    }
    dispatch(resetError());
  }, []);

  useEffect(() => {
    if (subscriptionSuccessStatus === true) {
      setShowPopupMsg("Offer Subscribed!");
      setShowSuccessPopup(true);
      setTimeout(() => {
        setShowSuccessPopup(false);
        setShowPopupMsg("");
        dispatch(resetSubscriptionSuccessStatus());
      }, 3000);
    }
    if (
      (userData?.user?.subscriptionType &&
        userData?.user?.subscriptionType === "GroupMonthly") ||
      (userData?.user?.subscriptionType &&
        userData?.user?.subscriptionType === "GroupQuarterly") ||
      (userData?.user?.subscriptionType &&
        userData?.user?.subscriptionType === "GroupYearly")
    ) {
      setAddUserUIStatus(true);
    } else {
      setAddUserUIStatus(false);
    }
  }, [subscriptionSuccessStatus]);

  ///// for handle terms and policy component
  // useEffect(() => {
  //   if (localStorage.getItem("subsTileOpen") === "true") {
  //     setShowOverlayForSubscriptionPlan(true);
  //   }
  //   if (localStorage.getItem("termsOrPolicyStatus") === "true") {
  //     setShowOverlay(true);
  //     setCurrentPage(localStorage.getItem("termsOrPolicy"));
  //   }
  // }, []);

  //// handle ui for subscription plans
  const overlayForSubscription = () => {
    if (!localStorage.getItem("subsTileOpen")) {
      localStorage.setItem("subsTileOpen", true);
    }
    setShowOverlayForSubscriptionPlan(!showOverlayForSubscriptionPlan);
    if (
      showOverlayForSubscriptionPlan &&
      localStorage.getItem("subsTileOpen")
    ) {
      localStorage.setItem("subsTileOpen", true);
      localStorage.removeItem("subsTileOpen");
    }
  };

  //// onclick offer tile open corresponding payment form page
  const openPaymentURL = async (planId) => {
    let object = {
      planId: planId,
    };
    const urlResponse = await dispatch(startSubscription(object));
    if (urlResponse.payload && urlResponse.payload.data) {
      // Open the payment URL in a new window
      setPlanIdfsc(urlResponse.payload.data);
      // const paymentWindow = window.open(urlResponse.payload.data);
      window.location.href = urlResponse.payload.data;
      localStorage.setItem("planIdOnSuccess", planId);
    } else {
      // Handle error case when no payment URL is received
      alert("Error opening payment page.");
    }
  };

  // useEffect(() => {
  //   if (subsId && paymentStatus) {
  //     addAndGetSubscription();
  //   }
  // }, [subsId, paymentStatus]);

  //// display single subscription plans and group subscription plans tile
  const Plan = ({ plan, onClick }) => {
    let durationText;
    if (plan.days === 30) {
      durationText = "per Month";
    } else {
      const months = Math.round(plan.days / 30);
      if (months === 12) {
        durationText = "per year";
      } else {
        if (months > 12) {
          const years = months / 12;
          durationText = years === 1 ? "1 year" : `${years.toFixed(1)} years`;
        } else {
          durationText = months === 1 ? "1 month" : `${months} months`;
        }
      }
    }

    return (
      <div className="subscription-Tile" onClick={() => onClick(plan?._id)}>
        <span>
          {plan?.price} $/ {durationText}
        </span>
        <p>{plan?.name}</p>
        {userData?.user?.subscriptionType === plan?.name &&
        userData?.user?.isSubsAdmin === true ? (
          <div div className="activeCheck">
            <ActiveOfferIcon />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };

  const addUserOverlay = () => {
    setaddUserOverlayOpen(!addUserOverlayOpen);
    if (userData?.user?.subscriptionId) {
      dispatch(getSubscriptionDetail(userData?.user?.subscriptionId));
    }
    if (removeEmail) {
      setRemoveEmail(false);
    }
  };

  //// handle terms and policy component for show
  const handleButtonClick = (page) => {
    setCurrentPage(page);
    // localStorage.setItem("termsOrPolicyStatus", true);
    // localStorage.setItem("termsOrPolicy", page);
    setShowOverlay(true);
  };

  //// handle terms and policy component for hide
  const handleMoveBack = () => {
    setShowOverlay(false);
    setCurrentPage("");
    // localStorage.setItem("termsOrPolicyStatus", false);
    // localStorage.removeItem("termsOrPolicy");
  };

  //// handle change password component for hide and show
  const overlayForChangePassword = () => {
    setShowOverlayForChangePassword(!showOverlayForChangePassword);
  };

  //// handle report an issue component for hide and show
  const overlayForReportAnIssueComp = () => {
    setShowOverlayForReportAnIssue(!showOverlayForReportAnIssue);
  };

  const closeSuccessPopup = () => {
    setShowSuccessPopup(false);
    setShowPopupMsg("");
  };

  useEffect(() => {
    // Function to parse URI parameters
    const parseUriParametersSet = () => {
      // Get the current URL
      const currentUrl = window.location.href;

      // Check if the URL contains the expected parameters
      if (
        localStorage.getItem("Fromios") &&
        localStorage.getItem("Fromios") === "true"
      ) {
        localStorage.setItem("Fromios", false);
        overlayForSubscription();
      }
    };

    // Call the function to parse URI parameters
    parseUriParametersSet();
  }, []);

  function SubscriptionEndDate(props) {
    // Assuming props.subscriptionEndDate contains the date string "2024-05-29T10:13:38.696Z"
    const subscriptionEndDate = new Date(props);

    // Define days array for day names
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    // Define months array for month names
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Extract day, month, date, hours, minutes, and seconds
    const day = days[subscriptionEndDate.getUTCDay()];
    const month = months[subscriptionEndDate.getUTCMonth()];
    const date = subscriptionEndDate.getUTCDate();
    const hours = subscriptionEndDate.getUTCHours();
    const minutes = subscriptionEndDate.getUTCMinutes();
    const seconds = subscriptionEndDate.getUTCSeconds();

    // Format the time to ensure two digits
    const formattedTime = (num) => {
      return num < 10 ? "0" + num : num;
    };

    // Construct the formatted date string
    const formattedDate = `${day} ${month} ${date} ${formattedTime(
      hours
    )}:${formattedTime(minutes)}:${formattedTime(seconds)}`;

    return <p>{formattedDate}</p>;
  }

  return (
    <>
      {/*  handle setting component layout */}
      {showSuccessPopup && (
        <EventSuccessPopup
          showPopupMsg={showPopupMsg}
          onClose={closeSuccessPopup}
        />
      )}
      {!showOverlayForSubscriptionPlan &&
        !showOverlayForChangePassword &&
        !showOverlay &&
        !showOverlayForReportAnIssue && (
          <div className="settingContent">
            {/* <div className="header">
          <span>Setting</span>
        </div> */}
            {/*================================*/}
            <div className="serviceTile">
              {user?.role === "realtor" && (
                <div
                  className="servTile"
                  onClick={() => overlayForSubscription()}
                >
                  <span>Subscription Plans</span>
                </div>
              )}
              <div className="servTile" onClick={overlayForChangePassword}>
                <span>Change Password</span>
              </div>
              <div
                className="servTile"
                onClick={() => handleButtonClick("terms")}
              >
                <span>Terms & Condition</span>
              </div>
              <div
                className="servTile"
                onClick={() => handleButtonClick("policy")}
              >
                <span>Privacy Policy</span>
              </div>
              <div className="servTile" onClick={overlayForReportAnIssueComp}>
                <span>Report an Issue</span>
              </div>
              {/* <div className="servTile">
              <span>Contact us</span>
            </div> */}
            </div>
          </div>
        )}
      {/*handle subscription Plan component layout */}
      {showOverlayForSubscriptionPlan && !addUserOverlayOpen && (
        <>
          {(status === "loading" && <LoadingSpinner />) ||
            (profileStatus === "loading" && <LoadingSpinner />)}
          <div className="overlayForSubscriptionPlan">
            <div className="headerLine">
              <div className="moveback" onClick={overlayForSubscription}>
                <MoveBackIcon />
              </div>
              <span>Subscription Plans</span>
              {addUserUIStatus && (
                <div className="addUserIcon" onClick={addUserOverlay}>
                  <PlusSignIcon />
                </div>
              )}
            </div>
            <div className="subscription-group">
              <div className="headingText">Single Plans</div>
              {singlePlans && singlePlans.length > 0 ? (
                <>
                  {singlePlans.map((plan) => (
                    <>
                      {/* {console.log(plan)} */}
                      <Plan
                        key={plan._id}
                        plan={plan}
                        onClick={openPaymentURL}
                      />
                    </>
                  ))}
                </>
              ) : (
                <>
                  {singlePlans && singlePlans.length === 0 ? (
                    <div className="onLoadMsg">No offer available</div>
                  ) : (
                    <div className="onLoadMsg">Loading...</div>
                  )}
                </>
              )}
            </div>
            <div className="subscription-group">
              <div className="headingText">Group Plans</div>
              {groupPlans && groupPlans.length > 0 ? (
                <>
                  {groupPlans.map((plan) => (
                    <>
                      {/* {console.log(plan)} */}
                      <Plan
                        key={plan._id}
                        plan={plan}
                        onClick={openPaymentURL}
                      />
                    </>
                  ))}
                </>
              ) : (
                <>
                  {groupPlans && groupPlans.length === 0 ? (
                    <div className="onLoadMsg">No offer available</div>
                  ) : (
                    <div className="onLoadMsg">Loading...</div>
                  )}
                </>
              )}
            </div>
            <div className="expDateTag">
              <p>Expiration Date:</p>
              {userData && (
                <>{SubscriptionEndDate(userData?.user?.subscriptionEndDate)}</>
              )}
            </div>
          </div>
        </>
      )}
      {/* component to show add add and remove user ui */}
      {addUserOverlayOpen && (
        <AddUserOverlay
          subsDetailData={subsDetailData}
          addUserOverlay={addUserOverlay}
          subsID={userData?.user?.subscriptionId}
          removeEmail={removeEmail}
        />
      )}
      {/* component to changed password ui */}
      {showOverlayForChangePassword && (
        <ChangePassword overlayForChangePassword={overlayForChangePassword} />
      )}
      {/* component to Terms and Policy ui */}
      {showOverlay && (
        <SettingContent content={currentPage} onMoveBack={handleMoveBack} />
      )}
      {showOverlayForReportAnIssue && (
        <ReportAnIssue overlayReportAnIssue={overlayForReportAnIssueComp} />
      )}
    </>
  );
};

export default Settings;
