import { createSlice } from "@reduxjs/toolkit";
import {
  getAppNotifications,
  getUserNotifications,
  addNotification,
  deleteNotification,
  updateNotification,
  createChildNotification,
  updateChildNotification,
  deleteChildNotification,
  sendChildNotification,
} from "../actions/notificationActions";

const initialState = {
  status: "idle",
  error: "",
  data: [],
  appNotifications: [],
  userNotifications: [],
  newNotificationData: null,
  newNotificationDataStatus: false,
  notificationCreated: false,
  notificationSend: false,
  notificationUpdated: false,
  wholeNotificationDelete: false,
  childAdded: false,
  childUpdated: false,
  childDeleted: false,
};

const notificationSlice = createSlice({
  name: "notificationSlice",
  initialState,
  reducers: {
    resetState: (state) => {
      state.status = "";
      state.error = "";
      state.notificationCreated = false;
      state.notificationSend = false;
      state.notificationUpdated = false;
      state.wholeNotificationDelete = false;
      state.childAdded = false;
      state.childUpdated = false;
      state.childDeleted = false;
    },
    resetStateNM: (state) => {
      state.data = [];
      state.appNotifications = [];
      state.userNotifications = [];
    },
    resetStateAppNoti: (state) => {
      state.appNotifications = [];
    },
    resetStateUserNoti: (state) => {
      state.userNotifications = [];
    },
    resetStateAddNewNotificationData: (state) => {
      state.newNotificationData = null;
      state.newNotificationDataStatus = false;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAppNotifications.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getAppNotifications.fulfilled, (state, action) => {
        if (action.payload.status === true) {
          if (action.payload.data.length > 0) {
            state.status = "succeeded";
            state.appNotifications = action.payload.data;
            // console.log("appNotifications: ", action.payload.data)
          } else {
            state.status = "failed";
            state.error = "No records found";
            state.appNotifications = null;
          }
        } else {
          state.status = "failed";
          state.error = action.payload.message;
          state.appNotifications = null;
        }
      })
      .addCase(getAppNotifications.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.appNotifications = null;
      })
      .addCase(getUserNotifications.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getUserNotifications.fulfilled, (state, action) => {
        if (action.payload.status === true) {
          if (action.payload.data != null) {
            state.status = "succeeded";
            state.userNotifications = action.payload.data;
            // console.log("userNotifications: ", action.payload.data)
          } else {
            state.status = "failed";
            state.error = "No records found";
            state.userNotifications = null;
          }
        } else {
          state.status = "failed";
          state.error = action.payload.message;
          state.userNotifications = null;
        }
      })
      .addCase(getUserNotifications.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.userNotifications = null;
      })
      .addCase(addNotification.pending, (state, action) => {
        state.status = "loading";
        state.notificationCreated = false;
      })
      .addCase(addNotification.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Assuming the newly created notification is returned in the payload
        state.data.push(action.payload);
        state.newNotificationData = action.payload.data;
        state.newNotificationDataStatus = true;
        state.notificationCreated = true;
      })
      .addCase(addNotification.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload.message;
        state.notificationCreated = false;
      })
      .addCase(deleteNotification.pending, (state, action) => {
        state.status = "loading";
        state.wholeNotificationDelete = false;
      })
      .addCase(deleteNotification.fulfilled, (state, action) => {
        if (action.payload.status === true) {
          state.status = "succeeded";
          state.wholeNotificationDelete = true;
        } else {
          state.status = "failed";
          state.error = action.payload.message;
          // Set any additional state properties as needed
          state.wholeNotificationDelete = false;
        }
      })
      .addCase(deleteNotification.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.wholeNotificationDelete = false;
      })
      .addCase(updateNotification.pending, (state, action) => {
        state.status = "loading";
        state.notificationUpdated = false;
      })
      .addCase(updateNotification.fulfilled, (state, action) => {
        if (action.payload.status === true) {
          if (action.payload.data != null) {
            state.status = "succeeded";
            state.userNotifications = action.payload.data;
            state.notificationUpdated = true;
          } else {
            state.status = "failed";
            state.error = "No records found";
            state.userNotifications = null;
            state.notificationUpdated = false;
          }
        } else {
          state.status = "failed";
          state.error = action.payload.message;
          state.notificationUpdated = false;
        }
      })
      .addCase(createChildNotification.pending, (state, action) => {
        state.status = "loading";
        state.childAdded = false;
      })
      .addCase(createChildNotification.fulfilled, (state, action) => {
        if (action.payload.status === true) {
          state.status = "succeeded";
          state.data.push(action.payload.data);
          state.childAdded = true;
        } else {
          state.status = "failed";
          state.error = action.payload.message;
          state.childAdded = false;
        }
      })
      .addCase(createChildNotification.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.childAdded = false;
      })
      .addCase(updateChildNotification.pending, (state, action) => {
        state.status = "loading";
        state.childUpdated = false;
      })
      .addCase(updateChildNotification.fulfilled, (state, action) => {
        if (action.payload.status === true) {
          if (action.payload.data != null) {
            state.status = "succeeded";
            state.userNotifications = action.payload.data;
            state.childUpdated = true;
          } else {
            state.status = "failed";
            state.error = "No records found";
            state.userNotifications = null;
            state.childUpdated = false;
          }
        } else {
          state.status = "failed";
          state.error = action.payload.message;
          state.childUpdated = false;
        }
      })
      .addCase(updateChildNotification.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        // state.userNotifications = null;
        state.childUpdated = false;
      })
      .addCase(deleteChildNotification.pending, (state, action) => {
        state.status = "loading";
        state.childDeleted = false;
      })
      .addCase(deleteChildNotification.fulfilled, (state, action) => {
        if (action.payload.status === true) {
          state.status = "succeeded";
          state.childDeleted = true;
        } else {
          state.status = "failed";
          state.error = action.payload.message;
          state.childDeleted = false;
        }
      })
      .addCase(deleteChildNotification.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.childDeleted = false;
      })
      .addCase(sendChildNotification.pending, (state, action) => {
        state.status = "loading";
        state.notificationSend = false;
      })
      .addCase(sendChildNotification.fulfilled, (state, action) => {
        if (action.payload.status === true) {
          if (action.payload.data != null) {
            state.status = "succeeded";
            // state.userNotifications = action.payload.data;
            state.notificationSend = true;
          } else {
            state.status = "failed";
            state.error = "No records found";
            // state.userNotifications = null;
            state.notificationSend = false;
          }
        } else {
          state.status = "failed";
          state.error = action.payload.message;
          // state.userNotifications = null;
          state.notificationSend = false;
        }
      })
      .addCase(sendChildNotification.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        // state.userNotifications = null;
        state.notificationSend = false;
      });
  },
});

export const {
  resetState,
  resetStateNM,
  resetStateAppNoti,
  resetStateUserNoti,
  resetStateAddNewNotificationData,
} = notificationSlice.actions;

export default notificationSlice.reducer;
