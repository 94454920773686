import React, { useState, useEffect, useRef } from "react";
import "./HomeCSS/SetAchievementDetail.css";
import MoveBackIcon from "../../../assets/svgs/LeftArrowIcon";
import {
  createAward,
  deleteAward,
  updateAward,
} from "../../../api/actions/profileActions";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "../../../utils/LoadingSpinner";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Document, Page, pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const AddUpdateAchievementDetail = ({
  award,
  onSave,
  goBackToProfilePage,
  isAwardsEditable,
}) => {
  const dispatch = useDispatch();
  const { status, error } = useSelector((state) => state.profileSlice);

  const [isViewAward, setIsViewAward] = useState(isAwardsEditable);
  const [isCancelEdit, setIsCancelEdit] = useState(false);
  const [text, setText] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [temText, setTemText] = useState("");
  const [temImage, setTemImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  const [isEditingAwardsEnable, setIsEditingAwardsEnable] = useState(
    !isAwardsEditable
  );
  const [numPages, setNumPages] = useState(null);

  useEffect(() => {
    if (isAwardsEditable && award) {
      setText(award?.text || "");
      setTemText(award?.text || "");
      setImagePreview(award?.image || "");
      setTemImage(award?.image || "");
    }
  }, [isAwardsEditable, award, award?.text, award?.image]);

  const isPDFUrl = (url) => {
    return url.endsWith(".pdf");
  };

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];

    if (!selectedImage) return; // No file selected, return

    if (
      !selectedImage.type.includes("image/") &&
      selectedImage.type !== "application/pdf"
    ) {
      alert("Only PDF and image files are allowed.");
      e.target.value = null;
      return;
    }
    // console.log(selectedImage);
    // if (selectedImage) {
    setImageFile(selectedImage);
    setImagePreview(URL.createObjectURL(selectedImage));
    // }
  };

  const fileInputRef = useRef(null);

  const handleAddFileClick = () => {
    fileInputRef.current.click();
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const saveAward = () => {
    if (text === "") {
      alert("Title is required");
      return;
    } else if (!imageFile && !award?.image) {
      alert("Please add award image");
      return;
    }

    const dataToSend = {
      text: text?.trim(),
      files: imageFile,
    };

    if (isEditingAwardsEnable && isCancelEdit) {
      dataToSend._id = award?._id;
      dispatch(updateAward(dataToSend));
    } else {
      dispatch(createAward(dataToSend));
      goBackToProfilePage();
    }
  };

  const deleteAwardAPI = () => {
    onSave();
    dispatch(deleteAward(award?._id));
  };

  const editAward = () => {
    setIsViewAward(false);
    setIsEditingAwardsEnable(true);
    setIsCancelEdit(true);
    if (imagePreview === null) {
      setImagePreview(temImage && temImage);
    }
  };

  const cancelEdit = () => {
    let changes = false;
    if (text !== "" && text !== award?.text) {
      changes = true;
    }
    if (award?.image !== imagePreview) {
      changes = true;
    }
    if (changes === true) {
      var userConfirmation = window.confirm(
        "Some changes you made may not be saved.\nDo you want to skip changes?"
      );
      if (userConfirmation) {
        setImagePreview(award?.image);
        setText(award?.text);
        setIsViewAward(true);
        setIsEditingAwardsEnable(false);
      }
    } else {
      setIsViewAward(true);
      setIsEditingAwardsEnable(false);
    }
  };

  const getFileType = (url) => {
    const extension = url.toLowerCase().split(".").pop();
    if (extension === "pdf") {
      return "pdf";
    } else {
      return "unknown";
    }
  };

  return (
    <>
      {status === "loading" && <LoadingSpinner />}
      <div className="addAchievementDetail">
        <div className="header">
          <div className="moveback" onClick={goBackToProfilePage}>
            <MoveBackIcon />
          </div>
          {/* <span className="respspan">Achievements</span> */}
          {!isEditingAwardsEnable && (
            <button
              type="submit"
              className="saveButton btn"
              onClick={editAward}
            >
              Edit
            </button>
          )}
          {isEditingAwardsEnable && (
            <div className="addFilebtn btn" onClick={handleAddFileClick}>
              <input
                ref={fileInputRef}
                type="file"
                id="imageInput"
                accept=".pdf,image/*"
                style={{ display: "none" }}
                onChange={handleImageChange}
              />
              Add
            </div>
          )}
          {isEditingAwardsEnable && (
            <button
              type="submit"
              className="saveButton btn"
              onClick={saveAward}
            >
              {isEditingAwardsEnable && isCancelEdit ? "Update" : "Save"}
            </button>
          )}
        </div>
        {isEditingAwardsEnable && isCancelEdit && (
          <div className="buttons">
            <button
              type="submit"
              className="saveButton btn"
              onClick={cancelEdit}
            >
              Cancel
            </button>
            <button className="deleteButton btn" onClick={deleteAwardAPI}>
              Delete
            </button>
          </div>
        )}
        <div className="getAchievementBox">
          <div className="titleSection">
            {isViewAward && <div className="title">{text}</div>}
            {isEditingAwardsEnable && (
              <input
                type="text"
                placeholder="Title"
                value={text}
                onChange={(e) => setText(e.target.value)}
                autoFocus
              />
            )}
          </div>
          {isEditingAwardsEnable ? (
            <>
              <label htmlFor="FileInput">
                <div
                  className="imageBox"
                  style={{
                    overflowY:
                      imageFile.type === "application/pdf" ? "auto" : "",
                  }}
                >
                  {(imageFile && imageFile.type === "application/pdf") ||
                  (imagePreview && award && imagePreview.endsWith(".pdf")) ? (
                    <>
                      <Document
                        file={imagePreview}
                        onLoadSuccess={onDocumentLoadSuccess}
                      >
                        {Array.from(new Array(numPages), (el, index) => (
                          <Page
                            key={`page_${index + 1}`}
                            pageNumber={index + 1}
                            className="pdf-page"
                          />
                        ))}
                      </Document>
                      {/* <embed src={imagePreview} className="forPdfView" /> */}
                    </>
                  ) : (
                    <>
                      {imagePreview && (
                        <TransformWrapper
                          defaultScale={1}
                          defaultPositionX={100}
                          defaultPositionY={100}
                          wheel={{ step: 10 }}
                          options={{ limitToBounds: false }}
                          pan={{ disabled: true }}
                        >
                          {/* {({ zoomIn, zoomOut, ...rest }) => (
                          <React.Fragment> */}
                          <TransformComponent>
                            <img src={imagePreview} alt="Image" />
                          </TransformComponent>
                          {/* <div
                              style={{
                                position: "absolute",
                                top: "10px",
                                left: "10px",
                                color: "white",
                                zIndex: 9999,
                              }}
                            >
                              <button onClick={() => zoomIn(zoomRatio)}>
                                Zoom In
                              </button>
                              <button onClick={() => zoomOut(zoomRatio)}>
                                Zoom Out
                              </button>
                            </div> */}
                          {/* </React.Fragment> */}
                          {/* )} */}
                        </TransformWrapper>
                      )}
                    </>
                  )}
                </div>
              </label>
            </>
          ) : (
            <label htmlFor="FileInput">
              {imagePreview && award && imagePreview.endsWith(".pdf") ? (
                <>
                  <div
                    className="imageBox"
                    style={{
                      overflowY: "auto",
                    }}
                  >
                    <Document
                      file={imagePreview}
                      onLoadSuccess={onDocumentLoadSuccess}
                    >
                      {Array.from(new Array(numPages), (el, index) => (
                        <Page
                          key={`page_${index + 1}`}
                          pageNumber={index + 1}
                          className="pdf-page"
                        />
                      ))}
                    </Document>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="imageBox"
                    style={{
                      overflowY: "auto",
                    }}
                  >
                    {imagePreview && (
                      <TransformWrapper
                        defaultScale={1}
                        defaultPositionX={100}
                        defaultPositionY={100}
                        wheel={{ step: 10 }}
                        options={{ limitToBounds: false }}
                        pan={{ disabled: true }}
                      >
                        {/* {({ zoomIn, zoomOut, ...rest }) => (
                          <React.Fragment> */}
                        <TransformComponent>
                          <img src={imagePreview} alt="Image" />
                        </TransformComponent>
                        {/* <div
                              style={{
                                position: "absolute",
                                top: "10px",
                                left: "10px",
                                color: "white",
                                zIndex: 9999,
                              }}
                            >
                              <button onClick={() => zoomIn(zoomRatio)}>
                                Zoom In
                              </button>
                              <button onClick={() => zoomOut(zoomRatio)}>
                                Zoom Out
                              </button>
                            </div> */}
                        {/* </React.Fragment> */}
                        {/* )} */}
                      </TransformWrapper>
                    )}
                  </div>
                </>
              )}
            </label>
          )}
        </div>
      </div>
    </>
  );
};

export default AddUpdateAchievementDetail;
