import React, { useState, useEffect } from "react";
import "./HomeCSS/reportAnIssue.css";
import "./HomeCSS/contactus.css";
import { createContactUs } from "../../../api/actions/homeActions";
import { useDispatch, useSelector } from "react-redux";
import { resetState } from "../../../api/reducerSlices/homeSlice";
import { EventFailedPopup as PopupForMsg } from "../../popup-boxes/main-page-Popup's/mainPageEventPopup";
import LoadingSpinner from "../../../utils/LoadingSpinner";

const ContactUs = () => {
  const dispatch = useDispatch();
  const { status, error, contactReasonCreated } = useSelector(
    (state) => state.homeSlice
  );

  const [subject, setSubject] = useState("");
  const [email, setEmail] = useState("");
  const [detail, setDetail] = useState("");
  const [subjectError, setSubjectError] = useState("");
  const [detailError, setDetailError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupMsg, setShowPopupMsg] = useState(false);

  useEffect(() => {
    if (contactReasonCreated === true) {
      setSubject("");
      setDetail("");
      setEmail("");
      setShowPopupMsg("Thanks, We will reach back soon");
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
        setShowPopupMsg("");
        dispatch(resetState());
      }, 3000);
    }
    if (error && error !== "") {
      setShowPopupMsg(error);
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
        setShowPopupMsg("");
        dispatch(resetState());
      }, 3000);
    }
  }, [error, contactReasonCreated]);

  const validateEmail = (email) => {
    // Regular expression for email validation, enhanced with stricter checks
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!email) {
      setEmailError("Email is required");
    } else {
      setSubjectError("");
    }

    if (!validateEmail(email)) {
      setEmailError("Valid email is required");
    } else {
      setSubjectError("");
    }

    if (!subject) {
      setSubjectError("Subject is required");
    } else {
      setSubjectError("");
    }

    if (!detail) {
      setDetailError("Description is required");
    } else {
      setDetailError("");
    }
    if (subject && validateEmail(email) && detail) {
      let contactUsObject = {
        subject: subject,
        detail: detail,
        email: email,
      };
      dispatch(createContactUs(contactUsObject));
    }
  };

  const handleSubjectChange = (event) => {
    setSubject(event.target.value);
    setSubjectError(""); // Clear subject error when typing
  };

  const handleDetailChange = (event) => {
    setDetail(event.target.value);
    setDetailError(""); // Clear issue error when typing
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setEmailError(""); // Clear issue error when typing
  };
  const closePopup = () => {
    setShowPopup(false);
    setShowPopupMsg("");
  };

  return (
    <>
      {status === "loading" && <LoadingSpinner />}
      <div className="containerfRAI cntuscomp">
        {showPopup && (
          <PopupForMsg showPopupMsg={showPopupMsg} onClose={closePopup} />
        )}
        <div className="centerContent">
          <div className="headerLine">
            <span>Contact Us</span>
          </div>
          <form className="cfsais" onSubmit={handleSubmit}>
            <label htmlFor="subject">
              Email
              <input
                type="text"
                id="email"
                name="email"
                value={email}
                onChange={handleEmailChange}
              />
              {emailError && <div className="error">{emailError}</div>}
            </label>
            <label htmlFor="subject">
              Subject
              <input
                type="text"
                id="subject"
                name="subject"
                value={subject}
                onChange={handleSubjectChange}
              />
              {subjectError && <div className="error">{subjectError}</div>}
            </label>
            <label htmlFor="issue">
              Description
              <textarea
                id="issue"
                name="issue"
                value={detail}
                onChange={handleDetailChange}
              />
              {detailError && <div className="error">{detailError}</div>}
            </label>
            <input type="submit" value="Submit" />
          </form>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
