import L from "leaflet";
import { resetChatState } from "../api/reducerSlices/chatSlice";
import { resetStateOnLogout } from "../api/reducerSlices/homeSlice";
import { resetStateNM } from "../api/reducerSlices/notificationSlice";
import { resetState as resetProfileState } from "../api/reducerSlices/profileSlice";
import { resetPropertyState } from "../api/reducerSlices/propertySlice";
import { resetState as resetSubscriptionsState } from "../api/reducerSlices/subscriptionsPlansSlice";

export const setToken = (token) => {
  localStorage.setItem("userToken", token);
  localStorage.setItem("newcontact", false);
  localStorage.setItem("tupoa", false);
};

export const getToken = () => {
  const userToken = localStorage.getItem("userToken");
  if (userToken !== null && userToken !== undefined) {
    return localStorage.getItem("userToken");
  }
};

export const deleteToken = () => {
  localStorage.removeItem("token");
};

// this is when user logout
export const resetAll = (dispatch) => {
  dispatch(resetChatState());
  dispatch(resetStateOnLogout());
  dispatch(resetStateNM());
  dispatch(resetProfileState());
  dispatch(resetPropertyState());
  dispatch(resetSubscriptionsState());
  localStorage.setItem("loginStatusfpr", false);
  localStorage.removeItem("contactCount");
  localStorage.removeItem("contactId");
  localStorage.removeItem("contactCount");
  localStorage.removeItem("neContactSaved");
  localStorage.removeItem("subsTileOpen");
  localStorage.setItem("termsOrPolicyStatus", false);
  localStorage.removeItem("termsOrPolicy");
  localStorage.removeItem("CatalogData");
  localStorage.setItem("setIsViewCatalog", false);
  localStorage.setItem("setIsEditingCatalog", false);
  localStorage.setItem("tupoa", false);
};

// this when user leave the page without logout or may be user move on next tab and not cameback for 5 minutes
export const resetAllForNotInPageWhile = () => {
  localStorage.removeItem("contactCount");
  localStorage.removeItem("contactId");
  localStorage.removeItem("contactCount");
  localStorage.removeItem("neContactSaved");
  localStorage.removeItem("subsTileOpen");
  localStorage.setItem("termsOrPolicyStatus", false);
  localStorage.removeItem("termsOrPolicy");
  localStorage.removeItem("CatalogData");
  localStorage.setItem("setIsViewCatalog", false);
  localStorage.setItem("setIsEditingCatalog", false);
  window.location.reload();
};

export const PLACES_API_KEY = "AIzaSyBkApPTZadLep72U-qNjSQQDRhZOQ5S34Y";

export const getCityNameFromCurrentLocation = async (position) => {
  console.log(position);
  // console.log("heloo for api " + JSON.stringify(position));
  const latitude = position?.coords?.latitude;
  const longitude = position?.coords?.longitude;

  // const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${"AIzaSyDXRCYo4MLfJ-LOwpkaA3Mg9Ya17GLDqus"}`;
  const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${PLACES_API_KEY}&language=en`;

  // console.log("heloo for api 123" + url);

  try {
    const response = await fetch(url).then((r) => r.json());
    const city = response.results[0].address_components.find((component) =>
      component.types.includes("locality")
    ).long_name;
    // console.log(`Your city is ${city}.`);
    return city;
  } catch (error) {
    console.log("error: ", error);
  }
  return null;
};

export const getMapIcon = (url) => {
  return L.icon({
    iconUrl: url,
    iconSize: [40, 40],
    iconAnchor: [12, 12],
    popupAnchor: [0, -10],
  });
};

export function TimeFormat(timeToParse) {
  var date = new Date();
  date.toLocaleTimeString();
  date.setMinutes(date.getMinutes() + parseInt(timeToParse));
  var hours = date.getHours();
  hours = hours % 12;
  hours = hours ? hours : 12;
  var minutes = date.getMinutes();
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var amPm = hours >= 12 ? "AM" : "PM";
  var strTime = hours + ":" + minutes + " " + amPm;
  return strTime;
}

export function convertISOToLocalFormat(timestamp) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const date = new Date(timestamp);
  // Adjust the date using the timezone offset
  date.setHours(date.getHours());
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  const formattedDate = `${day} ${month}`;
  return `${formattedDate}`;
}

export function convertISOToLocalFormat2(timestamp) {
  const date = new Date(timestamp);
  // Adjust the date using the timezone offset
  date.setHours(date.getHours());
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  const formattedDate = `${year}-${month}-${day}`;
  return `${formattedDate}`;
}

export function convertISOToLocalFormatV2(timestamp) {
  const messageDate = new Date(timestamp);
  const currentDate = new Date();

  // Check if the message was sent today
  if (
    messageDate.getDate() === currentDate.getDate() &&
    messageDate.getMonth() === currentDate.getMonth() &&
    messageDate.getFullYear() === currentDate.getFullYear()
  ) {
    // Format time HH:MM AM/PM
    const hours = messageDate.getHours();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12; // Convert 0 to 12 for 12-hour format
    const minutes = messageDate.getMinutes().toString().padStart(2, "0");
    return `${formattedHours}:${minutes} ${ampm}`;
  }

  // Check if the message was sent yesterday
  const yesterday = new Date(currentDate);
  yesterday.setDate(currentDate.getDate() - 1);
  if (
    messageDate.getDate() === yesterday.getDate() &&
    messageDate.getMonth() === yesterday.getMonth() &&
    messageDate.getFullYear() === yesterday.getFullYear()
  ) {
    return "Yesterday";
  }

  // Otherwise, return the date in the format "DD Mon"
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const day = messageDate.getDate();
  const month = months[messageDate.getMonth()];
  return `${day} ${month}`;
}
