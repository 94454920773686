import { createAsyncThunk } from "@reduxjs/toolkit";
import { getToken } from "../../utils/CommonUtils";

const config = {
  method: "POST", // *GET, POST, PUT, DELETE, etc.
  cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
  headers: {
    "Content-Type": "application/json",
    "Cross-Origin-Embedder-Policy": false,
    Authorization: "Bearer ",
  },
  redirect: "follow", // manual, *follow, error
  referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
};

export const getAppNotifications = createAsyncThunk(
  "appNotification",
  async (object, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/notification`,
        // "https://api.realtorganizer.com/notification",
        {
          ...config,
          method: "GET",
          headers: { ...config.headers, Authorization: "Bearer " + getToken() },
        }
      );
      return response.json(); // parses JSON response into native JavaScript objects
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getUserNotifications = createAsyncThunk(
  "userNotification",
  async (object, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/user-notification/user`,
        // "https://api.realtorganizer.com/user-notification/user",
        {
          ...config,
          method: "GET",
          headers: { ...config.headers, Authorization: "Bearer " + getToken() },
        }
      );
      return response.json(); // parses JSON response into native JavaScript objects
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const addNotification = createAsyncThunk(
  "addNotification",
  async (object, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/user-notification`,
        // `https://api.realtorganizer.com/user-notification`,
        {
          ...config,
          headers: { ...config.headers, Authorization: "Bearer " + getToken() },
          body: JSON.stringify(object),
        }
      );
      return response.json(); // parses JSON response into native JavaScript objects
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateNotification = createAsyncThunk(
  "updateNotification",
  async (notification, { rejectWithValue }) => {
    try {
      const { _id } = notification;
      delete notification._id;

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/user-notification/${_id}`,
        // `https://api.realtorganizer.com/user-notification/${_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + getToken(),
          },
          body: JSON.stringify(notification),
        }
      );

      if (!response.ok) {
        // Handle non-successful responses
        const errorMessage = await response.text();
        throw new Error(errorMessage || response.statusText);
      }

      return response.json(); // parses JSON response into native JavaScript objects
    } catch (error) {
      // Return custom error message from backend if present
      return rejectWithValue(error.message);
    }
  }
);

export const deleteNotification = createAsyncThunk(
  "notification/delete",
  async (notificationId, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/user-notification/${notificationId}`,
        // `https://api.realtorganizer.com/user-notification/${notificationId}`,
        {
          ...config,
          method: "DELETE",
          headers: { ...config.headers, Authorization: "Bearer " + getToken() },
        }
      );
      if (!response.ok) {
        // Throw error if response is not successful
        throw new Error("Failed to delete notification");
      }
      return notificationId; // Return the deleted notification id
    } catch (error) {
      // Handle errors and return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const createChildNotification = createAsyncThunk(
  "createChildNotification",
  async (object, { rejectWithValue }) => {
    try {
      const { _id } = object;
      delete object._id;
      // console.log("sending data: " + JSON.stringify(object));
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/user-notification/${_id}/child`,
        // `https://api.realtorganizer.com/user-notification/${_id}/child`,
        {
          ...config,
          headers: { ...config.headers, Authorization: "Bearer " + getToken() },
          body: JSON.stringify(object),
        }
      );
      return response.json(); // parses JSON response into native JavaScript objects
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateChildNotification = createAsyncThunk(
  "updateChildNotification",
  async (object, { rejectWithValue }) => {
    try {
      const { parent_id } = object;
      const { child_id } = object;
      delete object.parent_id;
      delete object.child_id;
      // console.log("sending data: " + JSON.stringify(object));
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/user-notification/${parent_id}/child/${child_id}`,
        // `https://api.realtorganizer.com/user-notification/${parent_id}/child/${child_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + getToken(),
          },
          body: JSON.stringify(object),
        }
      );
      return response.json(); // parses JSON response into native JavaScript objects
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteChildNotification = createAsyncThunk(
  "deleteChildNotification",
  async (object, { rejectWithValue }) => {
    try {
      const { parent_id } = object;
      const { child_id } = object;

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/user-notification/${parent_id}/child/${child_id}`,
        // `https://api.realtorganizer.com/user-notification/${parent_id}/child/${child_id}`,
        {
          ...config,
          method: "DELETE",
          headers: {
            ...config.headers,
            Authorization: "Bearer " + getToken(),
          },
        }
      );
      return response.json(); // parses JSON response into native JavaScript objects
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const sendChildNotification = createAsyncThunk(
  "sendChildNotification",
  async (object, { rejectWithValue }) => {
    try {
      const { parent_id } = object;
      const { child_id } = object;
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/user-notification/${parent_id}/child/send/${child_id}`,
        // `https://api.realtorganizer.com/user-notification/${parent_id}/child/send/${child_id}`,
        {
          ...config,
          method: "GET",
          headers: { ...config.headers, Authorization: "Bearer " + getToken() },
        }
      );
      return response.json(); // parses JSON response into native JavaScript objects
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
