import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "../auth/Css/defaultSignSignUpcss.css";
import "../auth/Css/opt.css";
import Artboard_icon from "../../assets/images/Artboard.png";
import { APP_ROUTES } from "../../utils/AppConstants";
import { useSelector } from "react-redux";
import { verifyOtp, resendOTP } from "../../api/actions/authActions";
import { useDispatch } from "react-redux";
import LoadingSpinner from "../../utils/LoadingSpinner";
import { resetAfterOTP } from "../../api/reducerSlices/authSlice";

const OTPScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { state } = useLocation();
  // console.log(state);

  const [optValues, setOptValues] = useState({
    opt1: "",
    opt2: "",
    opt3: "",
    opt4: "",
    opt5: "",
  });

  const [OTP, setOTP] = useState("");

  const { status, error, otpVerified } = useSelector((state) => state.auth);

  useEffect(() => {
    if (otpVerified === true) {
      if (state.navigateToRoute === APP_ROUTES.RESET_PASSWORD_ROUTE) {
        navigate(state.navigateToRoute, {
          state: { userOtp: OTP, userEmail: state.userEmail },
        });
      } else {
        alert("Now, you can login!");
        dispatch(resetAfterOTP());
        navigate(state.navigateToRoute);
      }
    } else if (status === "failed" && error !== "") {
      alert(error);
      setOptValues({
        opt1: "",
        opt2: "",
        opt3: "",
        opt4: "",
        opt5: "",
      });
    }
  }, [
    status,
    OTP,
    error,
    navigate,
    otpVerified,
    state.navigateToRoute,
    state.userEmail,
  ]);

  const handleInputChange = (e, fieldName) => {
    const { value } = e.target;

    setOptValues((prevValues) => ({
      ...prevValues,
      [fieldName]: value,
    }));

    if (value.length === 1) {
      const nextFieldName = getNextFieldName(fieldName);
      const nextInput = document.getElementsByName(nextFieldName)[0];
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  const resendOtp = () => {
    const dataToSend = {
      email: state.userEmail,
    };
    console.log(dataToSend);
    dispatch(resendOTP(dataToSend));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let otp = "";
    if (Object.values(optValues).every((value) => value.length === 1)) {
      Object.values(optValues).forEach((element) => {
        otp += element;
      });

      setOTP(otp);
      const dataToSend = {
        email: state.userEmail,
        otp: otp,
      };
      // console.log(dataToSend);
      dispatch(verifyOtp(dataToSend));
    } else {
      // Display an error message or take appropriate action
      alert("Please fill in all fields.");
    }
  };

  const getNextFieldName = (currentFieldName) => {
    const fieldOrder = ["opt1", "opt2", "opt3", "opt4", "opt5"];
    const currentIndex = fieldOrder.indexOf(currentFieldName);
    return currentIndex < fieldOrder.length - 1
      ? fieldOrder[currentIndex + 1]
      : null;
  };

  return (
    <>
      {status === "loading" && <LoadingSpinner />}
      <div className="container">
        <div className="artboard">
          <div className="artboardPic">
            <img src={Artboard_icon} alt="" />
          </div>
          <span>
            <strong>REALTOR</strong> ORGANIZER
          </span>
        </div>
        <div className="box bx">
          <form onSubmit={handleSubmit}>
            <div className="input-group">
              {["opt1", "opt2", "opt3", "opt4", "opt5"].map(
                (fieldName, index) => (
                  <input
                    key={fieldName}
                    type="text"
                    name={fieldName}
                    maxLength="1"
                    value={optValues[fieldName]}
                    onChange={(e) => handleInputChange(e, fieldName)}
                    autoFocus={index === 0}
                  />
                )
              )}
            </div>
            <button
              className="btn-nbg"
              onClick={(e) => {
                e.preventDefault();
                resendOtp();
              }}
            >
              Resend code
            </button>
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
    </>
  );
};

export default OTPScreen;
