import { createAsyncThunk } from "@reduxjs/toolkit";
import { getToken } from "../../utils/CommonUtils";
import axios from "axios";

const config = {
  method: "POST", // *GET, POST, PUT, DELETE, etc.
  cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
  headers: {
    "Content-Type": "application/json",
    "Cross-Origin-Embedder-Policy": false,
    // "Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InRlc3QxQGdtYWlsLmNvbSIsInJvbGUiOiJyZWFsdG9yIiwiaWQiOiI2NTVjMmJiODMwNTU2OWRjYmVmOTM4MzAiLCJpYXQiOjE3MDczMDEzMDMsImV4cCI6MTczODgzNzMwM30.ki56OzG10bYOxk2VBPNgfakWBaTV7IfQItmisWY7wps"
    Authorization: "Bearer ",
  },
  redirect: "follow", // manual, *follow, error
  referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
};

const config2 = {
  method: "POST", // *GET, POST, PUT, DELETE, etc.
  cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
  headers: {
    "Content-Type": "multipart/form-data",
    "Cross-Origin-Embedder-Policy": false,
    Authorization: "Bearer ",
  },
  redirect: "follow", // manual, *follow, error
  referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
};

export const getUserProfile = createAsyncThunk(
  "getUserProfile",
  async (object, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/user`,
        // "https://api.realtorganizer.com/user",
        {
          ...config,
          method: "GET",
          headers: { ...config.headers, Authorization: "Bearer " + getToken() },
        }
      );
      return response.json(); // parses JSON response into native JavaScript objects
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getOtherUserProfile = createAsyncThunk(
  "getOtherUserProfile",
  async (userId, { rejectWithValue }) => {
    // console.log("hello otherUserProfileId : " + userId);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/user?id=${userId}`,
        // `https://api.realtorganizer.com/user?id=${userId}`,
        {
          ...config,
          method: "GET",
          headers: { ...config.headers, Authorization: "Bearer " + getToken() },
        }
      );
      return response.json(); // parses JSON response into native JavaScript objects
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateUserProfile = createAsyncThunk(
  "updateUserProfile",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user`, {
        // const response = await fetch(`https://api.realtorganizer.com/user`, {
        ...config,
        method: "PUT",
        headers: { ...config.headers, Authorization: "Bearer " + getToken() },
        body: JSON.stringify(payload),
      });
      return response.json(); // parses JSON response into native JavaScript objects
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateUserProfileImage = createAsyncThunk(
  "updateUserProfileImage",
  async (payload, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      // console.log("new image" + JSON.stringify(payload));
      formData.append("files", payload?.files);

      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/user/image`,
        // `https://api.realtorganizer.com/user/image`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + getToken(),
          },
        }
      );
      return response.data;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const createCatalog = createAsyncThunk(
  "createCatalog",
  async (payload, { rejectWithValue }) => {
    try {
      // console.log("Testing Detele Cat: ", payload?.files[0])

      const formData = new FormData();
      formData.append("title", payload?.title);
      formData.append("description", payload?.description);
      formData.append("isPublic", payload?.isPublic);
      payload?.files?.forEach((file) => {
        formData.append("files", file);
      });

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/catalog`,
        // `https://api.realtorganizer.com/catalog`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + getToken(),
          },
        }
      );

      return response.data;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateCatalog = createAsyncThunk(
  "updateCatalog",
  async (payload, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append("title", payload?.title);
      formData.append("description", payload?.description);
      formData.append("isPublic", payload?.isPublic);

      payload?.files?.forEach((file) => {
        formData.append("files", file);
      });

      if (payload.hasOwnProperty("deleteImages")) {
        formData.append("deleteImages", payload?.deleteImages);
      }
      // console.log("Awards data: " + JSON.stringify(payload));
      // console.log("formData data: " + formData);
      // console.log("Testing updating Catalog: ", payload)

      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/catalog/${payload?._id}`,
        // `https://api.realtorganizer.com/catalog/${payload?._id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + getToken(),
          },
        }
      );

      return response.data;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteCatalog = createAsyncThunk(
  "deleteCatalog",
  async (catalogId, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/catalog/${catalogId}`,
        // `https://api.realtorganizer.com/catalog/${catalogId}`,
        {
          ...config,
          method: "DELETE",
          headers: { ...config.headers, Authorization: "Bearer " + getToken() },
        }
      );
      return response.json(); // parses JSON response into native JavaScript objects
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteCatalogImage = createAsyncThunk(
  "deleteCatalogImage",
  async (catalogId, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/catalog/${catalogId}`,
        // `https://api.realtorganizer.com/catalog/${catalogId}`,
        {
          ...config,
          method: "DELETE",
          headers: { ...config.headers, Authorization: "Bearer " + getToken() },
        }
      );
      return response.json(); // parses JSON response into native JavaScript objects
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const createAward = createAsyncThunk(
  "createAward",
  async (payload, { rejectWithValue }) => {
    try {
      console.log("Testing creating Awards: ", payload);

      const formData = new FormData();
      formData.append("text", payload?.text);
      formData.append("files", payload?.files);

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/awards`,
        // `https://api.realtorganizer.com/awards`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + getToken(),
          },
        }
      );

      return response.data;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateAward = createAsyncThunk(
  "updateAward",
  async (payload, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append("text", payload?.text);

      if (payload.hasOwnProperty("files")) {
        formData.append("files", payload?.files);
      }
      // console.log("Testing updating Awards: ", payload)

      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/awards/${payload?._id}`,
        // `https://api.realtorganizer.com/awards/${payload?._id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + getToken(),
          },
        }
      );

      return response.data;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteAward = createAsyncThunk(
  "deleteAward",
  async (awardId, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/awards/${awardId}`,
        // `https://api.realtorganizer.com/awards/${awardId}`,
        {
          ...config,
          method: "DELETE",
          headers: { ...config.headers, Authorization: "Bearer " + getToken() },
        }
      );
      return response.json(); // parses JSON response into native JavaScript objects
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const createReview = createAsyncThunk(
  "createReview ",
  async (reviewData, { rejectWithValue }) => {
    try {
      // console.log("data" + JSON.stringify(reviewData));
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/review`,
        // `https://api.realtorganizer.com/review`,
        {
          ...config,
          method: "POST",
          headers: { ...config.headers, Authorization: "Bearer " + getToken() },
          body: JSON.stringify(reviewData),
        }
      );
      return response.json(); // parses JSON response into native JavaScript objects
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const sharingCatalog = createAsyncThunk(
  "sharingCatalog",
  async (data, { rejectWithValue }) => {
    try {
      const { catalogId } = data;
      delete data.catalogId;
      console.log("id" + catalogId);
      // console.log("data" + JSON.stringify(data));
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/catalog/share/${catalogId}`,
        // `https://api.realtorganizer.com/catalog/share/${catalogId}`,
        {
          ...config,
          method: "POST",
          headers: { ...config.headers, Authorization: "Bearer " + getToken() },
          body: JSON.stringify(data),
        }
      );
      return response.json(); // parses JSON response into native JavaScript objects
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const imageOrderForCatalog = createAsyncThunk(
  "imageOrderForCatalog",
  async (orderList, { rejectWithValue }) => {
    try {
      const catalogId = orderList?.catalogId;
      // console.log("id" + catalogId);
      delete orderList.catalogId;
      // console.log("data" + JSON.stringify(orderList));
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/catalog/image-order/${catalogId}`,
        // `https://api.realtorganizer.com/catalog/image-order/${catalogId}`,
        {
          ...config,
          method: "PUT",
          headers: { ...config.headers, Authorization: "Bearer " + getToken() },
          body: JSON.stringify(orderList),
        }
      );
      return response.json(); // parses JSON response into native JavaScript objects
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
