import React, { useState, useEffect } from "react";
import SearchIcon from "../../../assets/svgs/SearchIcon";
import GetPropertyDetail from "./GetPropertyDetail";
import MoveBackIcon from "../../../assets/svgs/LeftArrowIcon";
import {
  getUserProperties,
  getPropertyImage,
} from "../../../api/actions/propertyActions";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "../../../utils/LoadingSpinner";
import {
  resetState,
  resetPropertyDetail,
} from "../../../api/reducerSlices/propertySlice";
// import { json } from "react-router-dom";
import {
  EventSuccessPopup,
  EventFailedPopup,
} from "../../popup-boxes/main-page-Popup's/mainPageEventPopup";
require("./HomeCSS/property.css");
require("./HomeCSS/searchbar.css");

const Property = () => {
  const dispatch = useDispatch();
  const { status, error, properties, propertyImage, propertyCardDeleted } =
    useSelector((state) => state.propertySlice);

  const [isAddClicked, setIsAddClicked] = useState(false);
  const [isPropertyItem, setIsPropertyItem] = useState(false);
  const [propertyDetail, setPropertyDetail] = useState({});
  const [isPropertyReport, setIsPropertyReport] = useState(false);
  const [propertyImages, setPropertyImages] = useState([]);
  const [cardNumberTC, setCardNumberTC] = useState(null);
  const [filteredTiles, setFilteredTiles] = useState([]);
  const [searchQuerysfp, setSearchQuerysfp] = useState("");
  const [isSignalForSearch, setIsSignalForSearch] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupMsg, setShowPopupMsg] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  useEffect(() => {
    // dispatch(resetState());
    dispatch(getUserProperties());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getUserProperties());
  }, []);

  useEffect(() => {
    if (propertyCardDeleted === true) {
      setShowPopupMsg("Property deleted!");
      setShowSuccessPopup(true);
      setTimeout(() => {
        setShowSuccessPopup(false);
        setShowPopupMsg("");
      }, 3000);
    }
    if (error && error !== "") {
      setShowPopupMsg(error);
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
        setShowPopupMsg("");
      }, 3000);
    }
  }, [propertyCardDeleted]);

  // useEffect(() => {
  //   const fetchPropertyDataAndImages = async () => {
  //     try {
  //       const propertyDataPromises = properties.map((property) =>
  //         dispatch(getPropertyImage(property?._id))
  //       );
  //       const propertyImageResponses = await Promise.all(propertyDataPromises);

  //       const updatedPropertyImages = propertyImageResponses.map(
  //         (response, index) => {
  //           const cardNumber = response.payload.data?._id;
  //           let image = "";
  //           // Check if PHOTOS array exists and is not empty
  //           if (
  //             response.payload.data.PHOTOS &&
  //             response.payload.data.PHOTOS.length > 0
  //           ) {
  //             // Iterate through each item in the PHOTOS array
  //             console.log(response.payload.data.PHOTOS);
  //             for (let photo of response.payload.data.PHOTOS) {
  //               // Check if the orderNo of the current photo is 0
  //               if (photo.orderNo === 0) {
  //                 // Save the image of the photo with orderNo 0
  //                 image = photo?.image;
  //                 // Exit the loop since we found the desired photo
  //                 break;
  //               }
  //             }
  //           }
  //           return { cardNumber, image };
  //         }
  //       );
  //       setPropertyImages(updatedPropertyImages);
  //       setIsSignalForSearch(true);
  //     } catch (error) {
  //       console.error("Error fetching property data and images:", error);
  //     }
  //   };

  //   if (properties && properties.length > 0) {
  //     fetchPropertyDataAndImages();
  //   }
  // }, [properties]);

  useEffect(() => {
    const fetchPropertyDataAndImages = async () => {
      try {
        const propertyDataPromises = properties.map((property) =>
          dispatch(getPropertyImage(property?._id))
        );
        const propertyImageResponses = await Promise.all(propertyDataPromises);

        const updatedPropertyImages = propertyImageResponses.map((response) => {
          const cardNumber = response.payload.data?._id;
          let image = "";
          // Check if PHOTOS array exists and is not empty
          if (
            response.payload.data.PHOTOS &&
            response.payload.data.PHOTOS.length > 0
          ) {
            // Iterate through each order number
            for (
              let orderNo = 0;
              orderNo < response.payload.data.PHOTOS.length;
              orderNo++
            ) {
              // Iterate through each photo of the property
              for (let i = 0; i < response.payload.data.PHOTOS.length; i++) {
                const photo = response.payload.data.PHOTOS[i];
                // Check if the orderNo of the current photo matches the desired order number
                if (photo.orderNo === orderNo) {
                  // Save the image of the photo with the matching orderNo
                  image = photo?.image;
                  // Exit the inner loop since we found the desired photo
                  break;
                }
              }
              // If image is found, exit the outer loop as well
              if (image) break;
            }
          }
          return { cardNumber, image };
        });
        setPropertyImages(updatedPropertyImages);
        setIsSignalForSearch(true);
      } catch (error) {
        console.error("Error fetching property data and images:", error);
      }
    };

    if (properties && properties.length > 0) {
      fetchPropertyDataAndImages();
    }
  }, [properties]);

  const handleAddPropertyCard = () => {
    dispatch(resetState());
    setIsAddClicked(true);
  };

  const propertyItemClicked = (object) => {
    setPropertyDetail(object);
    setIsPropertyItem(true);
    setCardNumberTC(object._id);
  };

  const goBackToPropertyPage = () => {
    if (isAddClicked === true) {
      setIsAddClicked(false);
    } else if (isPropertyItem === true) {
      dispatch(resetPropertyDetail());
      setIsPropertyItem(false);
      dispatch(getPropertyImage(propertyDetail._id))
        .then((updatedPropertyImages) => {
          if (
            updatedPropertyImages.payload.data.PHOTOS &&
            updatedPropertyImages.payload.data.PHOTOS.length > 0
          ) {
            setPropertyImages((prevImages) => {
              return prevImages.map((tile) => {
                if (tile.cardNumber === cardNumberTC) {
                  const firstPhotoWithOrderNoZero =
                    updatedPropertyImages.payload.data.PHOTOS.find(
                      (photo) => photo.orderNo === 0
                    );
                  if (firstPhotoWithOrderNoZero) {
                    return {
                      ...tile,
                      image: firstPhotoWithOrderNoZero.image,
                    };
                  }
                }
                return tile;
              });
            });
          } else {
            // If image is not available, update with empty string
            setPropertyImages((prevImages) => {
              return prevImages.map((tile) => {
                if (tile.cardNumber === cardNumberTC) {
                  return {
                    ...tile,
                    image: "",
                  };
                }
                return tile;
              });
            });
          }
        })
        .catch((error) => {
          console.error("Error fetching property image:", error);
          // Handle error appropriately
        });
    }

    dispatch(getUserProperties());
  };

  const handleSearchInputChange = (event) => {
    setSearchQuerysfp(event.target.value);
  };

  useEffect(() => {
    setFilteredTiles(properties);
  }, [properties]);

  useEffect(() => {
    if (isSignalForSearch) {
      const filtered = properties?.filter(
        (tile) =>
          tile?.communityArea
            .toLowerCase()
            .includes(searchQuerysfp.toLowerCase().trim()) ||
          tile?.propertyNumber
            .toLowerCase()
            .includes(searchQuerysfp.toLowerCase().trim())
      );
      setFilteredTiles(filtered?.length > 0 ? filtered : properties);
    }
  }, [searchQuerysfp, properties, isSignalForSearch]);

  const closePopup = () => {
    setShowPopup(false);
    setShowPopupMsg("");
  };

  const closeSuccessPopup = () => {
    setShowSuccessPopup(false);
    setShowPopupMsg("");
  };

  const clickOnLoadingCard = (passingString) => {
    setShowPopupMsg(passingString);
    setShowPopup(true);
    setTimeout(() => {
      setShowPopup(false);
      setShowPopupMsg("");
    }, 3000);
  };

  return (
    <>
      {showSuccessPopup && (
        <EventSuccessPopup
          showPopupMsg={showPopupMsg}
          onClose={closeSuccessPopup}
        />
      )}
      {showPopup && (
        <EventFailedPopup showPopupMsg={showPopupMsg} onClose={closePopup} />
      )}
      {properties === 0 && <LoadingSpinner />}
      <div className="propertyMain">
        {!isPropertyReport ? (
          <div className="header">
            {isAddClicked || isPropertyItem ? (
              <div className="moveback" onClick={goBackToPropertyPage}>
                <MoveBackIcon />
              </div>
            ) : (
              ""
            )}
            {/* <span>Property Detail</span> */}
          </div>
        ) : null}

        <>
          {isAddClicked || isPropertyItem ? (
            <GetPropertyDetail
              onPropertyDeleted={goBackToPropertyPage}
              propertyBasicInfo={isAddClicked ? {} : propertyDetail}
              isPropertySnaggingInspectionVisible={(isPSIVisible) => {
                setIsPropertyReport(isPSIVisible);
              }}
            />
          ) : (
            <>
              <form role="search" className="searchBarBox pstky">
                <div className="searchIcon">
                  <SearchIcon />
                </div>
                <input
                  id="search"
                  type="search"
                  placeholder="Search..."
                  value={searchQuerysfp}
                  onChange={handleSearchInputChange}
                />
              </form>
              <div className="btnLine">
                <button className="btnAdd" onClick={handleAddPropertyCard}>
                  + ADD
                </button>
              </div>
              <div className="propertyCardsTile">
                {filteredTiles && filteredTiles.length > 0
                  ? filteredTiles?.map((object, index) => (
                      <>
                        <div
                          className="propertyCard"
                          key={object._id}
                          onClick={() => {
                            if (
                              propertyImages[index]?.image ||
                              propertyImages[index]?.image === ""
                            ) {
                              propertyItemClicked(object);
                            } else {
                              clickOnLoadingCard(
                                "Wait card Data is loading..."
                              );
                            }
                          }}
                          style={{
                            backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${propertyImages[index]?.image})`,
                          }}
                        >
                          {!propertyImages[index]?.image &&
                          propertyImages[index]?.image !== "" ? (
                            <div className="loaderst">
                              <div className="circle"></div>
                            </div>
                          ) : (
                            <div className="detailsTag">
                              <div>
                                <span className="reqInfo">Community Area</span>
                                <div className="info">
                                  {object?.communityArea}
                                </div>
                              </div>
                              <div>
                                <span className="reqInfo">Property Number</span>
                                <div className="info">
                                  {object?.propertyNumber}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    ))
                  : // <p
                    //   style={{
                    //     width: "100%",
                    //     display: "flex",
                    //     justifyContent: "center",
                    //     padding: "15px 0 0 0 ",
                    //     fontSize: "1rem",
                    //     wordBreak: "break-all",
                    //     color: "#494949",
                    //   }}
                    // >
                    //   {filteredTiles &&
                    //   filteredTiles &&
                    //   searchQuerysfp.length === 0
                    //     ? "Loading..."
                    //     : "Nothing to caught."}
                    // </p>
                    ""}
              </div>
            </>
          )}
        </>
      </div>
    </>
  );
};

export default Property;
