import React, { useState, useEffect } from "react";
import SearchIcon from "../../../assets/svgs/SearchIcon";
import IncomingNotifications from "./IncomingNotifications";
import OutgoingNotifications from "./OutgoingNotifications";
import { useSelector } from "react-redux";
// import { resetState } from "../../../api/reducerSlices/notificationSlice";
import LoadingSpinner from "../../../utils/LoadingSpinner";
// import Realtor from "./Realtor";
require("./HomeCSS/notifications.css");
require("./HomeCSS/searchbar.css");

const Notifications = () => {
  const { status } = useSelector((state) => state.homeSlice);
  const { user } = useSelector((state) => state.auth);
  const [showFirstBox, setShowFirstBox] = useState(true);
  const [showSecondBox, setShowSecondBox] = useState(
    localStorage.getItem("showSecondBox") === "true" || false
  );
  const [searchBarDisplay, setSearchBarDisplay] = useState(true);
  const [searchBarDisplayForsacr, setSearchBarDisplayForsacr] = useState(true);
  const [searchQuerysfp, setSearchQuerysfp] = useState("");
  useEffect(() => {
    if (localStorage.getItem("showSecondBox") === "true") {
      setShowFirstBox(false);
      setShowSecondBox(true);
    }
  }, []);

  const handleShowFirstBox = () => {
    setShowFirstBox(true);
    setShowSecondBox(false);
    localStorage.setItem("showSecondBox", false);
  };

  const handleShowSecondBox = () => {
    setShowFirstBox(false);
    setShowSecondBox(true);
    localStorage.setItem("showSecondBox", true);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1000) {
        setSearchBarDisplay(true);
      } else {
        if (window.innerWidth <= 1000 && showSecondBox === true) {
          setSearchBarDisplay(true);
        } else if (window.innerWidth <= 1000 && showSecondBox === false) {
          setSearchBarDisplay(false);
        }
      }
    };
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [showSecondBox]);

  const handleSearchInputChange = (event) => {
    setSearchQuerysfp(event.target.value);
  };

  const searchBarHide = () => {
    setSearchBarDisplayForsacr(false);
  };

  const searchBarShow = () => {
    setSearchBarDisplayForsacr(true);
  };

  return (
    <>
      {status === "loading" && <LoadingSpinner />}
      <div className="notificationsMain">
        <div className="pstky">
          {user.role === "realtor" && (
            <div className="notificationsBoxButtons">
              <button
                onClick={handleShowFirstBox}
                className={`${showFirstBox ? "active" : "non-active"}`}
              >
                Notifications
              </button>
              <button
                onClick={handleShowSecondBox}
                className={`${showSecondBox ? "active" : "non-active"}`}
              >
                Create Notification
              </button>
            </div>
          )}
          {searchBarDisplay &&
            searchBarDisplayForsacr &&
            user?.role === "realtor" && (
              <form role="search" className="searchBarBox notsb">
                <div className="searchIcon">
                  <SearchIcon />
                </div>
                <input
                  id="search"
                  type="search"
                  placeholder="Search..."
                  value={searchQuerysfp}
                  onChange={handleSearchInputChange}
                />
              </form>
            )}
        </div>
        {user.role !== "realtor" && (
          <div
            className={`incomingNotification ${showFirstBox ? "show" : "hide"}`}
          >
            <IncomingNotifications />
          </div>
        )}
        {user.role === "realtor" && (
          <div className="notificationsBox">
            <div
              className={`incomingNotification ${
                showFirstBox ? "show" : "hide"
              }`}
            >
              <IncomingNotifications />
            </div>
            <div
              className={`outgoingNotifications ${
                showSecondBox ? "show" : "hide"
              }`}
            >
              <OutgoingNotifications
                searchQuerysfp={searchQuerysfp}
                forSearchBarHide={searchBarHide}
                forSearchBarShow={searchBarShow}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Notifications;
