import React, { useEffect, useState } from "react";
import "../Css/defaultSignSignUpcss.css";
import "../Css/userbox.css";
import { Link, useNavigate } from "react-router-dom";
import Artboard_icon from "../../../assets/images/Artboard.png";
import ShowPasswordIcon from "../../../assets/svgs/ShowPasswordIcon";
import HidePasswordIcon from "../../../assets/svgs/HidePasswordIcon";
import { APP_ROUTES } from "../../../utils/AppConstants";
import { useSelector, useDispatch } from "react-redux";
import { signinUser, resendOTP } from "../../../api/actions/authActions";
import LoadingSpinner from "../../../utils/LoadingSpinner";
import { LoginEventPopup } from "../../popup-boxes/auth-Popup's/authEventPopup";
import {
  resetAfterSignUp,
  resetAfterOTP,
} from "../../../api/reducerSlices/authSlice";
import { logoutUser } from "../../../api/reducerSlices/authSlice";
import { resetAll } from "../../../utils/CommonUtils";

const clearBrowserCacheAndLocalStorage = () => {
  // Clear browser cache
  if ("caches" in window) {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  }

  // Clear local storage
  localStorage.clear();
};

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);

  const { status, error, authenticated, user } = useSelector(
    (state) => state.auth
  );

  const [showPopup, setShowPopup] = useState(false);
  const [showPopupMsg, setShowPopupMsg] = useState(false);

  const resendOtp = () => {
    const dataToSend = {
      email: formData.email,
    };
    console.log(dataToSend);
    dispatch(resendOTP(dataToSend));
  };

  useEffect(() => {
    //   // selectors to access state
    if (authenticated === true && user?.isEmailVerified === true) {
      console.log(user);
      navigate(APP_ROUTES.HOME_ROUTE);
    }

    if (status === "failed") {
      if (error === "Email is not verified") {
        setShowPopupMsg(
          "Email is not verified so please verify first. We sent otp on your registered email!"
        );
      } else {
        setShowPopupMsg(error);
      }
      setShowPopup(true);
    }

    if (status === "failed" && error === "Email is not verified") {
      // alert("Please verify first!");
      setTimeout(() => {
        dispatch(resetAfterSignUp());
        dispatch(resetAfterOTP());
        resendOtp();
        navigate(APP_ROUTES.VERIFY_OTP_ROUTE, {
          state: {
            navigateToRoute: APP_ROUTES.SIGN_IN_ROUTE,
            userEmail: formData.email,
          },
        });
      }, 3000);
    }
  }, [status, authenticated, navigate, error, user?.isEmailVerified]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
    // Clear the corresponding error when the user starts typing
    setErrors({
      ...errors,
      [e.target.id]: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basic validation
    const newErrors = {};
    if (!formData.email.trim()) {
      newErrors.email = "Please enter your email address";
    }
    if (!formData.password.trim()) {
      newErrors.password = "Please enter your password";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const dataToSendForLogin = {
      email: formData.email.trim(),
      password: formData.password.trim(),
    };

    dispatch(signinUser(dataToSendForLogin));
  };

  const closePopup = () => {
    setShowPopup(false);
    setShowPopupMsg("");
  };

  //////////////////

  const ifUserWantLogout = () => {
    resetAll(dispatch);
    dispatch(logoutUser());
    localStorage.setItem("activeTab", APP_ROUTES.Realtor_ROUTE);
    localStorage.setItem("isActiveTab", "realtorTab");
  };

  useEffect(() => {
    const currentUrl = window.location.href;
    console.log("Current URL:", currentUrl); // Log the current URL
    if (
      (currentUrl.includes("/signin") &&
        localStorage.getItem("loginStatusfpr") === "true") ||
      localStorage.getItem("loginStatusfpr") === true
    ) {
      console.log("URL contains /signin. Logging out...");
      dispatch(resetAfterSignUp());
      clearBrowserCacheAndLocalStorage();
      ifUserWantLogout();
    } else {
      console.log("URL does not contain /signin.");
    }
  }, [dispatch]);

  // Perform URL check first
  if (
    (window.location.href.includes("/signin") &&
      localStorage.getItem("loginStatusfpr") === "true") ||
    localStorage.getItem("loginStatusfpr") === true
  ) {
    console.log("URL contains /signin. Logging out...");
    dispatch(resetAfterSignUp());
    clearBrowserCacheAndLocalStorage();
    ifUserWantLogout();
  }

  return (
    <>
      {status === "loading" && <LoadingSpinner />}
      {showPopup && (
        <LoginEventPopup showPopupMsg={showPopupMsg} onClose={closePopup} />
      )}
      <div className="container">
        <div className="artboard">
          <div className="artboardPic">
            <img src={Artboard_icon} alt="" />
          </div>
          <span>
            <strong>REALTOR</strong> ORGANIZER
          </span>
        </div>
        <div className="box">
          <form onSubmit={handleSubmit}>
            <span>
              <div className="hide">Welcome to</div>
              <span className="space">Login</span>
            </span>
            <p>Enter Your Credentials And Get Ready To Explore!</p>
            <div className="input-group fc">
              <label htmlFor="email">Email Address:</label>
              <input
                type="email"
                id="email"
                placeholder="example@gmail.com"
                value={formData.email}
                onChange={handleChange}
                autoFocus
              />
              {errors.email && <span className="error">{errors.email}</span>}
            </div>
            <div className="input-group sc pos-rel">
              <label htmlFor="password">Password:</label>
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                placeholder="**************"
                value={formData.password}
                onChange={handleChange}
              />
              <button
                type="button"
                className="password-toggle btn"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <ShowPasswordIcon /> : <HidePasswordIcon />}
              </button>
              {errors.password && (
                <span className="error">{errors.password}</span>
              )}
            </div>
            <div className="input-group">
              <Link to={APP_ROUTES.FORGOT_PASSWORD_ROUTE}>
                Forgot Password?
              </Link>
            </div>
            <button type="submit" disabled={status === "loading"}>
              {status === "loading" ? "Logging in..." : "LOGIN"}
            </button>
          </form>
          <p>
            Don't have an account?
            <Link to={APP_ROUTES.SIGN_UP_ROUTE} className="signUp">
              Sign Up
            </Link>
          </p>
        </div>
      </div>
    </>
  );
};

export default Login;
