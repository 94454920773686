import React, { useEffect, useState, useRef } from "react";
import Gallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { Document, Page, pdfjs } from "react-pdf";

import FileIcon from "../../assets/iconImages/DefaultForFile.png";
import MoveBackIcon from "../../assets/svgs/LeftArrowIcon";
import { useDispatch, useSelector } from "react-redux";
import { getPropertyDetail } from "../../api/actions/chatActions";
import LoadingSpinner from "../../utils/LoadingSpinner";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { truncate } from "lodash";
require("../home/HomeComponent/HomeCSS/PropertyDetail.css");
require("./ChatsCSS/propertySharedView.css");

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PROPERTY_ENUMS = Object.freeze({
  Description: "Description",
  OWNERSHIP_DOCS: "OWNERSHIP_DOCS",
  PHOTOS: "PHOTOS",
  FLOOR_PLANS: "FLOOR_PLANS",
  TENANCY_DETAILS: "TENANCY_DETAILS",
  SNAGGING: "SNAGGING",
});

const PropertySharedView = ({
  goBackToProfilePage,
  getSharedViewId,
  proopertySections,
}) => {
  const dispatch = useDispatch();
  const { status, propertyDetailForView, propertyDetaiGettingStatus } =
    useSelector((state) => state.chatSlice);

  const [description, setDescription] = useState("");
  // Separate state arrays for each section
  const [docsOwnershipDocs, setDocsOwnershipDocs] = useState([]);
  const [docsPhotos, setDocsPhotos] = useState([]);
  const [docsFloorPlans, setDocsfloorPlans] = useState([]);
  const [docsTenancyDetails, setDocsTenancyDetails] = useState([]);
  const [docsSnagging, setDocsSnagging] = useState([]);
  // Separate state arrays for each section
  // handle cards
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    let object = {
      perpertyId: getSharedViewId,
      sections: proopertySections,
    };
    dispatch(getPropertyDetail(object));
  }, []);

  useEffect(() => {
    // console.log(propertyDetailForView);
    if (proopertySections.includes(PROPERTY_ENUMS.Description)) {
      setDescription(propertyDetailForView?.description);
    }
    if (proopertySections.includes(PROPERTY_ENUMS.OWNERSHIP_DOCS)) {
      setDocsOwnershipDocs(propertyDetailForView?.OWNERSHIP_DOCS);
    }
    if (proopertySections.includes(PROPERTY_ENUMS.PHOTOS)) {
      setDocsPhotos(propertyDetailForView?.PHOTOS);
    }
    if (proopertySections.includes(PROPERTY_ENUMS.FLOOR_PLANS)) {
      setDocsfloorPlans(propertyDetailForView?.FLOOR_PLANS);
    }
    if (proopertySections.includes(PROPERTY_ENUMS.TENANCY_DETAILS)) {
      setDocsTenancyDetails(propertyDetailForView?.TENANCY_DETAILS);
    }
    if (proopertySections.includes(PROPERTY_ENUMS.SNAGGING)) {
      setDocsSnagging(propertyDetailForView?.SNAGGING);
    }
  }, [propertyDetailForView, proopertySections]);

  const divRef = useRef(null);
  useEffect(() => {
    // Calculate the height of the div based on its content
    if (divRef.current) {
      const height = divRef.current.scrollHeight;
      // Optionally, add some padding to the height if needed
      divRef.current.style.height = height + "px";
    }
  }, [description]);

  useEffect(() => {
    const resizeHandler = () => {
      if (divRef.current) {
        const height = divRef.current.scrollHeight;
        // Optionally, add some padding to the height if needed
        divRef.current.style.height = height + "px";
      }
    };

    // Call the resize handler initially and on resize
    window.addEventListener("resize", resizeHandler);
    resizeHandler();

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, [description]);

  // check if url is image
  //   const [isImage, setIsImage] = useState(null);
  //   const checkimage = (urlvalidate) => {
  //     const img = new Image();
  //     img.src = urlvalidate;

  //     img.onload = () => {
  //       setIsImage(true);
  //     };

  //     img.onerror = () => {
  //       setIsImage(false);
  //     };
  //   };

  const checkIfImage = (url) => {
    const imageExtensions = /\.(jpg|jpeg|png|gif|bmp|avif|webp)$/i;
    return imageExtensions.test(url);
  };

  const getFileType = (url) => {
    const extension = url.toLowerCase().split(".").pop();
    if (extension === "pdf") {
      return "pdf";
    } else if (extension === "doc" || extension === "docx") {
      return "word";
    } else {
      return "unknown";
    }
  };

  //   // check if data is url or in object
  //   const isValidUrlFormat = (input) => {
  //     // Regular expression to check if the input has a valid URL format
  //     const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
  //     return urlRegex.test(input);
  //   };

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
  };

  const closeImageSlider = () => {
    setSelectedImageIndex(null);
  };

  const galleryImages = docsPhotos?.map((image) => ({
    original: image.image,
    thumbnail: image.image,
  }));

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleDownload = (url) => {
    // Create a temporary anchor element
    const link = document.createElement("a");
    link.href = url;
    // Extract the file name from the URL
    const fileName = url.substring(url.lastIndexOf("/") + 1);
    link.download = fileName;
    // Simulate click on the anchor to trigger download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      {status === "loading" && propertyDetaiGettingStatus && <LoadingSpinner />}
      <div className="sharedViewOverlay">
        <div className="propertySharedView">
          <div className="headerLine">
            <div className="movebackc" onClick={goBackToProfilePage}>
              <MoveBackIcon />
            </div>
            <span>Property</span>
          </div>

          {/* description box */}
          {proopertySections.includes(PROPERTY_ENUMS.Description) && (
            <div className="descriptionBox infoBox">
              <div className="infoBoxHeader">
                <h2>Description:</h2>
              </div>
              <pre ref={divRef} className="notes-textarea">
                {description}
              </pre>
            </div>
          )}
          {/* Ownership Documents */}
          {proopertySections.includes(PROPERTY_ENUMS.OWNERSHIP_DOCS) && (
            <div className="OwnershipDocs infoBox">
              <div className="infoBoxHeader">
                <h2>Ownership Documents:</h2>
              </div>
              <div className="OwnershipDocsCardsBox scrbrd">
                {docsOwnershipDocs?.map((doc, index) => (
                  <div className="OwnershipDocsCardAdd" key={index}>
                    <div className="Docstitle span">{doc.title}</div>
                    {getFileType(doc?.image) === "pdf" && (
                      <button
                        className="downloadPDFBtn"
                        onClick={() => handleDownload(doc.image)}
                      >
                        Download pdf
                      </button>
                    )}
                    <div
                      className="docsPic"
                      style={{
                        overflowY:
                          getFileType(doc?.image) === "pdf" ? "auto" : "hidden",
                      }}
                    >
                      {doc?.image && getFileType(doc?.image) === "pdf" && (
                        <>
                          <Document
                            file={doc?.image}
                            onLoadSuccess={onDocumentLoadSuccess}
                            onLoadError={(error) => setError(true)}
                          >
                            {Array.from(new Array(numPages), (el, index1) => (
                              <Page
                                key={`page_${index}_${index1 + 1}`}
                                pageNumber={index1 + 1}
                                className="pdf-page"
                              />
                            ))}
                          </Document>
                          {error && <div>Failed to loading PDF</div>}
                        </>
                      )}
                      {doc?.image &&
                        getFileType(doc?.image) !== "pdf" &&
                        checkIfImage(doc?.image) && (
                          <TransformWrapper
                            defaultScale={1}
                            defaultPositionX={100}
                            defaultPositionY={100}
                            wheel={{ step: 10 }}
                            options={{ limitToBounds: false }}
                            pan={{ disabled: true }}
                          >
                            <TransformComponent>
                              <img src={doc.image} alt="Image" />
                            </TransformComponent>
                          </TransformWrapper>
                        )}
                      {doc?.image &&
                        getFileType(doc?.image) !== "pdf" &&
                        !checkIfImage(doc?.image) && (
                          <img src={FileIcon} alt="File" />
                        )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          {/* Photos */}
          {proopertySections.includes(PROPERTY_ENUMS.PHOTOS) && (
            <div className="photos infoBox">
              <div className="infoBoxHeader">
                <h2>Photos:</h2>
              </div>
              <div className="photosTile scrbrd">
                {docsPhotos?.map((doc, index) => (
                  <div
                    className="photosCard"
                    key={index}
                    onClick={() => handleImageClick(index)}
                  >
                    {doc.image && (
                      <div className="photoTilePic" key={index}>
                        <img src={doc.image} alt={doc.title} />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
          {/* FLoor Plans: */}
          {proopertySections.includes(PROPERTY_ENUMS.FLOOR_PLANS) && (
            <div className="floorPlans infoBox">
              <div className="infoBoxHeader">
                <h2>FLoor Plans:</h2>
              </div>
              <div className="floorPlansTile scrbrd">
                {docsFloorPlans?.map((doc, index) => (
                  <div className="floorPlansTileCard" key={index}>
                    <div className="floorPlansTileCardTitle span">
                      {doc.title}
                    </div>
                    {getFileType(doc?.image) === "pdf" && (
                      <button
                        className="downloadPDFBtn"
                        onClick={() => handleDownload(doc.image)}
                      >
                        Download pdf
                      </button>
                    )}
                    {doc.image && (
                      <div
                        className="floorPlansTileCardPic"
                        style={{
                          overflowY:
                            getFileType(doc?.image) === "pdf"
                              ? "auto"
                              : "hidden",
                        }}
                      >
                        {doc?.image && getFileType(doc?.image) === "pdf" && (
                          <>
                            <Document
                              file={doc?.image}
                              onLoadSuccess={onDocumentLoadSuccess}
                              onLoadError={(error) => setError(true)}
                            >
                              {Array.from(new Array(numPages), (el, index1) => (
                                <Page
                                  key={`page_${index}_${index1 + 1}`}
                                  pageNumber={index1 + 1}
                                  className="pdf-page"
                                />
                              ))}
                            </Document>
                            {error && <div>Failed to loading PDF</div>}
                          </>
                        )}
                        {doc?.image &&
                          getFileType(doc?.image) !== "pdf" &&
                          checkIfImage(doc?.image) && (
                            <TransformWrapper
                              defaultScale={1}
                              defaultPositionX={100}
                              defaultPositionY={100}
                              wheel={{ step: 10 }}
                              options={{ limitToBounds: false }}
                              pan={{ disabled: true }}
                            >
                              <TransformComponent>
                                <img src={doc.image} alt="Image" />
                              </TransformComponent>
                            </TransformWrapper>
                          )}
                        {doc?.image &&
                          getFileType(doc?.image) !== "pdf" &&
                          !checkIfImage(doc?.image) && (
                            <img src={FileIcon} alt="File" />
                          )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
          {/* Tenancy Details */}
          {proopertySections.includes(PROPERTY_ENUMS.TENANCY_DETAILS) && (
            <div className="TenancyDetails infoBox">
              <div className="infoBoxHeader">
                <h2>Tenancy Details:</h2>
              </div>
              <div className="TenancyDetailsTile scrbrd">
                {docsTenancyDetails?.map((doc, index) => (
                  <div className="TenancyDetailsTileCard" key={index}>
                    <div className="TenancyDetailsTileCardTitle span">
                      {doc.title}
                    </div>
                    {getFileType(doc?.image) === "pdf" && (
                      <button
                        className="downloadPDFBtn"
                        onClick={() => handleDownload(doc.image)}
                      >
                        Download pdf
                      </button>
                    )}
                    {doc.image && (
                      <div
                        className="TenancyDetailsTileCardPic"
                        style={{
                          overflowY:
                            getFileType(doc?.image) === "pdf"
                              ? "auto"
                              : "hidden",
                        }}
                      >
                        {doc?.image && getFileType(doc?.image) === "pdf" && (
                          <>
                            <Document
                              file={doc?.image}
                              onLoadSuccess={onDocumentLoadSuccess}
                              onLoadError={(error) => setError(true)}
                            >
                              {Array.from(new Array(numPages), (el, index1) => (
                                <Page
                                  key={`page_${index}_${index1 + 1}`}
                                  pageNumber={index1 + 1}
                                  className="pdf-page"
                                />
                              ))}
                            </Document>
                            {error && <div>Failed to loading PDF</div>}
                          </>
                        )}
                        {doc?.image &&
                          getFileType(doc?.image) !== "pdf" &&
                          checkIfImage(doc?.image) && (
                            <TransformWrapper
                              defaultScale={1}
                              defaultPositionX={100}
                              defaultPositionY={100}
                              wheel={{ step: 10 }}
                              options={{ limitToBounds: false }}
                              pan={{ disabled: true }}
                            >
                              <TransformComponent>
                                <img src={doc.image} alt="Image" />
                              </TransformComponent>
                            </TransformWrapper>
                          )}
                        {doc?.image &&
                          getFileType(doc?.image) !== "pdf" &&
                          !checkIfImage(doc?.image) && (
                            <img src={FileIcon} alt="File" />
                          )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
          {/* SNAGGING */}
          {proopertySections.includes(PROPERTY_ENUMS.SNAGGING) && (
            <div className="snagging infoBox">
              <div className="infoBoxHeader">
                <h2>Snagging:</h2>
              </div>
              <div className="snaggingBoxHeader">
                <div className="snaggingTile scrbrd">
                  {docsSnagging?.map((doc, index) => (
                    <div className="snaggingTileCard" key={index}>
                      <div className="snaggingCardTitle span">{doc.title}</div>
                      <div className="snaggingTileCardPic">
                        {doc.image && (
                          <div className="snaggingTileCardPic" key={index}>
                            <TransformWrapper
                              defaultScale={1}
                              defaultPositionX={100}
                              defaultPositionY={100}
                              wheel={{ step: 10 }}
                              options={{ limitToBounds: false }}
                              pan={{ disabled: true }}
                            >
                              <TransformComponent>
                                <img src={doc.image} alt="Image" />
                              </TransformComponent>
                            </TransformWrapper>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          {selectedImageIndex !== null && (
            <div className="image-slider">
              <Gallery
                items={galleryImages}
                startIndex={selectedImageIndex}
                onRequestClose={closeImageSlider}
              />
              <div className="close-slider" onClick={closeImageSlider}>
                Close
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PropertySharedView;
