import { createAsyncThunk } from "@reduxjs/toolkit";
import { getToken } from "../../utils/CommonUtils";
import axios from "axios";

const config = {
  method: "POST", // *GET, POST, PUT, DELETE, etc.
  cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
  headers: {
    "Content-Type": "application/json",
    "Cross-Origin-Embedder-Policy": false,
    // "Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InRlc3QxQGdtYWlsLmNvbSIsInJvbGUiOiJyZWFsdG9yIiwiaWQiOiI2NTVjMmJiODMwNTU2OWRjYmVmOTM4MzAiLCJpYXQiOjE3MDczMDEzMDMsImV4cCI6MTczODgzNzMwM30.ki56OzG10bYOxk2VBPNgfakWBaTV7IfQItmisWY7wps"
    Authorization: "Bearer ",
  },
  redirect: "follow", // manual, *follow, error
  referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
};

const config2 = {
  method: "POST", // *GET, POST, PUT, DELETE, etc.
  cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
  headers: {
    // "Content-Type": "multipart/form-data",
    "Cross-Origin-Embedder-Policy": false,
    Authorization: "Bearer ",
  },
  redirect: "follow", // manual, *follow, error
  referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
};

export const getSubscriptionPlans = createAsyncThunk(
  "subscriptionPlans",
  async (object, { rejectWithValue }) => {
    // console.log("subscriptionplans: helloooooooo");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/subscription/plan`,
        // `https://api.realtorganizer.com/subscription/plan`,
        {
          ...config,
          method: "GET",
          headers: { ...config.headers, Authorization: "Bearer " + getToken() },
        }
      );
      // console.log("subscriptionplans: " + response.json());
      return response.json(); // parses JSON response into native JavaScript objects
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const startSubscription = createAsyncThunk(
  "startSubscription",
  async (id, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/subscription/start`,
        // `https://api.realtorganizer.com/subscription/start`,
        {
          ...config,
          headers: { ...config.headers, Authorization: "Bearer " + getToken() },
          body: JSON.stringify(id),
        }
      );
      return response.json(); // parses JSON response into native JavaScript objects
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const successSubscription = createAsyncThunk(
  "successSubscription",
  async (id, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/subscription/success`,
        // `https://api.realtorganizer.com/subscription/success`,
        {
          ...config,
          method: "PUT",
          headers: { ...config.headers, Authorization: "Bearer " + getToken() },
          body: JSON.stringify(id),
        }
      );
      return response.json(); // parses JSON response into native JavaScript objects
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getSubscriptionDetail = createAsyncThunk(
  "subscriptionDetail",
  async (id, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/subscription/${id}/users`,
        // `https://api.realtorganizer.com/subscription/${id}/users`,
        {
          ...config,
          method: "GET",
          headers: { ...config.headers, Authorization: "Bearer " + getToken() },
        }
      );
      // console.log("subscriptionplans: " + response.json());
      return response.json(); // parses JSON response into native JavaScript objects
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const addUser = createAsyncThunk(
  "addUser",
  async (data, { rejectWithValue }) => {
    try {
      const { id } = data;
      delete data.id;
      // console.log(" property data sharing: " + JSON.stringify(data));
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/subscription/${id}/user`,
        // `https://api.realtorganizer.com/subscription/${id}/user`,
        {
          ...config,
          method: "POST",
          headers: { ...config.headers, Authorization: "Bearer " + getToken() },
          body: JSON.stringify(data),
        }
      );
      return response.json(); // parses JSON response into native JavaScript objects
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const removeUser = createAsyncThunk(
  "removeUser",
  async (object, { rejectWithValue }) => {
    try {
      const { subsId } = object;
      const { userId } = object;

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/subscription/${subsId}/user/${userId}`,
        // `https://api.realtorganizer.com/subscription/${subsId}/user/${userId}`,
        {
          ...config,
          method: "DELETE",
          headers: { ...config.headers, Authorization: "Bearer " + getToken() },
        }
      );
      return response.json(); // parses JSON response into native JavaScript objects
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
