import { createSlice } from "@reduxjs/toolkit";
import {
  getSubscriptionPlans,
  startSubscription,
  successSubscription,
  getSubscriptionDetail,
  addUser,
  removeUser,
} from "../actions/subscriptionsPlansActions";

const initialState = {
  status: "idle",
  error: "",
  subscriptionPlans: [],
  startSubscriptionURL: null,
  subscriptionDetail: null,
  userAdded: false,
  userRemoved: false,
  subscriptionSuccessStatus: false,
};

const subscriptionsPlansSlice = createSlice({
  name: "subscriptionsPlansSlice",
  initialState,
  reducers: {
    resetState: (state) => {
      state.status = "";
      state.error = "";
      state.subscriptionPlans = [];
      state.startSubscriptionURL = null;
      state.subscriptionDetail = null;
      state.subscriptionSuccessStatus = false;
      state.userAdded = false;
      state.userRemoved = false;
    },
    resetError: (state) => {
      state.status = "idle";
      state.error = "";
    },
    resetURL: (state) => {
      state.startSubscriptionURL = null;
    },
    resetSubscriptionSuccessStatus: (state) => {
      state.subscriptionSuccessStatus = false;
    },
    resetUserAddedStatus: (state) => {
      state.userAdded = false;
    },
    resetUserRemovedStatus: (state) => {
      state.userRemoved = false;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getSubscriptionPlans.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getSubscriptionPlans.fulfilled, (state, action) => {
        if (action.payload.status === true) {
          if (action.payload.data.length > 0) {
            state.status = "succeeded";
            state.subscriptionPlans = action.payload.data;
            // console.log("subscriptionsPlansSlice: ", action.payload.data);
          } else {
            state.status = "failed";
            state.error = "No data found";
            state.subscriptionPlans = null;
          }
        } else {
          state.status = "failed";
          state.error = action.payload.message;
          state.subscriptionPlans = null;
        }
      })
      .addCase(getSubscriptionPlans.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.subscriptionPlans = null;
      })
      .addCase(startSubscription.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(startSubscription.fulfilled, (state, action) => {
        if (action.payload.status === true) {
          state.status = "succeeded";
          state.startSubscriptionURL = action.payload.data;
          // console.log("createProperty: ", action.payload.data)
        } else {
          state.status = "failed";
          state.error = action.payload.message;
          state.startSubscriptionURL = null;
        }
      })
      .addCase(successSubscription.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(successSubscription.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.subscriptionDetail = action.payload.data;
        state.subscriptionSuccessStatus = true;
        state.subscriptionSuccessStatuschk = true;
      })
      .addCase(successSubscription.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.subscriptionDetail = null;
      })
      .addCase(getSubscriptionDetail.pending, (state, action) => {
        // state.status = "loading";
      })
      .addCase(getSubscriptionDetail.fulfilled, (state, action) => {
        if (action.payload.status === true) {
          if (action.payload.data) {
            state.status = "succeeded";
            state.subscriptionDetail = action.payload.data;
            // console.log("subscriptionsPlansSlice: ", action.payload.data);
          } else {
            state.status = "failed";
            state.error = "No data found";
            state.subscriptionDetail = null;
          }
        } else {
          state.status = "failed";
          state.error = action.payload.message;
          state.subscriptionDetail = null;
        }
      })
      .addCase(addUser.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(addUser.fulfilled, (state, action) => {
        if (action.payload.status === true) {
          state.status = "succeeded";
          state.subscriptionDetail = action.payload.data;
          state.userAdded = true;
          // console.log("user added: ");
        } else {
          state.status = "failed";
          state.error = action.payload.message;
          // state.subscriptionDetail = null;
          // console.log("user added failed");
          state.userAdded = false;
        }
      })
      .addCase(addUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        console.log("user added failed");
        // state.subscriptionDetail = null;
        state.userAdded = false;
      })
      .addCase(removeUser.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(removeUser.fulfilled, (state, action) => {
        if (action.payload.status === true) {
          state.status = "succeeded";
          state.userRemoved = true;
        } else {
          state.status = "failed";
          state.error = action.payload.message;
        }
      })
      .addCase(removeUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.userRemoved = false;
      });
  },
});

export const {
  resetState,
  resetError,
  resetURL,
  resetSubscriptionSuccessStatus,
  resetUserAddedStatus,
  resetUserRemovedStatus,
} = subscriptionsPlansSlice.actions;

export default subscriptionsPlansSlice.reducer;
