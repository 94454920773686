import React from "react";

const YoutubeIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.8333 0H3.16667C1.41667 0 0 1.41667 0 3.16667V16.8333C0 18.5833 1.41667 20 3.16667 20H16.8333C18.5833 20 20 18.5833 20 16.8333V3.16667C20 1.41667 18.5833 0 16.8333 0ZM8.025 13.0167V7.04167C9.75833 8.04167 11.4667 9.025 13.2167 10.0333C11.475 11.0333 9.75833 12.025 8.025 13.025V13.0167Z"
        fill="#3C5688"
      />
    </svg>
  );
};

export default YoutubeIcon;
