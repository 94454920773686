import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Artboard_icon from "../../../assets/images/Artboard.png";
import ShowPasswordIcon from "../../../assets/svgs/ShowPasswordIcon";
import HidePasswordIcon from "../../../assets/svgs/HidePasswordIcon";
import "../Css/defaultSignSignUpcss.css";
import "../Css/signup.css";
import { APP_ROUTES } from "../../../utils/AppConstants";
import { useSelector } from "react-redux";
import { signupUser } from "../../../api/actions/authActions";
import {
  resetAfterSignUp,
  resetAfterOTP,
} from "../../../api/reducerSlices/authSlice";
import { useDispatch } from "react-redux";
import LoadingSpinner from "../../../utils/LoadingSpinner";
import ArrowToMove from "../../../assets/svgs/ArrowToMove";
import {
  RegistrationSuccessPopup,
  RegistrationEventPopup,
} from "../../popup-boxes/auth-Popup's/authEventPopup";

const SignUp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [role, setRole] = useState("");
  const [SUOPDClass, setSUOPDClass] = useState({ display: "flex" });
  const [boxSUDClass, setboxSUDClass] = useState({ display: "none" });
  const [getError, setError] = useState("");
  const [showPassword, setShowPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState("");

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const { status, error, authenticated } = useSelector((state) => state.auth);
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupMsg, setShowPopupMsg] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  useEffect(() => {
    if (authenticated === true) {
      setShowSuccessPopup(true);
      setTimeout(() => {
        setShowSuccessPopup(false);
        dispatch(resetAfterSignUp());
        dispatch(resetAfterOTP());
        navigate(APP_ROUTES.VERIFY_OTP_ROUTE, {
          state: {
            navigateToRoute: APP_ROUTES.SIGN_IN_ROUTE,
            userEmail: formData.email,
          },
        });
      }, 3000);
    }

    if (status === "failed") {
      setShowPopupMsg(error);
      setShowPopup(true);
    }
  }, [status, authenticated, navigate, error]);

  const handleButtonClick = (value) => {
    setRole(value);
    // console.log(value);
    setSUOPDClass({ display: "none" });
    setboxSUDClass({ display: "flex" });
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
    setError("");
  };

  const togglePasswordVisibility = (field) => {
    if (field === "password") {
      setShowPassword(!showPassword);
    } else if (field === "confirmPassword") {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      !formData.name.trim() ||
      !formData.email.trim() ||
      !formData.password.trim() ||
      !formData.confirmPassword.trim()
    ) {
      setError("Please fill in all fields");
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      setError("Password and Confirm Password must match");
      return;
    }

    if (
      formData.password === formData.confirmPassword &&
      formData.password?.length < 4
    ) {
      setError("Password length must be 4 or greater.");
      return;
    }

    const dataToSendForSignup = {
      name: formData.name.trim(),
      email: formData.email.trim(),
      password: formData.password.trim(),
      role: role,
    };

    dispatch(signupUser(dataToSendForSignup));
  };

  const closePopup = () => {
    setShowPopup(false);
    setShowPopupMsg("");
  };

  const closeSuccessPopup = () => {
    setShowSuccessPopup(false);
  };

  return (
    <>
      {status === "loading" && <LoadingSpinner />}
      {showSuccessPopup && (
        <RegistrationSuccessPopup onClose={closeSuccessPopup} />
      )}
      {showPopup && (
        <RegistrationEventPopup
          showPopupMsg={showPopupMsg}
          onClose={closePopup}
        />
      )}
      <div className="container">
        <div className="artboard">
          <div className="artboardPic">
            <img src={Artboard_icon} alt="" />
          </div>
          <span>
            <strong>REALTOR</strong> ORGANIZER
          </span>
        </div>
        <div className="SUOP" style={SUOPDClass}>
          <div className="btnnbox">
            <button onClick={() => handleButtonClick("realtor")}>
              Register as Realtor
            </button>
            <button onClick={() => handleButtonClick("individual")}>
              Register as Individual
            </button>
          </div>
          <p>
            <Link to="/" className="login">
              Login
            </Link>
            <ArrowToMove />
          </p>
        </div>
        <div className="box boxSU boxSUD" style={boxSUDClass}>
          <form onSubmit={handleSubmit} className="formSU">
            <span>
              <div className="hide">Welcome to</div>
              <span className="space">SIGN UP</span>
            </span>
            <p>Enter Your Credentials And Get Ready To Explore!</p>
            <div className="input-group">
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                id="name"
                placeholder="Name"
                value={formData.name}
                onChange={handleChange}
                autoFocus
              />
            </div>
            <div className="input-group">
              <label htmlFor="email">Email Address:</label>
              <input
                type="email"
                id="email"
                placeholder="example@gmail.com"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <div className="input-group pos-rel">
              <label htmlFor="password">Password:</label>
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                placeholder="**************"
                value={formData.password}
                onChange={handleChange}
              />
              <button
                type="button"
                className="password-toggle btn"
                onClick={() => togglePasswordVisibility("password")}
              >
                {showPassword ? <ShowPasswordIcon /> : <HidePasswordIcon />}
              </button>
            </div>
            <div className="input-group pos-rel">
              <label htmlFor="confirmPassword">Confirm Password:</label>
              <input
                type={showConfirmPassword ? "text" : "password"}
                id="confirmPassword"
                placeholder="**************"
                value={formData.confirmPassword}
                onChange={handleChange}
              />
              <button
                type="button"
                className="password-toggle btn"
                onClick={() => togglePasswordVisibility("confirmPassword")}
              >
                {showConfirmPassword ? (
                  <ShowPasswordIcon />
                ) : (
                  <HidePasswordIcon />
                )}
              </button>
            </div>
            {getError && <span className="error">{getError}</span>}
            <button type="submit" disabled={status === "loading"}>
              SIGN UP
              {/* {status === "loading" ? "Loading..." : "SIGN UP"} */}
            </button>
          </form>
          <p>
            Already a Member?
            <Link to={APP_ROUTES.SIGN_IN_ROUTE} className="login">
              Login
            </Link>
          </p>
        </div>
      </div>
    </>
  );
};

export default SignUp;
