import React from "react";

const SearchIcon = () => {
  return (
    <svg
      width="9"
      height="9"
      viewBox="0 0 9 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.90703 8.43555L6.77333 6.41918C8.26837 4.98988 8.31393 2.6955 6.87669 1.21364C5.43944 -0.268229 3.02901 -0.412147 1.40784 0.887112C-0.21334 2.18637 -0.470428 4.46812 0.824546 6.064C2.11952 7.65989 4.50739 8.00404 6.24221 6.84484L8.41748 8.91167C8.55396 9.0325 8.76664 9.02894 8.89855 8.9036C9.03045 8.77827 9.03421 8.5762 8.90703 8.44652V8.43555ZM0.752563 3.78657C0.752563 2.0901 2.19998 0.714844 3.98545 0.714844C5.77093 0.714844 7.21834 2.0901 7.21834 3.78657C7.21834 5.48304 5.77093 6.8583 3.98545 6.8583C2.19998 6.8583 0.752563 5.48304 0.752563 3.78657Z"
        fill="#9D9D9D"
      />
    </svg>
  );
};

export default SearchIcon;
