import React from "react";

const NotificationIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.0195312 18.9194C0.159531 18.3794 0.519531 18.0094 0.909531 17.6394C2.13953 16.4794 2.85953 15.0494 2.96953 13.3694C3.03953 12.2894 3.00953 11.1994 3.01953 10.1194C3.03953 7.33943 4.24953 5.23943 6.65953 3.86943C7.16953 3.57943 7.74953 3.40943 8.30953 3.22943C8.74953 3.07943 9.17953 3.33943 9.27953 3.74943C9.37953 4.18943 9.12953 4.55943 8.65953 4.68943C7.25953 5.04943 6.16953 5.82943 5.37953 7.03943C4.80953 7.92943 4.52953 8.90943 4.51953 9.95943C4.50953 10.8994 4.51953 11.8494 4.51953 12.7894C4.48953 15.2594 3.53953 17.2994 1.72953 18.9594C1.63953 19.0394 1.54953 19.1894 1.55953 19.2994C1.57953 19.4794 1.75953 19.4794 1.91953 19.4794C7.31953 19.4794 12.7095 19.4794 18.1095 19.4794C18.2295 19.4794 18.3995 19.4194 18.4595 19.3294C18.5695 19.1794 18.4195 19.0694 18.2995 18.9694C17.4795 18.2394 16.8195 17.3894 16.3395 16.3994C16.1295 15.9594 16.2495 15.5194 16.6395 15.3194C17.0395 15.1194 17.4595 15.2794 17.6795 15.7294C18.0995 16.5794 18.6695 17.3094 19.3895 17.9294C19.9495 18.4094 20.1295 19.1794 19.8695 19.8594C19.6095 20.5394 18.9795 20.9794 18.2395 20.9794C16.7895 20.9794 15.3395 20.9794 13.8895 20.9794C13.7695 20.9794 13.6795 20.9694 13.6295 21.1294C13.1795 22.7094 12.1495 23.6394 10.5295 23.9394C10.4995 23.9394 10.4695 23.9594 10.4495 23.9794H9.50953C9.50953 23.9794 9.43953 23.9394 9.40953 23.9394C8.88953 23.8594 8.39953 23.6694 7.95953 23.3794C7.08953 22.8094 6.54953 22.0094 6.31953 20.9894C6.22953 20.9894 6.14953 20.9894 6.05953 20.9894C4.61953 20.9894 3.16953 20.9894 1.72953 20.9894C1.16953 20.9894 0.709531 20.7594 0.389531 20.3094C0.229531 20.0794 0.139531 19.7994 0.0195312 19.5394C0.0195312 19.3394 0.0195312 19.1294 0.0195312 18.9294V18.9194ZM12.0795 20.9894H7.91953C8.12953 21.7994 9.06953 22.4694 9.98953 22.4694C10.9195 22.4694 11.8595 21.8094 12.0795 20.9894Z" fill="white" />
      <path d="M17.4993 12.9996C13.9393 13.0196 11.0293 10.1196 10.9993 6.52963C10.9693 2.98963 13.8793 0.0396348 17.4093 0.0196348C21.0293 -0.000365211 23.9593 2.87963 23.9793 6.43963C23.9893 10.0696 21.1193 12.9796 17.4993 12.9996ZM17.4893 11.4896C20.2393 11.4896 22.4693 9.25963 22.4793 6.51963C22.4793 3.77963 20.2293 1.51963 17.4893 1.51963C14.7493 1.51963 12.5093 3.74963 12.5093 6.49963C12.5093 9.25963 14.7293 11.4896 17.4893 11.4996V11.4896Z" fill="white" />
      <path d="M16.99 5.50914C16.79 5.50914 16.62 5.50914 16.46 5.50914C16.04 5.47914 15.75 5.16914 15.75 4.75914C15.75 4.35914 16.05 4.02914 16.46 4.00914C16.9 3.98914 17.35 3.98914 17.79 4.00914C18.19 4.02914 18.49 4.34914 18.5 4.74914C18.5 6.08914 18.51 7.42914 18.5 8.76914C18.5 9.18914 18.15 9.50914 17.75 9.50914C17.34 9.50914 17 9.17914 17 8.71914C17 7.74914 17 6.78914 17 5.81914C17 5.72914 17 5.63914 17 5.50914H16.99Z" fill="white" />
    </svg>
  );
};

export default NotificationIcon;