import { createSlice } from "@reduxjs/toolkit";
import {
  getChatDetails,
  getChats,
  sendMessage,
  createThreadId,
  getCatalogDetail,
  getPropertyDetail,
  deleteChat,
} from "../actions/chatActions";

const initialState = {
  status: "idle",
  error: "",
  chatsList: [],
  chatDetails: [],
  catalogDetailForView: [],
  catalogDetaiGettingStatus: false,
  propertyDetailForView: [],
  propertyDetaiGettingStatus: false,
  threadCreated: false,
  chatDeleted: false,
};

const chatSlice = createSlice({
  name: "chatSlice",
  initialState,
  reducers: {
    resetState: (state) => {
      state.status = "";
      state.error = "";
      // state.chatsList = [];
      // state.chatDetails = [];
    },
    resetError: (state) => {
      state.status = "idle";
      state.error = "";
    },
    resetChatState: (state) => {
      state.chatsList = [];
      state.chatDetails = [];
      state.catalogDetailForView = [];
      state.propertyDetailForView = [];
      state.chatDeleted = false;
      state.threadCreated = false;
    },
    resetLoadedChatDetails: (state) => {
      state.chatDetails = [];
    },
    resetCatalogDetails: (state) => {
      state.catalogDetailForView = [];
    },
    resetCatalogDetailsGettingStatus: (state) => {
      state.catalogDetaiGettingStatus = false;
    },
    resetPropertyDetails: (state) => {
      state.propertyDetailForView = [];
    },
    resetPropertyDetailsGettingStatus: (state) => {
      state.propertyDetaiGettingStatus = false;
    },
    resetChatDeleteStatus: (state) => {
      state.chatDeleted = false;
    },
    resetThreadCreatedStatus: (state) => {
      state.threadCreated = false;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getChats.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getChats.fulfilled, (state, action) => {
        if (action.payload.status === true) {
          state.status = "succeeded";
          state.chatsList = action.payload.data;
          // console.log("getChats: ", action.payload.data)
        } else {
          state.status = "failed";
          state.error = action.payload.message;
          state.chatsList = null;
        }
      })
      .addCase(getChats.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.chatsList = null;
      })
      .addCase(getChatDetails.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getChatDetails.fulfilled, (state, action) => {
        if (action.payload.status === true) {
          state.status = "succeeded";
          state.chatDetails = action.payload.data;
          // console.log("getChatDetails: ", action.payload.data)
        } else {
          state.status = "failed";
          state.error = action.payload.message;
          state.chatDetails = null;
        }
      })
      .addCase(getChatDetails.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.chatDetails = null;
      })
      .addCase(sendMessage.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(sendMessage.fulfilled, (state, action) => {
        if (action.payload.status === true) {
          state.status = "succeeded";
          state.chatDetails = action.payload.data;
          // console.log("send");
        } else {
          state.status = "failed";
          state.error = action.payload.message;
          // console.log("not send");
        }
      })
      .addCase(sendMessage.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        console.log("not send");
      })
      .addCase(createThreadId.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(createThreadId.fulfilled, (state, action) => {
        if (action.payload.status === true) {
          state.status = "succeeded";
          state.threadCreated = true;
        } else {
          state.status = "failed";
          state.error = action.payload.message;
        }
      })
      .addCase(createThreadId.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(getCatalogDetail.pending, (state, action) => {
        state.status = "loading";
        state.catalogDetaiGettingStatus = true;
      })
      .addCase(getCatalogDetail.fulfilled, (state, action) => {
        if (action.payload.status === true) {
          state.status = "succeeded";
          state.catalogDetailForView = action.payload.data;
          // console.log("catalogDetailForView: ", action.payload.data);
        } else {
          state.status = "failed";
          state.error = action.payload.message;
          state.catalogDetailForView = null;
        }
        state.catalogDetaiGettingStatus = false;
      })
      .addCase(getCatalogDetail.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.catalogDetailForView = null;
        state.catalogDetaiGettingStatus = false;
      })
      .addCase(getPropertyDetail.pending, (state, action) => {
        state.status = "loading";
        state.propertyDetaiGettingStatus = true;
      })
      .addCase(getPropertyDetail.fulfilled, (state, action) => {
        if (action.payload.status === true) {
          state.status = "succeeded";
          state.propertyDetailForView = action.payload.data;
          // console.log("propertyDetailForView: ", action.payload.data);
          // console.log("succeded");
        } else {
          state.status = "failed";
          state.error = action.payload.message;
          state.propertyDetailForView = null;
        }
        state.propertyDetaiGettingStatus = false;
      })
      .addCase(getPropertyDetail.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.propertyDetailForView = null;
        state.propertyDetaiGettingStatus = false;
      })
      .addCase(deleteChat.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(deleteChat.fulfilled, (state, action) => {
        if (action.payload.status === true) {
          state.status = "succeeded";
          state.chatDeleted = true;
        } else {
          state.status = "failed";
          state.error = action.payload.message;
        }
      })
      .addCase(deleteChat.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const {
  resetState,
  resetError,
  resetLoadedChatDetails,
  resetCatalogDetails,
  resetCatalogDetailsGettingStatus,
  resetPropertyDetails,
  resetPropertyDetailsGettingStatus,
  resetChatState,
  resetChatDeleteStatus,
  resetThreadCreatedStatus,
} = chatSlice.actions;

export default chatSlice.reducer;
