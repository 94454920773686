import React from "react";
require("../popupScreenStyle.css");

// Login page Popup component
export function LoginEventPopup({ showPopupMsg, onClose }) {
  return (
    <div className="popupScreen">
      <div className="popup">
        <p>{showPopupMsg}</p>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
}

// Registration page Popup component
export function RegistrationSuccessPopup({ onClose }) {
  return (
    <div className="popupScreen">
      <div className="popup">
        <p>
          Registration successful. You can now login..
          <br />
          Wait: Going to Login Page
        </p>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
}

export function RegistrationEventPopup({ showPopupMsg, onClose }) {
  return (
    <div className="popupScreen">
      <div className="popup">
        <p>{showPopupMsg}</p>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
}

// PasswordReset page Popup component
export function PasswordResetSuccessPopup({ onClose }) {
  return (
    <div className="popupScreen">
      <div className="popup">
        <p>
          Password reset successful. You can now login with your new password.
          <br />
          Wait: Going to Login Page.
        </p>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
}

export function PasswordResetFailedPopup({ showPopupMsg, onClose }) {
  return (
    <div className="popupScreen">
      <div className="popup">
        <p>{showPopupMsg}</p>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
}
