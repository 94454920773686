import React, { useState, useEffect } from "react";
import NotificationItem from "./NotificationItem";
import XIcon from "../../../assets/svgs/XIcon";
import ClockIcon from "../../../assets/svgs/ClockIcon";
import { getAppNotifications } from "../../../api/actions/notificationActions";
import { resetState } from "../../../api/reducerSlices/notificationSlice";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "../../../utils/LoadingSpinner";
require("./HomeCSS/incomingNotifications.css");

const IncomingNotifications = () => {
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const { status, error, appNotifications } = useSelector(
    (state) => state.notificationSlice
  );
  const [isClearnoti, setIsClearnoti] = useState(false);

  useEffect(() => {
    if (!isClearnoti && appNotifications !== null) {
      dispatch(resetState());
      setIsClearnoti(true);
    }
    dispatch(getAppNotifications());
  }, []);

  useEffect(() => {
    if (isClearnoti && appNotifications !== null) {
      setNotifications(appNotifications);
    } else if (error) {
      // alert(error);
      dispatch(resetState());
    }
  }, [status, error, appNotifications]);

  const handleFullNotificationContent = (notification) => {
    setSelectedNotification(notification);
    setIsModalOpen(true);
  };

  useEffect(() => {
    // console.log(selectedNotification);
  }, [selectedNotification]);

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedNotification(null);
  };

  return (
    <>
      {status === "loading" && <LoadingSpinner />}
      <div className="incoming-notifications">
        <span className="responsiveDisplayN">Notifications</span>
        {notifications && notifications?.length > 0 ? (
          <ul>
            {notifications.map((notification) => (
              <NotificationItem
                key={notification._id}
                notification={notification}
                onViewFullContent={handleFullNotificationContent}
              />
            ))}
          </ul>
        ) : (
          <p
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              padding: "15px 0",
              backgroundColor: "#ffffff",
            }}
          >
            {notifications && notifications
              ? "Loading..."
              : "No notifications yet."}
          </p>
        )}

        {/* Show notification content */}
        {isModalOpen && selectedNotification !== null && (
          <div className="modal">
            <div className="modal-content">
              <button className="close-button" onClick={closeModal}>
                <XIcon />
              </button>
              <div
                className="notification"
                key={selectedNotification?.data?.from?._id}
              >
                <div className="firstLine">
                  {/* <div className="profile-info">
                    {console.log(selectedNotification)}
                    {selectedNotification?.data?.form?.image &&
                    selectedNotification?.data?.form?.image !== null ? (
                      <img
                        src={selectedNotification?.data?.form?.image}
                        alt="Profile"
                        className="profile-pic"
                      />
                    ) : (
                      <div className="initials">
                        {selectedNotification?.data?.form?.name
                          ?.charAt(0)
                          .toUpperCase()}
                      </div>
                    )}
                  </div> */}
                  {selectedNotification?.type === "userNotification" && (
                    <>
                      {selectedNotification?.data?.from?.image ? (
                        <div className="profile-pic">
                          <img
                            src={selectedNotification?.data?.from?.image}
                            alt=""
                          />
                        </div>
                      ) : (
                        <div className="initials">
                          {selectedNotification?.data?.from?.name
                            ?.charAt(0)
                            ?.toUpperCase()}
                        </div>
                      )}
                    </>
                  )}
                  {selectedNotification?.type === "contactAdded" && (
                    <>
                      {selectedNotification?.data?.form?.image ? (
                        <div className="profile-pic">
                          <img
                            src={selectedNotification?.data?.form?.image}
                            alt=""
                          />
                        </div>
                      ) : (
                        <div className="initials">
                          {selectedNotification?.data?.form?.name
                            ?.charAt(0)
                            ?.toUpperCase()}
                        </div>
                      )}
                    </>
                  )}
                  {selectedNotification?.type === "propertyReminder" && (
                    <>
                      <div className="noti-image">
                        <ClockIcon />
                      </div>
                    </>
                  )}
                  <div className="name">
                    {selectedNotification?.type === "userNotification" && (
                      <>
                        {selectedNotification?.data?.from?.name && (
                          <>{selectedNotification?.data?.from?.name}</>
                        )}
                      </>
                    )}
                    {selectedNotification?.type === "contactAdded" && (
                      <>
                        {selectedNotification?.data?.form?.name && (
                          <>{selectedNotification?.data?.form?.name}</>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div className="body">{selectedNotification?.message}</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default IncomingNotifications;
