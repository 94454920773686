import React from "react";
import { convertISOToLocalFormatV2 } from "../../utils/CommonUtils";
import PersonIcon from "../../assets/svgs/PersonIcon";
import FileIcon from "../../assets/iconImages/DefaultForFile.png";
import { Document, Page, pdfjs } from "react-pdf";
require("./ChatsCSS/chatMessage.css");

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ChatMessage = ({
  key,
  sender,
  message,
  updatedAt,
  sendingImage,
  messageOwnerimage,
  data,
  type,
  openSharedComponent,
  handleTileClick,
}) => {
  const checkIfImage = (url) => {
    const imageExtensions = /\.(jpg|jpeg|png|gif|bmp|avif|webp)$/i;
    return imageExtensions.test(url);
  };

  const getFileType = (url) => {
    const extension = url.toLowerCase().split(".").pop();
    if (extension === "pdf") {
      return "pdf";
    } else if (extension === "doc" || extension === "docx") {
      return "word";
    } else {
      return "unknown";
    }
  };

  return (
    <div
      className={`chatUserMsgBox ${
        sender === "me" ? "sendUserMsgBox" : "receivedUserMsgBox"
      }`}
    >
      <div className="firstLine">
        <div className="userMsgPic">
          {messageOwnerimage ? (
            <img src={messageOwnerimage} alt="" />
          ) : (
            <div className="initials">
              <PersonIcon />
            </div>
          )}
        </div>
        <div
          key={key}
          className={
            (sender === "me" ? "sent " : "received ") +
            (sendingImage && !message ? "msgStyleSet2 " : "") +
            ((sendingImage && message) ||
            (data &&
              data !== null &&
              data?.sections?.[0] !== "DESCRIPTION" &&
              data?.sections?.[0] !== "Description" &&
              data?.sections?.[0] !== "description" &&
              message)
              ? "msgStyleSet1 "
              : "") +
            ((sendingImage || (data && data !== null)) &&
              data?.sections?.[0] !== "DESCRIPTION" &&
              data?.sections?.[0] !== "Description" &&
              data?.sections?.[0] !== "description" &&
              "msgStyleSet3 ")
          }
          style={{
            cursor:
              (type === "property" &&
                data?.sections?.[0] !== "DESCRIPTION" &&
                type === "property" &&
                data?.sections?.[0] !== "Description" &&
                type === "property" &&
                data?.sections?.[0] !== "description") ||
              type === "catalog"
                ? "pointer"
                : null,
          }}
          onClick={() =>
            (type === "property" &&
              data?.sections?.[0] !== "DESCRIPTION" &&
              type === "property" &&
              data?.sections?.[0] !== "Description" &&
              type === "property" &&
              data?.sections?.[0] !== "description") ||
            type === "catalog"
              ? openSharedComponent(
                  type,
                  (type === "property" && data?.propertyId) ||
                    (type === "catalog" && data?.catalogId),
                  type === "property" && data?.sections
                )
              : null
          }
        >
          {(sendingImage || (data && data !== null)) &&
            data?.sections?.[0] !== "DESCRIPTION" &&
            data?.sections?.[0] !== "Description" &&
            data?.sections?.[0] !== "description" && (
              <div
                className="imageSend"
                onClick={() =>
                  type !== "property" && type !== "catalog"
                    ? handleTileClick(sendingImage)
                    : null
                }
              >
                {sendingImage && <img src={sendingImage} alt="" />}
                {data && data !== null && (
                  //   <img
                  //     src={
                  //       // data?.image === "Description" ||
                  //       // data?.sections?.[0] === "DESCRIPTION"
                  //       //   ? FIleIcon
                  //       //   :
                  //       data?.image
                  //     }
                  //     alt=""
                  // />
                  <>
                    {getFileType(data?.image) === "pdf" && (
                      <>
                        <Document file={data?.image}>
                          <Page pageNumber={1} />
                        </Document>
                      </>
                    )}
                    {checkIfImage(data?.image) && (
                      <img src={data.image} alt="Image" />
                    )}
                    {getFileType(data?.image) !== "pdf" &&
                      !checkIfImage(data?.image) && (
                        <img src={FileIcon} alt="File" />
                      )}
                  </>
                )}
              </div>
            )}
          {data?.sections?.[0] === "DESCRIPTION" ||
          data?.sections?.[0] === "Description" ||
          data?.sections?.[0] === "description" ? (
            <pre>{data?.image}</pre>
          ) : (
            message && <pre>{message}</pre>
          )}
        </div>
      </div>
      <label>{convertISOToLocalFormatV2(updatedAt)}</label>
    </div>
  );
};
export default ChatMessage;
