import React from "react";

const ForwardIcon = () => {
  return (
    <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.36389 9.00008C6.03348 8.60779 6.14802 7.68679 5.59491 7.14244L0.715362 2.34021C0.321147 1.95224 0.318613 1.31736 0.709718 0.926253C1.09862 0.537347 1.72917 0.537347 2.11807 0.926253L8.32767 7.13585C8.80497 7.61315 8.80497 8.387 8.32767 8.8643L2.12089 15.0711C1.73043 15.4615 1.09736 15.4615 0.706895 15.0711C0.316429 14.6806 0.316429 14.0475 0.706895 13.6571L5.36389 9.00008Z" fill="#4668A8" />
    </svg>
  );
};

export default ForwardIcon;
