import React, { useEffect, useState, useRef } from "react";
import ChatMessage from "./ChatMessage";
import CataLogSharedView from "./CataLogSharedView";
import PropertySharedView from "./PropertySharedView";
import MoveBackIcon from "../../assets/svgs/LeftArrowIcon";
import { useDispatch, useSelector } from "react-redux";
import { getChatDetails, sendMessage } from "../../api/actions/chatActions";
import LoadingSpinner from "../../utils/LoadingSpinner";
import SendMessageButtonIcon from "../../assets/svgs/SendMessageButtonIcon";
import PlusSignIcon from "../../assets/svgs/PlusSignIcon";
import XIcon from "../../assets/svgs/XIcon";
import PersonIcon from "../../assets/svgs/PersonIcon";
import { isNull } from "lodash";
require("./ChatsCSS/chatRoom.css");

const ChatRoom = ({
  selectedContact,
  onBack,
  setCatalogView,
  setPropertyView,
  setGetSharedViewId,
  setProopertySections,
  fromContact,
  threadIdForContact,
  handleTileClick,
}) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { status, error, chatDetails } = useSelector(
    (state) => state.chatSlice
  );

  const [message, setMessage] = useState("");
  const [chatPersonImsge, setChatPersonImsge] = useState(null);
  const [chatMessages, setChatMessages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const textareaRef = useRef(null);
  const chatRoomRef = useRef(null);
  const [showLoading, setShowLoading] = useState(true);
  const [getData, setGetData] = useState(3000);
  const [userImage, setUserImage] = useState(false);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if ((fromContact === true && threadIdForContact !== null) ||
      (!fromContact && selectedContact?._id)) {
      dispatch(getChatDetails(fromContact ? threadIdForContact : selectedContact?._id));
    }
  }, [selectedContact, fromContact, threadIdForContact, getData, dispatch]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoading(false);
      setGetData(1000);
    }, getData);

    return () => clearTimeout(timer);
  }, [getData]);

  useEffect(() => {
    if (chatDetails && Array.isArray(chatDetails)) {
      setChatMessages([...chatDetails]);
      scrollToBottom();
    }
  }, [chatDetails]);

  useEffect(() => {
    scrollToBottom();
  }, [chatMessages]);

  const scrollToBottom = () => {
    if (chatRoomRef.current) {
      chatRoomRef.current.scrollTop = chatRoomRef.current.scrollHeight;
    }
  };

  const moveBackToChatsPage = () => {
    localStorage.setItem("tupoa", false);
    setChatMessages([]);
    onBack();
  };

  const handlemsg = (event) => {
    setMessage(event.target.value);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };

  const removeImage = () => {
    setSelectedImage(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const setMessageOwnerDetails = (msg) => {
    if (msg?.from?._id === user?._id) {
      return "me";
    } else {
      return "notMe";
    }
  };

  const sendMessageFunc = () => {
    if (message === "" && selectedImage === null) {
      return;
    }
    const newMessage = {
      chatId:
        fromContact === true && threadIdForContact !== null
          ? threadIdForContact
          : selectedContact?._id,
      message: message,
      files: selectedImage,
    };

    dispatch(sendMessage(newMessage));
    setMessage("");
    removeImage();
    autosize({ target: textareaRef.current });
  };

  const ImageRendering = ({ children }) => {
    let image =
      children?.from?._id === user?._id
        ? children?.to?.image
        : children?.from?.image;
    let image2 = children?.image;
    let name =
      children?.from?._id === user?._id
        ? children?.to?.name?.charAt(0).toUpperCase()
        : children?.from?.name?.charAt(0).toUpperCase();

    if (image !== "" && image !== null && image !== undefined) {
      setChatPersonImsge(image);
    } else if (image2 !== "" && image2 !== null && image2 !== undefined) {
      setChatPersonImsge(image2);
    } else if (
      (image === "" || image === null || image === undefined) &&
      (image2 === "" || image2 === null || image2 === undefined)
    ) {
      setChatPersonImsge("");
    }
    return (
      <>
        {image !== "" && typeof image === "string" && (
          <img src={image} alt="Profile" />
        )}
        {image2 !== "" && typeof image2 === "string" && (
          <img src={image2} alt="Profile" />
        )}
        {!image && !image2 && (
          <div className="initials">
            <PersonIcon />
          </div>
        )}
      </>
    );
  };

  const ImageRendering2 = (user) => {
    return user?.image;
  };

  const fileInputRef = useRef(null);
  const [height, setHeight] = useState(0);

  const autosize = (event) => {
    setTimeout(function () {
      event.target.style.cssText = "height:0px";
      const newHeight = Math.min(20 * 5, event.target.scrollHeight);
      setHeight(newHeight);
      event.target.style.cssText = `height:${newHeight}px`;
    }, 0);
  };

  const openSharedView = (sharedType, typeId, sections) => {
    if (sharedType === "property") {
      setPropertyView(true);
      setProopertySections(sections);
    } else if (sharedType === "catalog") {
      setCatalogView(true);
    }
    setGetSharedViewId(typeId);
  };

  return (
    <>
      <div className="chatRoom">
        <div className="chatContentBoxHeader">
          <div className="moveback" onClick={moveBackToChatsPage}>
            <MoveBackIcon />
          </div>
          <div className="profile-pic">
            <ImageRendering>{selectedContact}</ImageRendering>
          </div>
          <span>
            {selectedContact ? (
              <>
                {selectedContact.from ? (
                  <>
                    {selectedContact?.from?._id === user?._id
                      ? (selectedContact.to?.name || "...").slice(0, 24) +
                      (selectedContact.to?.name?.length > 24 ? "..." : "")
                      : (selectedContact.from?.name || "...").slice(0, 24) +
                      (selectedContact.from?.name?.length > 24 ? "..." : "")}
                  </>
                ) : (
                  (selectedContact.name || "...").slice(0, 24) +
                  (selectedContact.name?.length > 24 ? "..." : "")
                )}
              </>
            ) : (
              "..."
            )}
          </span>
        </div>
        <div className="chat-messages" ref={chatRoomRef}>
          {status === "loading" && showLoading === true ? (
            <LoadingSpinner />
          ) : (
            chatMessages?.map((msg, index) => (
              <ChatMessage
                key={index}
                sender={setMessageOwnerDetails(msg)}
                message={msg.message}
                updatedAt={msg.updatedAt}
                sendingImage={msg.downloadHref}
                messageOwnerimage={ImageRendering2(msg?.from)}
                data={msg.data}
                userImage={userImage}
                type={msg?.type}
                openSharedComponent={openSharedView}
                handleTileClick={handleTileClick}
              />
            ))
          )}
          <div />
        </div>
        <div className="message-input">
          {selectedImage !== null ? (
            <div className="imageMsgSection">
              <div className="removeImage" onClick={() => removeImage()}>
                <XIcon />
              </div>
              <img src={URL.createObjectURL(selectedImage)} alt="image" />
            </div>
          ) : (
            ""
          )}
          <div className="secondLine">
            <div
              className="chatPLusBox"
              onClick={() => fileInputRef.current.click()}
            >
              <PlusSignIcon />
              <input
                ref={fileInputRef}
                type="file"
                id="imageInput"
                accept="image/*"
                onChange={handleImageChange}
                style={{ display: "none" }}
              />
            </div>
            <textarea
              ref={textareaRef}
              onKeyDown={autosize}
              placeholder="Message..."
              value={message}
              onChange={handlemsg}
            />
            <div className="sendMsgButton" onClick={sendMessageFunc}>
              <SendMessageButtonIcon />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatRoom;
