import { createSlice } from "@reduxjs/toolkit";
import {
  getAppContacts,
  getUsersLocations,
  saveContact,
  updateContact,
  deleteContact,
  createIssue,
  createContactUs,
} from "../actions/homeActions";

const initialState = {
  status: "idle",
  error: "",
  data: [],
  appContacts: [],
  contactAdded: false,
  contactUpdated: false,
  contactDeleted: false,
  issueCreated: false,
  contactReasonCreated: false,
};

const homeSlice = createSlice({
  name: "homeSlice",
  initialState,
  reducers: {
    resetState: (state) => {
      state.status = "";
      state.error = "";
      state.contactAdded = false;
      state.contactUpdated = false;
      state.contactDeleted = false;
      state.issueCreated = false;
      state.contactReasonCreated = false;
    },
    resetStateOnLogout: (state) => {
      state.data = [];
      state.appContacts = [];
    },
    // resetState: () => initialState
  },
  extraReducers(builder) {
    builder
      .addCase(getUsersLocations.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getUsersLocations.fulfilled, (state, action) => {
        if (action.payload.status === true) {
          if (action.payload.data.length > 0) {
            state.status = "succeeded";
            state.data = action.payload.data;
            // console.log("getUsersLocations: ", action.payload.data)
          } else {
            state.authenticated = false;
            state.status = "failed";
            state.error = "No records found";
            state.data = null;
          }
        } else {
          state.status = "failed";
          state.error = action.payload.message;
          state.data = null;
        }
      })
      .addCase(getUsersLocations.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.data = null;
      })
      .addCase(getAppContacts.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getAppContacts.fulfilled, (state, action) => {
        if (action.payload.status === true) {
          if (action.payload.data.length > 0) {
            state.status = "succeeded";
            state.appContacts = action.payload.data;
            // console.log("getAppContacts: ", action.payload.data)
          } else {
            state.authenticated = false;
            state.status = "failed";
            state.error = "No records found";
            state.appContacts = null;
          }
        } else {
          state.status = "failed";
          state.error = action.payload.message;
          state.appContacts = null;
        }
      })
      .addCase(getAppContacts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.appContacts = null;
      })
      .addCase(saveContact.pending, (state, action) => {
        state.status = "loading";
        state.contactAdded = false;
      })
      .addCase(saveContact.fulfilled, (state, action) => {
        if (action.payload.status === true) {
          state.status = "succeeded";
          state.contactAdded = true;
        } else {
          state.status = "failed";
          state.error = action.payload.message;
          state.contactAdded = false;
        }
      })
      .addCase(saveContact.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.contactAdded = false;
      })
      .addCase(updateContact.pending, (state, action) => {
        state.status = "loading";
        state.contactUpdated = false;
      })
      .addCase(updateContact.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.contactUpdated = true;
      })
      .addCase(updateContact.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.contactUpdated = false;
      })
      .addCase(deleteContact.pending, (state, action) => {
        state.status = "loading";
        // state.contactDeleted = false;
      })
      .addCase(deleteContact.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.contactDeleted = true;
      })
      .addCase(deleteContact.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        // state.contactDeleted = false;
      })
      .addCase(createIssue.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(createIssue.fulfilled, (state, action) => {
        if (action.payload.status === true) {
          state.status = "succeeded";
          state.issueCreated = true;
        } else {
          state.status = "failed";
          state.error = action.payload.message;
          state.issueCreated = false;
        }
      })
      .addCase(createIssue.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.issueCreated = false;
      })
      .addCase(createContactUs.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(createContactUs.fulfilled, (state, action) => {
        if (action.payload.status === true) {
          state.status = "succeeded";
          state.contactReasonCreated = true;
        } else {
          state.status = "failed";
          state.error = action.payload.message;
          state.contactReasonCreated = false;
        }
      })
      .addCase(createContactUs.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.contactReasonCreated = false;
      });
  },
});

export const { resetState, resetStateOnLogout } = homeSlice.actions;

export default homeSlice.reducer;
