import React from "react";

const FacebookIcon = () => {
  return (
    <svg
      width="6"
      height="10"
      viewBox="0 0 6 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.52011 10C1.52011 9.95742 1.51515 9.91483 1.51515 9.87476C1.51515 8.44197 1.51515 7.01169 1.51515 5.57891C1.51515 5.53633 1.51515 5.49625 1.51515 5.44114H0V3.66269H1.51515C1.51515 3.61259 1.51515 3.57502 1.51515 3.53745C1.51515 3.07154 1.50523 2.60563 1.52507 2.14223C1.54739 1.58114 1.71105 1.06765 2.10286 0.649334C2.40044 0.331216 2.77241 0.150866 3.19149 0.0606906C3.53122 -0.0119505 3.87591 -0.0044359 4.2206 0.00808843C4.50082 0.0181079 4.77855 0.0431566 5.05877 0.0631955C5.08109 0.0631955 5.10341 0.0707101 5.1282 0.0757198V1.6613C5.09597 1.6613 5.06125 1.6613 5.02653 1.6613C4.68184 1.66631 4.33715 1.6613 3.99246 1.67883C3.58578 1.70138 3.35516 1.92932 3.34028 2.34262C3.32292 2.77096 3.33532 3.20179 3.33284 3.63013C3.33284 3.63513 3.3378 3.64265 3.34276 3.65768H5.06869C4.99182 4.25634 4.91742 4.83998 4.84303 5.43613H3.3378C3.33532 5.47371 3.33036 5.50126 3.33036 5.52881C3.33036 6.99667 3.33036 8.46452 3.33036 9.93237C3.33036 9.95491 3.33284 9.97746 3.33532 10H1.51763H1.52011Z"
        fill="white"
      />
    </svg>
  );
};

export default FacebookIcon;
